import React from 'react';

import './blinking-plus.icon.scss';

import {
    circle_plus,
    circle_plus_blinking,
} from '../../../../assets/image.assets';

const BlinkingPlusIcon = ({ blinking, _onClick = () => {} }) => {
    return (
        <div className={'blinking-plus'} onClick={_onClick}>
            {blinking && <div className="wave wave-1" />}
            {blinking && <div className="wave wave-2" />}
            <img
                src={blinking ? circle_plus_blinking : circle_plus}
                alt="plus"
                className={'plus-img'}
            />
        </div>
    );
};

export default BlinkingPlusIcon;
