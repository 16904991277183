import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl/lib';

import './start-battle.scss';

import { openCloseStartBattle } from '../../redux/team-game/team-game.slice';
import { roomInfo_req } from '../../api/team.game/team.game.api';
import { battles } from '../../assets/image.assets';
import TeamButton from '../../components/team-game/buttons/team-button/team-button';
import StandardLabel from '../../components/team-game/labels/standard-label/standard-label';
import SecondaryLabel from '../../components/team-game/labels/secondary-label/secondary-label';
import BattleItem from './battle-item/battle-item';
import StandardFillBar from '../../components/team-game/bars/standard-fill-bar/standard-fill-bar';
import socketBridge from '../../socket';
import CloseButtonTeam from '../../components/team-game/buttons/close-button-team/close-button-team';
import {
    UserChannelEmittingEvents,
    UserChannelHandlingEvents,
} from '../../socket/socket.events';
import roomSingleton from '../../singletons/room.singleton';
import { closePopup, openPopups } from '../../redux/popup.slice';
import { navigate } from '../../redux/global.slice';
import routesEnum from '../../enums/routes.enum';
import PopupsEnum from '../../enums/popups.enum';

const StartBattle = () => {
    const intl = useIntl();
    const [battleItems, setBattleItems] = useState([]);
    const [animated, setAnimated] = useState(false);
    const dispatch = useDispatch();
    const me = useSelector((state) => state.me.data);
    const openCloseStartBattleStart = useSelector(
        (state) => state.teamGame.startBattleOpened
    );
    const configs = useSelector((state) => state.teamGame.configs);
    const currentUserInfo = useSelector(
        (state) => state.teamGame.currentUserInfo
    );
    const battleInfo = useSelector((state) => state.teamGame.battleInfo);

    useEffect(() => {
        if (!openCloseStartBattleStart) {
            return;
        }
        const getRoomInfo = async () => {
            const response = await roomInfo_req();

            if (response && response.length) {
                setBattleItems(response);
            }
        };

        getRoomInfo();

        setAnimated(true);
    }, [openCloseStartBattleStart]);
    const _lobbyItemClick = (data) => {
        socketBridge.emitToUserChannel({
            event: UserChannelEmittingEvents.FIND_ROOM,
            roomType: data.name,
            userId: me.userId,
            partnerId: null,
            battle: data.battle,
        });
    };
    useEffect(() => {
        function watcher({ event, ...args }) {
            switch (event) {
                case UserChannelHandlingEvents.ALLOW_PLAY:
                    roomSingleton.roomType = args.roomType;

                    if (args.allowPlay) {
                        dispatch(closePopup());
                        dispatch(navigate(routesEnum.GAMEPLAY));
                    } else {
                        if (!args.allowPlay && args.message === 1) {
                            dispatch(
                                openPopups({
                                    popupIds: [PopupsEnum.NOT_ENOUGH_RED],
                                })
                            );
                        }
                        // switch (args.message) {
                        //   case AllowPlayMessages.
                        // }
                    }
                    break;
                default:
                    break;
            }
        }

        socketBridge.watchUserChannel(watcher);

        return () => {
            socketBridge.unwatchUserChannel(watcher);
        };
    }, [dispatch]);
    if (!openCloseStartBattleStart) {
        return <></>;
    }

    const closeBattle = () => {
        setAnimated(false);

        setTimeout(() => {
            dispatch(openCloseStartBattle(false));
        }, 300);
    };

    return (
        <div className={'start-battle'}>
            <TeamButton
                text={intl.messages.team_game.play}
                _onClick={() => setAnimated(true)}
            />
            <div
                className={
                    'circle-animating-wrapper' + (animated ? ' animated' : '')
                }
            >
                <div className="start-battle-inner">
                    <div className="top-rect" />
                    <div className="top-bar">
                        <div className="left-text">
                            <StandardLabel
                                text={intl.messages.team_game.start_battle}
                            />
                            <SecondaryLabel
                                text={
                                    intl.messages.team_game
                                        .choose_the_battle_type
                                }
                            />
                        </div>
                        <div className="right-info">
                            <div className="battle-info">
                                <img src={battles} alt="battles" />
                                <div className="battle-data">
                                    <StandardLabel
                                        text={intl.messages.team_game.battle}
                                        fontSize={15}
                                    />
                                    <StandardFillBar
                                        maxPoints={configs.maxGames}
                                        points={battleInfo.games}
                                        fromColor={'#AD2125'}
                                        toColor={'#871B24'}
                                    />
                                </div>
                            </div>
                            <div className="close-wrapper">
                                <CloseButtonTeam _onClick={closeBattle} />
                            </div>
                        </div>
                    </div>
                    <div className="battle-items">
                        {battleItems.map((item) => {
                            return (
                                <BattleItem
                                    key={item.roomType}
                                    item={item}
                                    lockedLevel={
                                        item.minLevel <
                                        (currentUserInfo || {}).level
                                            ? item.minLevel
                                            : 0
                                    }
                                    _onClick={() => {
                                        closeBattle();
                                        _lobbyItemClick({
                                            name: item.roomType,
                                            battle: true,
                                        });
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StartBattle;
