import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import './divided.scss';
import './more-info-section.scss';

import editTeamEnum from '../../../../enums/team-game/editTeam.enum';
import {
    editTeam_req,
    leave_req,
    teamInfo_req,
} from '../../../../api/team.game/team.game.api';
import MoreInfoStatistics from './sections/more-info-statistics';
import MoreInfoConditions from './sections/more-info-conditions';
import StandardLabel from '../../../../components/team-game/labels/standard-label/standard-label';
import SecondaryLabel from '../../../../components/team-game/labels/secondary-label/secondary-label';
import {
    calendar_icon,
    player_icon,
    user_hex,
} from '../../../../assets/image.assets';
import UniversalShield from '../../../../components/team-game/shields/universalShield/universal.shield';
import EditIcon from '../../../../components/team-game/icons/edit/edit.icon';
import GradientLineInfo from '../../../../components/team-game/info/gradient-line/gradient-line-info';
import FloatingTabChanger from '../../../../components/team-game/tab-changers/floating-tab-changer/floating-tab-changer';
import CloseButton from '../../../../components/buttons/close-button/close-button';
import {
    leftShowState,
    rightShowState,
} from '../../../../helpers/team-game/divided-view.helper';
import CloseButtonTeam from '../../../../components/team-game/buttons/close-button-team/close-button-team';
import routesEnum from '../../../../enums/routes.enum';
import { navigate } from '../../../../redux/global.slice';
import ChooseTeamLogoSection from '../../choose-team-logo/choose-team-logo-section';
import {
    battleChange,
    battleInfoState,
    getCreateTeamConfigs,
    teamInfoState,
    teamStatistics,
} from '../../../../redux/team-game/team-game.slice';
import userSingleton from '../../../../singletons/user.singleton';
import PopupsEnum from '../../../../enums/popups.enum';
import { openPopups } from '../../../../redux/popup.slice';

const renderTabContent = (
    currentTab,
    createTeamConfigs,
    teamInfo,
    teamStats,
    leaveTeam,
    getTeamInfoSilent
) => {
    switch (currentTab) {
        case 0:
            return (
                <MoreInfoStatistics
                    teamInfo={teamInfo}
                    leaveTeam={leaveTeam}
                    teamStats={teamStats}
                />
            );
        case 1:
            return (
                <MoreInfoConditions
                    createTeamConfigs={createTeamConfigs}
                    teamInfo={teamInfo}
                    leaveTeam={leaveTeam}
                    getTeamInfoSilent={getTeamInfoSilent}
                />
            );
    }
};

const MoreInfoSection = ({
    pageData,
    teamStats = {},
    getTeamInfoSilent = () => {},
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [showState, setShowState] = useState(0);
    const [currentTab, setCurrentTab] = useState(0);
    const teamInfo = useSelector((state) => state.teamGame.teamInfo);

    const configs = useSelector((state) => state.teamGame.configs);
    const memberList = useSelector((state) => state.teamGame.memberList);
    const navigateData = useSelector((state) => state.global.navigateData);

    const createTeamConfigs = useSelector(
        (state) => state.teamGame.createTeamConfigs
    );
    const members = memberList ? Object.values(memberList) : [];

    useEffect(() => {
        setShowState(1);
    }, []);

    const goChooseTeamLogoSection = () => {
        dispatch(
            navigate({
                route: routesEnum.TEAM_LOGO_SECTION,
                navigateData: {
                    pageData: pageData,
                    teamName: teamInfo.name,
                    chosenIcons: (teamInfo.logo || []).map((data) => {
                        return {
                            icon: data,
                            url: configs.iconBaseUrl + 'icon-' + data + '.svg',
                        };
                    }),
                    data: true,
                },
            })
        );
    };
    const teamData = async () => {
        const teamInfoData = await teamInfo_req();

        dispatch(battleChange(teamInfoData.configs));
        dispatch(teamInfoState(teamInfoData.teamData));
        dispatch(battleInfoState(teamInfoData.battleInfo));
        dispatch(teamStatistics(teamInfoData.teamStats));
        dispatch(getCreateTeamConfigs(teamInfoData.teamCreateConfigs));
    };
    useEffect(() => {
        const chosenIcons = navigateData && navigateData.chosenIcons;
        const _saveAction = async () => {
            try {
                const response = await editTeam_req(
                    editTeamEnum.logo,
                    chosenIcons.map((data) => data.icon)
                );

                if (response && response.success) {
                    teamData();
                }
            } catch (e) {}
        };
        _saveAction();
    }, [navigateData]);
    // useEffect(() => {
    //     teamData();
    // }, []);
    const _goToDashboard = () => {
        setShowState(0);

        setTimeout(() => {
            dispatch(navigate(routesEnum.TEAM_DASHBOARD));
        }, 400);
    };

    const leaveTeam = async () => {
        try {
            dispatch(
                openPopups({
                    popupIds: [PopupsEnum.LEAVE_POPUP],
                    popupData: [
                        {
                            title: intl.messages.team_game.leave_the_team,
                            text: intl.formatMessage(
                                {
                                    id: 'do_you_really_want_to_leave_team',
                                },
                                {
                                    team: teamInfo.name,
                                }
                            ),
                            positiveText: intl.messages.team_game.yes,
                            negativeText: intl.messages.team_game_no,
                            _positiveAction: async () => {
                                const response = await leave_req();
                                if (response && response.success) {
                                    navigate('/join-team');
                                }
                            },
                        },
                    ],
                })
            );
        } catch (e) {}
    };

    const _saveAction = async (chosenIcons) => {
        try {
            const response = await editTeam_req(
                editTeamEnum.logo,
                chosenIcons.map((data) => data.icon)
            );

            if (response && response.success) {
                getTeamInfoSilent();

                dispatch(navigate(routesEnum.MORE_INFO_SECTION));
            }
        } catch (e) {}
    };
    const teamName = userSingleton.teamName;

    return (
        <div className={'divided-view-content more-info-section'}>
            <div className="left-side-wrapper">
                <div className={'left-side' + leftShowState(showState)}>
                    <div className="top-text-info">
                        <div className="left-text-info">
                            <StandardLabel
                                text={teamName ? teamName : teamInfo.name}
                            />
                            <SecondaryLabel
                                text={'ID: ' + teamInfo ? teamInfo.teamId : ''}
                            />
                        </div>
                        <div className="right-text-info">
                            <div className="with-icon">
                                <img
                                    src={calendar_icon}
                                    alt="calendar"
                                    className={'calendar-icon'}
                                />
                                <StandardLabel
                                    text={teamInfo.creationDate}
                                    fontSize={14}
                                />
                            </div>
                            <SecondaryLabel
                                text={intl.messages.team_game.creation_date}
                                fontSize={12}
                            />
                        </div>
                    </div>

                    <div className="info-shield">
                        <UniversalShield
                            text={teamName ? teamName : teamInfo.name}
                            shield={configs.logoBaseUrl + teamInfo.logoUrl}
                            disableOnError
                        />
                        {teamInfo.leader && (
                            <EditIcon _onClick={goChooseTeamLogoSection} />
                        )}
                        {/* {teamInfo.leader && (
                            <EditIcon
                                _onClick={() =>
                                    changePage(2, {
                                        teamName: teamInfo.name,
                                        chosenIcons: (teamInfo.logo || []).map(
                                            (data) => {
                                                return {
                                                    icon: data,
                                                    url:
                                                        configs.iconBaseUrl +
                                                        'icon-' +
                                                        data +
                                                        '.svg',
                                                };
                                            }
                                        ),
                                        _saveAction,
                                    })
                                }
                            />
                        )} */}
                    </div>
                    <div className="gradient-lines">
                        <GradientLineInfo
                            leftSide={{
                                image: user_hex,
                            }}
                            rightSide={[
                                {
                                    key: 'player-count',
                                    top: {
                                        icon: player_icon,
                                        value: members.length,
                                    },
                                    bottomValue:
                                        intl.messages.team_game.players,
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
            <div className="right-side-wrapper with-shield">
                <div className={'right-side' + rightShowState(showState)}>
                    <div className="top-tabs">
                        <div className="top-tabs-floating">
                            <FloatingTabChanger
                                tabs={[
                                    intl.messages.team_game.statistics,
                                    intl.messages.team_game.conditions,
                                ]}
                                currentTab={currentTab}
                                setCurrentTab={setCurrentTab}
                            />
                        </div>
                        <div className="top-tabs-close">
                            <CloseButtonTeam _onClick={_goToDashboard} />
                        </div>
                    </div>
                    <div className="more-info-content">
                        {renderTabContent(
                            currentTab,
                            createTeamConfigs,
                            teamInfo,
                            teamStats,
                            leaveTeam,
                            getTeamInfoSilent
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        // createTeamConfigs: state.teamGame.createTeamConfigs,
        // configs: state.teamGame.configs,
        // teamInfo: state.teamGame.teamInfo,
        // teamStats: state.teamGame.teamStats,
        // memberList: state.teamGame.memberList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // navigate: (routeData) => dispatch(routerActions.navigate(routeData)),
        // getTeamInfoSilent: () => dispatch(teamGameActions.getTeamInfoSilent()),
        // openPopups: (popupData) => dispatch(popupActions.openPopups(popupData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MoreInfoSection);
