import React, { useState, useEffect } from 'react';
import './team-dashboard.scss';
import ChatSection from '../../../sections/team-game/chat/chat-section';
import TeamInfoSection from '../../../sections/team-game/teamInfo/team-info-section';
import TeamNotifications from '../../../global-components/team-notifications/team-notifications';
import TopPanel from '../../../components/team-game/panels/top-panel/top-panel';
import { useDispatch } from 'react-redux';
import {
    getBattleDetails_req,
    teamList_req,
} from '../../../api/team.game/team.game.api';
import { memberListState } from '../../../redux/team-game/team-game.slice';
const TeamDashboard = () => {
    const [listTeam, setListTeam] = useState([]);
    const [battleDetails, setBattleDetails] = useState([]);
    const dispatch = useDispatch();
    const teamList = async () => {
        const teamListData = await teamList_req();
        dispatch(memberListState(teamListData.teamUsers));
        setListTeam(teamListData);
    };
    const getBattleDetails = async () => {
        const getBattleDetailsData = await getBattleDetails_req();
        setBattleDetails(getBattleDetailsData);
    };
    useEffect(() => {
        teamList();
        getBattleDetails();
    }, []);

    if (listTeam.length === 0) {
        return null;
    }
    return (
        <div id={'team-dashboard'}>
            <TopPanel battleDetails={battleDetails} />
            <div className="content">
                <div className="left-side">
                    <ChatSection memberList={listTeam} />
                </div>
                <div className="right-side">
                    <TeamInfoSection
                        listTeam={listTeam}
                        battleDetails={battleDetails}
                        getMemberList={teamList}
                    />
                </div>
            </div>
            {/* <TeamNotifications
                position={'left'}
                setNotificationExists={setNotificationExists}
                player={player}
                updateMembers={getMemberList}
                getTeamInfo={getTeamInfo}
                teamInfo={teamInfo}
                fromRoute={'/dashboard'}
                battleChange={battleChange}
            /> */}
        </div>
    );
};

export default TeamDashboard;
