import PIXI from '../../wrappers/pixi.wrapper';

import wheelEnums from './wheel.enums';

import pixiLoader from '../../loaders/pixi.loader';

class WheelSegment extends PIXI.Container {
    constructor(i, text, type, isFirst, isLast, app) {
        super();
        this.app = app;
        this.create(i, text, type, isFirst, isLast);
        this.pivot.x = 0;
        this.pivot.y = this.height / 2;
    }

    static createBgImageForSector(j, text) {
        let imgId = '';
        // eslint-disable-next-line no-restricted-globals
        if (text.slice(0, 1) === 'x') {
            imgId = `sector4`;
        } else {
            imgId = `sector${j % 2 === 0 ? '0' : '1'}`; //
        }

        return imgId;
    }

    create(i, text) {
        this.sprite = new PIXI.Sprite(
            pixiLoader.getImage(
                `images/wheel/${WheelSegment.createBgImageForSector(
                    i,
                    text
                )}.png`
            ).texture
        );
        this.sprite.angle = 90;
        this.sprite.y = 10;
        this.addChild(this.sprite);
        this.text = new PIXI.Text(
            text,
            new PIXI.TextStyle({
                fill: i % 2 === 0 ? '#BE1622' : '#FFFFFF',
                fontFamily: 'Roboto-Slab',
                fontSize: 35,
            })
        );
        this.text.x = -this.sprite.width / 1.25; //-140
        this.text.y = this.sprite.height / 3 + 2; //65
        this.text.angle = 0;
        this.addChild(this.text);

        const _to = () => {
            const scale = this.scale.x;
            if (this.scale.x < 1) {
                this.pivot.y = this.height / 2;
                this.text.x = this.width / 2;
                this.text.y = this.height / 2 - this.text.height / 2 - 4;
                this.scale.set(scale + 0.1);
            } else {
                this.scale.set(1);
                this.app.ticker.remove(_to);
            }
        };
        this.app.ticker.add(_to);
    }
}

export default WheelSegment;
