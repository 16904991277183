import { axiosInstance } from './config';

export const getUser_req = async () => {
    const uid = window.location.search.replace('?user_id=', '');

    const response = await axiosInstance.post('/dev/auth', {
        uid,
        zone: 'fb',
        newLvlupRequired: true,
    });

    // Don't ask why :D
    if (response.data === 'Invalid params') {
        throw new Error('Please provide userId in the URL');
    }

    return response.data;
};

export const userInfo_req = async (userId) => {
    const response = await axiosInstance.post('/userUpdates', {
        userId: userId,
    });
    return response.data;
};
export const levelUp_req = async (userId) => {
    const response = await axiosInstance.post('/checkLevelUp', {
        userId: userId,
    });
    return response.data;
};
export const checkNotification_req = async (userId) => {
    const response = await axiosInstance.get(
        `/checkNotifications?userId=${userId}`
    );
    return response.data;
};
