import React from 'react';

import './badge-image.scss';

const BadgeImage = ({ badgeRef, image, index, item, standardBadgeAnimate }) => {
    return (
        <img
            src={image}
            className={`badge-image${
                item && item.level === index && standardBadgeAnimate
                    ? ' badge-animate'
                    : ''
            }`}
            ref={badgeRef}
            alt="#"
        />
    );
};
export default BadgeImage;
