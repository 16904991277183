import React from 'react';
import './language.page.scss';
import { useDispatch, useSelector } from 'react-redux';
import { changeLocale, navigate } from '../../redux/global.slice';
import routesEnum from '../../enums/routes.enum';
import { setCookie } from '../../helpers/cookie.helpers';
import localeEnum from '../../enums/locale.enum';
import { bazar_blot_logo } from '../../assets/image.assets';

const LanguagePage = () => {
  const dispatch = useDispatch();
  const selectLanguage = (lang) => {
    dispatch(changeLocale(lang));
  };
  const locale = useSelector((state) => state.global.locale);

  setCookie('locale', locale, 30);
  const goHomePage = () => {
    dispatch(navigate(routesEnum.LOBBY));
  };
  return (
    <div id="language-page" className="page">
      <div className="language_wrapper">
        <img
          src={bazar_blot_logo}
          alt="candy wings logo"
          className="logo_language"
        />
        <div className="language_block" onClick={() => goHomePage()}>
          <div
            className="language_ru"
            onClick={() => selectLanguage(localeEnum.RU)}
          >
            РУС
          </div>
          <div
            className="language_en"
            onClick={() => selectLanguage(localeEnum.EN)}
          >
            ENG
          </div>
          <div
            className="language_hy"
            onClick={() => selectLanguage(localeEnum.AM)}
          >
            ՀԱՅ
          </div>
        </div>
      </div>
      <div className="language_bg_border"></div>
      <div className="language_bg"></div>
    </div>
  );
};
export default LanguagePage;
