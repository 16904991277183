import React from 'react';
import { useIntl } from 'react-intl';
import { tour_time_member } from '../../../assets/image.assets';
import Timer from '../../../components/timer/timer';
import './tournament-join-timer.scss';

const TournamentJoinTimer = ({ endDate }) => {
    const intl = useIntl();
    return (
        <div className="tour-game-time">
            <div className="tour-game-joined">
                <img src={tour_time_member} alt="#" />
                <span>{intl.messages.tournament.joined}</span>
            </div>
            <div className="tour-game-joined-time">
                <div className="tour-game-time-item">
                    <div className="time-item">
                        <div className="tour-time-item">
                            <Timer endDate={endDate} />
                        </div>
                    </div>
                    <div className="tour-time-text-block">
                        <p>{intl.messages.tournament.days}</p>
                        <p>{intl.messages.tournament.hours}</p>
                        <p className="tour-time-text-min">
                            {intl.messages.tournament.min}
                        </p>
                        <p className="tour-time-text-sec">
                            {intl.messages.tournament.sec}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TournamentJoinTimer;
