import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import './team-info.listItem.scss';

import {
    cup_icon,
    level_arrow,
    player_icon,
} from '../../../../assets/image.assets';
import joinTeamEnum from '../../../../enums/team-game/joinTeam.enum';
import _3dButton from '../../buttons/3d-button/3d-button';
import StandardLabel from '../../labels/standard-label/standard-label';
import SecondaryLabel from '../../labels/secondary-label/secondary-label';
import { joinTeam_req } from '../../../../api/team.game/team.game.api';
import PopupsEnum from '../../../../enums/popups.enum';
import { joinErrorCodes } from '../../../../enums/team-game/errors.enum';
import { useDispatch } from 'react-redux';
import { openPopups } from '../../../../redux/popup.slice';

const getConditionData = (type, intl) => {
    switch (type) {
        case 'level':
            return {
                icon: level_arrow,
                text: intl.formatMessage({ id: 'level' }),
            };
        case 'winRate':
            return {
                icon: cup_icon,
                text: intl.formatMessage({ id: 'win_rate' }),
            };
        default:
            return {
                icon: level_arrow,
                text: 'N/A',
            };
    }
};

const getDataByState = (state, intl) => {
    switch (state) {
        case joinTeamEnum.JOIN:
            return {
                text: intl.formatMessage({ id: 'join' }),
                buttonType: 1,
            };
        case joinTeamEnum.ACCEPT:
            return {
                text: intl.formatMessage({ id: 'accept' }),
                buttonType: 1,
            };
        case joinTeamEnum.WAITING:
            return {
                text: intl.formatMessage({ id: 'waiting' }),
                buttonType: 2,
            };
    }
};

const TeamInfoListItem = ({
    team,
    joinTeamAction,
    acceptTeamAction,
    configs,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [teamList, setTeamList] = useState([]);
    const dataByState = getDataByState(team.state, intl) || {};

    const actionByType = () => {
        if (
            team.state === joinTeamEnum.JOIN &&
            typeof joinTeamAction === 'function'
        ) {
            joinTeamAction(team);
        }
        if (
            team.state === joinTeamEnum.ACCEPT &&
            typeof acceptTeamAction === 'function'
        ) {
            acceptTeamAction(team);
        }
    };

    const joinTeam = async () => {
        const requestId = team.teamId;

        try {
            const joined = await joinTeam_req(requestId);
            if (joined && joined.success) {
                const _teamList = [...teamList];
                _teamList.forEach((teamData) => {
                    if (teamData.teamId === requestId) {
                        teamData.state = 2;
                    }
                });
                setTeamList(_teamList);
            }
        } catch (e) {
            if (e && e.response && e.response.data) {
                const { errorCode } = e.response.data;

                if (
                    errorCode === joinErrorCodes.MAX_MEMBERS_REACHED ||
                    errorCode === joinErrorCodes.CONDITIONS_NOT_MET
                ) {
                    dispatch(
                        openPopups({
                            popupIds: [PopupsEnum.JOIN_TEAM_POPUP],
                            popupData: [e.response.data],
                        })
                    );
                } else if (
                    errorCode === joinErrorCodes.MAX_REQUEST_LIMIT_REACHED
                ) {
                    dispatch(
                        openPopups({
                            popupIds: [PopupsEnum.REQUEST_LIMIT],
                            popupData: {
                                errorCode,
                            },
                        })
                    );
                }
            }
        }
    };

    return (
        <div className={'team-info-list-item'}>
            <div className="side left-side">
                <div className="shield-wrapper">
                    <img
                        src={configs && configs.logoBaseUrl + team.logoUrl}
                        // src={WoodShieldImg}
                        alt="shield"
                    />
                </div>
                <div className="info-section">
                    <div className="top-info">
                        <StandardLabel text={team.name} fontSize={15} />
                        <SecondaryLabel
                            text={'ID: ' + team.teamId}
                            fontSize={12}
                        />
                    </div>
                    <div className="bottom-info">
                        <div className={'bottom-info-inner'}>
                            <div className="users-count">
                                <img src={player_icon} alt="player" />
                                <StandardLabel
                                    text={team.usersCount}
                                    fontSize={15}
                                />
                            </div>
                            <SecondaryLabel
                                text={<FormattedMessage id={'players'} />}
                                fontSize={12}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="side right-side">
                {team.joinConditions && team.joinConditions.length && (
                    <div className="join-info-wrapper">
                        <StandardLabel
                            text={<FormattedMessage id={'join_conditions'} />}
                            fontSize={15}
                        />

                        <div className="join-info">
                            {team.joinConditions.map((condition) => {
                                const conditionData = getConditionData(
                                    condition.param,
                                    intl
                                );
                                return (
                                    <div
                                        className="more-info"
                                        key={
                                            condition.value + conditionData.text
                                        }
                                    >
                                        <div className="join-info-top">
                                            <img
                                                src={conditionData.icon}
                                                alt="condition icon"
                                            />
                                            <StandardLabel
                                                text={
                                                    condition.value +
                                                    (condition.param ===
                                                    'winRate'
                                                        ? '%'
                                                        : '')
                                                }
                                                fontSize={15}
                                            />
                                        </div>
                                        <div className="join-info-bottom">
                                            <SecondaryLabel
                                                text={conditionData.text}
                                                fontSize={12}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
                <div className="button-with-condition">
                    {team.state === joinTeamEnum.LEAVE ||
                    team.state === joinTeamEnum.KICK ||
                    team.state === joinTeamEnum.REJECT ||
                    team.state === joinTeamEnum.MAX_MEMBERS_REACHED ? (
                        <div className={'unavailable-text'}>
                            <StandardLabel
                                text={
                                    <FormattedMessage id={'team_unavailable'} />
                                }
                                fontSize={17}
                            />
                            <SecondaryLabel
                                text={(() => {
                                    if (team.state === joinTeamEnum.LEAVE) {
                                        return (
                                            <FormattedMessage
                                                id={'you_have_left_this_team'}
                                            />
                                        );
                                    } else if (
                                        team.state === joinTeamEnum.KICK
                                    ) {
                                        return (
                                            <FormattedMessage
                                                id={'you_have_been_fired'}
                                            />
                                        );
                                    } else if (
                                        team.state ===
                                        joinTeamEnum.MAX_MEMBERS_REACHED
                                    ) {
                                        return (
                                            <FormattedMessage
                                                id={
                                                    'unavailable_max_number_of_members'
                                                }
                                            />
                                        );
                                    } else {
                                        return (
                                            <FormattedMessage
                                                id={'unavailable_rejected'}
                                            />
                                        );
                                    }
                                })()}
                                fontSize={13}
                            />
                        </div>
                    ) : (
                        <div className="join-team" onClick={joinTeam}>
                            <_3dButton
                                text={dataByState.text}
                                type={dataByState.buttonType}
                                _onClick={() => actionByType()}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default React.memo(TeamInfoListItem);
