const createTeamEnum = {
    user: 1,
    name: 2,
    logo: 3,
    joinConditions: 4,
};

Object.freeze(createTeamEnum);

export default createTeamEnum;
