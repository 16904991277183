import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
    battles,
    star_icon,
    star_with_back,
    swords_icon,
} from '../../../../../assets/image.assets';
import _3dButton from '../../../../../components/team-game/buttons/3d-button/3d-button';
import SecondaryLabel from '../../../../../components/team-game/labels/secondary-label/secondary-label';
import StandardLabel from '../../../../../components/team-game/labels/standard-label/standard-label';

const MoreInfoStatistics = ({ leaveTeam = () => {} }) => {
    const intl = useIntl();
    const teamStats = useSelector((state) => state.teamGame.teamStatistic);
    const teamInfo = useSelector((state) => state.teamGame.teamInfo);
    return (
        <div className={'statistics-content'}>
            <div className="top-info">
                <p className={'statistics-info'}>
                    {intl.messages.team_game.your_team_statistics_text}
                </p>
            </div>
            <div className="statistic-lines">
                <div className="statistic-line">
                    <img src={battles} alt="" className={'hex-img'} />
                    <div className="battle-data">
                        <StandardLabel
                            text={intl.messages.team_game.battles_and_games}
                            fontSize={15}
                        />
                        <div className="mini-infos">
                            <div className="mini-info">
                                <div className="info-wrapper">
                                    <div className="mini-info-top">
                                        <img
                                            src={swords_icon}
                                            alt="swords"
                                            className={'mini-info-icon'}
                                        />
                                        <StandardLabel
                                            text={' ' + teamStats.battleWins}
                                            fontSize={15}
                                        />
                                    </div>
                                    <SecondaryLabel
                                        text={
                                            intl.messages.team_game.battles_won
                                        }
                                        fontSize={12}
                                    />
                                </div>
                            </div>
                            <div className="mini-info long">
                                <div className="info-wrapper">
                                    <div className="mini-info-top">
                                        <StandardLabel
                                            text={teamStats.totalBattles}
                                            fontSize={15}
                                        />
                                    </div>
                                    <SecondaryLabel
                                        text={intl.messages.team_game.battles}
                                        fontSize={12}
                                    />
                                </div>
                                <div className="info-wrapper">
                                    <div className="mini-info-top">
                                        <StandardLabel
                                            text={teamStats.totalGames}
                                            fontSize={15}
                                        />
                                    </div>
                                    <SecondaryLabel
                                        text={
                                            intl.messages.team_game.count_games
                                        }
                                        fontSize={12}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="statistic-line">
                    <img src={star_with_back} alt="" className={'hex-img'} />
                    <div className="stars-data">
                        <p className={'stars-text'}>
                            {
                                intl.messages.team_game
                                    .stars_gained_during_the_existence
                            }
                        </p>
                        <div className="gained-stars">
                            <div className="top-line">
                                <img
                                    src={star_icon}
                                    alt="star"
                                    className={'top-line-icon'}
                                />
                                <StandardLabel
                                    text={teamStats.totalStars}
                                    fontSize={15}
                                />
                            </div>
                            <SecondaryLabel
                                text={
                                    intl.messages.team_game.count_gained_stars
                                }
                                fontSize={12}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {!teamInfo.leader && (
                <_3dButton
                    type={3}
                    text={intl.messages.team_game.leave}
                    _onClick={leaveTeam}
                />
            )}
        </div>
    );
};

export default MoreInfoStatistics;
