import React from 'react';
import { useIntl } from 'react-intl/lib';

import './fill-your-team-info.scss';

import _3dButton from '../../buttons/3d-button/3d-button';
import StandardLabel from '../../labels/standard-label/standard-label';
import { useDispatch } from 'react-redux';
import PopupsEnum from '../../../../enums/popups.enum';
import { openPopups } from '../../../../redux/popup.slice';

const FillYourTeam = ({ withoutBack }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const firstUsePopup = () => {
        dispatch(
            openPopups({
                popupIds: [PopupsEnum.FIRST_TIME_USE],
            })
        );
    };
    return (
        <div
            className={'fill-your-team' + (withoutBack ? ' without-back' : '')}
        >
            <StandardLabel text={intl.messages.team_game.fill_your_team} />
            <p>{intl.messages.team_game.its_best_to_invite_players}</p>
            <div className="button-wrapper" onClick={firstUsePopup}>
                <_3dButton text={intl.messages.team_game.how} />
            </div>
        </div>
    );
};

// const mapDispatchToProps = (dispatch) => {
//     return {
//         openPopups: (popupData) =>
//             dispatch(popupsActions.openPopups(popupData)),
//     };
// };

export default FillYourTeam;
