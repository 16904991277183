import React from 'react';

import './standard-text-box-input.scss';

const StandardTextbox = ({
    type = 'text',
    value = '',
    _onChange = () => {},
    placeholder = '',
    withError,
}) => {
    return (
        <input
            className={
                'standard-textbox' +
                (value.length > 0 ? ' uppercased' : '') +
                (withError ? ' with-error' : '')
            }
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={_onChange}
        />
    );
};

export default StandardTextbox;
