import React from 'react';

import './main-tab-changer.scss';

const MainTabChanger = ({
    currentTab = 0,
    setCurrentTab = () => {},
    tabs = [],
    fromColor,
    toColor,
    color,
    children,
}) => {
    if (!tabs || !tabs.length) {
        return <></>;
    }

    const width = 100 / tabs.length;

    return (
        <div className={'main-tab-changer'}>
            <div className={'main-tabs-wrapper'}>
                <div
                    className="detached"
                    style={{
                        left: currentTab * width + '%',
                        width: width + '%',
                        background: color
                            ? color
                            : `linear-gradient(180deg, ${fromColor} 0%,${toColor} 100%)`,
                    }}
                />
                {tabs.map((tab, index) => {
                    return (
                        <div
                            key={index}
                            className={'changer-tab'}
                            onClick={() => setCurrentTab(index)}
                            style={{
                                width: width + '%',
                            }}
                        >
                            {tab}
                        </div>
                    );
                })}
            </div>
            <div className="tab-content">{children[currentTab]}</div>
        </div>
    );
};

export default React.memo(MainTabChanger);
