const createErrorCodes = {
    NAME_REGEXP_NOT_MATCHED: 0,
    NAME_IN_USE: 1,
    INVALID_ICON_SELECTED: 2,
    INVALID_JOIN_CONDITIONS: 3,
    INVALID_USER: 4,
    NOT_ENOUGH_RED: 5,
    NOT_ENOUGH_LEVEL: 6,
    USER_IN_TEAM: 7,
};

Object.freeze(createErrorCodes);

const joinErrorCodes = {
    INVALID_DATA: 0,
    ACTIVE_REQUEST: 1,
    ACTIVE_INVITE: 2,
    INVALID_JWT: 3,
    NOT_A_LEADER: 4,
    IN_TEAM: 5,
    WAS_IN_TEAM: 6,
    USER_NOT_FOUND: 7,
    MAX_MEMBERS_REACHED: 8,
    CONDITIONS_NOT_MET: 9,
    FORBIDDEN_FOR_LEADER: 10,
    NOT_IN_TEAM: 11,
    MAX_REQUEST_LIMIT_REACHED: 12,
    INVALID_ICON_SET: 13,
    ICON_SET_ALREADY_PURCHASED: 14,
    NOT_ENOUGH_CURRENCY: 15,
    INVITE_REJECTED_BEFORE: 16,
    TEAM_NOT_FOUND: 17,
    NOT_ENOUGH_LEVEL: 18,
};

Object.freeze(joinErrorCodes);

export { createErrorCodes, joinErrorCodes };
