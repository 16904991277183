import React, { useEffect, useState } from 'react';

import './user.panel.scss';

import LevelBar from '../../bars/level-bar/level.bar';
import ChipInfo from '../../info/chip-info/chip.info';

import { useIntl } from 'react-intl';
import UserPanelAvatar from '../../user/user-panel-avatar/user-panel-avatar';
import { useDispatch, useSelector } from 'react-redux';
import { openPopups } from '../../../redux/popup.slice';
import PopupsEnum from '../../../enums/popups.enum';
import Discount from '../discount/discount';
import { menu, game_play_menu, plus_icon,store } from '../../../assets/image.assets';
import {
    openCloseChat,
    openCloseGift,
    openCloseSmile,
    openGamePlayMenu,
} from '../../../redux/menu-game.slice';
import routesEnum from '../../../enums/routes.enum';
import { navigate } from '../../../redux/global.slice';
import roomSingleton from '../../../singletons/room.singleton';
import { Player } from '@lottiefiles/react-lottie-player';
import shopswitch from '../../../assets/shopswitch.json';
import { setChip } from '../../../redux/chip.info.slice';
import { userInfo_req } from '../../../api/user.api';
import { setUserUpdate } from '../../../redux/user.update.slice';
import { setCurrentUserInfo } from '../../../redux/userinfo.slice';
import {tournamentColor} from "../../../redux/theme.change.slice";
import themeEnum from "../../../enums/theme.enum";

function roomTypeData(type) {
    switch (type) {
        case 'micro':
            return 3000;
        case 'big':
            return 7000;
        case 'professionals':
            return 15000;
        default:
            break;
    }
}

const UserPanel = () => {
    const [discount, setDiscount] = useState(true);
    const [boolChip, setBoolChip] = useState(false);
    const [redChipData, setRedChipData] = useState(-1);
    const dispatch = useDispatch();
    const intl = useIntl();
    const userInfo = useSelector((state) => state.me.data);

    const userUpdate = useSelector((state) => state.userUpdate.data);

    const openedState = useSelector(
        (state) => state.userMenuGame.rightMenu.rightMenuOpenedState
    );

    const userRoomState = useSelector((state) => state.gameConfig.roomId);
    const navigateRoute = useSelector((state) => state.global.route);
    const chipInfo = useSelector((state) => state.chipInfo);

    const userChip = userRoomState ? roomSingleton.roomType : '';
    const userUpdateReq = async () => {
        const userUpdateChip = await userInfo_req(userInfo.userId);
        dispatch(setUserUpdate(userUpdateChip));
    };

    if (!chipInfo || !userUpdate || !userInfo) {
        return null;
    }

    const openRightMenu = () => {
        dispatch(openGamePlayMenu(true));
        dispatch(
            openGamePlayMenu({
                rightMenuOpenedState: openedState === 3 ? 1 : 2,
            })
        );
        dispatch(openCloseGift({ openedState: 3 }));
        dispatch(openCloseSmile({ smileOpenedState: 3 }));
        dispatch(openCloseChat({ chatOpenedState: 3 }));
    };

    const redChipUpdate = () => {
        if (chipInfo && chipInfo.chipUpdate && chipInfo.red) {
            setTimeout(() => {
                // setRedChipData(userUpdate.red + chipInfo.red);
                // userUpdateReq();
                return userUpdate.red;
            }, 1500);
        } else {
            return userInfo.red;
        }
    };

    const goStorePage = (chip) => {
        if (navigateRoute !== 3) {
            if (chip.type === 'yellow') {
                dispatch(navigate({ route: routesEnum.STORE, currentTab: 1 }));
            } else {
                // dispatch(navigate(routesEnum.BADGES));
                // dispatch(
                //     openPopups({
                //         popupIds: [PopupsEnum.LEVEL_UP],
                //     })
                // );
                dispatch(
                    openPopups({
                        popupIds: [PopupsEnum.TOURNAMENT_UNLOCK],
                    })
                );
            }
        }
    };
    const chipInfoData = () => {
        if (chipInfo.chipBoolean) {
            setTimeout(() => {
                return userUpdate.red;
            }, 100);
        }

        return chipInfo.chipBoolean
            ? userUpdate.red + chipInfo.red
            : userInfo.red;
    };
    return (
        <>
            {navigateRoute < 20 ? (
                <div
                    className={`
                ${
                    navigateRoute === 7 ? 'user-panel-tournament' : 'user-panel'
                }`}
                >
                    <div className="left_side">
                        <UserPanelAvatar
                            userLevel={
                                userUpdate ? userUpdate.level : userInfo.level
                            }
                            userInfo={userInfo}
                        />
                        <div className="user_data">
                            <div className="user_name_points">
                                <span
                                    className={`${
                                        userInfo.vip
                                            ? 'nickname-vip'
                                            : 'nickname'
                                    }`}
                                >
                                    {userInfo.fullName}
                                </span>
                            </div>

                            <LevelBar
                                vip={userInfo.vip}
                                percent={
                                    userUpdate
                                        ? (userUpdate.points * 100) /
                                          userUpdate.edge
                                        : (userInfo.points * 100) /
                                          userInfo.edge
                                }
                            />
                            <p className="user_edge">
                                {userUpdate
                                    ? userUpdate.points
                                    : userInfo.points}{' '}
                                / {userUpdate ? userUpdate.edge : userInfo.edge}
                            </p>
                        </div>

                        <div
                            className={`chips_wrapper ${
                                discount ? 'discount_chip' : ''
                            }`}
                        >
                            {userInfo.discountType > 0 &&
                                userInfo.discountTimer > 0 && (
                                    <Discount
                                        discountType={userInfo.discountType}
                                        discountDate={userInfo.discountDate}
                                    />
                                )}

                            <ChipInfo
                                chip={'red'}
                                start={chipInfoData()}
                                end={
                                    userChip
                                        ? userUpdate.red -
                                          roomTypeData(userChip)
                                        : userUpdate.red
                                }
                                imageId={plus_icon}
                                discountTimer={userInfo.discountTimer}
                                _onClick={() => goStorePage({ type: 'red' })}
                            />

                            <ChipInfo
                                chip={'green'}
                                start={userUpdate.green}
                                end={
                                    chipInfo.green === null
                                        ? userInfo.green
                                        : chipInfo.green + userInfo.green
                                }
                                _onClick={() =>{
                                    dispatch(navigate(routesEnum.BADGES));
                                    dispatch(tournamentColor(themeEnum.LOBBY));}
                                }
                            />

                            <ChipInfo
                                chip={'yellow'}
                                start={userUpdate.yellow}
                                end={
                                    chipInfo.yellow === null
                                        ? userInfo.yellow
                                        : chipInfo.yellow + userInfo.yellow
                                }
                                imageId={plus_icon}
                                _onClick={() => goStorePage({ type: 'yellow' })}
                            />
                        </div>
                    </div>

                    {navigateRoute !== 3 &&
                    userInfo.discountType > 0 &&
                    userInfo.discountTimer > 0 ?
                    (
                        <div className="user-panel-animation" onClick={()=>dispatch(navigate({route:routesEnum.STORE,currentTab:3}))}>
                            <Player src={shopswitch} autoplay loop  />
                            {/* <div
                                className="user_panel_whell"
                                onClick={() =>
                                    dispatch(
                                        dispatch(navigate(routesEnum.STORE))
                                    )
                                }
                            >
                                <img src={whell_animation_store} alt="#" />
                            </div>
                            <div
                                className="user_panel_store"
                                onClick={() =>
                                    dispatch(
                                        dispatch(navigate(routesEnum.STORE))
                                    )
                                }
                            >
                                <img src={store} alt="#" />
                            </div> */}
                        </div>
                    ) :
                        (<div
                            className="user_panel_store"
                            onClick={() =>
                                dispatch(dispatch(navigate(routesEnum.STORE)))
                            }
                        >
                            <img src={store} alt="#" />
                        </div>)
                    }

                    {navigateRoute === 3 ? (
                        <div className="burger-menu" onClick={openRightMenu}>
                            <img src={game_play_menu} alt="#" />
                        </div>
                    ) : (
                        <div
                            className="burger-menu"
                            onClick={() =>
                                dispatch(
                                    openPopups({
                                        popupIds: [PopupsEnum.SETTINGS_POPUP],
                                    })
                                )
                            }
                        >
                            <span className="burger-menu-item-first"></span>
                            <span className="burger-menu-item-second"></span>
                            <span className="burger-menu-item-third"></span>
                        </div>
                    )}
                </div>
            ) : null}
        </>
    );
};

export default UserPanel;
