import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl/lib';

import './notification-item.scss';

import _3dButton from '../../../components/team-game/buttons/3d-button/3d-button';
import notificationsEnum from '../../../enums/team-game/notifications.enum';

const NotificationItem = ({
    item,
    _rejectInvite = () => {},
    _acceptInvite = () => {},
    _rejectRequest = () => {},
    _acceptRequest = () => {},
    _saw = () => {},
}) => {
    const messageItem = () => {
        switch (item.type) {
            case notificationsEnum.USER_ACCEPTED_INVITE:
                return (
                    <FormattedMessage
                        id={'user_joined_the_team'}
                        values={{
                            username: item.userName,
                        }}
                    />
                );
            case notificationsEnum.USER_ACCEPTED_JOIN_REQUEST:
                return (
                    <FormattedMessage
                        id={'user_joined_the_team'}
                        values={{
                            username: item.userName,
                        }}
                    />
                );
            case notificationsEnum.USER_LEFT:
                return (
                    <FormattedMessage
                        id={'user_left_the_team'}
                        values={{
                            username: item.userName,
                        }}
                    />
                );
            case notificationsEnum.USER_KICKED:
                return (
                    <FormattedMessage
                        id={'user_left_the_team'}
                        values={{
                            username: item.message,
                        }}
                    />
                );
            case notificationsEnum.INVITATION_FROM_TEAM:
                return (
                    <FormattedMessage
                        id={'invites_you_to_join'}
                        values={{
                            username: item.userName,
                            team: item.teamName,
                        }}
                    />
                );
            case notificationsEnum.REQUEST_TO_JOIN_TEAM:
                return (
                    <FormattedMessage
                        id={'wants_to_join_your_team'}
                        values={{
                            username: item.userName,
                        }}
                    />
                );
        }
    };

    const actionItems = () => {
        if (item.type === notificationsEnum.INVITATION_FROM_TEAM) {
            return (
                <>
                    <_3dButton
                        text={intl.messages.team_game.reject}
                        type={3}
                        _onClick={_rejectInvite}
                    />
                    <_3dButton
                        text={intl.messages.team_game.accept}
                        _onClick={_acceptInvite}
                    />
                </>
            );
        } else if (item.type === notificationsEnum.REQUEST_TO_JOIN_TEAM) {
            return (
                <>
                    <_3dButton
                        text={intl.messages.team_game.reject}
                        type={3}
                        _onClick={_rejectRequest}
                    />
                    <_3dButton
                        text={intl.messages.team_game.accept}
                        _onClick={_acceptRequest}
                    />
                </>
            );
        }

        return <_3dButton text={intl.messages.ok} _onClick={_saw} />;
    };

    const intl = useIntl();
    return (
        <div className={'notification-item'}>
            <span className={'message'}>{messageItem()}</span>
            <div className="actions-wrapper">{actionItems()}</div>
        </div>
    );
};

export default NotificationItem;
