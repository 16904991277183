import React from 'react';

import './standard-fill-bar.scss';

const StandardFillBar = ({
    maxPoints,
    points,
    color,
    fromColor,
    toColor,
    icon,
}) => {
    let percent = 0;

    if (maxPoints && points) {
        percent = (points / maxPoints) * 100;
    }

    return (
        <div className={'standard-fill-bar' + (icon ? ' fill' : '')}>
            <div className={`fill-wrapper${!icon ? ' closed' : ''}`}>
                <div className={'loader-wrapper'}>
                    <div className="loader-inner-wrapper">
                        <div
                            className="loader-inner"
                            style={{
                                width: percent + '%',
                                border: !percent && 'none',
                                background: color
                                    ? color
                                    : `linear-gradient(to right, ${fromColor} 0%,${toColor} 100%)`,
                            }}
                        />
                    </div>
                    <span className={'loader-text'}>
                        {points || 0} / {maxPoints}
                    </span>
                </div>
                {icon && (
                    <div className={'fill-bar-img'}>
                        <img src={icon} alt="icon" className={'fill-icon'} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default StandardFillBar;
