import {
    axiosInstanceTeam,
    axiosInstance,
    axiosInstanceSaturn,
} from '../config';

export const teamInfo_req = async () => {
    const response = await axiosInstanceTeam.post('/user/info');
    return response.data;
};

export const teamList_req = async () => {
    const response = await axiosInstanceTeam.get('/team/members');
    return response.data;
};

export const leaderboard_req = async (type = 'current') => {
    const response = await axiosInstanceTeam.get('/battle/leaderboard/' + type);
    return response.data;
};

export const battleMyTeam_req = async (type = 'current') => {
    const response = await axiosInstanceTeam.get('/battle/myteam/' + type);
    return response.data;
};

export const getChatHistory_req = async (limit, skip) => {
    let reqUrl = '/team/chat/history';

    if (skip && limit) {
        reqUrl += `?skip=${skip}&limit=${limit}`;
    } else if (skip) {
        reqUrl += `?skip=${skip}`;
    } else if (limit) {
        reqUrl += `?limit=${limit}`;
    }

    const response = await axiosInstanceTeam.get(reqUrl);
    return response.data;
};

export const getTeamsList_req = async () => {
    const response = await axiosInstanceTeam.get('/team/list');
    return response.data;
};

export const searchTeam_req = async (searchValue) => {
    const response = await axiosInstanceTeam.post('/team/search', {
        key: searchValue,
    });
    return response.data;
};

export const joinTeam_req = async (teamId) => {
    const response = await axiosInstanceTeam.post('/user/request/', {
        requestTeamId: teamId,
    });
    return response.data;
};

export const iconsList_req = async () => {
    const response = await axiosInstanceTeam.get('/getIcons');
    return response.data;
};

export const getGameConfig_req = async (zone, userId) => {
    const response = await axiosInstance.get(
        `/getGameConfig?zone=${zone}&userId=${userId}&platform=web`
    );
    return response.data;
};

export const reportPlayer_req = async (
    userId,
    reportUserId,
    teammate,
    roomId
) => {
    const response = await axiosInstance.post(`/reportUser`, {
        platform: 'web',
        userId,
        reportUserId,
        teammate,
        roomId,
    });
    return response.data;
};

export const validateCreateTeam_req = async (type, param) => {
    const response = await (process.env.REACT_APP_BUILD_ENV !== 'development'
        ? axiosInstanceTeam
        : axiosInstanceSaturn
    ).post('/team/create/validate/', {
        type,
        param,
    });

    return response.data;
};

export const createTeam_req = async (name, logo, conditions) => {
    const response = await (process.env.REACT_APP_BUILD_ENV !== 'development'
        ? axiosInstanceTeam
        : axiosInstanceSaturn
    ).post('/team/create/submit', {
        name,
        logo,
        conditions,
    });

    return response.data;
};

export const editTeam_req = async (type, param) => {
    const response = await (process.env.REACT_APP_BUILD_ENV !== 'development'
        ? axiosInstanceTeam
        : axiosInstanceSaturn
    ).post('/team/edit', {
        type,
        param,
    });

    return response.data;
};

export const roomInfo_req = async () => {
    const response = await axiosInstanceTeam.get('/user/roomInfo');
    return response.data;
};

export const getUserInvites_req = async () => {
    const response = await axiosInstanceTeam.get('/user/invites');
    return response.data;
};

export const getTeamRequests_req = async () => {
    const response = await axiosInstanceTeam.get('/team/requests');
    return response.data;
};

export const acceptInvite_req = async (inviteId, accept) => {
    const response = await axiosInstanceTeam.post('/user/acceptInvite', {
        inviteId,
        accept,
    });

    return response.data;
};

export const acceptRequest_req = async (requestId, accept) => {
    const response = await axiosInstanceTeam.post('/team/acceptRequest', {
        requestId,
        accept,
    });

    return response.data;
};

export const seenIntro_req = async (type) => {
    const response = await axiosInstanceTeam.post('/user/seenIntro', {
        type,
    });

    return response.data;
};

export const inviteUser_req = async (invitedUserId) => {
    const response = await axiosInstanceTeam.post('/team/invite', {
        invitedUserId,
    });

    return response.data;
};

export const leave_req = async () => {
    const response = await axiosInstanceTeam.post('/user/leave');
    return response.data;
};

export const kick_req = async (memberId) => {
    const response = await axiosInstanceTeam.post('/team/disqualify', {
        memberId,
    });
    return response.data;
};

export const canInvite_req = async (jwt, invitedUserId) => {
    const response = await axiosInstanceTeam.post(
        '/team/canInvite',
        {
            invitedUserId,
        },
        {
            headers: {
                jwt,
            },
        }
    );
    return response.data;
};

export const getNotifications_req = async () => {
    const response = await axiosInstanceTeam.get('/team/notifications');
    return response.data;
};

export const getBattleDetails_req = async () => {
    const response = await axiosInstanceTeam.get('/battle/details');
    return response.data;
};

export const purchaseIconSet_req = async (setId) => {
    const response = await axiosInstanceTeam.post('/user/purchaseIconSet', {
        setId,
    });
    return response.data;
};
