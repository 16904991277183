import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './wheel-page.scss';
import WheelPixi from '../../pixi/wheel/wheel.pixi';
import {
    red,
    wheel_arrow,
    wheel_icon,
    wheel_spin,
    wheel_bottom,
} from '../../assets/image.assets';
import Background from '../../components/background/background';
import { formatNumberWithSpaces } from '../../utils/formatter.util';

// api/wheel/rolled TODO
const WheelPage = () => {
    const wheelRef = useRef();
    const wheelPixiRef = useRef();
    const dispatch = useDispatch();
    const me = useSelector((state) => state.me.data);
    const data = [
        { text: 'x2', type: 'discount' },
        { text: '100', type: 'gold' },
        { text: '120', type: 'gold' },
        { text: '150', type: 'gold' },
        { text: 'x2', type: 'discount' },
        { text: '180', type: 'gold' },
        { text: '200', type: 'gold' },
        { text: '220', type: 'gold' },
        { text: 'x2', type: 'discount' },
        { text: '250', type: 'gold' },
        { text: '280', type: 'gold' },
        { text: '300', type: 'gold' },
    ];

    const [createSector, setCreateSector] = useState(false);

    useEffect(() => {
        if (wheelRef.current) {
            wheelPixiRef.current = new WheelPixi(640, 640, data);

            wheelPixiRef.current.createElipse();
            wheelPixiRef.current.createSegments(data);
            wheelRef.current.appendChild(wheelPixiRef.current.app.view);

            // setTimeout(() => {
            //     wheelPixiRef.current.rotate();
            // }, 100);
        }
    }, [createSector]);

    useEffect(() => {
        setCreateSector(true);
    }, []);

    const handleClickOnBtn = () => {
        setTimeout(() => {
            wheelPixiRef.current.smoothStopRotation();
        }, 600);
    };

    return (
        <div className="wheel-page">
            <Background>
                <div className="daily-bonus">
                    <p>daily bonus</p>
                    <div className="daily-chip">
                        <img src={red} />
                        <span>{formatNumberWithSpaces(me.red)}</span>
                    </div>
                </div>
                <div className="wheel-wrapper">
                    <div className="wheel-bg-layer">
                        <div
                            className="wheel-cracks"
                            onClick={handleClickOnBtn}
                        >
                            <div className="big-elipse" />
                            <div className="wheel-bg">
                                {/* <div className="elipse" /> */}
                                {createSector && (
                                    <>
                                        <div
                                            className="rotating-wrapper"
                                            ref={wheelRef}
                                        />
                                        <img
                                            src={wheel_icon}
                                            alt=""
                                            className="wheel-icon"
                                        />
                                        <img
                                            src={wheel_arrow}
                                            alt="wheel-arrow"
                                            className="wheel-arrow"
                                        />
                                    </>
                                )}
                            </div>
                            <div className="spin-bottom">
                                <img src={wheel_bottom} alt="#" />
                            </div>
                        </div>
                        <div className="spin-img-rotate">
                            <img src={wheel_spin} alt="" />
                        </div>
                        <div className="spin-wrapper">
                            <div className="spin-bg">
                                <div className="spin-text-bg">
                                    <span>spin</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Background>
        </div>
    );
};

export default WheelPage;
