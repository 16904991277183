import React, { useState } from 'react';
import { useIntl } from 'react-intl/lib';
import './right-side.scss';

import {
    battle,
    star,
    empty,
    wood_shield,
    star_icon,
    swords_icon,
    checked,
    rating_image,
} from '../../../assets/image.assets';
import { inviteUser_req } from '../../../api/team.game/team.game.api';
import _3dButton from '../../../components/team-game/buttons/3d-button/3d-button';
import { joinErrorCodes } from '../../../enums/team-game/errors.enum';
import StandardLabel from '../../../components/team-game/labels/standard-label/standard-label';
import SecondaryLabel from '../../../components/team-game/labels/secondary-label/secondary-label';
import GradientLineInfo from '../../../components/team-game/info/gradient-line/gradient-line-info';
import UniversalShield from '../../../components/team-game/shields/universalShield/universal.shield';
import { useDispatch } from 'react-redux';
import { openPopups } from '../../../redux/popup.slice';
import PopupsEnum from '../../../enums/popups.enum';
import ButtonTreeD from '../../../components/buttons/button_3d/button-3d';
import { setCurrentUserInfo } from '../../../redux/userinfo.slice';
import routesEnum from '../../../enums/routes.enum';
import { tournamentColor } from '../../../redux/theme.change.slice';
import themeEnum from '../../../enums/theme.enum';
import { navigate } from '../../../redux/global.slice';

const openTG = (closeUserInfo) => {
    if (typeof window.openTeamGame === 'function') {
        closeUserInfo();
        window.openTeamGame();
    }
};

const NotInTeam = ({
    currentUserInfo,
    canInvite,
    closeUserInfo,
    fromRoute,
}) => {
    const dispatch = useDispatch();
    const [invited, setInvited] = useState(false);

    const invitePlayer = async () => {
        try {
            await inviteUser_req(2);
        } catch (e) {
        } finally {
            setInvited(true);
        }
    };

    const intl = useIntl();

    const actionButton = () => {
        if (fromRoute === 'Lobby') {
            return (
                <_3dButton
                    text={intl.messages.team_game.join}
                    _onClick={() => openTG(closeUserInfo)}
                />
            );
        }

        if (invited) {
            return (
                <_3dButton type={2} text={intl.messages.team_game.waiting} />
            );
        }

        switch (canInvite) {
            case -2:
                return (
                    <_3dButton
                        text={intl.messages.team_game.invite}
                        _onClick={invitePlayer}
                    />
                );
            case joinErrorCodes.INVITE_REJECTED_BEFORE:
                return (
                    <div className={'right-info-text-wrapper'}>
                        <StandardLabel
                            text={intl.messages.team_game.player_unavailable}
                            fontSize={17}
                        />
                        <SecondaryLabel
                            text={'Invite rejected before'}
                            fontSize={13}
                        />
                    </div>
                );
        }
    };

    return (
        <div className={'not-in-team'}>
            <div className="empty-shield-wrapper">
                <img src={wood_shield} alt="empty shield" />
            </div>
            <StandardLabel text={intl.messages.team_game.not_in_team} />
            <p>{intl.messages.team_game.this_player_never_participated}</p>

            <div className="button-wrapper">{actionButton()}</div>
        </div>
    );
};

const InTeam = ({
    setOpened,
    currentUserInfo = {},
    myUserInfo = {},
    canInvite,

    closeUserInfo = () => {},
    fromRoute,
}) => {
    const [invited, setInvited] = useState(false);
    const dispatch = useDispatch();
    const invitePlayer = async () => {
        try {
            await inviteUser_req(currentUserInfo.userId);
        } catch (e) {
        } finally {
            setInvited(true);
        }
    };

    let wasInTeam = false;

    if (!myUserInfo) {
        myUserInfo = {};
    }

    if (
        currentUserInfo.teamInfo &&
        currentUserInfo.teamInfo.wasInTeam &&
        !currentUserInfo.teamInfo.name
    ) {
        wasInTeam = true;
    }

    const myUserTeamInfo = myUserInfo.teamInfo || {};
    const currentUserTeamInfo = currentUserInfo.teamInfo || {};

    let isLeader = false;

    if (currentUserInfo && currentUserInfo.leader) {
        isLeader = true;
    } else if (
        currentUserTeamInfo.leader &&
        currentUserInfo.userId === myUserInfo.userId
    ) {
        isLeader = true;
    } else if (currentUserTeamInfo.leader && fromRoute === 'Scene') {
        isLeader = true;
    }

    const intl = useIntl();
    const openTeamGame = () => {
        setOpened(false);
        setTimeout(() => {
            dispatch(setCurrentUserInfo(null));
        }, 400);
        dispatch(navigate(routesEnum.LEAGUE_INTRODUCTION));
        dispatch(tournamentColor(themeEnum.TEAMGAME));
    };
    const actionButton = () => {
        if (fromRoute === 'Lobby') {
            return (
                <_3dButton
                    text={
                        isLeader
                            ? intl.messages.team_game.visit_my_team
                            : intl.messages.team_game.view_team
                    }
                    _onClick={() => openTG(closeUserInfo)}
                />
            );
        }
        const openFirePopup = () => {
            dispatch(
                openPopups({
                    popupIds: [PopupsEnum.FIRE_PLAYER_POPUP],
                    popupData: [
                        {
                            username: currentUserInfo.username,
                            memberId: currentUserInfo.userId,
                            additionalAction: () => {
                                closeUserInfo();
                            },
                        },
                    ],
                })
            );
        };
        if (
            myUserTeamInfo &&
            myUserTeamInfo.leader &&
            !currentUserInfo.leader &&
            currentUserInfo.userId !== myUserInfo.userId &&
            currentUserTeamInfo.teamId === myUserTeamInfo.teamId &&
            fromRoute !== 'Scene'
        ) {
            return (
                <_3dButton
                    type={3}
                    text={intl.messages.team_game.fire}
                    _onClick={openFirePopup}
                />
            );
        }

        // if (
        //     myUserTeamInfo &&
        //     !myUserTeamInfo.leader &&
        //     myUserInfo.userId &&
        //     myUserInfo.userId === currentUserInfo.userId &&
        //     fromRoute !== 'Scene'
        // ) {
        //     return (
        //         <_3dButton
        //             type={3}
        //             text={intl.messages.leave}
        //             _onClick={async () => {
        //                 try {
        //                     const response = await leave_req();
        //                     if (response && response.success) {
        //                         navigate('/join-team');
        //                     }
        //                 } catch (e) {}
        //             }}
        //         />
        //     );
        // }

        if (invited) {
            return (
                <_3dButton type={2} text={intl.messages.team_game.waiting} />
            );
        }

        switch (canInvite) {
            case -2:
                return (
                    <_3dButton
                        text={intl.messages.team_game.invite}
                        _onClick={invitePlayer}
                    />
                );
            case joinErrorCodes.ACTIVE_REQUEST:
            case joinErrorCodes.ACTIVE_INVITE:
                return (
                    <_3dButton
                        type={2}
                        text={intl.messages.team_game.waiting}
                    />
                );
            case joinErrorCodes.WAS_IN_TEAM:
                return (
                    <div className={'right-info-text-wrapper'}>
                        <StandardLabel
                            text={intl.messages.team_game.player_unavailable}
                            fontSize={17}
                        />
                        <SecondaryLabel
                            text={
                                intl.messages.team_game.unavailable_removed_left
                            }
                            fontSize={13}
                        />
                    </div>
                );
            case joinErrorCodes.NOT_ENOUGH_LEVEL:
                return (
                    <div className={'right-info-text-wrapper'}>
                        <StandardLabel
                            text={intl.messages.team_game.player_unavailable}
                            fontSize={17}
                        />
                        <SecondaryLabel
                            text={
                                intl.messages.team_game
                                    .unavailable_has_not_reached
                            }
                            fontSize={13}
                        />
                    </div>
                );
            case joinErrorCodes.MAX_MEMBERS_REACHED:
                return (
                    <div className={'right-info-text-wrapper'}>
                        <StandardLabel
                            text={intl.messages.team_game.player_unavailable}
                            fontSize={17}
                        />
                        <SecondaryLabel
                            text={
                                intl.messages.unavailable_max_number_of_members
                            }
                            fontSize={13}
                        />
                    </div>
                );
            case joinErrorCodes.INVITE_REJECTED_BEFORE:
                return (
                    <div className={'right-info-text-wrapper'}>
                        <StandardLabel
                            text={intl.messages.team_game.player_unavailable}
                            fontSize={17}
                        />
                        <SecondaryLabel
                            text={intl.messages.team_game.unavailable_rejected}
                            fontSize={13}
                        />
                    </div>
                );
        }
    };

    return (
        <div className={'in-team'}>
            {!wasInTeam && (
                <div className="member-info">
                    <span className={'team-text'}>
                        {intl.messages.team_game.team}
                    </span>
                    <div className="pos-section">
                        <span className={'member-text'}>
                            {isLeader
                                ? intl.messages.team_game.leader
                                : intl.messages.team_game.member}
                        </span>
                        {isLeader && (
                            <img src={checked} alt="leader" width={25} />
                        )}
                    </div>
                </div>
            )}
            <div className="in-team-shield">
                <UniversalShield
                    text={currentUserTeamInfo.name}
                    // shield={
                    //     wasInTeam
                    //         ? empty
                    //         : configs.logoBaseUrl + currentUserTeamInfo.logoUrl
                    // }
                    withRibbon={!wasInTeam}
                />
            </div>
            <div className="right-content">
                <div className="gradient-lines">
                    <div className="gradient-line-first">
                        <GradientLineInfo
                            leftSide={{
                                image: rating_image,
                            }}
                            rightSide={[
                                {
                                    key: 'finished-battles',
                                    top: {
                                        value:
                                            intl.messages.team_game
                                                .professional_status,
                                    },
                                },
                                {
                                    key: 'games',
                                    top: {
                                        value:
                                            currentUserInfo.totalGames ||
                                            currentUserTeamInfo.totalGames ||
                                            0,
                                    },
                                    bottomValue: intl.messages.team_game.rating,
                                },
                            ]}
                        />
                    </div>

                    <GradientLineInfo
                        leftSide={{
                            image: star,
                        }}
                        rightSide={[
                            {
                                key: 'earned-stars',
                                top: {
                                    icon: star_icon,
                                    value:
                                        currentUserInfo.totalStars ||
                                        currentUserTeamInfo.totalStars ||
                                        0,
                                },
                                bottomValue:
                                    intl.messages.team_game.count_gained_stars,
                            },
                        ]}
                    />
                    <GradientLineInfo
                        leftSide={{
                            image: battle,
                        }}
                        rightSide={[
                            {
                                key: 'finished-battles',
                                top: {
                                    icon: swords_icon,
                                    value:
                                        currentUserTeamInfo.totalBattles || 0,
                                },
                                bottomValue:
                                    intl.messages.team_game.finished_battles,
                            },
                            {
                                key: 'games',
                                top: {
                                    value:
                                        currentUserInfo.totalGames ||
                                        currentUserTeamInfo.totalGames ||
                                        0,
                                },
                                bottomValue:
                                    intl.messages.team_game.count_games,
                            },
                        ]}
                    />
                </div>
                {actionButton()}
            </div>
            <div className="visit-button" onClick={openTeamGame}>
                {' '}
                <ButtonTreeD text={intl.messages.visit} />{' '}
            </div>
        </div>
    );
};

const UserInfoRight = ({
    setOpened,
    currentUserInfo = {},

    canInvite,
    myUserInfo,

    closeUserInfo,
    fromRoute,
}) => {
    return (
        <div className={'right-side'}>
            {currentUserInfo.teamInfo &&
            (currentUserInfo.teamInfo.teamId ||
                currentUserInfo.teamInfo.wasInTeam) ? (
                <InTeam
                    setOpened={setOpened}
                    currentUserInfo={currentUserInfo}
                    myUserInfo={myUserInfo}
                    openPopups={openPopups}
                    canInvite={canInvite}
                    closeUserInfo={closeUserInfo}
                    fromRoute={fromRoute}
                />
            ) : (
                <NotInTeam
                    canInvite={canInvite}
                    myUserInfo={myUserInfo}
                    closeUserInfo={closeUserInfo}
                    fromRoute={fromRoute}
                    currentUserInfo={currentUserInfo}
                />
            )}
        </div>
    );
};

export default UserInfoRight;
