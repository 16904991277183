import React from 'react';
import { useIntl } from 'react-intl';

import './leave-popup.scss';

import { useDispatch } from 'react-redux';
import { closePopup } from '../../../redux/popup.slice';
import CloseButtonTeam from '../../../components/team-game/buttons/close-button-team/close-button-team';
import StandardLabel from '../../../components/team-game/labels/standard-label/standard-label';
import _3dButton from '../../../components/team-game/buttons/3d-button/3d-button';
import { navigate } from '../../../redux/global.slice';
import routesEnum from '../../../enums/routes.enum';
import { tournamentColor } from '../../../redux/theme.change.slice';
import themeEnum from '../../../enums/theme.enum';
import { leave_req } from '../../../api/team.game/team.game.api';

const LeavePopup = ({ popupData = {} }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const closePromptPopup = () => {
        dispatch(closePopup());
    };
    const closePopupBtn = async () => {
        const response = await leave_req();
        if (response && response.success) {
            dispatch(closePopup());
            dispatch(navigate(routesEnum.LOBBY));
            dispatch(tournamentColor(themeEnum.LOBBY));
        }
    };
    return (
        <div className={'popup-wrapper leave-popup'}>
            <div className="close-leave">
                <CloseButtonTeam _onClick={closePromptPopup} />
            </div>
            <StandardLabel text={popupData.title || ''} />
            <p className={'info'}>{popupData.text || ''}</p>
            <div className="buttons-wrapper">
                <_3dButton
                    text={popupData.negativeText || intl.messages.team_game.no}
                    type={2}
                    _onClick={closePromptPopup}
                />
                <_3dButton
                    text={popupData.positiveText || intl.messages.team_game.yes}
                    type={3}
                    _onClick={closePopupBtn}
                />
            </div>
        </div>
    );
};

export default LeavePopup;
