import React from 'react';
import {
    boy,
    clubs_icon,
    diamonds_icon,
    hearts_icon,
    spade_icon,
} from '../../../assets/image.assets';
import './user-order.scss';

const UserOrder = ({ contractValue, contractSuit, isCapout, seat }) => {
    const suit = {
        0: hearts_icon,
        1: diamonds_icon,
        2: spade_icon,
        3: clubs_icon,
        4: boy,
    };

    const userContractSuit = suit[`${contractSuit}`];
    return (
        <div
            className={`${
                isCapout ? 'user-order-capout' : 'user-order'
            } user-order-${seat}`}
        >
            <div className="user-order-value">
                {contractSuit !== undefined && (
                    <img src={userContractSuit} alt="#" />
                )}
            </div>
            {contractValue && <span>{contractValue}</span>}
            {isCapout && <span className="capout-value">CP</span>}
        </div>
    );
};

export default UserOrder;
