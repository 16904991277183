import React, {useState, useEffect} from 'react';
import './user-info.scss';
import {
    main_user_info_back,
    cards,
    edit,
    user_info_tournament, user_avatar,
} from '../../assets/image.assets';
import {useIntl} from 'react-intl/lib';

import {useDispatch, useSelector} from 'react-redux';
import {openPopups} from '../../redux/popup.slice';
import PopupsEnum from '../../enums/popups.enum';
import {setCurrentUserInfo} from '../../redux/userinfo.slice';
import SecondaryLabel from '../../components/team-game/labels/secondary-label/secondary-label';
import PieChart from '../../components/charts/pie-chart/pie-chart';
import UserInfoRight from './right-side/right-side';
import {navigate} from '../../redux/global.slice';
import routesEnum from '../../enums/routes.enum';
import {tournamentColor} from '../../redux/theme.change.slice';
import themeEnum from '../../enums/theme.enum';
import StandardSlider from '../../components/sliders/standard-slider/standard-slider';
import _3dButton from "../../components/team-game/buttons/3d-button/3d-button";

const normalizeUserBadgesForSlides = (badges, route, closeUserInfo) => {
    const slides = [];

    const _onClick = () => {
        if (route === 2 && window.showBadges) {
            window.showBadges({
                window: 'achievements',
                status: true,
            });
            closeUserInfo();
        }
    };

    if (badges && badges.length) {
        for (const badge of badges) {
            try {
                if (badge.level > 0) {
                    slides.push({
                        key: `${badge.bage}-${badge.level}`,
                        imageURL: `https://cdn.blotclub.am/badges/${
                            badge.bage
                        }/${badge.bage}-${badge.level - 1}.png`,
                        _onClick,
                    });
                } else {
                    slides.push({
                        key: `${badge.bage}-${badge.level}`,
                        imageURL: `https://cdn.blotclub.am/badges/empty/empty-0.png`,
                        _onClick,
                    });
                }
            } catch (e) {
            }
        }
    }

    return slides;
};
const UserInfo = ({userInfo}) => {
    const [opened, setOpened] = useState(false);
    const intl = useIntl();
    const [animated, setAnimated] = useState(true);
    const [animating, setAnimating] = useState(false);
    const [animatedPercent, setAnimatedPercent] = useState(0);
    const dispatch = useDispatch();
    const navigateRoute = useSelector((state) => state.global.route);
    const route = useSelector((state) => state.global.route);
    const me = useSelector((state) => state.me.data);
    const lang = useSelector((state) => state.global.locale);
    const languageData = Number(lang);

    const percent = (userInfo.points * 100) / userInfo.edge;
    useEffect(() => {
        setOpened(true);
        setTimeout(() => {
            setAnimating(true);
            setAnimatedPercent(percent);
            setTimeout(() => {
                setAnimating(false);
            }, 500);
        }, 1000);
    }, []);

    const closeUserInfo = () => {
        setOpened(false);
        setTimeout(() => {
            dispatch(setCurrentUserInfo(null));
        }, 400);
    };
    const openVipPopup = () => {
            if (!userInfo.vip) {
                dispatch(setCurrentUserInfo(null));
                dispatch(
                    openPopups({
                        popupIds: [PopupsEnum.VIP_POPUP],
                    })
                );
            } else {
                dispatch(setCurrentUserInfo(null));
                dispatch(
                    openPopups({
                        popupIds: [PopupsEnum.VIP_NICKNAME_POPUP],
                        popupData: [userInfo]
                    })
                )

            }
        }
    ;
    const openTournament = () => {
        setOpened(false);
        setTimeout(() => {
            dispatch(setCurrentUserInfo(null));
        }, 400);
        dispatch(navigate(routesEnum.TOURNAMENT));
        dispatch(tournamentColor(themeEnum.TOURNAMENT));
    };

    return (
        <div className={` ${'user-info'} ${opened ? ' open' : ''}`}>
            <div className="user-info-left-side">
                <div className="main-user-info">
                    {me.userId === userInfo.userId && (
                        <div
                            className={`edit-username ${
                                languageData !== 3 ? 'edit-username-lang' : ''
                            }`}
                            onClick={openVipPopup}
                        >
                            <p>{intl.messages.editUserName}</p>
                            <img src={edit} alt="edit-icon"/>
                        </div>
                    )}
                    <img
                        src={main_user_info_back}
                        alt="background"
                        className={'main-user-info-back'}
                    />
                    <div className="inner-wrapper">
                        <div className="user-pic-name">
                            <div className="user-img-wrapper">
                                <img src={userInfo.avatar ? userInfo.avatar : user_avatar}
                                     alt="user"
                                />
                                <div className="level">{userInfo.level}</div>
                            </div>
                            <div className="user-additional">
                                <span className="user-name">
                                    {userInfo.username}
                                </span>
                                <br/>
                                <>
                                    <span className={'user-id'}>
                                        {intl.messages.user_id}:
                                        <span className={'highlighted'}>
                                            {userInfo.userId}
                                        </span>
                                    </span>
                                    <div className="userinfo-level-bar">
                                        <div
                                            className={`userinfo-level-full${
                                                animating ? ' animating' : ''
                                            }`}
                                            style={{
                                                width: animatedPercent + '%',
                                            }}
                                        />
                                    </div>
                                </>
                            </div>
                        </div>
                        <StandardSlider
                            slides={normalizeUserBadgesForSlides(
                                me.bages,
                                route,
                                closeUserInfo
                            )}
                        />
                    </div>
                </div>
                <div className="games-played-content">
                    <div className="games-played">
                        <div className="games-played-img">
                            <img src={cards} alt="cards"/>
                        </div>

                        <span className="games-played-text">
                            {intl.messages.games_played}
                        </span>
                        <div className="games-played-text-wrapper">
                            <span>{userInfo.total}</span>
                        </div>
                    </div>

                    <div className="win-rate">
                        <div className="win-rate-block">
                            <div className="win-rate-pie-chart">
                                <PieChart
                                    slices={[
                                        {
                                            percent: (
                                                Number(userInfo.rate) || 0
                                            ).toFixed(1),
                                            color: '#CB820A',
                                        },
                                    ]}
                                    showPercent
                                    showAnimation={animated}
                                />
                            </div>
                        </div>

                        <div className="win-rate-info">
                            <span className="rate-text">
                                {intl.messages.team_game.win_rate}
                            </span>
                            <SecondaryLabel
                                text={intl.formatMessage(
                                    {
                                        id: 'for_the_last_games',
                                    },
                                    {
                                        count: 100,
                                    }
                                )}
                                fontSize={14}
                            />
                        </div>
                    </div>
                </div>
                <div className="user-tournament-info">
                    <div className="tour-img-block">
                        <img src={user_info_tournament} alt="#"/>
                    </div>
                    <span className="user-tournament-text">
                        {intl.messages.tournamentInfo}
                    </span>
                    <div></div>
                </div>
                <div className="user-tournament-info">
                    <div className="user-tournament-joined">
                        <p>{me.joined_tournaments}</p>
                        <span>{intl.messages.joined}</span>
                    </div>

                    <div className="user-tournament-finished">
                        <p>{me.finished_tournaments}</p>
                        <span>{intl.messages.finishedTournament}</span>
                    </div>
                </div>
                <div className="joined-tournament-block">
                    <div className="joined-tournament-content">
                        <div className="joined-left">
                            <p>{intl.messages.participantsTournament}</p>{' '}
                        </div>
                        <div className="joined-right" onClick={openTournament}>
                            <span>{intl.messages.badges.play}</span>
                        </div>
                    </div>

                    <div className="joined-tournament-bottom"></div>
                </div>
            </div>
            <UserInfoRight
                // configs={configs}
                setOpened={setOpened}
                currentUserInfo={me}
                // openPopups={openPopups}
                // canInvite={canInvite}
                // myUserInfo={myUserInfo}
                // navigate={navigate}
                // closeUserInfo={closeUserInfo}
                // fromRoute={fromRoute}
            />
            <div className="empty-close" onClick={closeUserInfo}/>
        </div>
    );
};

export default UserInfo;
