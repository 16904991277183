import React, { useState } from 'react';
import './user-image-info.scss';
import UserImageInfoName from '../user-image-info-name/user-image-info-name';
import UserScore from '../user-score/user-score';
import UserGift from '../user-gift/user-gift';
import FirstStart from '../first-start/first-start';
import Tooltip from '../../tooltip/tooltip';
import UserOrder from '../user-order/user-order';
import ButtonTreeD from '../../buttons/button_3d/button-3d';
import socketBridge from '../../../socket';
import { RoomChannelEmittingEvents } from '../../../socket/socket.events';
import roomSingleton from '../../../singletons/room.singleton';
import { useDispatch, useSelector } from 'react-redux';
import Anouncement from '../../../pages/game-play/anouncement/anouncement';
import UserContra from '../user-contra/user-contra';
import {
    setCurrentUserInfo,
    setPartnerIdData,
} from '../../../redux/userinfo.slice';
import UserInfo from '../../../global-components/userInfo/user-info';
import UserTiming from '../../../pages/game-play/user-timing/user-timing';
import UserRecontra from '../user-recontra/user-recontra';
import { vip_ribbon, vip_ring } from '../../../assets/image.assets';
import UserChat from '../user-chat/user-chat';
import UserSmile from '../user-smile/user-smile';
import UserBot from '../user-bot/user-bot';

const UserImageInfo = ({
    userInfo,
    connectedUser,
    counter,
    imageId,
    seat,
    userSaySuit,
    bazarFinish,
    announcement,
    playIndex,
    stateIndex,
    showAnnouncement,
    showUserAnnouncement,
    startFrom,
    isContra,
    firstPlay,
    isRecontra,
    allowContra,
    isBot,
    isCapout,
    contraUserId,
    recontraUserId,
    contraTooltipUserId,
    partnerId,
    score,
    contractSuit,
    contractValue,
    timeout,
    timeoutUserIds,

    team,
    currentBazarUsers,
    declareAnnouncementWords,
    declareAnnouncementUserId,
    firstPlayContraUserId,
    takeChat,
    isBlot,
    anounnceUserId,
    bazarFinishUserId,
}) => {
    const [closeAnnounce, setCloseAnnounce] = useState(true);
    const [closeRecontra, setCloseRecontra] = useState(false);
    const [closeContra, setCloseContra] = useState(false);
    const [contraTooltip, setContraTooltip] = useState(isContra);
    const [opened, setOpened] = useState(false);
    const dispatch = useDispatch();
    const me = useSelector((state) => state.me);
    const showMeAnnounce = anounnceUserId === userInfo.userId && seat !== 1;
    const isFirst = startFrom && startFrom[0] === userInfo.userId;

    const isContraUserId = contraUserId === userInfo.userId;
    const tooltipContra = contraTooltipUserId === userInfo.userId;
    const isRecontraUserId = recontraUserId === userInfo.userId;
    const isFirstContraUserId = firstPlayContraUserId === userInfo.userId;
    const roomIdGet = roomSingleton.roomId;
    const currentRecontra = currentBazarUsers.includes(me.data.userId);
    const userInfoData = useSelector((state) => state.userInfo);
    const time = timeoutUserIds.includes(userInfo.userId) && timeout;

    if (partnerId) {
        dispatch(setPartnerIdData(partnerId));
    }
    const anounce = () => {
        socketBridge.emitToRoomChannel({
            event: RoomChannelEmittingEvents.ANNOUNCEMENT,
            cancel: false,
            state: playIndex || 0,
            roomId: roomIdGet,
            userId: me.data.userId,
        });
        setCloseAnnounce(false);
        setTimeout(() => {
            setCloseAnnounce(true);
        }, timeout);
    };

    const cancel = () => {
        socketBridge.emitToRoomChannel({
            event: RoomChannelEmittingEvents.ANNOUNCEMENT,
            cancel: true,
            state: playIndex || 0,
            roomId: roomIdGet,
            userId: me.data.userId,
        });
        setCloseAnnounce(false);
        setTimeout(() => {
            setCloseAnnounce(true);
        }, timeout);
    };
    const recontra = (sayIsRecontra) => {
        socketBridge.emitToRoomChannel({
            event: RoomChannelEmittingEvents.TALK,
            bazarInfo: {
                isRecontra: sayIsRecontra || undefined,
            },
            roomId: roomIdGet,
            userId: me.data.userId,
            state: stateIndex || 0,
        });
        setContraTooltip(false);
    };
    const closeRecontraBtn = () => {
        setCloseRecontra(true);
    };
    const contraBtn = (sayIsContra) => {
        socketBridge.emitToRoomChannel({
            event: RoomChannelEmittingEvents.TALK,
            bazarInfo: {
                isContra: sayIsContra || undefined,
            },
            roomId: roomIdGet,
            userId: me.data.userId,
            state: stateIndex || 0,
        });

        setCloseContra(true);
        setTimeout(() => {
            setContraTooltip(false);
        }, 3000);
    };
    const userInfoFn = () => {
        dispatch(setCurrentUserInfo(userInfo));
        // setOpened(!opened);
    };
    function userInfoImg(userInfo, time, isBot) {
        if (userInfo.vip && !isBot) {
            return 'user-game-vip-block';
        } else {
            if (time) {
                return 'user-game-time-block';
            }
            {
                return 'user_game_block';
            }
        }
    }
    return (
        <>
            <div className={`user_image_info_${seat}`}>
                <div className={`${userInfoImg(userInfo, time, isBot)} `}>
                    {' '}
                    {userInfo.vip && !isBot ? (
                        ''
                    ) : (
                        <div className={`${time && 'user-timing'}`}></div>
                    )}
                    {/* {userInfo.vip ? (
                        <img
                            src={vip_game_play}
                            alt="#"
                            className="vip-user-game"
                        />
                    ) : (
                        ''
                    )} */}
                    {timeoutUserIds.includes(userInfo.userId) && timeout && (
                        <div
                            className={`${
                                userInfo.vip && !isBot
                                    ? 'user-timing-block'
                                    : ''
                            }`}
                        >
                            <UserTiming
                                duration={timeout / 1000}
                                percent={100}
                                vip={userInfo.vip}
                                isBot={isBot}
                                counter={counter}
                            />
                        </div>
                    )}
                    <div
                        className={`${
                            userInfo.vip && !isBot
                                ? ''
                                : time
                                ? 'user-img-info-img-block'
                                : 'user_image_info_img'
                        }`}
                    >
                        {/* {isBot ? (
                            <UserBot
                                timeout={timeout}
                                isBot={isBot}
                                time={time}
                            />
                        ) : (*/}
                        <img
                            src={userInfo.avatar}
                            alt="user"
                            className={`${
                                userInfo.vip && !isBot
                                    ? 'user-avatar-vip'
                                    : 'user-avatar'
                            } ${
                                time && userInfo.vip && !isBot
                                    ? 'user-avatar-scale'
                                    : ''
                            }`}
                            onClick={userInfoFn}
                            onError={(ev) => {
                                ev.target.onerror = null;
                                ev.target.src =
                                    'https://cdn.blotclub.am/avatars/enky1.png';
                            }}
                        />
                    </div>
                    {userInfo.vip && !isBot ? (
                        <>
                            <div
                                className={`vip-user ${
                                    time ? 'vip-user-time' : ''
                                }`}
                            >
                                {!isBot && <img src={vip_ring} alt="#" />}
                            </div>

                            <div
                                className={`vip-ribbon ${
                                    time ? 'vip-ribbon-scale' : ''
                                }`}
                            >
                                <img src={vip_ribbon} alt="#" />
                            </div>
                        </>
                    ) : (
                        ''
                    )}
                </div>
                {imageId && <img src={imageId} alt="#" />}
                <UserImageInfoName name={userInfo.name} />
                {tooltipContra && isContra && (
                    <div className="contra-tooltip">
                        <Tooltip seat={seat} text="contra" />
                    </div>
                )}
                {isBlot && declareAnnouncementUserId === userInfo.userId && (
                    <Tooltip seat={seat} text={isBlot} />
                )}
                {!bazarFinish &&
                    !firstPlay &&
                    userSaySuit &&
                    userSaySuit.value && (
                        <Tooltip
                            seat={seat}
                            valueSuit={userSaySuit.value}
                            suit={userSaySuit.suit}
                            isCapout={userSaySuit.isCapout}
                        />
                    )}

                {!bazarFinish &&
                    !firstPlay &&
                    userSaySuit &&
                    userSaySuit.pass && (
                        <Tooltip seat={seat} pass={userSaySuit.pass} />
                    )}
                {(declareAnnouncementWords &&
                    declareAnnouncementWords.length > 0 &&
                    declareAnnouncementUserId === userInfo.userId) ||
                (showAnnouncement &&
                    showAnnouncement.declareAnnouncementWords &&
                    showAnnouncement.declareAnnouncementWords.length > 0 &&
                    userInfo.userId === showAnnouncement.userId) ? (
                    <Tooltip
                        showAnnouncement={
                            declareAnnouncementWords &&
                            declareAnnouncementWords.length > 0
                                ? declareAnnouncementWords
                                : showAnnouncement.declareAnnouncementWords
                        }
                        seat={seat}
                    />
                ) : (
                    <></>
                )}
                {takeChat &&
                    takeChat.chat &&
                    takeChat.userId === userInfo.userId && (
                        <UserChat seat={seat} emojiId={takeChat.emojiId} />
                    )}
                {takeChat &&
                    !takeChat.chat &&
                    takeChat.userId === userInfo.userId && (
                        <UserSmile seat={seat} emojiId={takeChat.emojiId} />
                    )}
                {isFirst && !bazarFinish && <FirstStart seat={seat} />}
                {/* <FirstStart seat={seat} /> */}
                {(isContraUserId && bazarFinish) || isFirstContraUserId ? (
                    <UserContra seat={seat} />
                ) : null}
                {bazarFinish && isRecontraUserId && (
                    <UserRecontra seat={seat} />
                )}
                {(bazarFinish || firstPlay) && seat !== 1 && (
                    <UserGift seat={seat} userInfo={userInfo} />
                )}
                {/* {seat === 1 && <AnimateGift />} */}
                {score && score.length > 0 ? (
                    <UserScore seat={seat} score={score} team={team} />
                ) : null}

                {(announcement || showUserAnnouncement) && showMeAnnounce && (
                    <Anouncement
                        showUserAnnouncement={showUserAnnouncement}
                        seat={seat}
                    />
                )}

                {allowContra &&
                    !isContra &&
                    firstPlay === null &&
                    !closeContra && (
                        <div
                            className="user-contra-btn"
                            onClick={() => contraBtn(true)}
                        >
                            <ButtonTreeD text="contra" />
                        </div>
                    )}
                {currentRecontra &&
                    seat === 1 &&
                    currentBazarUsers &&
                    currentBazarUsers.length > 1 &&
                    isContra &&
                    firstPlay === null &&
                    !isRecontra &&
                    !closeRecontra && (
                        <div className="recontra-button-block">
                            <div
                                className="recontra-button"
                                onClick={() => recontra(true)}
                            >
                                <ButtonTreeD text="recontra" />
                            </div>
                            <div
                                className="pass-button"
                                onClick={closeRecontraBtn}
                            >
                                <ButtonTreeD text="pass" />
                            </div>
                        </div>
                    )}
                {seat === 1 &&
                    announcement &&
                    announcement.length > 0 &&
                    closeAnnounce && (
                        <div className="announce-user-button">
                            <div className="anounse-button-block">
                                <div
                                    className="anounse-button"
                                    onClick={anounce}
                                >
                                    <ButtonTreeD text="Anounsement" />
                                </div>
                                <div className="cancel-button" onClick={cancel}>
                                    <ButtonTreeD text="cancel" />
                                </div>
                            </div>
                        </div>
                    )}
                {bazarFinishUserId === userInfo.userId && (
                    <UserOrder
                        contractSuit={contractSuit}
                        contractValue={contractValue}
                        isCapout={isCapout}
                        seat={seat}
                    />
                )}
            </div>
            <div className="userinfo-open">
                {opened && (
                    <UserInfo
                        opened={opened}
                        setOpened={setOpened}
                        userInfo={userInfoData.data}
                    />
                )}
            </div>
        </>
    );
};

export default UserImageInfo;
