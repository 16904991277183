import React, { useState } from 'react';
import BackButton from '../../../../components/buttons/back-button/back-button';
import TourRewardsLine from './tour-rewards-line/tour-rewards-line';
import {
    first_reward,
    reward_red,
    second_reward,
    third_reward,
    four_reward,
    star,
} from '../../../../assets/image.assets';
import './tournament-rewards.scss';
import ButtonTreeD from '../../../../components/buttons/button_3d/button-3d';
import { useDispatch, useSelector } from 'react-redux';
import { groupByPlaces } from '../../../../helpers/groupBy-reward.helper.js';
import { navigate } from '../../../../redux/global.slice';
import routesEnum from '../../../../enums/routes.enum';
import { tournamentJoin_req } from '../../../../api/tournament/tournament.api';

const TournamentRewards = ({ tourRewards }) => {
    const dispatch = useDispatch();
    const [tourJoinData, setTourJoinData] = useState(false);
    const tourData = useSelector((state) => state.global.navigateData);

    const tourInfoData = tourData.tourData[0];

    // const prizeRules = JSON.parse(tourInfoData.prizeRules);

    const me = useSelector((state) => state.me.data);
    const joined = useSelector((state) => state.global.joined);
    // if (!tourInfoData) {
    //     return null;
    // }

    const tourMore = () => {
        dispatch(navigate(routesEnum.TOURNAMENT));
    };
    const tournamentPage = () => {
        dispatch(navigate(routesEnum.TOURNAMENT_DASHBOARD));
    };

    const tournamentJoin = async () => {
        const userId = me.userId;
        const tourId = tourInfoData.id;
        const tournamentJoinData = await tournamentJoin_req(userId, tourId);
        if (tournamentJoinData) {
            setTourJoinData(true);
            dispatch(
                navigate({
                    route: routesEnum.TOURNAMENT_DASHBOARD,
                    navigateData: tourData,
                })
            );
        }
    };

    // const groupedRewardData = groupByPlaces(tourInfoData.rewardsData);
    // const groupData =
    //     groupedRewardData && groupedRewardData.length > 4
    //         ? groupedRewardData.reverse()
    //         : groupedRewardData;

    let startUsersCount = 1;
    const places = [];

    // prizeRules.forEach((reward, index) => {
    //     if (reward.winningUsersCount === 1) {
    //         places.push({
    //             winningUsersCount: reward.winningUsersCount,
    //             places: startUsersCount,
    //             description: reward.description,
    //             prizes: reward.prizes,
    //         });
    //         startUsersCount++;
    //     } else {
    //         places.push({
    //             winningUsersCount: reward.winningUsersCount,
    //             description: reward.description,
    //             prizes: reward.prizes,
    //             places:
    //                 startUsersCount +
    //                 '-' +
    //                 (startUsersCount + reward.winningUsersCount - 1),
    //         });

    //         startUsersCount += reward.winningUsersCount;
    //     }
    // });

    return (
        <div className="tournament-rewards">
            <div className="tournament-rewards-content">
                <>
                    {tourRewards &&
                        tourRewards.prizes.map((item, index) => (
                            <TourRewardsLine
                                item={item}
                                joined={joined}
                                index={index}
                            />
                        ))}
                </>
            </div>

            {joined ? (
                <div className="tour-info-btn">
                    <div className="tour-info-join" onClick={tournamentPage}>
                        <ButtonTreeD text="ok" />
                    </div>
                </div>
            ) : (
                <div className="tour-info-btn">
                    <div className="tour-info-more" onClick={tourMore}>
                        <ButtonTreeD text="close" />
                    </div>
                    <div className="tour-info-join" onClick={tournamentJoin}>
                        <ButtonTreeD text="join" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default TournamentRewards;
