import { createSlice } from '@reduxjs/toolkit';

const createMembersObj = (members) => {
    const membersObj = {};

    if (members && members.length) {
        for (const user of members) {
            membersObj[user.userId] = user;
        }
    }

    return membersObj;
};
export const teamGameSlice = createSlice({
    name: 'teamGame',
    initialState: {
        zone: '',
        configs: null,
        createTeamConfigs: null,
        currentUserInfo: null,
        myUserInfo: null,
        player: null,
        teamInfo: {},
        teamStats: {},
        battleInfo: {},
        teamStatistic: {},
        FTUE: null,
        notificationExists: false,
        startBattleOpened: false,
        isTeamInfoLoading: false,
        memberList: null,
        isMemberListLoading: false,
        currentLeaderboard: null,
        isCurrentLeaderboardLoading: false,
        pastLeaderboard: null,
        isPastLeaderboardLoading: false,
        currentMyTeam: null,
        isCurrentMyTeamLoading: false,
        pastMyTeam: null,
        isPastMyTeamLoading: false,
        battleDetails: null,
        notificationOpen: false,
        teamNotificationUser: [],
        memberUsers: [],
    },

    reducers: {
        teamMember: (state, { payload }) => {
            if (!payload) {
                return state;
            }
            return {
                ...state,
                memberUsers: payload,
            };
        },
        teamNotificationData: (state, { payload }) => {
            if (!payload) {
                return state;
            }
            return {
                ...state,
                teamNotificationUser: payload,
            };
        },
        initFromData: (state, { payload }) => {
            if (!payload) {
                return state;
            }
            return {
                ...state,
                zone: payload.zone || 'dev',
                player: payload.player,
                myUserInfo: payload.userInfo,
            };
        },
        battleChange: (state, { payload }) => {
            return {
                ...state,
                configs: payload,
            };
        },
        teamInfoState: (state, { payload }) => {
            return {
                ...state,
                teamInfo: payload,
            };
        },
        teamStatistics: (state, { payload }) => {
            return {
                ...state,
                teamStatistic: payload,
            };
        },
        setCurrentUserInfo: (state, { payload }) => {
            return { ...state, currentUserInfo: payload };
        },
        setPlayer: (state, { payload }) => {
            if (!payload) {
                return state;
            }

            return {
                ...state,
                player: payload,
            };
        },
        battleInfoState: (state, { payload }) => {
            return {
                ...state,
                battleInfo: payload,
            };
        },
        teamNotificationOpen: (state, { payload }) => {
            if (!payload) {
                return {
                    ...state,
                    notificationOpen: !state.notificationOpen,
                };
            }

            return { ...state, notificationOpen: payload };
        },
        getTeamInfo: (state, { payload, isLoading }) => {
            if (isLoading) {
                return {
                    ...state,
                    isTeamInfoLoading: isLoading,
                    isMemberListLoading: isLoading,
                };
            }

            if (!payload) {
                return state;
            }
            const membersObj = createMembersObj(payload.teamUsers);

            return {
                ...state,
                configs: payload.configs,
                teamInfo: payload.teamData,
                teamStats: payload.teamStats,
                memberList: membersObj,
                battleInfo: payload.battleInfo,
                FTUE: payload.FTUE,
                isTeamInfoLoading: isLoading,
                isMemberListLoading: isLoading,
            };
        },
        getCreateTeamConfigs: (state, { payload }) => {
            if (!payload) {
                return state;
            }
            return {
                ...state,
                createTeamConfigs: payload,
            };
        },
        getTeamInfoSilent: (state, { payload }) => {
            if (!payload) {
                return state;
            }

            const membersObj = createMembersObj(payload.teamUsers);

            return {
                ...state,
                configs: payload.configs,
                teamInfo: payload.teamData,
                teamStats: payload.teamStats,
                memberList: membersObj,
                battleInfo: payload.battleInfo,
                FTUE: payload.FTUE,
            };
        },
        setNotificationExists: (state, { payload }) => {
            return {
                ...state,
                notificationExists: !!payload,
            };
        },
        openCloseStartBattle: (state, { payload }) => {
            if (!payload) {
                return {
                    ...state,
                    startBattleOpened: !state.startBattleOpened,
                };
            }

            return { ...state, startBattleOpened: payload };
        },
        getCurrentLeaderboard: (state, { payload, isLoading }) => {
            if (isLoading) {
                return { ...state, isCurrentLeaderboardLoading: isLoading };
            }

            if (!payload) {
                return state;
            }

            return {
                ...state,
                isCurrentLeaderboardLoading: isLoading,
                currentLeaderboard: payload,
            };
        },
        getMemberList: (state, { payload, isLoading }) => {
            if (!payload || isLoading) {
                return state;
            }

            const membersObj = createMembersObj(payload.teamUsers || {});

            return {
                ...state,
                memberList: membersObj,
                isMemberListLoading: isLoading,
            };
        },
        memberListState: (state, { payload }) => {
            return {
                ...state,
                memberList: payload,
            };
        },
        getPastLeaderboard: (state, { payload, isLoading }) => {
            if (isLoading) {
                return { ...state, isPastLeaderboardLoading: isLoading };
            }

            if (!payload) {
                return state;
            }

            return {
                ...state,
                isPastLeaderboardLoading: isLoading,
                pastLeaderboard: payload,
            };
        },
        getCurrentMyTeam: (state, { payload, isLoading }) => {
            if (isLoading) {
                return { ...state, isCurrentMyTeamLoading: isLoading };
            }

            if (!payload) {
                return state;
            }

            return {
                ...state,
                isCurrentMyTeamLoading: isLoading,
                currentMyTeam: payload,
            };
        },
        getPastMyTeam: (state, { payload, isLoading }) => {
            if (isLoading) {
                return { ...state, isPastMyTeamLoading: isLoading };
            }

            if (!payload) {
                return state;
            }

            return {
                ...state,
                isPastMyTeamLoading: isLoading,
                pastMyTeam: payload,
            };
        },
        removeFTUE: (state) => {
            return { ...state, FTUE: null };
        },

        getBattleDetails: (state, { payload }) => {
            if (!payload) {
                return state;
            }

            return {
                ...state,
                battleDetails: payload,
            };
        },
    },
});

export const {
    teamNotificationData,
    getCreateTeamConfigs,
    initFromData,
    battleChange,
    setCurrentUserInfo,
    setPlayer,
    getTeamInfo,
    getTeamInfoSilent,
    setNotificationExists,
    openCloseStartBattle,
    getCurrentLeaderboard,
    getMemberList,
    memberListState,
    getPastLeaderboard,
    getCurrentMyTeam,
    getPastMyTeam,
    removeFTUE,
    getBattleDetails,
    teamInfoState,
    teamStatistics,
    battleInfoState,
    teamNotificationOpen,
    teamMember,
} = teamGameSlice.actions;

export default teamGameSlice.reducer;
