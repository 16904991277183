import React from 'react';
import { vip_game_play } from '../../../assets/image.assets';
import './user-image-info-name.scss';

const UserImageInfoName = ({ name, vip }) => {
    return (
        <div className="user_avatar_name">
            {vip && <img src={vip_game_play} alt="vip" />}

            <span className="game_user_name">{name ? name : 'Karlen'}</span>
        </div>
    );
};

export default UserImageInfoName;
