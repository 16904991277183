import React, { useEffect } from 'react';
import LobbyName from '../lobby-name/lobby-name';
import './lobby-cash-game.scss';
import {
    arrow_icon,
    tournament,
    big_game,
    micro_game,
    proffesional,
    bonus_game,
} from '../../../assets/image.assets';
import LobbyBet from '../lobby-bet/lobby-bet';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '../../../redux/global.slice';
import routesEnum from '../../../enums/routes.enum';
import socketBridge from '../../../socket';
import {
    UserChannelEmittingEvents,
    UserChannelEmittingEventsName,
    UserChannelHandlingEvents,
} from '../../../socket/socket.events';
import { openPopups } from '../../../redux/popup.slice';
import PopupsEnum from '../../../enums/popups.enum';
import roomSingleton from '../../../singletons/room.singleton';
import { useIntl } from 'react-intl';

const LobbyCashGame = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const me = useSelector((state) => state.me);

    const gameRoom = (type) => {
        socketBridge.emitToUserChannel({
            event: UserChannelEmittingEvents.FIND_ROOM,
            eventName: UserChannelEmittingEventsName.FIND_ROOM,
            roomType: type,
            userId: me.data.userId,
        });
    };
    const configData = useSelector(
        (state) => state.gameConfig.gameConfigData.rooms
    );

    useEffect(() => {
        function watcher({ event, ...args }) {
            switch (event) {
                case UserChannelHandlingEvents.ALLOW_PLAY:
                    roomSingleton.roomType = args.roomType;
                    if (args.allowPlay) {
                        dispatch(navigate(routesEnum.GAMEPLAY));
                    } else {
                        if (!args.allowPlay && args.message === 1) {
                            dispatch(
                                openPopups({
                                    popupIds: [PopupsEnum.NOT_ENOUGH_RED],
                                })
                            );
                        }
                        // switch (args.message) {
                        //   case AllowPlayMessages.
                        // }
                    }
                    break;
                default:
                    break;
            }
        }

        socketBridge.watchUserChannel(watcher);

        return () => {
            socketBridge.unwatchUserChannel(watcher);
        };
    }, [dispatch]);

    return (
        <div className="lobby_cashe_game">
            <div
                className="lobby_cash_game_block"
                onClick={() => gameRoom('micro')}
            >
                <img
                    className="cash_game micro-game"
                    src={micro_game}
                    alt="tournament"
                />
                <LobbyName text={intl.messages.beginners} type={2} />
                <LobbyBet
                    bet={configData.micro.amount + configData.micro.rake}
                    win={configData.micro.amount * 2}
                    type={1}
                />
            </div>
            <div
                className="lobby_cash_game_block"
                onClick={() => gameRoom('big')}
            >
                <img
                    className="cash_game big-game"
                    src={big_game}
                    alt="tournament"
                />
                <LobbyName text={intl.messages.bigGame} type={2} />
                <LobbyBet
                    bet={configData.big.amount + configData.big.rake}
                    win={configData.big.amount * 2}
                    type={1}
                />
            </div>
            <div
                className="lobby_cash_game_block"
                onClick={() => gameRoom('professional')}
            >
                <img
                    className="cash_game prof-game"
                    src={proffesional}
                    alt="tournament"
                />
                <LobbyName text={intl.messages.professionals} type={2} />
                <LobbyBet
                    bet={
                        configData.professional.amount +
                        configData.professional.rake
                    }
                    win={configData.professional.amount * 2}
                    type={1}
                />
            </div>
            <div
                className="lobby_cash_game_block"
                onClick={() => gameRoom('bonus')}
            >
                <img
                    className="cash_game bonus-game"
                    src={bonus_game}
                    alt="tournament"
                />
                <LobbyName text={intl.messages.bonusGame} type={1} />
                <LobbyBet
                    bet={configData.bonus.amount}
                    win={configData.bonus.win}
                    type={2}
                />
            </div>
        </div>
    );
};

export default LobbyCashGame;
