import React, { useDebugValue, useEffect, useState } from 'react';
import './tournament-info.scss';
import { tour_info } from '../../../assets/image.assets';
import TournamentBg from '../../../components/background/tournament-bg/tournament-bg';
import TourInfo from './tour-info/tour-info';
import TournamentPartisipation from './tournament-partisipation/tournament-partisipation';
import TournamentRewards from './tournament-rewards/tournament-rewards';
import TournamentLocked from './tournament-locked/tournament-locked';
import { useDispatch, useSelector } from 'react-redux';
import TourHeaderInfo from './tour-header-info/tour-header-info';
import userSingleton from '../../../singletons/user.singleton';
import { leaderboard_req } from '../../../api/team.game/team.game.api';
import {
    tournamentLeaderboard_req,
    tournamentReward_req,
} from '../../../api/tournament/tournament.api';
import { setUserMeTournament } from '../../../redux/tournament/user-me-tournament.slice';

const TournamentInfo = () => {
    const userTourTab = userSingleton._currentTabUser;
    const [tourInfo, setTourInfo] = useState(-1);
    const [leaderBoard, setLeaderBoard] = useState([]);
    const [tourRewards, setTourRewards] = useState([]);
    const [tourCurrentTab, setTourCurrentTab] = useState(userTourTab);
    const dispatch = useDispatch();
    const tourData = useSelector((state) => state.global.navigateData);
    const tourInfoData = tourData.tourData[0];
    const me = useSelector((state) => state.me.data);

    const tournamentName = tourInfoData.name;
    const id = tourInfoData.id;
    const tournamentLeaderBoard = async () => {
        const tourLeaderBoard = await tournamentLeaderboard_req(id);

        const userMePostition = tourLeaderBoard?.data?.leaderboard.filter(
            (item) => item.userId === me.userId
        );

        dispatch(setUserMeTournament(userMePostition));
        setLeaderBoard(tourLeaderBoard);
    };
    const tournamentRewards = async () => {
        const templateId = tourInfoData.templateId;
        const tourRewardsData = await tournamentReward_req(templateId);
        setTourRewards(tourRewardsData);
    };
    useEffect(() => {
        tournamentLeaderBoard();
        tournamentRewards();
    }, []);

    const tournamentTabContent = (tourCurrentTab) => {
        switch (tourCurrentTab + 1) {
            case 1:
                return <TourInfo setTourInfo={setTourInfo} />;
            case 2:
                return <TournamentRewards tourRewards={tourRewards.data} />;
            case 3:
                return <TournamentPartisipation setTourInfo={setTourInfo} />;
            case 4:
                return <TournamentLocked setTourInfo={setTourInfo} />;
            default:
                return <TourInfo setTourInfo={setTourInfo} />;
        }
    };
    return (
        <div className="tournament-info">
            <div className="tour-cup">
                <span>{tournamentName}</span>
                <div className="tour-info-img">
                    <img src={tour_info} alt="#" />
                </div>
            </div>
            <div className="tour-info-content">
                <TourHeaderInfo
                    tourCurrentTab={tourCurrentTab}
                    setTourCurrentTab={setTourCurrentTab}
                />
                <TournamentBg>
                    {tournamentTabContent(tourCurrentTab)}
                </TournamentBg>
            </div>
        </div>
    );
};
export default TournamentInfo;
