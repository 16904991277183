import React from 'react';
import { useIntl } from 'react-intl';

import './request-limit-popup.scss';

import { closePopup } from '../../../redux/popup.slice';
import CloseButtonTeam from '../../../components/team-game/buttons/close-button-team/close-button-team';
import StandardLabel from '../../../components/team-game/labels/standard-label/standard-label';
import _3dButton from '../../../components/team-game/buttons/3d-button/3d-button';

const getTextForErrorCode = (intl, errorCode) => {
    switch (errorCode) {
        case 8:
            return {
                firstLine: intl.messages.team_game.your_selected_team_is_full,
                secondLine: intl.messages.team_game.please_try_another_one,
            };
        case 9:
            return {
                firstLine:
                    intl.messages.team_game.you_exceeded_joining_request_limit,
                secondLine:
                    intl.messages.team_game.please_wait_until_leader_review,
            };
        default:
            return {
                firstLine: '',
                secondLine: '',
            };
    }
};

const RequestLimitPopup = ({ popupData }) => {
    const intl = useIntl();
    const textForError = getTextForErrorCode(intl, popupData.errorCode);
    return (
        <div className={'popup-wrapper request-limit-popup'}>
            <CloseButtonTeam _onClick={closePopup} />
            <StandardLabel
                text={intl.messages.team_gamerequest_limit_exceeded}
            />
            <p className={'info'}>
                {textForError.firstLine}
                <br />
                {textForError.secondLine}
            </p>
            <_3dButton
                text={intl.messages.team_game.got_it}
                _onClick={closePopup}
            />
        </div>
    );
};

export default RequestLimitPopup;
