import React, { useState, useEffect, useRef } from 'react';
const Timer = ({ endDate }) => {
    const refInterval = useRef(null);
    const [timeDown, setTimeDown] = useState(
        { day: '' },
        { hour: '' },
        { min: '' },
        { sec: '' }
    );

    function timeOut() {
        if (!endDate) {
            return;
        }
        const second = 1000;
        const minute = second * 60;
        const hour = minute * 60;
        const day = hour * 24;
        const year = day * 365;
        const countDown = new Date(endDate).getTime();

        refInterval.current = setInterval(() => {
            const now = new Date().getTime();
            const distance = countDown - now;

            if (distance < 0) return;
            let dayText = Math.floor((distance % year) / day);
            let hourText = Math.floor((distance % day) / hour);

            let minuteTex = Math.floor((distance % hour) / minute);
            let secondText = Math.floor((distance % minute) / second);
            if (dayText < 10) {
                dayText = `0${dayText}`;
            }
            if (hourText < 10) {
                hourText = `0${hourText}`;
            }
            if (secondText < 10) {
                secondText = `0${secondText}`;
            }
            if (minuteTex < 10) {
                minuteTex = `0${minuteTex}`;
            }
            setTimeDown({
                day: dayText,
                hour: hourText,
                min: minuteTex,
                sec: secondText,
            });

            // `${hourText} :${minuteTex}:${secondText}`
            if (distance < 0) {
                clearInterval(refInterval.current);
            }
        }, 1000);
    }
    useEffect(() => {
        timeOut();
        return () => clearInterval(refInterval.current);
    }, []);
    if (!endDate) {
        return <></>;
    }

    return (
        <div className="timer">
            <span>{timeDown.day}</span>
            {timeDown.day && <p>:</p>}

            <span>{timeDown.hour}</span>
            {timeDown.hour && <p>:</p>}
            <span>{timeDown.min}</span>
            {timeDown.min && <p>:</p>}
            <span>{timeDown.sec}</span>
            {timeDown.span && <p>:</p>}
        </div>
    );
};

export default Timer;
