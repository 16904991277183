import React from 'react';
import {
    boy,
    clubs_icon,
    diamonds_icon,
    hearts_icon,
    spade_icon,
} from '../../../assets/image.assets';

import './last-cards.scss';

const LastCards = ({ id = '1', cardData,winner }) => {
    console.log('winner',winner)
    function getTooltipSuit(suit) {
        const images = [
            hearts_icon,
            diamonds_icon,
            spade_icon,
            clubs_icon,
            boy,
        ];
        return images[suit];
    }
    return (
        <div className={`last_cards last_cards_${id} ${winner?"last_card_winner":" "}`}>
            <span>{cardData.value}</span>
            <img src={getTooltipSuit(cardData.suit)} className="suit-img" />
        </div>
    );
};
export default LastCards;
