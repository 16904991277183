import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import './join-team-panel.scss';

import SearchTextboxInput from '../../inputs/search-text-box/search-text-box.input';
import BlinkingPlusIcon from '../../icons/blinking-plus/blinking-plus.icon';

import {
    home_icon,
    home_icon_home,
    league_with_back,
} from '../../../../assets/image.assets';
import StandardLabel from '../../labels/standard-label/standard-label';
import { useDispatch } from 'react-redux';
import { navigatePopup, openPopups } from '../../../../redux/popup.slice';
import PopupsEnum from '../../../../enums/popups.enum';
import { navigate } from '../../../../redux/global.slice';
import routesEnum from '../../../../enums/routes.enum';
import { tournamentColor } from '../../../../redux/theme.change.slice';
import themeEnum from '../../../../enums/theme.enum';

const JoinTeamPanel = ({ searchValue, handleSearchInput, canCreateTeam }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const createTeam = () => {
        dispatch(
            openPopups({
                popupIds: [PopupsEnum.CREATE_TEAM],
            })
        );
    };
    const closeTeamGame = () => {
        dispatch(navigate(routesEnum.LOBBY));
        dispatch(tournamentColor(themeEnum.LOBBY));
    };
    return (
        <div className={'join-team-panel'}>
            <div className="league-title">
                <img src={league_with_back} alt="league" />
                <StandardLabel
                    text={<FormattedMessage id={'league'} />}
                    fromColor={'#FFFFFF'}
                    toColor={'#A2A2A2'}
                />
            </div>
            <div className={'search-wrapper'}>
                <SearchTextboxInput
                    placeholder={intl.formatMessage({ id: 'search_for_team' })}
                    value={searchValue}
                    _onChange={handleSearchInput}
                />
            </div>
            <div className="icons-wrapper">
                <div className="icons-create-team" onClick={createTeam}>
                    <BlinkingPlusIcon blinking={canCreateTeam} />
                </div>

                <div className="vertical-line" />
                <img
                    src={home_icon_home}
                    alt="home"
                    className={'home-icon'}
                    onClick={closeTeamGame}
                />
            </div>
        </div>
    );
};

export default JoinTeamPanel;
