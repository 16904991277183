import React from 'react';

import './hexagon.info.scss';

import { hexagon_back } from '../../../../assets/image.assets';

const HexagonInfo = ({ withBack = true, width, height, hex }) => {
    return (
        <div
            className={'hexagon-info'}
            style={{
                width: width + 'px',
                height: height + 'px',
            }}
        >
            {withBack && (
                <img
                    src={hexagon_back}
                    alt="hex-back"
                    className={'hex-back'}
                    style={{
                        width: width + 'px',
                        height: height + 'px',
                    }}
                />
            )}
            {hex && (
                <img
                    src={hex}
                    alt="hexagon"
                    className={'hex-img'}
                    style={{
                        width: width - 6 + 'px',
                        height: height - 6 + 'px',
                    }}
                />
            )}
        </div>
    );
};

export default HexagonInfo;
