import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    tour_panel,
    tour_home_icon,
    tour_time_yellow,
    tour_time_red,
    tour_time_green,
} from '../../../../assets/image.assets';
import PopupsEnum from '../../../../enums/popups.enum';
import routesEnum from '../../../../enums/routes.enum';
import { navigate } from '../../../../redux/global.slice';
import { openPopups } from '../../../../redux/popup.slice';
import userSingleton from '../../../../singletons/user.singleton';
import Timer from '../../../timer/timer';
import './tour-user-panel.scss';

const TourUserPanel = ({ data }) => {
    const dispatch = useDispatch();
    const [distance, setDistance] = useState(0);
    const tourData = useSelector((state) => state.global.navigateData);
    const refInterval = useRef(null);
    const tourInfoData = tourData.tourData[0];

    const backTourList = () => {
        dispatch(navigate(routesEnum.TOURNAMENT));
    };
    const goTourInfo = () => {
        userSingleton._currentTabUser = 0;
        dispatch(
            openPopups({
                popupIds: [PopupsEnum.TOURNAMENT_RESULT],
            })
        );
        // dispatch(
        //     navigate({
        //         route: routesEnum.TOURNAMENT_INFO,
        //         navigateData: tourData,
        //         joined: true,
        //     })
        // );
    };

    useEffect(() => {
        tourTimeImg(data);
        return () => clearInterval(refInterval.current);
    }, []);
    function tourImg(endDate, startDate) {
        const fullDate = endDate - startDate;
        const data = fullDate * 0.33;
        const firstDate = endDate - data * 2;
        const secondDate = endDate - data;
        const currentDate = endDate - distance;

        if (currentDate <= firstDate) {
            return <img src={tour_time_yellow} alt="#" />;
        }

        if (currentDate >= firstDate && currentDate <= secondDate) {
            return <img src={tour_time_green} alt="#" />;
        }

        if (currentDate >= secondDate) {
            return <img src={tour_time_red} alt="#" />;
        }
    }
    function tourTimeImg(data) {
        const endDate = data.endDate;

        const countDown = new Date(endDate).getTime();

        refInterval.current = setInterval(() => {
            const now = new Date().getTime();
            const distanceTime = countDown - now;
            setDistance(distanceTime);
            if (distanceTime < 0) {
                clearInterval(refInterval.current);
            }
        }, 1000);
    }

    return (
        <div className="tour-user-panel">
            <div className="tour-img-panel" onClick={goTourInfo}>
                <img src={tour_panel} />
            </div>
            <span className="tour-user-panel-name">{tourInfoData.name}</span>
            <div className="tour-panel-timer">
                <div>{tourImg(data.endDate, data.startDate)}</div>

                <div className="tour-user-panel-timer">
                    <Timer endDate={data.endDate} />
                </div>
            </div>
            <div className="tour-home-icon" onClick={backTourList}>
                <img src={tour_home_icon} alt="#" />
            </div>
        </div>
    );
};

export default TourUserPanel;
