const PopupsEnum = {
    GREEN_POPUP: 1,
    SETTINGS_POPUP: 2,
    GIFT_FORM_POPUP: 3,
    VIP_POPUP: 4,
    VIP_INFO_POPUP: 5,
    STORE_POPUP: 6,
    GIFT_CANCEL_POPUP: 7,
    VIP_NICKNAME_POPUP: 8,
    LEVEL_UP: 9,
    ADMIN_CHIP_POPUP: 10,
    NOT_ENOUGH_GOLD: 11,
    NOT_ENOUGH_GREEN: 12,
    NOT_ENOUGH_RED: 13,
    TOURNAMENT_TABLE: 14,
    TOURNAMENT_REWARDS: 15,
    TOURNAMENT_FINISHED: 16,
    TOURNAMENT_RESULT: 17,
    EXIT_GAME: 18,
    BADGE_POPUP: 19,
    UNFOLLOW_POPUP: 20,
    TOURNAMENT_UNLOCK: 30,

    CREATE_TEAM: 21,
    GOT_IT: 22,
    INVITE_FRIENDS: 23,
    PROMPT_POPUP: 24,
    JOIN_TEAM_POPUP: 25,
    CREATE_PRICE_POPUP: 26,
    FIRE_PLAYER_POPUP: 27,
    LEAVE_POPUP: 28,
    FIRST_TIME_USE: 29,
    REQUEST_LIMIT: 30,
};

export default PopupsEnum;
