import React, { createRef, useEffect } from 'react';
import CircleType from 'circletype';

import './curved-label.scss';

const curveText = (textRef) => {
    if (textRef && textRef.current) {
        const circleType = new CircleType(textRef.current).dir(-1).radius(284);
        circleType.forceWidth(1);
        circleType.forceHeight(1);
    }
};

const CurvedLabel = ({ text = '', addToCurveVal = 0 }) => {
    const textRef = createRef();

    useEffect(() => {
        curveText(textRef, text, addToCurveVal);
    }, []);

    useEffect(() => {
        curveText(textRef, text, addToCurveVal);
    }, [text]);

    return (
        <div className={'curved-label'}>
            <span ref={textRef}>{text.toUpperCase()}</span>
        </div>
    );
};

export default CurvedLabel;
