import React from 'react';

import './triangle-loader.scss';

import { ball_triangle } from '../../../../assets/image.assets';

const TriangleLoader = () => {
    return (
        <div className={'triangle-loader'}>
            <img src={ball_triangle} alt="triangle loader" />
        </div>
    );
};

export default TriangleLoader;
