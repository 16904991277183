import React, { useEffect, useState } from 'react';
import './store-page.scss';

import RedTabPopup from './red-tab/red-tab';
import YellowTab from './yellow-tab/yellow-tab';
import VipTabPopup from './vip-tab/vip-tab';
import ChipNavbarTab from './chip-navbar-tab/chip-navbar-tab';
import Background from '../../components/background/background';
import BackButton from '../../components/buttons/back-button/back-button';
import { useDispatch, useSelector } from 'react-redux';
import routesEnum from '../../enums/routes.enum';
import { navigate } from '../../redux/global.slice';
import WheelTab from './wheel-tab/wheel-tab';
import { tournamentColor } from '../../redux/theme.change.slice';
import themeEnum from '../../enums/theme.enum';

const StorePage = () => {
    const tabData = useSelector((state) => state.global.currentTab);

    const [currentTab, setCurrentTab] = useState(tabData > -1 ? tabData : 0);
    const dispatch = useDispatch();
    const configData = useSelector((state) => state.gameConfig.gameConfigData);

    useEffect(() => {
        dispatch(tournamentColor(themeEnum.LOBBY));
    }, []);
    const tabContent = (currentTab) => {
        switch (currentTab + 1) {
            case 1:
                return <RedTabPopup data={configData} />;
            case 2:
                return <YellowTab data={configData} />;
            case 3:
                return <VipTabPopup data={configData.vipPacks} />;
            case 4:
                return <WheelTab data={configData.discount} />;
        }
    };
    const goLooby = () => {
        dispatch(dispatch(navigate(routesEnum.LOBBY)));
    };
    return (
        <>
            <Background>
                <div className="store_popup">
                    <div className="back-lobby-store" onClick={() => goLooby()}>
                        <BackButton text={'lobby'} />
                    </div>
                    <ChipNavbarTab
                        setCurrentTab={setCurrentTab}
                        currentTab={currentTab}
                    />
                    <div className="content_store">
                        {tabContent(currentTab)}
                    </div>
                </div>
            </Background>
        </>
    );
};

export default StorePage;
