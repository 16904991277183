import React, { useState, useEffect, createRef } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import './congrats-section.scss';

import {
    battles,
    checked_hex,
    cup_hex,
    hex_20,
    player_hex,
    plus_hex,
    star_with_back,
} from '../../../../assets/image.assets';
import StandardLabel from '../../../../components/team-game/labels/standard-label/standard-label';
import UniversalShield from '../../../../components/team-game/shields/universalShield/universal.shield';
import SecondaryLabel from '../../../../components/team-game/labels/secondary-label/secondary-label';
import _3dButton from '../../../../components/team-game/buttons/3d-button/3d-button';
import {
    leftShowState,
    rightShowState,
} from '../../../../helpers/team-game/divided-view.helper';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '../../../../redux/global.slice';
import routesEnum from '../../../../enums/routes.enum';
import userSingleton from '../../../../singletons/user.singleton';

const getHints = (intl) => {
    return [
        {
            icon: battles,
            text: intl.messages.team_game.create_info_list[0],
        },
        {
            icon: player_hex,
            text: intl.messages.team_game.create_info_list[1],
        },
        {
            icon: plus_hex,
            text: intl.messages.team_game.create_info_list[2],
        },
        {
            icon: hex_20,
            text: intl.messages.team_game.create_info_list[3],
        },
        {
            icon: cup_hex,
            text: intl.messages.team_game.create_info_list[4],
        },
        {
            icon: star_with_back,
            text: intl.messages.team_game.create_info_list[5],
        },
        {
            icon: checked_hex,
            text: intl.messages.team_game.create_info_list[6],
        },
    ];
};

const CongratsSection = () => {
    const intl = useIntl();

    const dispatch = useDispatch();
    const [showState, setShowState] = useState(0);
    const [activeHintIndex, setActiveHinIndex] = useState(0);
    const hints = getHints(intl);
    const hintRefs = hints.map(() => createRef());
    const navigateData = useSelector((state) => state.global.navigateData);
    const teamName = userSingleton.teamName;

    useEffect(() => {
        setShowState(1);
    }, []);

    const nextHint = () => {
        if (activeHintIndex + 1 > hints.length - 1) {
            return;
        }
        setActiveHinIndex(activeHintIndex + 1);

        if (activeHintIndex + 1 > 2) {
            const ref = hintRefs[activeHintIndex + 1];

            if (ref && ref.current) {
                ref.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        }
    };

    return (
        <div className={'congrats divided-view-content'}>
            <div className="left-side-wrapper">
                <div className={'left-side' + leftShowState(showState)}>
                    <StandardLabel text={intl.messages.team_game.congrats} />
                    <p>
                        <FormattedMessage
                            id={'you_have_successfully_created_your_team'}
                        />
                    </p>
                    <UniversalShield
                        text={teamName}
                        icons={
                            navigateData &&
                            navigateData.chosenIcons &&
                            navigateData.chosenIcons.map((data) => data.url)
                        }
                    />
                </div>
            </div>
            <div className="right-side-wrapper">
                <div className={'right-side' + rightShowState(showState)}>
                    <div className="rules-tips-text">
                        <StandardLabel
                            text={intl.formatMessage({ id: 'rules_and_tips' })}
                        />
                        <p className={'top-tip'}>
                            <FormattedMessage
                                id={'now_you_are_the_team_leader'}
                            />
                        </p>
                    </div>
                    <div
                        className={
                            'tips-list' +
                            (activeHintIndex === hints.length - 1
                                ? ' with-scroll'
                                : '')
                        }
                    >
                        {hints.map((item, index) => {
                            return (
                                <div
                                    key={item.text}
                                    ref={hintRefs[index]}
                                    className={
                                        'tip-item' +
                                        (index === activeHintIndex
                                            ? ' active'
                                            : '')
                                    }
                                >
                                    <img src={item.icon} alt="hex image" />
                                    <p className={'tip-text'}>{item.text}</p>
                                </div>
                            );
                        })}
                    </div>
                    <div className="rules-counter">
                        {activeHintIndex !== hints.length - 1 && (
                            <div className="counter-text">
                                <StandardLabel
                                    text={
                                        <FormattedMessage
                                            id={'current_of_total'}
                                            values={{
                                                current: activeHintIndex + 1,
                                                total: hints.length,
                                            }}
                                        />
                                    }
                                    fontSize={15}
                                />
                                <SecondaryLabel
                                    text={
                                        intl.messages.team_game.rules_and_tips
                                    }
                                    fontSize={12}
                                />
                            </div>
                        )}
                        <div
                            className={
                                'hint-button-wrapper' +
                                (activeHintIndex === hints.length - 1
                                    ? ''
                                    : ' no-padding')
                            }
                        >
                            <_3dButton
                                text={intl.formatMessage({
                                    id:
                                        activeHintIndex !== hints.length - 1
                                            ? 'next'
                                            : 'go_to_dashboard',
                                })}
                                _onClick={
                                    activeHintIndex !== hints.length - 1
                                        ? nextHint
                                        : () => {
                                              dispatch(
                                                  navigate(
                                                      routesEnum.TEAM_DASHBOARD
                                                  )
                                              );
                                          }
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CongratsSection;
