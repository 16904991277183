import React, { useEffect, createRef } from 'react';
import {
    green_panel,
    red_panel,
    gold_panel,
    gold,
} from '../../../assets/image.assets';

import './chip.info.scss';

import positionsSingleton from '../../../singletons/positions.singleton';

import positionsHelper from '../../../helpers/positions.helper';
import CountUp from 'react-countup';
import imageLoader from '../../../loaders/image.loader';
import { useDispatch, useSelector } from 'react-redux';
import userSingleton from '../../../singletons/user.singleton';
import routesEnum from '../../../enums/routes.enum';
import { navigate } from '../../../redux/global.slice';

function getChipImage(chip) {
    switch (chip) {
        case 'red':
            return red_panel;
        case 'green':
            return green_panel;
        case 'yellow':
            return gold;
        default:
            return red_panel;
    }
}

const ChipInfo = ({
    chip = 'red',
    start,
    end,
    _onClick = () => {},
    discountTimer,
    imageId,
}) => {
    const chipRef = createRef();
    const dispatch = useDispatch();
    const navigateRoute = useSelector((state) => state.global.route);
    const goStoreTab = (chip) => {
        if (chip === 'red') {
            userSingleton._storeTab = 0;
        } else {
            userSingleton._storeTab = 1;
        }
        dispatch(navigate(routesEnum.BADGES));
    };
    useEffect(() => {
        if (chipRef.current) {
            const relativePositions = positionsHelper.getRelativePosition(
                chipRef.current
            );

            positionsSingleton.setPosition(
                chip,
                relativePositions.left,
                relativePositions.top
            );
        }
    }, [chip, chipRef]);

    return (
        <div
            className={`chip_info${discountTimer > 0 ? '_discount' : ''} ${
                navigateRoute === 7 ? 'chip-info-tour' : ''
            }`}
        >
            <img
                className={discountTimer > 0 ? 'chip' : 'chip_discount'}
                src={getChipImage(chip)}
                alt={chip}
                ref={chipRef}
                onClick={_onClick}
            />
            {discountTimer < 1 && navigateRoute !== 3 && (
                <img
                    src={imageId}
                    alt="#"
                    className="plus-icon"
                    onClick={_onClick}
                />
            )}
            {chip === 'yellow' && navigateRoute !== 3 && (
                <img
                    src={imageId}
                    alt="#"
                    className="plus-icon"
                    onClick={_onClick}
                />
            )}
            <span className={discountTimer > 0 ? 'amount_discount' : 'amount'}>
                <CountUp
                    start={start}
                    end={end}
                    duration={1}
                    separator=" "
                    decimals={0}
                    decimal=" "
                    delay={0.1}
                />
            </span>
        </div>
    );
};

export default ChipInfo;
