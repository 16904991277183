import React, { useState } from 'react';
import './tour-info.scss';
import {
    arrow_icon,
    settings_close,
    tour_cup,
    tour_knifes,
    tour_red,
    tour_time,
    tour_vip,
} from '../../../../assets/image.assets';
import ButtonTreeD from '../../../../components/buttons/button_3d/button-3d';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '../../../../redux/global.slice';
import routesEnum from '../../../../enums/routes.enum';
import Timer from '../../../../components/timer/timer';
import { formatNumberWithSpaces } from '../../../../utils/formatter.util';
import { tournamentJoin_req } from '../../../../api/tournament/tournament.api';

function getVip(id) {
    switch (id) {
        case 111:
            return '1 Week';
        case 112:
            return '1 Month';
        case 113:
            return '1 Year';
        default:
            return;
    }
}
const TourInfo = () => {
    const tourInfoData = useSelector((state) => state.global.navigateData);
    const userTourData = useSelector(
        (state) => state.global.navigateData.userTourData
    );
    const tourData = tourInfoData.tourData[0];

    const joined = useSelector((state) => state.global.joined);

    const tournamentName = tourData.name;
    // const tournamentReward = JSON.parse(tourData.prizeRules);

    // const tourInfoVip = tournamentReward[0].prizes.filter(
    //     (item) => item.type === 'vip'
    // );
    // const tourInfoRed = tournamentReward[0].prizes.filter(
    //     (item) => item.type === 'red'
    // );

    const me = useSelector((state) => state.me.data);
    const dispatch = useDispatch();
    const tourMoreInfo = () => {
        dispatch(navigate(routesEnum.TOURNAMENT));
    };
    const tournamentPage = () => {
        dispatch(navigate(routesEnum.TOURNAMENT_DASHBOARD));
    };

    const tournamentJoin = async () => {
        const userId = me.userId;
        const tourId = tourData.id;
        const tournamentJoinData = await tournamentJoin_req(userId, tourId);
        if (tournamentJoinData) {
            dispatch(
                navigate({
                    route: routesEnum.TOURNAMENT_DASHBOARD,
                    navigateData: {
                        tourData: Array.isArray(tourData)
                            ? tourData
                            : [tourData],
                        userTourData,
                    },
                })
            );
        }
    };

    return (
        <div className="tour-info-block">
            <div className="tour-info-text">
                <p>introducING the {tournamentName} tournament</p>
                <span>Play all games, gain highest score and get rewards!</span>
            </div>
            <div className="tour-info-time">
                <div className="tour-time">
                    <img src={tour_time} />
                    <div className="tour-time-block">
                        <div className="tour-info-timer">
                            <Timer endDate={tourData.endDate} />
                        </div>
                        <div className="tour-time-days">
                            <span>days</span>
                            <span className="tour-time-hours">hours</span>
                            <span className="tour-time-min">min</span>
                            <span className="tour-time-sec">sec</span>
                        </div>
                    </div>
                </div>
                {/* <div className="tour-info-cup">
                    <img src={tour_cup} alt="#" className="tour-cup-img" />
                    {tourInfoRed && tourInfoRed[0] && (
                        <div className="tour-prize">
                            <img src={tour_red} alt="#" />
                            <div className="tour-prize-text">
                                <span>
                                    {formatNumberWithSpaces(
                                        tourInfoRed[0].amount
                                    )}
                                </span>
                                <p>prize found</p>
                            </div>
                        </div>
                    )}

                    {tourInfoVip && tourInfoVip[0] && (
                        <div className="tour-prize">
                            <img src={tour_vip} alt="#" />
                            <div className="tour-prize-text">
                                <span>{getVip(tourInfoVip[0].amount)}</span>
                                <p className="tour-prize-vip">vip</p>
                            </div>
                        </div>
                    )}
                </div> */}
                <div className="tour-swords">
                    <img src={tour_knifes} />
                    <div className="tour-swords-game">
                        <span>{tourData.maxGames}</span>
                        <p>games</p>
                    </div>
                </div>
            </div>
            {joined ? (
                <div className="tour-info-btn">
                    <div className="tour-info-join" onClick={tournamentPage}>
                        <ButtonTreeD text={'ok'} />
                    </div>
                </div>
            ) : (
                <div className="tour-info-btn">
                    <div className="tour-info-join" onClick={tournamentJoin}>
                        <ButtonTreeD text={joined ? 'ok' : 'join'} />
                    </div>
                    <div className="tour-info-more" onClick={tourMoreInfo}>
                        <ButtonTreeD text="close" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default TourInfo;
