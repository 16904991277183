import React from 'react';
import './tour-rewards-tooltip.scss';

const TourRewardsTooltip = () => {
    return (
        <div className="tour-rewards-tooltip">
            <p>Hold your position and get the reward</p>
        </div>
    );
};

export default TourRewardsTooltip;
