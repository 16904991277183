import React from 'react';
import Star from '../../../../components/star/star';
import './left-section.scss';
import LevelBar from '../../../../components/bars/level-bar/level.bar';
import { useIntl } from 'react-intl';

const LeftSection = ({ item }) => {
    const intl = useIntl();
    if (!item) {
        return null;
    }

    const stars=[1,2,3,4,5].map(i=>{
        if(i<=item.level){
            return <Star type="active" />
        }
       return <Star/>
    })
    return (
        <div className="left-section">
            <p>{item.bage}</p>
            <div className="star-block">

                {stars}
               {/* <Star type="active" />
                <Star type="active" />
                <Star />
                <Star />
                <Star />*/}
            </div>
            {item.nextLevelCount ? (
                <span>
                    {item.count} / {item.nextLevelCount}
                </span>
            ) : (
                <span>{intl.messages.badges.complete}</span>
            )}
            <LevelBar
                percent={
                    item.level < 5
                        ? (item.count * 100) / item.nextLevelCount
                        : 100
                }
            />
        </div>
    );
};
export default LeftSection;
