import React, { useEffect, useState } from 'react';
import './waiting-players.scss';
import WaitingLoader from './waiting-loader/waiting-loader';

import PrimaryRibbon from '../../../../components/ribbons/primary.ribbon';
import { red_chips_waiting } from '../../../../assets/image.assets';
import UserImageInfoName from '../../../../components/user/user-image-info-name/user-image-info-name';
import Anouncement from '../../anouncement/anouncement';
import ButtonTreeD from '../../../../components/buttons/button_3d/button-3d';
import UserTiming from '../../user-timing/user-timing';
import Tooltip from '../../../../components/tooltip/tooltip';
import { Player } from '@lottiefiles/react-lottie-player';
import chit from '../../../../assets/smiles/chit.json';
import { useDispatch } from 'react-redux';
import socketBridge from '../../../../socket';
import { UserChannelEmittingEvents } from '../../../../socket/socket.events';
import { navigate } from '../../../../redux/global.slice';
import routesEnum from '../../../../enums/routes.enum';
import roomSingleton from '../../../../singletons/room.singleton';
import { useIntl } from 'react-intl/lib';
import themeEnum from '../../../../enums/theme.enum';
import { tournamentColor } from '../../../../redux/theme.change.slice';

function roomTypeChip(type) {
    switch (type) {
        case 'micro':
            return 400;
        case 'big':
            return 600;
        case 'professional':
            return 2000;
        case 'bonus':
            return 50;
        default:
            break;
    }
}

const WaitingPlayers = ({ userInfo }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [showCancel, setShowCancel] = useState(false);
    const roomChipData = roomSingleton.roomType;
    const cancelGame = () => {
        socketBridge.emitToUserChannel({
            event: UserChannelEmittingEvents.FORCE_LEAVE_USER,
            partnerId: null,
        });
        dispatch(navigate(routesEnum.LOBBY));
    };
    useEffect(() => {
        dispatch(tournamentColor(themeEnum.LOBBY));
        setTimeout(() => {
            setShowCancel(true);
        }, 3000);
    }, []);

    return (
        <>
            <div className="waiting-players">
                <div className="waiting-popup">
                    <div className="ribbon-wrapper">
                        <PrimaryRibbon
                            type={3}
                            text={intl.messages.gamePlay.waiting.waitingText}
                        />
                    </div>
                    <div className="waiting-text-block">
                        <span className="waiting-text">
                            {intl.messages.gamePlay.waiting.waitingUser}
                        </span>
                    </div>
                    <div className="waiting-loading-block">
                        <div className="waiting-loading">
                            <WaitingLoader />
                        </div>
                        <div
                            className={`waiting-load${
                                showCancel ? ' with-cancel' : ''
                            }`}
                            onClick={cancelGame}
                        >
                            {showCancel && (
                                <ButtonTreeD text={intl.messages.cancel} />
                            )}
                        </div>
                    </div>
                    <div className="waiting-table">
                        <span>
                            {roomChipData}{' '}
                            {intl.messages.gamePlay.waiting.waitingGameReward}
                        </span>
                    </div>
                    <div className="waiting-chip-block">
                        <div className="waiting-under-block">
                            <div className="waiting-chip">
                                <img src={red_chips_waiting} alt="red-chips" />
                            </div>
                            <div className="waiting-chip-table">
                                <span className="chip-count">
                                    {roomTypeChip(roomChipData)}
                                </span>
                                <span className="chip-type">
                                    {intl.messages.gamePlay.waiting.waitingRed}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="me-user">
                <div className="me-user-block">
                    <div className="me-user-img">
                        <img
                            src={userInfo.avatar}
                            alt="user"
                            onError={(ev) => {
                                ev.target.onerror = null;
                                ev.target.src =
                                    'https://cdn.blotclub.am/avatars/enky1.png';
                            }}
                        />
                    </div>
                </div>

                <UserImageInfoName
                    name={userInfo.username}
                    vip={userInfo.vip}
                />
            </div>
        </>
    );
};
export default WaitingPlayers;
