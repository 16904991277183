import React from 'react';
import './background.scss';

const Background = ({ children }) => {
  return (
    <div className="background">
      <div className="background_block"></div>
      <div className="background_pattern">{children}</div>
    </div>
  );
};

export default Background;
