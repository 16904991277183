import React from 'react';
import './last-hand.scss';
import LastCards from '../last-cards/last-cards';
import {useIntl} from 'react-intl';
import {seatOffset} from '../../../helpers/user.helper';
import lastHandCards from './last-hand-cards';
import {useSelector} from "react-redux";

const LastHand = ({lastHand, mySeat, startFrom, winnerUser, usersInfo}) => {

    const meUser = useSelector((state) => state.me.data)

    console.log('winnerUser', winnerUser)
    console.log('userInfo', meUser.userId)
    console.log('usersInfo', usersInfo)
    const intl = useIntl();
    let rightHand = []
    const newArr = () => {
        let j = 0
        while (mySeat !== startFrom) {
            startFrom++
            j++
            if (startFrom === 4) {
                startFrom = 0
            }
        }
        if (mySeat === startFrom) {
            for (let i = 0; i <= 3; i++, j++) {
                if (j === 4) {
                    j = 0
                }
                rightHand[i] = lastHand[j]
            }
        }
    }
    newArr()


    if (!lastHand) {
        return <></>;
    }

    return (
        <div className="last_hand">
            <div className="last_hand_text">
                <span>{intl.messages.gamePlay.lastHand}</span>
            </div>
            <div className="last_hand_card_block">
                {rightHand &&
                rightHand.map((card, index) => {
                    const seat = seatOffset(index, mySeat)
                    const cardData = lastHandCards[card];
                    if (!cardData) {
                        return <></>;
                    }

                    return (
                        <LastCards
                            id={index + 1}
                            cardData={cardData}
                            lastHand={lastHand}
                        />
                    );
                })}
            </div>
        </div>
    );
};
export default LastHand;
