import { axiosInstance } from '../config';

export const badgesList_req = async (jwt) => {
    const response = await axiosInstance.post('/getUserBages', {
        jwt,
    });
    return response.data;
};
export const newBadges_req = async (jwt) => {
    const response = await axiosInstance.post('/checkBagesLevelUp', {
        jwt,
    });
    return response.data;
};

export const checkBadges_req = async (jwt) => {
    const response = await axiosInstance.post('/checkUserNewBages', {
        jwt,
    });
    return response.data;
};
