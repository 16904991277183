import React from 'react';
import { x_icon } from '../../../../assets/image.assets';

import './close-button-team.scss';

const CloseButtonTeam = ({ _onClick = () => {} }) => {
    return (
        <div className={'close-button'} onClick={_onClick}>
            <img src={x_icon} alt="close" />
        </div>
    );
};

export default CloseButtonTeam;
