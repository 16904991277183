import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BackgroundPopup from '../../../components/background/background-popup/background-popup';
import { openCloseSmile } from '../../../redux/menu-game.slice';
import './smiles.scss';
import chit from '../../../assets/smiles/chit.json';
import cry from '../../../assets/smiles/cry.json';
import glasses from '../../../assets/smiles/glasses.json';
import hand from '../../../assets/smiles/hand.json';
import hura from '../../../assets/smiles/hura.json';
import hypnotize from '../../../assets/smiles/hypnotize.json';
import party from '../../../assets/smiles/party.json';
import sleep from '../../../assets/smiles/sleep.json';
import smile from '../../../assets/smiles/smile.json';
import squint from '../../../assets/smiles/squint.json';
import sweat from '../../../assets/smiles/smile.json';
import what from '../../../assets/smiles/squint.json';
import { Player } from '@lottiefiles/react-lottie-player';
import socketBridge from '../../../socket';
import { RoomChannelEmittingEvents } from '../../../socket/socket.events';
const Smiles = () => {
    const [smileOpened, setSmileOpened] = useState(false);
    const dispatch = useDispatch();
    const openedState = useSelector(
        (state) => state.userMenuGame.smile.smileOpenedState
    );
    const data = [
        { smileImg: chit, id: 1 },
        { smileImg: cry, id: 2 },
        { smileImg: glasses, id: 3 },
        { smileImg: hand, id: 4 },
        { smileImg: hura, id: 5 },
        { smileImg: hypnotize, id: 6 },
        { smileImg: party, id: 7 },
        { smileImg: sleep, id: 8 },
        { smileImg: smile, id: 9 },
        { smileImg: squint, id: 10 },
        { smileImg: sweat, id: 11 },
        { smileImg: what, id: 12 },
    ];
    useEffect(() => {
        if (openedState === 1) {
            setSmileOpened(true);
        } else if (openedState === 2) {
            setSmileOpened(false);
            setTimeout(() => {
                dispatch(openCloseSmile({ smileOpenedState: 3 }));
            }, 500);
        }
    }, [openedState]);
    const smileUser = (id) => {
        socketBridge.emitToRoomChannel({
            event: RoomChannelEmittingEvents.SEND_EMOJI,
            emojiId: id,
            chat: false,
        });
        dispatch(openCloseSmile({ smileOpenedState: 3 }));
    };
    return (
        <div className={`smiles${smileOpened ? ' open' : ''}`}>
            <div className="smiles-user">
                <BackgroundPopup>
                    <p>emojis</p>
                    <div className="menu-gradline"> </div>
                    <div className="smiles-block">
                        {data.map((item) => (
                            <div
                                className="smile-block"
                                key={item.id}
                                onClick={() => smileUser(item.id)}
                            >
                                <Player src={item.smileImg} autoplay loop />
                            </div>
                        ))}
                    </div>
                </BackgroundPopup>
            </div>
        </div>
    );
};

export default Smiles;
