import React from 'react';

import './thin-bar.scss';

const ThinBar = ({ maxPoints, points, color, fromColor, toColor }) => {
    let percent = 0;

    if (maxPoints && points) {
        percent = (points / maxPoints) * 100;
    }

    return (
        <div className={'thin-bar'}>
            <div
                className="inner-fill"
                style={{
                    width: percent + '%',
                    background: color
                        ? color
                        : `radial-gradient(ellipse at center, ${fromColor} 50%,${toColor} 100%)`,
                }}
            />
        </div>
    );
};

export default ThinBar;
