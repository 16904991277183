import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    icon_check,
    icon_complete,
    red_small,
    yellow_small,
} from '../../../../assets/image.assets';
import './badge-bottom-section.scss';

const badgeBottomText = (item, intl) => {
    switch (item.bage) {
        case 'napoleon':
            return (
                <p>
                    {' '}
                    {intl.formatMessage(
                        {
                            id: 'napoleon',
                        },
                        {
                            item: item.nextLevelCount,
                        }
                    )}
                </p>
            );
        case 'chiefAccountant':
            return (
                <p>
                    {' '}
                    {intl.formatMessage(
                        {
                            id: 'chiefaccountant',
                        },
                        {
                            item: item.nextLevelCount,
                        }
                    )}
                </p>
            );
        case 'godOfMars':
            return (
                <p>
                    {' '}
                    {intl.formatMessage(
                        {
                            id: 'gofOfMars',
                        },
                        {
                            item: item.nextLevelCount,
                        }
                    )}
                </p>
            );
        case 'kingOfCapot':
            return (
                <p>
                    {' '}
                    {intl.formatMessage(
                        {
                            id: 'kingOfCapot',
                        },
                        {
                            item: item.nextLevelCount,
                        }
                    )}
                </p>
            );
        case 'luckyThree':
            return (
                <p>
                    {' '}
                    {intl.formatMessage(
                        {
                            id: 'luckyThree',
                        },
                        {
                            item: item.nextLevelCount,
                        }
                    )}
                </p>
            );
        case 'pensioner':
            return (
                <p>
                    {' '}
                    {intl.formatMessage(
                        {
                            id: 'pensioner',
                        },
                        {
                            item: item.nextLevelCount,
                        }
                    )}
                </p>
            );
        case 'hasher':
            return (
                <p>
                    {' '}
                    {intl.formatMessage(
                        {
                            id: 'hasher',
                        },
                        {
                            item: item.nextLevelCount,
                        }
                    )}
                </p>
            );
        case 'fourWarriors':
            return (
                <p>
                    {' '}
                    {intl.formatMessage(
                        {
                            id: 'fourWarriors',
                        },
                        {
                            item: item.nextLevelCount,
                        }
                    )}
                </p>
            );
        case 'superBlot':
            return (
                <p>
                    {' '}
                    {intl.formatMessage(
                        {
                            id: 'superBlot',
                        },
                        {
                            item: item.nextLevelCount,
                        }
                    )}
                </p>
            );
        case 'sniper':
            return (
                <p>
                    {' '}
                    {intl.formatMessage({
                        id: 'sniper',
                    })}
                </p>
            );
        case 'opposition':
            return (
                <p>
                    {' '}
                    {intl.formatMessage(
                        {
                            id: 'opposition',
                        },
                        {
                            item: item.nextLevelCount,
                        }
                    )}
                </p>
            );
        case 'butcher':
            return (
                <p>
                    {' '}
                    {intl.formatMessage(
                        {
                            id: 'butcher',
                        },
                        {
                            item: item.nextLevelCount,
                        }
                    )}
                </p>
            );
        case 'shadowAttack':
            return (
                <p>
                    {' '}
                    {intl.formatMessage(
                        {
                            id: 'shadowAttack',
                        },
                        {
                            item: item.nextLevelCount,
                        }
                    )}
                </p>
            );
        case 'don':
            return (
                <p>
                    {' '}
                    {intl.formatMessage({
                        id: 'don',
                    })}
                </p>
            );
        case 'greateVictory':
            return (
                <p>
                    {' '}
                    {intl.formatMessage(
                        {
                            id: 'greateVictory',
                        },
                        {
                            item: item.nextLevelCount,
                        }
                    )}
                </p>
            );
    }
};

const BadgeBottomSection = ({ item, standardBadgeAnimate }) => {
    const intl = useIntl();

    return (
        <div
            className={`badge-bottom-section${
                standardBadgeAnimate ? ' bottom-animate' : ''
            }`}
        >
            {item.nextLevelCount ? (
                <div className="play-count">
                    <img src={icon_check} alt="icon-check" />
                    {badgeBottomText(item, intl)}
                    {/* <p>Play {item.nextLevelCount} games</p> */}
                </div>
            ) : (
                <div className="complete-badge">
                    <img src={icon_complete} alt="icon-check" />
                    <p>{intl.messages.badges.complete}!</p>
                </div>
            )}
            {item.nextLevelCount && (
                <div className="badge-bottom-chip">
                    {item.nextLevelReward.map((reward) => (
                        <div key={reward.type}>
                            {reward.type === 'red' && (
                                <div className="badge-bottom-chip-count">
                                    <span>{reward.chip}</span>
                                    <img src={red_small} alt="#" />
                                </div>
                            )}
                            {reward.type === 'yellow' && (
                                <div className="badge-bottom-chip-count">
                                    <span>{reward.chip}</span>
                                    <img src={yellow_small} alt="#" />
                                </div>
                            )}
                            {/* <BadgeImage ref={badgeRefs[i]} */}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default BadgeBottomSection;
