import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import {
    battle,
    checked,
    red_chips_reward,
    star,
    user,
} from '../../../../../assets/image.assets';
import ThinBar from '../../../../../components/team-game/bars/thin-bar/thin-bar';
import HexFlipper from '../../../../../components/team-game/flippers/hex-flipper/hex-flipper';
import SecondaryLabel from '../../../../../components/team-game/labels/secondary-label/secondary-label';
import StandardLabel from '../../../../../components/team-game/labels/standard-label/standard-label';
import DeadBodyUser from '../../../../../components/team-game/users/dead-body/dead-body-user';
import { formatNumberWithSpaces } from '../../../../../utils/formatter.util';

const MyTeamTableItem = ({ item, member = {}, index, battlePeriod }) => {
    const [flippedSide, setFlippedSide] = useState(1);
    const intl = useIntl();

    let flipTimeout = 0;

    const flip = () => {
        clearTimeout(flipTimeout);
        flipTimeout = setTimeout(() => {
            setFlippedSide(flippedSide === 1 ? 2 : 1);
        }, 3000);
    };

    useEffect(() => {
        if (item.prize > 0 && battlePeriod === 'past' && index === 1) {
            flip();
        }
        return () => {
            clearTimeout(flipTimeout);
        };
    }, []);

    useEffect(() => {
        if (item.prize > 0 && battlePeriod === 'past' && index === 1) {
            flip();
        }
    }, [flippedSide]);

    return (
        <tr className={!item.active ? 'not-active' : ''}>
            <td>
                <div className="place">
                    <StandardLabel text={index + 1} fontSize={15} />
                </div>
            </td>
            <td>
                <div className="user-personal">
                    <div className="user-img">
                        {item.active ? (
                            <img
                                src={member.avatar || user}
                                alt="user"
                                onError={(ev) => {
                                    ev.target.onerror = null;
                                    ev.target.src = user;
                                }}
                            />
                        ) : (
                            <DeadBodyUser />
                        )}

                        {item.active && member.level !== undefined && (
                            <div className="user-level">
                                <span>{member.level}</span>
                            </div>
                        )}
                    </div>
                    <div className="user-name">
                        <StandardLabel text={member.name} fontSize={15} />

                        <div className="position">
                            {item.active ? (
                                <span>
                                    {intl.formatMessage({
                                        id: member.leader ? 'leader' : 'member',
                                    })}
                                </span>
                            ) : (
                                <span>
                                    <FormattedMessage id={'left_game'} />
                                </span>
                            )}
                            {item.leader && <img src={checked} alt="leader" />}
                        </div>
                    </div>
                </div>
            </td>
            <td>
                <div className={'battle-data'}>
                    <img src={battle} alt="battle hex" />
                    <div className="game-count-wrapper">
                        <StandardLabel
                            text={item.games + ' / ' + item.maxGames}
                            fontSize={15}
                        />
                        <ThinBar
                            points={item.games}
                            maxPoints={item.maxGames}
                            color={'#871B24'}
                        />
                    </div>
                </div>
            </td>
            <td>
                <div className={'star-data'}>
                    {item.prize ? (
                        <HexFlipper
                            allowFlip={battlePeriod === 'past'}
                            firstImage={star}
                            secondImage={
                                battlePeriod === 'past' && red_chips_reward
                            }
                            _onFlip={battlePeriod === 'past' && setFlippedSide}
                        />
                    ) : (
                        <HexFlipper firstImage={star} />
                    )}
                    <div className="star-chip-count-wrapper">
                        <div
                            className={
                                'count-wrapper star-count' +
                                (flippedSide === 1 ? ' active' : '')
                            }
                        >
                            <StandardLabel
                                text={formatNumberWithSpaces(item.stars)}
                                fontSize={15}
                            />
                            <SecondaryLabel
                                text={intl.formatMessage({
                                    id: 'stars',
                                })}
                                fontSize={12}
                            />
                        </div>
                        <div
                            className={
                                'count-wrapper chip-count' +
                                (flippedSide === 2 ? ' active' : '')
                            }
                        >
                            <StandardLabel
                                text={formatNumberWithSpaces(item.prize)}
                                fontSize={15}
                            />
                            <SecondaryLabel
                                text={intl.formatMessage({
                                    id: 'chips',
                                })}
                                fontSize={12}
                            />
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default MyTeamTableItem;
