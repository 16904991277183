import React from 'react';
import './star.scss';
import imageLoader from '../../loaders/image.loader';

const Star = ({ type }) => {
  return (
    <div className="star">
      <div className="star_img">
        <img src={imageLoader.getImage('star_inActive')} alt="star" />
      </div>

      <div className="star_type">
        {type === 'active' ? (
          <img src={imageLoader.getImage('star_active')} alt="star" />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
export default Star;
