import React from 'react';
import './user-score.scss';

const UserScore = ({ seat = '', score = 0, team, teamOne, teamFirst }) => {
    return (
        <div className={`user_score_${seat}`}>
            {teamOne ? (
                <div>
                    {seat === 1 && <span>{teamOne.score}</span>}
                    {seat === 4 && <span>{teamFirst.score}</span>}
                </div>
            ) : (
                <>
                    {!team &&
                        team.length === 0 &&
                        (seat === 1 || seat === 4) && <span>0</span>}
                    {team === 0 && seat === 1 && <span>{score[0]}</span>}
                    {team === 0 && seat === 4 && <span>{score[1]}</span>}
                    {team === 1 && seat === 1 && <span>{score[1]}</span>}
                    {team === 1 && seat === 4 && <span>{score[0]}</span>}
                </>
            )}
        </div>
    );
};
export default UserScore;
