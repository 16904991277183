import { createSlice } from '@reduxjs/toolkit';
export const gameConfigSlice = createSlice({
    name: 'gameConfig',
    initialState: {
        gameConfigData: [],
    },
    reducers: {
        setGameConfigData: (state, action) => {
            state.gameConfigData = action.payload;
        },
    },
});

export const { setGameConfigData } = gameConfigSlice.actions;

export default gameConfigSlice.reducer;
