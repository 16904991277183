import React from 'react';

import './thin-button.scss';

const ThinButton = ({ type = 1, text, _onClick = () => {}, isActive }) => {
    let typeClass = 'red';

    switch (type) {
        case 2:
            typeClass = 'dark';
            break;
        case 3:
            typeClass = 'silver';
            break;
    }

    if (isActive) {
        typeClass += ' active';
    }

    return (
        <button className={'thin-button ' + typeClass} onClick={_onClick}>
            {text}
        </button>
    );
};

export default ThinButton;
