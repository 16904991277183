const jwtChangeEnum = {
    CREATE: 0,
    JOIN: 1,
    KICK: 2,
    LEAVE: 3,
};

Object.freeze(jwtChangeEnum);

export default jwtChangeEnum;
