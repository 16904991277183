import React from 'react';
import './round-score-line.scss';

const RoundScoreLine = ({ text = '', valueUs,valueThem, team, scoreUs, scoreThem }) => {
    return (
        <tbody className="round-score-line">
            <tr className="round-score-one-line">
                <td className={text ? 'round-score-text' : 'round-score-empty'}>
                    {text}
                </td>
                <td className="round-score-item">
                    {scoreUs || (valueUs ? valueUs : '-')}
                </td>

                <td className="round-score-item">
                    {scoreThem || (valueThem ? valueThem : '-')}
                </td>
            </tr>
        </tbody>
    );
};
export default RoundScoreLine;
