import React from 'react';
import { arrow_icon } from '../../../assets/image.assets';
import './back-button.scss';

const BackButton = ({ _onClose = () => {}, text }) => {
  return (
    <div className="back_lobby" onClick={_onClose}>
      <img src={arrow_icon} />
      <span>{text}</span>
    </div>
  );
};
export default BackButton;
