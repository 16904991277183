import PIXI from '../wrappers/pixi.wrapper';
import pixiLoader from '../loaders/pixi.loader';
import cardNames from './cardNames';

export default class Card extends PIXI.Sprite {
    constructor(user, texture) {
        super(texture || pixiLoader.getImage('images/cards/cover.png').texture);
        this.user = user;

        // this.anchor.set(0.5);
        // this.pivot.set(this.width / 2, this.height / 2);

        this.interactive = true;
        this.played = false;
        this.handNumber = 0;
        this.cursor = 'pointer';
    }

    static getCardImage(cardId) {
        const cardObj = cardNames[cardId];
        if (!cardObj) {
            return pixiLoader.getImage('images/cards/cover.png');
        }
        return pixiLoader.getImage(
            `images/cards/C${cardObj.suit}${cardObj.value}.png`
        );
    }

    changeCard(cardId) {
        this.texture = cardId
            ? Card.getCardImage(cardId).texture
            : pixiLoader.getImage('images/cards/C00.png').texture;
    }

    coverCard() {
        this.texture = pixiLoader.getImage('images/cards/cover.png').texture;
    }
}
