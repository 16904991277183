import React from 'react';
import PopupsEnum from '../enums/popups.enum';

import NotEnoughRed from './not-enough/not-enough-red/not-enough-red';
import './popups.base.scss';
import FirstTimeUsePopup from './team-game-popup/first-time-use/first-time-use.popup';
import LeavePopup from './team-game-popup/leave-popup/leave-popup';
import RequestLimitPopup from './team-game-popup/request-limit/request-limit-popup';

const JoinTeamPopup = React.lazy(() =>
    import('./team-game-popup/join-team/join-team.popup')
);
const GreenPopup = React.lazy(() =>
    import('./green-chip-popup/green-chip-popup')
);
const SettingsPopup = React.lazy(() =>
    import('./settings-popup/settings-popup')
);
const GiftFormPopup = React.lazy(() =>
    import('./gift-form-popup/gift-form-popup')
);
const GiftCancelPopup = React.lazy(() =>
    import('./gift-form-popup/gift-cancel-popup/gift-cancel-popup')
);
const VipPopup = React.lazy(() => import('./vip-popup/vip-popup'));
const VipInfoPopup = React.lazy(() =>
    import('./vip-popup/vip-info-popup/vip-info-popup')
);
const VipNicknamePopup = React.lazy(() =>
    import('./vip-popup/vip-nickname-popup/vip-nickname-popup')
);
const LevelUpPopup = React.lazy(() =>
    import('./level-up-popup/level-up-popup')
);

const AdminChipPopup = React.lazy(() =>
    import('./admin-chip-popup/admin-chip-popup')
);
const NotEnoughGold = React.lazy(() =>
    import('./not-enough/not-enough-gold/not-enough-gold')
);
const NotEnoughGreen = React.lazy(() =>
    import('./not-enough/not-enough-green/not-enough-green')
);
const TournamentTable = React.lazy(() =>
    import('./tournament/tournament-table/tournament-table')
);
const TournamentRewards = React.lazy(() =>
    import('./tournament/tournament-rewards/tournament-rewards')
);
const TournamentFinished = React.lazy(() =>
    import('./tournament/tournament-finished/tournament-finished')
);
const TournamentResult = React.lazy(() =>
    import('./tournament/tournament-result/tournament-result')
);
const ExitGamePopup = React.lazy(() =>
    import('./exit-game-popup/exit-game-popup')
);
const UnfollowPopup = React.lazy(() =>
    import('./tournament/unfollow-popup/unfollow-popup')
);
const BadgePopup = React.lazy(() => import('./badge-popup/badge-popup'));
const CreateTeam = React.lazy(() =>
    import('./team-game-popup/creation-price/creation-price.popup')
);
const GotItPopup = React.lazy(() =>
    import('./team-game-popup/gotItPopup/gotIt.popup')
);

const InvitePlayersPopup = React.lazy(() =>
    import('./team-game-popup/invite-players/invite-players.popup')
);

const PromptPopup = React.lazy(() =>
    import('./team-game-popup/prompt-popup/prompt-popup')
);
const FirePlayerPopup = React.lazy(() =>
    import('./team-game-popup/fire-player/fire-player-popup')
);
const TournamentUnlockPopup = React.lazy(() =>
    import('./tournament-unlock-popup/tournament-unlock-popup')
);
const _onClose = (closePopup) => {
    closePopup();
};

const getPopup = ({
    popupId,
    popupData,
    closePopup,
    nextPopup,
    prevPopup,
    childPopup,
    navigatePopup,
}) => {
    switch (popupId) {
        case PopupsEnum.GREEN_POPUP:
            return <GreenPopup _onClose={() => _onClose(closePopup)} />;
        case PopupsEnum.SETTINGS_POPUP:
            return <SettingsPopup _onClose={() => _onClose(closePopup)} />;
        case PopupsEnum.GIFT_FORM_POPUP:
            return <GiftFormPopup _onClose={() => _onClose(closePopup)} />;
        case PopupsEnum.VIP_POPUP:
            return <VipPopup _onClose={() => _onClose(closePopup)} />;
        case PopupsEnum.VIP_INFO_POPUP:
            return <VipInfoPopup _onClose={() => _onClose(closePopup)}  />;
        case PopupsEnum.GIFT_CANCEL_POPUP:
            return <GiftCancelPopup _onClose={() => _onClose(closePopup)} />;
        case PopupsEnum.VIP_NICKNAME_POPUP:
            return <VipNicknamePopup _onClose={() => _onClose(closePopup)} popupData={popupData}/>;
        case PopupsEnum.LEVEL_UP:
            return (
                <LevelUpPopup
                    _onClose={() => _onClose(closePopup)}
                    popupData={popupData}
                />
            );
        case PopupsEnum.ADMIN_CHIP_POPUP:
            return (
                <AdminChipPopup
                    _onClose={() => _onClose(closePopup)}
                    popupData={popupData}
                />
            );
        case PopupsEnum.NOT_ENOUGH_GOLD:
            return <NotEnoughGold _onClose={() => _onClose(closePopup)} />;
        case PopupsEnum.NOT_ENOUGH_GREEN:
            return <NotEnoughGreen _onClose={() => _onClose(closePopup)} />;
        case PopupsEnum.NOT_ENOUGH_RED:
            return <NotEnoughRed _onClose={() => _onClose(closePopup)} />;
        case PopupsEnum.TOURNAMENT_TABLE:
            return (
                <TournamentTable
                    _onClose={() => _onClose(closePopup)}
                    popupData={popupData}
                />
            );
        case PopupsEnum.TOURNAMENT_REWARDS:
            return (
                <TournamentRewards
                    _onClose={() => _onClose(closePopup)}
                    popupData={popupData}
                />
            );
        case PopupsEnum.TOURNAMENT_FINISHED:
            return (
                <TournamentFinished
                    _onClose={() => _onClose(closePopup)}
                    popupData={popupData}
                />
            );
        case PopupsEnum.TOURNAMENT_RESULT:
            return <TournamentResult />;
        case PopupsEnum.EXIT_GAME:
            return <ExitGamePopup _onClose={() => _onClose(closePopup)} />;
        case PopupsEnum.BADGE_POPUP:
            return (
                <BadgePopup
                    _onClose={() => _onClose(closePopup)}
                    popupData={popupData}
                />
            );
        case PopupsEnum.UNFOLLOW_POPUP:
            return (
                <UnfollowPopup
                    _onClose={() => _onClose(closePopup)}
                    popupData={popupData}
                />
            );
        case PopupsEnum.CREATE_TEAM:
            return <CreateTeam popupData={popupData} />;
        case PopupsEnum.GOT_IT:
            return <GotItPopup popupData={popupData} />;
        case PopupsEnum.INVITE_FRIENDS:
            return <InvitePlayersPopup />;
        case PopupsEnum.PROMPT_POPUP:
            return <PromptPopup popupData={popupData} />;
        case PopupsEnum.JOIN_TEAM_POPUP:
            return <JoinTeamPopup popupData={popupData} />;
        case PopupsEnum.FIRE_PLAYER_POPUP:
            return <FirePlayerPopup />;
        case PopupsEnum.TOURNAMENT_UNLOCK:
            return <TournamentUnlockPopup />;
        case PopupsEnum.LEAVE_POPUP:
            return <LeavePopup popupData={popupData} />;
        case PopupsEnum.FIRST_TIME_USE:
            return <FirstTimeUsePopup popupData={popupData} />;
        case PopupsEnum.REQUEST_LIMIT:
            return <RequestLimitPopup popupData={popupData} />;
        default:
            return <React.Fragment />;
    }
};

const PopupBase = (props) => {
    return (
        <div className="popup_base">
            <div className="dark_back" onClick={props.closePopup} />
            <div className="popup_base_inner">
                <div className="popup">
                    <React.Suspense fallback={<React.Fragment />}>
                        {getPopup(props)}
                    </React.Suspense>
                </div>
            </div>
        </div>
    );
};

export default PopupBase;
