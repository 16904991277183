import React from 'react';
import './user-gift.scss';
import imageLoader from '../../../loaders/image.loader';
import { openCloseGift } from '../../../redux/menu-game.slice';
import { useDispatch, useSelector } from 'react-redux';

const UserGift = ({ seat = '', userInfo }) => {
    const dispatch = useDispatch();
    const giftOpenedState = useSelector(
        (state) => state.userMenuGame.gift.openedState
    );
    const sendGift = () => {
        dispatch(
            openCloseGift({
                openedState: giftOpenedState === 3 ? 1 : 2,
                userId: userInfo.userId,
            })
        );
    };
    return (
        <div className={`user-gift-${seat}`} onClick={sendGift}>
            <img src={imageLoader.images['send_gift'].src} alt="" />
        </div>
    );
};

export default UserGift;
