import React from 'react';
import { useIntl } from 'react-intl';

import './chip-reward-banner.scss';

import { red_chips_reward } from '../../../../assets/image.assets';
import StandardLabel from '../../labels/standard-label/standard-label';

const ChipRewardBanner = ({ reward = 0 }) => {
    const intl = useIntl();
    return (
        <div className={'chip-reward-banner'}>
            <div className="inner-wrapper">
                <img
                    src={red_chips_reward}
                    alt="chips"
                    className={'reward-chips'}
                />
                <div className={'reward-text'}>
                    <span className={'reward-info'}>
                        {intl.messages.team_game.team_reward}
                    </span>
                    <StandardLabel
                        text={reward + ' ' + intl.messages.team_game.chips}
                        fontSize={20}
                    />
                </div>
            </div>
        </div>
    );
};

export default ChipRewardBanner;
