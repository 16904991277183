import React, { useEffect, useState, createRef } from 'react';

import './animating-chip.scss';

import { yellow, red } from '../../../../assets/image.assets';

export default function AnimatingChip({
    delay,
    initialStyles = {},
    animatedStyles = [],
    type,
}) {
    const [currentStyles, setCurrentStyles] = useState(initialStyles);
    const timeoutRef = createRef();

    useEffect(() => {
        timeoutRef.current = setTimeout(() => {
            if (animatedStyles.length) {
                animatedStyles.forEach((animatedStyle) => {
                    setTimeout(() => {
                        setCurrentStyles(animatedStyle.animatingStyle);
                    }, animatedStyle.delay);
                });
            }
        }, delay);

        return function () {
            clearTimeout(timeoutRef.current);
        };
    }, []);
    return (
        <img
            src={type === 'red' ? red : yellow}
            alt="#"
            className="animating-chip"
            style={{
                left: currentStyles.x,
                top: currentStyles.y,
                opacity: currentStyles.opacity,
            }}
        />
    );
}
