import React, { useState } from 'react';
import './standard-slider.scss';
import { arrow_left_icon, arrow_right } from '../../../assets/image.assets';

const activeSlide = (slides) => {
    let activeIndex = 0;

    if (!slides || !slides.length) {
        return activeIndex;
    }

    if (slides.length < 4) {
        return slides.length - 1;
    }

    return 4;
};

const slideMarginLeft = (currentIndex, slides) => {
    let multiplier = -42;

    if (currentIndex > 0) {
        multiplier = -34;
    }

    if (currentIndex === slides.length - 1) {
        multiplier = -36;
    }

    return (currentIndex - 3) * multiplier;
};

const StandardSlider = ({ slides = [] }) => {
    const [currentIndex, setCurrentIndex] = useState(activeSlide(slides));

    if (!slides || !slides.length) {
        return <></>;
    }

    const prevSlide = () => {
        if (currentIndex === 0) {
            return;
        }

        setCurrentIndex(currentIndex - 1);
    };

    const nextSlide = () => {
        if (currentIndex === slides.length - 1) {
            return;
        }

        setCurrentIndex(currentIndex + 1);
    };

    return (
        <div className={'standard_slider'}>
            <img
                src={arrow_left_icon}
                alt="arrow-left"
                className={'arrow-btn'}
                onClick={prevSlide}
            />
            <div className="slides_wrapper">
                <div
                    className="slides"
                    style={{
                        width: (slides.length - 3) * 40 + 2 * 44 + 73 + 'px',
                        marginLeft:
                            slideMarginLeft(currentIndex, slides) + 'px',
                    }}
                >
                    {slides.map((slide, index) => {
                        let additionalClass = '';

                        if (index === currentIndex) {
                            additionalClass = ' active';
                        } else if (index === currentIndex - 1) {
                            additionalClass = ' before_active';
                        } else if (index === currentIndex + 1) {
                            additionalClass = ' after_active';
                        }

                        return (
                            <div
                                className={'slide' + additionalClass}
                                key={slide.key}
                                onClick={() => {
                                    if (slide._onClick) {
                                        slide._onClick();
                                    }
                                }}
                            >
                                <img src={slide.imageURL} alt="badge" />
                            </div>
                        );
                    })}
                </div>
            </div>
            <img
                src={arrow_right}
                alt="arrow-right"
                className={'arrow-btn'}
                onClick={nextSlide}
            />
        </div>
    );
};

export default StandardSlider;
