import React from 'react';
import './score-info.scss';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import roomSingleton from '../../../singletons/room.singleton';

function roomTypeData(type) {
    switch (type) {
        case 'micro':
            return 151;
        case 'big':
            return 151;
        case 'professional':
            return 151;
        case 'bonus':
            return 151;
        default:
            break;
    }
}
const ScoreInfo = ({ score, roomType, team }) => {
    const rooms = useSelector((state) => state.gameConfig.gameConfigData.rooms);
    const intl = useIntl();
    const roomData = roomType ? roomType : roomSingleton.roomType;
    return (
        <div className="score_info">
            <div className="us_score_block">
                <div className="us_score_text">
                    <span> {intl.messages.gamePlay.us}</span>
                </div>
                <div className="us_score">
                    {/* {roomTypeData(roomData)} */}
                    {team === 0 ? (
                        <>
                            <span>{score && score[0]}</span> /{' '}
                            {roomTypeData(roomData)}
                        </>
                    ) : (
                        <>
                            {' '}
                            <span>{score && score[1]}</span> /{' '}
                            {roomTypeData(roomData)}
                        </>
                    )}
                </div>
            </div>
            <div className="them_score_block">
                <div className="them_text">
                    <span>{intl.messages.gamePlay.them}</span>
                </div>
                <div className="them_score">
                    {team === 0 ? (
                        <>
                            <span>{score && score[1]}</span> /{' '}
                            {roomTypeData(roomData)}
                        </>
                    ) : (
                        <>
                            {' '}
                            <span>{score && score[0]}</span> /{' '}
                            {roomTypeData(roomData)}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ScoreInfo;
