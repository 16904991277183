import React from 'react';

import './universal.shield.scss';

import CurvedLabel from '../../labels/curved-label/curved-label';
import {
    bottom_shield_big,
    empty,
    left_shield_big,
    right_shield_big,
    team_ribbon_back,
    team_ribbon_front,
    wood_shield,
} from '../../../../assets/image.assets';

const getHighlightedImg = (highlighted) => {
    switch (highlighted) {
        case 1:
            return left_shield_big;
        case 2:
            return right_shield_big;
        case 3:
            return bottom_shield_big;
    }
};

const UniversalShield = ({
    shield,
    highlighted,
    icons = [],
    text = '',
    withRibbon = true,
    disableOnError,
    inLobby,
    onHighlightClick = () => {},
}) => {
    return (
        <div className={'universal-shield'}>
            {withRibbon && (
                <img
                    src={team_ribbon_back}
                    alt="ribbon back"
                    className={'ribbon-back'}
                />
            )}
            <div className={'shield-wrapper'}>
                <img
                    src={shield || wood_shield}
                    alt="shield"
                    className={'shield-img'}
                    onError={(ev) => {
                        if (!disableOnError) {
                            ev.target.onerror = null;
                            ev.target.src = empty;
                        }
                    }}
                />
                {icons && icons.length ? (
                    icons.map((icon, index) => {
                        return (
                            <img
                                alt={'icon'}
                                src={icon}
                                key={'icon-' + index}
                                className={
                                    'shield-icon shield-icon-' + (index + 1)
                                }
                            />
                        );
                    })
                ) : (
                    <></>
                )}
                {highlighted > 0 && (
                    <img
                        src={getHighlightedImg(highlighted)}
                        alt="highlight"
                        className={'highlighted-image'}
                    />
                )}
                <div
                    className="click_highlight top_left"
                    onClick={() => onHighlightClick(1)}
                />
                <div
                    className="click_highlight top_right"
                    onClick={() => onHighlightClick(2)}
                />
                <div
                    className="click_highlight bottom"
                    onClick={() => onHighlightClick(3)}
                />
            </div>
            {withRibbon && (
                <div className="ribbon-wrapper">
                    <img
                        src={team_ribbon_front}
                        alt="ribbon front"
                        className={'ribbon-front'}
                    />
                    <div
                        className={
                            'curved-shield-text-wrapper' +
                            (inLobby ? ' in-lobby' : '')
                        }
                        style={{
                            bottom:
                                (text.length * 0.5 < 5
                                    ? 5
                                    : text.length * 0.5) + 'px',
                        }}
                    >
                        <CurvedLabel text={text} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default UniversalShield;
