import React from 'react';
import {
    diamonds_icon,
    hearts_icon,
    clubs_icon,
    spade_icon,
    boy,
} from '../../assets/image.assets';
import { groupByAnnounce } from '../../helpers/groupBy.helper';
import './tooltip.scss';

const Tooltip = ({
    seat,
    suit,
    pass,
    valueSuit,
    showAnnouncement,
    text,
    isCapout,
}) => {
    const anounce = showAnnouncement && groupByAnnounce(showAnnouncement);
    const anouncement = [];

    for (const type in anounce) {
        anouncement.push({
            type,
            value: anounce[type],
        });
    }

    function getTooltipSuit(suit) {
        const images = [
            hearts_icon,
            diamonds_icon,
            spade_icon,
            clubs_icon,
            boy,
        ];
        return images[suit];
    }
    function typeQuarte(item) {
        if (item.type === 'quarte') {
            return '50';
        }
        if (item.type === 'quint') {
            return '100';
        }
        if (item.type !== 'quarte' && item.type !== 'quint') {
            return item.type;
        }
    }
    return (
        <div className={`tooltip-${seat} tooltip`}>
            <div className="tooltip-suit">
                {text && <span className="contra-text">{text}</span>}
            </div>

            {anounce && (
                <div className="tooltip-suit">
                    {anouncement.map((item) => (
                        <React.Fragment key={item.type}>
                            {item.value > 1 ? (
                                <div className="pass-say-value">
                                    {typeQuarte(item)} x {item.value}
                                </div>
                            ) : (
                                <div className="item-text">
                                    <span> {typeQuarte(item)}</span>
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            )}
            {pass && (
                <div className="tooltip-suit">
                    <span className="pass-say">Pass</span>
                </div>
            )}
            {valueSuit && (
                <div className="tooltip-suit">
                    <img
                        src={getTooltipSuit(suit)}
                        className={`${
                            isCapout ? 'suit-img-capout' : 'suit-img'
                        }`}
                    />

                    <span
                        className={`${
                            isCapout ? 'say-value-capout' : 'say-suit'
                        }`}
                    >
                        {valueSuit ? valueSuit : ''}
                    </span>
                    {isCapout && <span className="say-suit-capout">CP</span>}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
