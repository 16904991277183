import React, { useState, useEffect, createRef, useRef } from 'react';

import './badges.scss';

import { badgesList_req, newBadges_req } from '../../api/badges/badges.api';
import { achievement, badges_level } from '../../assets/image.assets';
import Background from '../../components/background/background';
import BackButton from '../../components/buttons/back-button/back-button';
import LeftSection from './components/left-section/left-section';
import RightSection from './components/right-section/right-section';

import positionsSingleton from '../../singletons/positions.singleton';
import AnimatingChip from './components/animating-chip/animating-chip';
import { useIntl } from 'react-intl';
import userSingleton from '../../singletons/user.singleton';
import BadgeItem from './components/badge-item/badge-item';
import { useDispatch, useSelector } from 'react-redux';
import routesEnum from '../../enums/routes.enum';
import { navigate } from '../../redux/global.slice';
import positionsHelper from '../../helpers/positions.helper';
import { Player } from '@lottiefiles/react-lottie-player';

const redPositionAddValues = [
    {
        x: 0,
        y: 0,
    },
    {
        x: 10,
        y: 5,
    },
    {
        x: 20,
        y: -5,
    },
    {
        x: 10,
        y: 10,
    },
    {
        x: 10,
        y: 10,
    },
];

function renderAnimatingChip(type) {
    return [0, 1, 2, 3, 4].map((value) => {
        return (
            <AnimatingChip
                delay={100}
                index={value}
                initialStyles={positionsSingleton.getPosition(
                    type === 'red' ? 'badge_red' : 'badge_yellow'
                )}
                type={type}
                animatedStyles={[
                    {
                        delay: 0,
                        animatingStyle: {
                            x:
                                positionsSingleton.getPosition(type).x +
                                150 +
                                redPositionAddValues[value].x,
                            y:
                                positionsSingleton.getPosition(type).y +
                                150 +
                                redPositionAddValues[value].y,
                            opacity: 1,
                        },
                    },
                    {
                        delay: 1000,
                        animatingStyle: {
                            ...positionsSingleton.getPosition(type),
                            opacity: 0,
                        },
                    },
                ]}
            />
        );
    });
}
const changeAnimate = ({
    newBadges,
    item,
    animatingChip,
    secondAnimate,
    lastBadgeAnimate,
    newBadgeIndex,
}) => {
    if (
        newBadges &&
        newBadges[newBadgeIndex] &&
        newBadges[newBadgeIndex].bage === item.bage
    ) {
        if (!animatingChip) {
            return 1;
        } else if (animatingChip && !secondAnimate && !lastBadgeAnimate) {
            return 3;
        } else if (secondAnimate && !lastBadgeAnimate) {
            return 2;
        } else if (lastBadgeAnimate) {
            return 4;
        }
    } else {
        return 0;
    }
};

const Badges = () => {
    const intl = useIntl();
    const dispatach = useDispatch();
    const parentRef = useRef();
    const itemRefs = useRef([]);
    const [badgesList, setBadgesList] = useState([]);
    const [newBadges, setNewBadges] = useState([]);
    const [newBadgeIndex, setNewBadgeIndex] = useState(-1);
    const [animateScreen, setAnimateScreen] = useState(-1);
    const [animatingChip, setAnimatingChip] = useState(false);
    const [toPositionInited, setToPositionInited] = useState(false);
    const [standardBadgeAnimate, setStandardBadgeAnimate] = useState(false);
    const [secondAnimate, setSecondAnimate] = useState(false);
    const [animateNewChip, setAnimateNewChip] = useState(true);
    const [lastBadgeAnimate, setLastBadgeAnimate] = useState(false);
    const redChipRef = createRef();
    const yellowChipRef = createRef();
    const badgesData = useSelector((state) => state.global.badgesData);

    const animatedBadges = () => {
        setAnimatingChip(true);

        if (redChipRef.current) {
            const relativePosition = redChipRef.current.getBoundingClientRect();

            positionsSingleton.setPosition(
                'badge_red',
                relativePosition.left,
                relativePosition.top
            );
            positionsSingleton.setPosition(
                'badge_yellow',
                relativePosition.left,
                relativePosition.top
            );

            setToPositionInited(true);
        }
        if (yellowChipRef.current) {
            const yellowChipPosition = redChipRef.current.getBoundingClientRect();

            positionsSingleton.setPosition(
                'badge_yellow',
                yellowChipPosition.left,
                yellowChipPosition.top
            );

            setToPositionInited(true);
        }

        setAnimateNewChip(false);

        setTimeout(() => {
            if (newBadges && newBadges[newBadgeIndex + 1]) {
                setAnimatingChip(false);
                setToPositionInited(false);
                setSecondAnimate(false);
                setStandardBadgeAnimate(false);
                setLastBadgeAnimate(false);
                setTimeout(() => {
                    setNewBadgeIndex(newBadgeIndex + 1);
                }, 1000);
            }
        }, 5000);
    };
    // useEffect(() => {

    //     const data = ref.current.scrollIntoView({
    //         behavior: 'smooth',
    //         block: 'start',
    //     });

    // }, []);

    useEffect(() => {
        const badgesListPage = async () => {
            const jwt = userSingleton.jwt;
            try {
                const badges = await badgesList_req(jwt);

                if (badges && badges.length) {
                    setBadgesList(badges);

                    if (badgesData && badgesData.length) {
                        setNewBadges(badgesData);
                        setNewBadgeIndex(0);
                    }
                }
            } catch (e) {}
        };

        badgesListPage();
    }, []);

    const scrollToBadgeItem = () => {
        if (parentRef.current && itemRefs.current && itemRefs.current.length) {
            const foundIndex = badgesList.findIndex(
                (badge) => badge.bage === newBadges[newBadgeIndex].bage
            );
            // const foundIndex =
            //     newAchievement && newAchievement[0].bage;
            if (foundIndex !== -1) {
                const itemRef = itemRefs.current[foundIndex];
                parentRef.current.scrollTo({
                    top: itemRef.offsetTop - itemRef.clientHeight / 2,
                    behavior: 'smooth',
                });
            }
        }
    };

    useEffect(() => {
        if (newBadgeIndex !== -1) {
            scrollToBadgeItem();
        }
    }, [newBadgeIndex]);

    const goLobby = () => {
        dispatach(navigate(routesEnum.LOBBY));
    };

    return (
        <div className="badges-page">
            <Background>
                <div className="badges">
                    <div className="back-lobby" onClick={() => goLobby()}>
                        <BackButton text={intl.messages.lobby} />
                    </div>

                    <div className="badges-header">
                        <div className="badges-header-left">
                            <img src={achievement} />
                            <p>{intl.messages.badges.achievements}</p>
                        </div>
                        <div className="badges-header-right">
                            <div className="badges-header-right-block">
                                <img src={achievement} />
                                <div className="badges-header-dashed"></div>
                                <img src={badges_level} />
                                <div className="badges-header-dashed"></div>
                                <img src={achievement} />
                            </div>
                            <div className="badges-header-text">
                                <span>{intl.messages.badges.collect}</span>
                            </div>
                        </div>
                    </div>
                    <div className="badges-list" ref={parentRef}>
                        {badgesList
                            ? badgesList.map((item, index) => {
                                  return (
                                      <div
                                          className="badges-list-block"
                                          key={item.bage}
                                          ref={(ref) =>
                                              (itemRefs.current[index] = ref)
                                          }
                                      >
                                          <div className="badges-block">
                                              <LeftSection item={item} />
                                              <RightSection
                                                  item={item}
                                                  newBadges={newBadges}
                                                  toPositionInited={
                                                      toPositionInited
                                                  }
                                                  animateScreen={changeAnimate({
                                                      newBadges,
                                                      animatingChip,
                                                      item,
                                                      animateScreen,
                                                      secondAnimate,
                                                      lastBadgeAnimate,
                                                      newBadgeIndex,
                                                  })}
                                                  newBadgeIndex={newBadgeIndex}
                                                  setAnimateScreen={
                                                      setAnimateScreen
                                                  }
                                                  animatingChip={animatingChip}
                                                  setAnimatingChip={
                                                      setAnimatingChip
                                                  }
                                                  standardBadgeAnimate={
                                                      standardBadgeAnimate
                                                  }
                                                  setStandardBadgeAnimate={
                                                      setStandardBadgeAnimate
                                                  }
                                                  animatedBadges={
                                                      animatedBadges
                                                  }
                                                  redChipRef={redChipRef}
                                                  yellowChipRef={yellowChipRef}
                                                  setSecondAnimate={
                                                      setSecondAnimate
                                                  }
                                                  setLastBadgeAnimate={
                                                      setLastBadgeAnimate
                                                  }
                                                  lastBadgeAnimate={
                                                      lastBadgeAnimate
                                                  }
                                              />
                                          </div>
                                          <div className="badges-border-block" />
                                      </div>
                                  );
                              })
                            : null}
                    </div>
                    {toPositionInited && renderAnimatingChip('red')}
                    {toPositionInited && renderAnimatingChip('yellow')}
                </div>
            </Background>
        </div>
    );
};

export default Badges;
