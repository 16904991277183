import React from 'react';

import './shield-info.scss';

import { empty } from '../../../../assets/image.assets';

const ShieldInfo = ({ image }) => {
    return (
        <div className={'shield-info'}>
            <img
                src={image}
                alt="shield"
                onError={(ev) => {
                    ev.target.onerror = null;
                    ev.target.src = empty;
                }}
            />
        </div>
    );
};

export default ShieldInfo;
