import React, { useEffect, useState } from 'react';
import './lobby-item.scss';
import {
    cash_game,
    tournament_lobby,
    team_game,
} from '../../assets/image.assets';
import LobbyName from './lobby-name/lobby-name';
import LobbyCashGame from './lobby-cash-game/lobby-cash-game';
import BackButton from '../buttons/back-button/back-button';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '../../redux/global.slice';
import routesEnum from '../../enums/routes.enum';
import { tournamentColor } from '../../redux/theme.change.slice';
import themeEnum from '../../enums/theme.enum';
import { checkBadges_req, newBadges_req } from '../../api/badges/badges.api';
import userSingleton from '../../singletons/user.singleton';
import {
    tournamentPopup_req,
    tournamentPrizeInfo_req,
} from '../../api/tournament/tournament.api';
import { getNotifications_req } from '../../api/team.game/team.game.api';
import { teamNotificationOpen } from '../../redux/team-game/team-game.slice';
import PopupsEnum from '../../enums/popups.enum';
import { openPopups } from '../../redux/popup.slice';
import { userInfo_req } from '../../api/user.api';
import { setUserUpdate } from '../../redux/user.update.slice';
import { setUpdateBoolean } from '../../redux/chip.info.slice';
import { useIntl } from 'react-intl';

const LobbyItem = ({ shouldAnimate }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [renderFirstPage, setRenderFirstPage] = useState(true);
    const [renderSecondPage, setRenderSecondPage] = useState(false);
    const [teamNotification, setTeamNotification] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const me = useSelector((state) => state.me.data);

    const handleChangeToFistPage = () => {
        if (!renderFirstPage) {
            setRenderFirstPage(() => true);
            setPageNumber(() => 0);
            setTimeout(() => {
                setRenderSecondPage(() => false);
            }, 500);
        }
    };

    const handleChangeToSecondPage = () => {
        if (!renderSecondPage) {
            setRenderSecondPage(() => true);
            setPageNumber(() => 1);
            setTimeout(() => {
                setRenderFirstPage(() => false);
            }, 500);
        }
    };
    const userUpdateReq = async () => {
        const userUpdateChip = await userInfo_req(me.userId);
        dispatch(setUpdateBoolean(true));
        dispatch(setUserUpdate(userUpdateChip));
    };
    const openTeamNotification = () => {
        dispatch(teamNotificationOpen(true));
    };
    const handleTeamGamePage = () => {
        userUpdateReq();
        dispatch(navigate(routesEnum.LEAGUE_INTRODUCTION));
        dispatch(tournamentColor(themeEnum.TEAMGAME));
    };
    const handleTournamentPage = () => {
        dispatch(navigate(routesEnum.TOURNAMENT));
        dispatch(tournamentColor(themeEnum.TOURNAMENT));
    };
    const tournamentPopup = async () => {
        try {
            const tournamentPopupData = await tournamentPopup_req();

            const tournamentEndPopup =
                tournamentPopupData.data &&
                tournamentPopupData.data.filter(
                    (item) => item.type === 'endTournamentGames'
                );

            if (tournamentEndPopup[0].type === 'endTournamentGames') {
                dispatch(
                    openPopups({
                        popupIds: [PopupsEnum.TOURNAMENT_FINISHED],
                        popupData: tournamentPopupData,
                    })
                );
            }
        } catch (e) {}
    };

    const getNotificationsInfo = async () => {
        try {
            const teamNotificationInfo = await getNotifications_req();
            setTeamNotification(teamNotificationInfo);
        } catch (e) {}
    };
    const checkNewBadges = async () => {
        const jwt = userSingleton.jwt;
        try {
            const newBadges = await checkBadges_req(jwt);
            if (newBadges.status) {
                // dispatch(navigate(routesEnum.BADGES));
                const newBadgesList = async () => {
                    const jwt = userSingleton.jwt;
                    try {
                        const newAchievement = await newBadges_req(jwt);

                        if (newAchievement && newAchievement.length) {
                            dispatch(
                                openPopups({
                                    popupIds: [PopupsEnum.BADGE_POPUP],
                                    popupData: [newAchievement],
                                })
                            );
                        }
                    } catch (e) {}
                };

                newBadgesList();
            }
        } catch (e) {}
    };
    useEffect(() => {
        checkNewBadges();
        tournamentPopup();
        getNotificationsInfo();
    }, []);

    return (
        <div className="lobby_content">
            <div
                className={`lobby_item${
                    pageNumber ? ' move_to_top' : '' || ' move_to_bottom'
                }`}
            >
                {renderFirstPage && (
                    <div className="lobby_menu">
                        <div
                            className={`lobby_games${
                                shouldAnimate ? ' to_animate' : ''
                            }`}
                        >
                            <div
                                className="lobby_game"
                                onClick={() => handleChangeToSecondPage()}
                            >
                                <img
                                    className="cash_game"
                                    src={cash_game}
                                    alt="tournament"
                                />
                                <LobbyName
                                    text={intl.messages.cashGames}
                                    type={3}
                                />
                            </div>
                            <div
                                className="lobby_game"
                                onClick={() => handleTeamGamePage()}
                            >
                                <img
                                    className="cash_game"
                                    src={team_game}
                                    alt="team-game"
                                />
                                <span className="lobby-teaminfo">
                                    {me.teamInfo.name}
                                </span>
                            </div>
                            <div
                                className="lobby_game"
                                onClick={() => handleTournamentPage()}
                            >
                                <img
                                    className="cash_game"
                                    src={tournament_lobby}
                                    alt="cashGame"
                                />
                                <LobbyName
                                    text={intl.messages.lobbyTournament}
                                    type={3}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {renderSecondPage && (
                    <div className="cash_game_menu">
                        <div
                            className={`cash_games${
                                shouldAnimate ? ' to_animate' : ''
                            }`}
                        >
                            <div onClick={() => handleChangeToFistPage()}>
                                <BackButton text={intl.messages.lobby} />
                            </div>
                            <div className="cash_game_menu_block">
                                <LobbyCashGame />
                            </div>
                        </div>
                    </div>
                )}
                <div
                    className="team-game-notification"
                    onClick={openTeamNotification}
                >
                    <span> {teamNotification.length}</span>
                </div>
            </div>
        </div>
    );
};
export default LobbyItem;
