import React, { createRef } from 'react';
import BadgeBottomSection from '../badge-bottom-section/badge-bottom-section';
import BadgeImage from '../badge-image/badge-image';
import './last-animate-badge.scss';

const LastAnimateBadge = ({ badgesRefs, item, lastBadgeAnimate }) => {
    const badgeCount = 5;
    for (let i = 0; i < badgeCount; i++) {
        badgesRefs.push(createRef());
    }
    return (
        <div className="last-badges-list">
            <div className={`badge-images`}>
                {badgesRefs.map((ref, index) => (
                    <BadgeImage
                        key={'badge-' + index}
                        badgeRef={ref}
                        image={
                            item.level >= index + 1
                                ? `https://testcdn.blotclub.am/tg/badges/${item.bage}/${item.bage}-${index}.png`
                                : `https://testcdn.blotclub.am/tg/badges/empty/empty-${index}.png`
                        }
                        index={index}
                        item={item}
                        standardBadgeAnimate={lastBadgeAnimate}
                    />
                ))}
            </div>
            <div
                className={`badge-bottom-animate${
                    item.nextLevelCount && lastBadgeAnimate ? ' animate' : ''
                }`}
            >
                <BadgeBottomSection
                    item={item}
                    standardBadgeAnimate={lastBadgeAnimate}
                />
            </div>
        </div>
    );
};
export default LastAnimateBadge;
