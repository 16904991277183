import React from 'react';
import { wheel_tab, wheel_not_active } from '../../../assets/image.assets';
import './wheel-tab.scss';

const WheelTab = ({ data }) => {
    return (
        <div className="wheel-tab">
            <div className="wheel-bg">
                <div className="wheel-content">
                    {data.discountTimer > 0 ? (
                        <img src={wheel_tab} alt="#" />
                    ) : (
                        <img src={wheel_not_active} alt="#" />
                    )}

                    {data.discountTimer <= 0 && (
                        <div className="wheel-text">
                            <span>Try again later</span>
                        </div>
                    )}
                </div>
                <div
                    className={`${
                        data.discountTimer > 0
                            ? 'wheel-under'
                            : 'wheel-no-active'
                    }`}
                >
                    <span>free spin</span>
                </div>
                <div className="button-layer"></div>
            </div>
        </div>
    );
};

export default WheelTab;
