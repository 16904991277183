import React from 'react';
import SecondaryLabel from '../../labels/secondary-label/secondary-label';
import StandardLabel from '../../labels/standard-label/standard-label';

import './gradient-line-info.scss';

const GradientLineInfo = ({ leftSide, rightSide }) => {
    return (
        <div className={'gradient-line'}>
            <div className="gradient-line-left-side">
                {leftSide && leftSide.image && (
                    <img
                        src={leftSide.image}
                        alt="gradient image"
                        className={'left-side-image'}
                    />
                )}
                {leftSide && leftSide.text && <p>{leftSide.text}</p>}
            </div>
            <div className="gradient-line-right-side">
                {rightSide && rightSide.length ? (
                    rightSide.map((data) => {
                        return (
                            <div className="right-side-info" key={data.key}>
                                {data.top && (
                                    <div className="right-side-info-top">
                                        {data.top.icon && (
                                            <img src={data.top.icon} />
                                        )}
                                        {data.top.value !== undefined && (
                                            <StandardLabel
                                                text={data.top.value}
                                                fontSize={15}
                                            />
                                        )}
                                    </div>
                                )}

                                {data.bottomValue !== undefined && (
                                    <div className="right-side-info-bottom">
                                        <SecondaryLabel
                                            text={data.bottomValue}
                                            fontSize={12}
                                        />
                                    </div>
                                )}
                            </div>
                        );
                    })
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default GradientLineInfo;
