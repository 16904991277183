import React from 'react';
import TourLocked from './tour-locked-line/tour-locked-line';
import './tournament-locked.scss';
import {
    tour_cup,
    tour_knifes,
    tour_member,
    tour_reward,
    tour_locked,
} from '../../../../assets/image.assets';
import { useDispatch } from 'react-redux';
import { navigate } from '../../../../redux/global.slice';
import routesEnum from '../../../../enums/routes.enum';

const TournamentLocked = () => {
    const dispatch = useDispatch();
    const goTourList = () => {
        dispatch(navigate(routesEnum.TOURNAMENT));
    };
    return (
        <div className="tournament-locked">
            <div className="tour-info-text">
                <p>introducING the monthly tournament</p>
                <span>Play all games, gain highest score and get rewards!</span>
            </div>
            <div className="tournament-locked-content">
                <TourLocked
                    imageId={tour_member}
                    text={'Join to tournament during month'}
                />
                <TourLocked
                    imageId={tour_knifes}
                    text={
                        'Play 60 games at any table till the end of tournament'
                    }
                />
                <TourLocked
                    imageId={tour_cup}
                    text={'Gain the scores and win'}
                />
                <TourLocked
                    imageId={tour_reward}
                    text={'Get rewards for first 100 positions.'}
                />
            </div>
            <div className="tournament-locked-button">
                <div className="tour-locked-level">
                    <img src={tour_locked} alt="#" />
                    <div className="tour-locked-block">
                        <span>UNLOCKS ON LEVEL 20</span>
                        <p>
                            Get access to all Tournament features after 20 level
                        </p>
                    </div>
                </div>
                <div className="tour-locked-border"></div>
                <div className="tour-locked-close" onClick={goTourList}>
                    <span>close</span>
                </div>
            </div>
            <div className="tournament-locked-bg"></div>
        </div>
    );
};
export default TournamentLocked;
