import axios from 'axios';
import userSingleton from '../singletons/user.singleton';

const baseURL = 'https://test3.blotclub.am/api';
const baseURLPost = 'https://www.carrier411.com/';
const tournamentURL = 'https://test3.blotclub.am/api/v1/tournament/';

const teamBaseURL =
    process.env.REACT_APP_BUILD_ENV !== 'production'
        ? 'https://test3.blotclub.am/tmapi'
        : 'https://vk.blotclub.am/tgapi';

if (process.env.REACT_APP_BUILD_ENV === 'saturn') {
    teamBaseURL = 'https://saturn.blotclub.am/tgapi';
}

export const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 60000,
});
export const axiosInstancePost = axios.create({
    baseURL: baseURLPost,
    timeout: 60000,
});
export const axiosInstanceTeam = axios.create({
    baseURL: teamBaseURL,
    timeout: 60000,
});
export const axiosInstanceTournament = axios.create({
    baseURL: tournamentURL,
    timeout: 60000,
});
export const axiosInstanceSaturn = axios.create({
    baseURL: 'https://saturn.blotclub.am/tgapi',
    timeout: 60000,
});
const setTeamHeaders = (reqConfig) => {
    reqConfig.headers['Content-Type'] = 'application/json';
    userSingleton.createTeamJwt
        ? (reqConfig.headers.jwt = userSingleton.createTeamJwt)
        : (reqConfig.headers.jwt = userSingleton.teamJwt);

    return reqConfig;
};
const setTournamentHeaders = (reqConfig) => {
    reqConfig.headers['Content-Type'] = 'application/json';
    if (userSingleton.jwt) {
        reqConfig.headers.authorization = userSingleton.jwt;
    }
    return reqConfig;
};

const successResponse = (response) => {
    return response;
};

const errorResponse = (error) => {
    return Promise.reject(error);
};
axiosInstanceTournament.interceptors.request.use(setTournamentHeaders);
axiosInstanceTeam.interceptors.request.use(setTeamHeaders);
axiosInstanceTeam.interceptors.response.use(successResponse, errorResponse);
// axiosInstance.interceptors.request.use(setHeaders);
axiosInstance.interceptors.response.use(successResponse, errorResponse);
