import React from "react";

const RoundScoreOrder = ({text, value, team, scoreUs, scoreThem, contractTeam}) => {
    return (
        <tbody className="round-score-line">
        <tr className="round-score-one-line">
            <td className={text ? 'round-score-text' : 'round-score-empty'}>
                {text}
            </td>
            <td className="round-score-item">
                {scoreUs || (team === contractTeam && value ? value : '-')}
            </td>

            <td className="round-score-item">
                {scoreThem || (team !== contractTeam && value ? value : '-')}
            </td>
        </tr>
        </tbody>
    )
}
export default RoundScoreOrder