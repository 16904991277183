class ApiStore {
    setBaseURL(baseURL) {
        this.baseURL = baseURL;
    }

    setJWT(jwt = '') {
        this.jwt = jwt;
    }

    getBaseURL() {
        return this.baseURL;
    }

    getJWT() {
        return this.jwt;
    }

    getNotifications() {}
}

export default new ApiStore();
