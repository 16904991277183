import React, { useState, useEffect } from 'react';
import cardNames from '../../../pixi/cardNames';
import './anouncement.scss';

const Anouncement = ({ showUserAnnouncement, seat }) => {
    return (
        <div
            className={`announcement announcement-${seat} ${
                seat % 2 === 0 ? 'vertical' : 'horizontal'
            }`}
        >
            {showUserAnnouncement &&
                showUserAnnouncement.map((cards) => {
                    return (
                        <div
                            className={'announce-group'}
                            key={seat}
                            style={{
                                height:
                                    seat === 3
                                        ? '80px'
                                        : 40 * cards.length + 'px',
                                width:
                                    seat === 3
                                        ? cards.length * 40 + 'px'
                                        : '80px',
                            }}
                        >
                            {cards.map((card, index) => {
                                const cardData = cardNames[card];
                                if (!cardData) {
                                    return <></>;
                                }

                                return (
                                    <img
                                        style={{
                                            top:
                                                seat === 3
                                                    ? 0
                                                    : index * 30 + 'px',
                                            left:
                                                seat === 3
                                                    ? index * 25 + 'px'
                                                    : 0,
                                        }}
                                        className={'announce-card'}
                                        src={`images/cards/C${cardData.suit}${cardData.value}.png`}
                                    />
                                );
                            })}
                        </div>
                    );
                })}
        </div>
    );
};

export default Anouncement;
