import React from 'react';
import './round-score-contra.scss';

const RoundScoreContra = ({
    text = '',
    scoreContra = 0,
    scoreContraValue = 0,
}) => {
    const data = scoreContra * scoreContraValue;
    console.log('text',text)
    console.log('scoreContra',scoreContra)
    console.log('scoreContra',scoreContraValue)
    return (
        <tbody className="round_score_line">
            <tr className="round_score_block">
                <td className="round_score_text_contra">
                    <span>{text}</span>
                </td>
                <td className="order_contra">
                    <span>{scoreContra}</span>
                    <span className="order_contra_high">*</span>
                    <span>{scoreContraValue}</span>
                    <span>= {data}</span>
                </td>
            </tr>
        </tbody>
    );
};

export default RoundScoreContra;
