import React from 'react';

import { useIntl } from 'react-intl';

import './team-list-section.scss';

import FillYourTeam from '../../../../components/team-game/info/fill-your-team/fill-your-team-info';
import TeamMemberListItem from '../../../../components/team-game/list-items/team-member/team-member.listItem';
import ShareHex from '../../../../components/team-game/share/share-hex/share-hex';
import { useSelector } from 'react-redux';

const TeamListSection = ({ memberList, setCurrentUserInfo }) => {
    const intl = useIntl();
    let members = memberList ? Object.values(memberList) : [];
    members = members.sort((a, b) => b.stars - a.stars);
    const teamInfo = useSelector((state) => state.teamGame.teamInfo);
    const configs = useSelector((state) => state.teamGame.configs);

    const renderLessMembers = () => {
        // {members.length < 10 ? <FillYourTeam /> : <></>}
        if (members.length < 10) {
            if (teamInfo.leader) {
                return <FillYourTeam />;
            } else {
            }
        }
    };

    const membersListClass = () => {
        if (!teamInfo && teamInfo.leader) {
            return ' with-fill-min';
        }

        if (members.length < 6) {
            return ' with-fill';
        }

        return '';
    };

    return (
        <div className={'team-list-section'}>
            <div className="members-wrapper">
                <div className={'members-list' + membersListClass()}>
                    {members && members.length ? (
                        members.map((member, index) => {
                            return (
                                <TeamMemberListItem
                                    key={member.userId}
                                    position={index + 1}
                                    member={member}
                                    setCurrentUserInfo={setCurrentUserInfo}
                                />
                            );
                        })
                    ) : (
                        <></>
                    )}
                    {renderLessMembers()}
                </div>
            </div>
            {teamInfo.leader &&
                members &&
                members.length < configs.maxMembersCount && (
                    <div className={'share-hex-back'}>
                        <ShareHex
                            // firstLine={intl.messages.share_your_team}
                            firstLine={
                                teamInfo.leader &&
                                intl.messages.team_game
                                    .invite_friends_to_join_your_team
                            }
                            isLeader={teamInfo.leader}
                        />
                    </div>
                )}
        </div>
    );
};

// const mapStateToProps = (state) => {
//     return {
//         teamInfo: state.teamGame.teamInfo,
//         configs: state.teamGame.configs,
//         isMemberListLoading: state.teamGame.isMemberListLoading,
//         memberList: state.teamGame.memberList,
//     };
// };

// const mapDispatchToProps = (dispatch) => {
//     return {
//         setCurrentUserInfo: (userInfo) =>
//             dispatch(teamGameActions.setCurrentUserInfo(userInfo)),
//     };
// };

export default TeamListSection;
