import React, { useState, useEffect } from 'react';
import './base.page.scss';
import { useDispatch, useSelector } from 'react-redux';

import routesEnum from '../enums/routes.enum';

import LoadingPage from './loading/loading.page';
import HomePage from './home/home.page';

import LanguagePage from './language/language.page';
import Badges from './badges/badges';
import GamePlay from './game-play/game.play';
import UserInfo from '../global-components/userInfo/user-info';
import MenuGamePlay from '../global-components/menu-game-play/menu-game-play';
import Gifts from './game-play/gifts/gifts';
import Smiles from './game-play/smiles/smiles';
import Chat from './game-play/chat/chat';
import StorePage from './store-page/store-page';
import { getGameConfig_req } from '../api/game.config.api';
import WheelPage from './wheel/wheel-page';
import { goldChipsData, vipChipsData } from '../redux/notEnough.slice';
import { setGameConfigData } from '../redux/game-config.slice';
import Tournament from './tournament/tournament';
import TournamentDashboard from './tournament/tournament-dashboard/tournament-dashboard';
import TournamentInfo from './tournament/tournament-info/tournament-info';
import UserPanel from '../components/panels/userPanel/user.panel';

import LeagueIntroduction from './team-game/league-introduction/league-introduction';
import JoinTeam from './team-game/join-team/join-team';
import TeamDashboard from './team-game/team-dashboard/team-dashboard';
import MoreInfoSection from '../sections/team-game/teamInfo/more-info/more-info-section';
import StartBattle from '../global-components/start-battle/start-battle';
import TeamNotifications from '../global-components/team-notifications/team-notifications';
import { teamNotificationData } from '../redux/team-game/team-game.slice';
import { getNotifications_req } from '../api/team.game/team.game.api';
import ChooseTeamLogoSection from '../sections/team-game/choose-team-logo/choose-team-logo-section';
import {
    leftShowState,
    rightShowState,
} from '../helpers/team-game/divided-view.helper';
import ChooseTeamNameSection from '../sections/team-game/create-team/choose-team-name/choose-team-name-section';
import JoiningConditionsSection from '../sections/team-game/create-team/joining-conditions/joining-conditions-section';
import { tournamentPopup_req } from '../api/tournament/tournament.api';

import CongratsSection from '../sections/team-game/create-team/congrats/congrats-section';

const BasePage = () => {
    const dispatch = useDispatch();
    const [pageData, setPageData] = useState({
        page: 1,
        data: null,
    });
    const [configData, setConfigData] = useState([]);
    const [userInfoUpdate, setUserInfoUpdate] = useState([]);
    const [teamUserNotificataion, setTeamUserNotification] = useState([]);
    const [open, setOpen] = useState(true);

    const route = useSelector((state) => state.global.route);
    const userInfoData = useSelector((state) => state.userInfo.data);
    const rightMenu = useSelector((state) => state.userMenuGame.rightMenu);
    const giftMenu = useSelector((state) => state.userMenuGame.gift);
    const smileOpened = useSelector((state) => state.userMenuGame.smile);
    const chatOpened = useSelector((state) => state.userMenuGame.chat);

    const teamNotification = useSelector(
        (state) => state.teamGame.notificationOpen
    );

    const me = useSelector((state) => state.me.data);
    const openCloseStartBattleStart = useSelector(
        (state) => state.teamGame.startBattleOpened
    );
    const tournamentPopup = async () => {
        const tournamentPopupReq = await tournamentPopup_req();

        // const tournamentAvailable = tournamentPopupReq.data.includes(
        //     (item) => item.type === 'tournamentAvailable'
        // );

        // if (tournamentAvailable) {
        //     dispatch(
        //         openPopups({
        //             popupIds: [PopupsEnum.TOURNAMENT_UNLOCK],
        //         })
        //     );
        // }
    };

    const gameConfig = async () => {
        try {
            const configGame = await getGameConfig_req(me.userId, me.zone);
            setConfigData(configGame);
            dispatch(setGameConfigData(configGame));
        } catch (e) {}
    };
    const getNotificationsInfo = async () => {
        try {
            const teamNotificationInfo = await getNotifications_req();
            setTeamUserNotification(teamNotificationInfo);
            dispatch(teamNotificationData(teamNotificationInfo));
        } catch (e) {}
    };

    useEffect(() => {
        if (me) {
            tournamentPopup();
            getNotificationsInfo();
            gameConfig();
        }
    }, [me]);

    const yellowData =
        configData &&
        configData.packs &&
        configData.packs.chips.filter((el) => el.chipType === 'yellow');

    const vipPacks = configData && configData.vipPacks;
    if (vipPacks) {
        dispatch(vipChipsData(vipPacks));
    }
    const changePage = (page, data) => {
        setPageData({
            page,
            data,
        });
    };
    dispatch(goldChipsData(yellowData));
    const renderPage = () => {
        switch (route) {
            case routesEnum.LOADING:
                return <LoadingPage />;
            case routesEnum.LOBBY:
                return <HomePage />;
            case routesEnum.GAMEPLAY:
                return <GamePlay />;
            case routesEnum.LANGUAGE:
                return <LanguagePage />;
            case routesEnum.BADGES:
                return <Badges />;
            case routesEnum.STORE:
                return <StorePage />;
            case routesEnum.WHEEL:
                return <WheelPage configData={configData} />;
            case routesEnum.TOURNAMENT:
                return <Tournament />;
            case routesEnum.TOURNAMENT_DASHBOARD:
                return <TournamentDashboard />;
            case routesEnum.TOURNAMENT_INFO:
                return <TournamentInfo />;
            case routesEnum.LEAGUE_INTRODUCTION:
                return <LeagueIntroduction />;
            case routesEnum.JOIN_TEAM:
                return <JoinTeam />;
            case routesEnum.TEAM_DASHBOARD:
                return <TeamDashboard />;
            case routesEnum.MORE_INFO_SECTION:
                return (
                    <MoreInfoSection
                        pageData={pageData}
                        changePage={changePage}
                    />
                );
            case routesEnum.TEAM_LOGO_SECTION:
                return (
                    <ChooseTeamLogoSection
                        leftShowState={leftShowState}
                        rightShowState={rightShowState}
                    />
                );
            case routesEnum.CHOOSE_TEAM:
                return <ChooseTeamNameSection />;
            case routesEnum.JOINING_CONDITION:
                return <JoiningConditionsSection />;
            case routesEnum.CONGRATS:
                return <CongratsSection />;
            default:
                return <LoadingPage />;
        }
    };

    return (
        <div id="base-page">
            {route !== 0 &&
                route !== 1 &&
                route !== 9 &&
                route !== 8 &&
                route !== 10 && <UserPanel />}

            {renderPage()}
            {openCloseStartBattleStart && <StartBattle />}
            {teamNotification && (
                <TeamNotifications
                    teamUserNotificataion={teamUserNotificataion}
                    teamNotification={teamNotification}
                    open={open}
                    position={'right'}
                    setOpen={setOpen}
                />
            )}

            {userInfoData && <UserInfo userInfo={userInfoData} />}
            {rightMenu.rightMenuOpenedState !== 3 && <MenuGamePlay />}
            {giftMenu.openedState !== 3 && <Gifts />}
            {smileOpened.smileOpenedState !== 3 && <Smiles />}
            {chatOpened.chatOpenedState !== 3 && <Chat />}
            {/* <MenuGamePlay /> */}
            {/* <Smiles /> */}
        </div>
    );
};

export default BasePage;
