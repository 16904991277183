// import { Container } from 'pixi.js';
import PIXI from '../../wrappers/pixi.wrapper';
import WheelSegment from './wheelSegment.pixi';
import pixiLoader from '../../loaders/pixi.loader';
// import wheelEnums from './wheel.enums';

class WheelPixi {
    constructor(width, height, data) {
        this.app = new PIXI.Application({
            width,
            height,
            transparent: true,
        });

        this.wheelData = data;
        this.segmentCount = this.wheelData.length;

        this.createMainContainer();

        this.rotateHandler = this._rotate.bind(this);

        this.app.stage.addChild(this.container);
    }

    get app() {
        return this.application;
    }

    set app(value) {
        this.application = value;
    }

    createMainContainer() {
        this.container = new PIXI.Container();
        this.container.x = this.app.screen.width / 2;
        this.container.y = this.app.screen.height / 2;

        this.container.pivot.set(
            this.app.screen.width / 2,
            this.app.screen.height / 2
        );
    }

    createSegments(data) {
        this.segmentsContainer = new PIXI.Container();

        for (let i = 0; i < this.segmentCount; i++) {
            setTimeout(() => {
                this.createSegment(i, data[i].text, data[i].type);
            }, i * 50);
        }

        this.container.addChild(this.segmentsContainer);
    }

    createSegment(i, text, type) {
        const wheelSegment = new WheelSegment(
            i,
            text,
            type,
            i === 0,
            i === this.segmentCount - 1,
            this.app
        );

        //176, 191
        wheelSegment.angle = 45;
        this.segmentsContainer.addChild(wheelSegment);
        wheelSegment.angle = (360 / this.segmentCount) * i;
        this.segmentsContainer.x = this.app.screen.width / 2;
        this.segmentsContainer.y = this.app.screen.height / 2;
    }
    createElipse() {
        let sprite = new PIXI.Sprite(
            pixiLoader.getImage('images/wheel/wheel-bg.png').texture
        );

        sprite.width = 432;
        sprite.height = 432;
        sprite.x = this.app.view.width / 2;
        sprite.y = this.app.view.height / 2;
        sprite.anchor.set(0.5);
        this.container.addChild(sprite);
    }

    rotate() {
        this.rotationSpeed = 0.0001;
        this.app.ticker.add(this.rotateHandler);
    }

    _rotate(delta) {
        if (!this.stopingAnimation) {
            if (this.rotationSpeed < 0.08) {
                this.rotationSpeed += 0.001;
            } else if (this.rotationSpeed < 0.2) {
                this.rotationSpeed += 0.004;
            } else if (this.rotationSpeed < 0.3) {
                this.rotationSpeed += 0.1;
            }
        } else {
            this.rotationSpeed -= 0.0035;
            if (this.rotationSpeed < 0.001) {
                this.rotationSpeed = 0;
                this.stopRotation();
            }
        }
        this.container.rotation += this.rotationSpeed * delta;

        if (this.rotation <= 0) {
            this.stopRotation();
        }
    }

    smoothStopRotation() {
        this.stopingAnimation = true;
    }

    stopRotation() {
        this.app.ticker.remove(this.rotateHandler);
    }
}

export default WheelPixi;
