class SocialHelper {
    shareOnFB() {
        if (window.FB) {
            window.FB.ui(
                {
                    method: 'share',
                    href: 'https://developers.facebook.com/docs/',
                },
                function (response) {}
            );
        }
    }
}

export default new SocialHelper();
