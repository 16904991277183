import React, { useState } from 'react';

import './hex-flipper.scss';

const HexFlipper = ({
    firstImage,
    secondImage,
    allowFlip = false,
    _onFlip = () => {},
}) => {
    const [animating, setAnimating] = useState(false);
    const [side, setSide] = useState(1);
    const [mainZIndex, setMainZIndex] = useState(0);

    const flipSide = () => {
        if (!allowFlip || animating) {
            return;
        }
        const newSide = side === 1 ? 2 : 1;

        if (typeof _onFlip === 'function') {
            _onFlip(newSide);
        }

        setAnimating(true);
        setSide(newSide);

        setTimeout(() => {
            setMainZIndex(mainZIndex === 1 ? 0 : 1);
            setAnimating(false);
        }, 300);
    };

    return (
        <div className={'hex-flipper'} onClick={flipSide}>
            {firstImage && (
                <img
                    src={firstImage}
                    alt="first-image"
                    className={
                        'hex-image first-image' + (side === 1 ? ' main' : '')
                    }
                    style={{
                        zIndex: mainZIndex,
                    }}
                />
            )}
            {secondImage && (
                <img
                    src={secondImage}
                    alt="second-image"
                    className={
                        'hex-image second-image' + (side === 2 ? ' main' : '')
                    }
                    style={{
                        zIndex: mainZIndex === 1 ? 0 : 1,
                    }}
                />
            )}
        </div>
    );
};

export default HexFlipper;
