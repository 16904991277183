class UserSingleton {
    constructor() {
        this._jwt = '';
        this._teamJwt = '';
        this._createTeamJwt = '';
        this._userId = 0;
        this._zone = '';
        this._currentTabUser = 0;
        this._storeTab = 0;
        this._userTeam = '';
        this._suitBazar = '';
        this._userLevel = 0;
    }

    set createTeamJwt(_createTeamJwt) {
        this._createTeamJwt = _createTeamJwt;
    }

    get createTeamJwt() {
        return this._createTeamJwt;
    }
    set userLevel(_userLevel) {
        this._userLevel = _userLevel;
    }

    get userLevel() {
        return this._userLevel;
    }

    set suitBazar(_suitBazar) {
        this._suitBazar = _suitBazar;
    }

    get suitBazar() {
        return this._suitBazar;
    }

    set userTeam(_userTeam) {
        this._userTeam = _userTeam;
    }

    get userTeam() {
        return this._userTeam;
    }

    set storeTab(_storeTab) {
        this._storeTab = _storeTab;
    }

    get storeTab() {
        return this._storeTab;
    }

    set currentTabUser(_currentTabUser) {
        this._currentTabUser = _currentTabUser;
    }

    get currentTabUser() {
        return this._currentTabUser;
    }

    set userId(_userId) {
        this._userId = _userId;
    }

    get userId() {
        return this._userId;
    }

    set zone(_zone) {
        this._zone = _zone;
    }

    get zone() {
        return this._zone;
    }

    set jwt(_jwt) {
        this._jwt = _jwt;
    }

    get jwt() {
        return this._jwt;
    }
    set teamJwt(_teamJwt) {
        this._teamJwt = _teamJwt;
    }

    get teamJwt() {
        return this._teamJwt;
    }
}
export default new UserSingleton();
