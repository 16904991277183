import React, { createRef } from 'react';
import BadgeImage from '../../badge-image/badge-image';
import './badges-img-block.scss';

const BadgesImgBlock = ({ item, badgesRefs, setSecondAnimate }) => {
    const badgeCount = 5;
    for (let i = 0; i < badgeCount; i++) {
        badgesRefs.push(createRef());
    }
    const data = item;
    setTimeout(() => {
        setSecondAnimate(true);
    }, 1500);
    return (
        <div className="badges-img-block">
            {badgesRefs.map((ref, index) => (
                <BadgeImage
                    key={'badge-' + index}
                    badgeRef={ref}
                    image={
                        item.level >= index + 1
                            ? `https://testcdn.blotclub.am/tg/badges/${item.bage}/${item.bage}-${index}.png`
                            : `https://testcdn.blotclub.am/tg/badges/empty/empty-${index}.png`
                    }
                    index={index}
                    item={item}
                />
            ))}
            <img
                src={`https://testcdn.blotclub.am/tg/badges/${data.bage}/${
                    data.bage
                }-${data.level - 1}.png`}
                className={'badge-img-animate'}
            />
        </div>
    );
};

export default BadgesImgBlock;
