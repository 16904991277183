import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PopupsEnum from '../../../../enums/popups.enum';
import { openPopups } from '../../../../redux/popup.slice';
import './tournament-game.scss';

const TournamentGame = ({ data }) => {
    let arr = [];
    arr.length = data.maxGames;
    arr.fill(1);
    const scoreData = useSelector((state) => state.global.navigateData);
    const dispatch = useDispatch();
    const tourTable = () => {
        dispatch(
            openPopups({
                popupIds: [PopupsEnum.TOURNAMENT_TABLE],
                popupData: [data],
            })
        );
    };
    const userMeGame = useSelector(
        (state) => state.userMeTour.userMeTournament
    );

    const scoreTourData = scoreData.tourData[0].tournamentId;
    const tourGameScore = scoreData;

    // const findData = tourGameScore.find((el) => el.tourId === scoreTourData);

    return (
        <>
            <div className="tournament-game-block">
                {arr.map((item, index) => (
                    <>
                        {/* {tournamentGame &&
                            tournamentGame.games &&
                            tournamentGame.games.map((item) => (
                                <div className="tournament-game-block">
                                    <span>{item.score}</span>
                                </div>
                            ))} */}

                        <div
                            className={`tournament-game ${
                                data.maxGames <= 3 ? 'tournament-game-mini' : ''
                            }`}
                            onClick={() => tourTable()}
                        >
                            <span>{index + 1}</span>
                        </div>
                    </>
                ))}
            </div>
            {/* {arr.map((item, index) => {
                if (findData && findData.length) {
                }
                return (
                    <>
                        {data.gameCount > 3 ? (
                            <div
                                className={`${
                                    findData &&
                                    findData.scores &&
                                    findData.scores.length === index
                                        ? 'tournament-active-index'
                                        : ''
                                }`}
                                onClick={
                                    findData &&
                                    findData.scores &&
                                    findData.scores.length === index
                                        ? tourTable
                                        : null
                                }
                            >
                                <div
                                    className={`${
                                        findData &&
                                        findData.scores &&
                                        findData.scores.length &&
                                        findData.scores[index]
                                            ? 'tournament-game'
                                            : 'tournament-game-count'
                                    }`}
                                >
                                    <div>
                                        {findData &&
                                        findData.scores &&
                                        findData.scores.length &&
                                        findData.scores[index] ? (
                                            (
                                                <>
                                                    <div>
                                                        {findData.scores[index]
                                                            .score === -1 ? (
                                                            <div className="point-active">
                                                                <span>0</span>
                                                                <p>pts</p>
                                                            </div>
                                                        ) : (
                                                            <span>
                                                                {
                                                                    findData
                                                                        .scores[
                                                                        index
                                                                    ].score
                                                                }{' '}
                                                            </span>
                                                        )}
                                                    </div>
                                                    {findData.scores[index]
                                                        .score !== -1 && (
                                                        <p>pts</p>
                                                    )}
                                                </>
                                            ) || <span>{index + 1}</span>
                                        ) : (
                                            <span>{index + 1}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </>
                );
            })} */}
        </>
    );
};
export default TournamentGame;
