import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';

import './joining-conditions-section.scss';
import { openPopups } from '../../../../redux/popup.slice';
import PopupsEnum from '../../../../enums/popups.enum';
import { navigate } from '../../../../redux/global.slice';
import routesEnum from '../../../../enums/routes.enum';
import apiStore from '../../../../api/team.game/store';
import {
    createTeam_req,
    teamInfo_req,
    validateCreateTeam_req,
} from '../../../../api/team.game/team.game.api';
import createTeamEnum from '../../../../enums/team-game/createTeam.enum';
import { createErrorCodes } from '../../../../enums/team-game/errors.enum';
import {
    leftShowState,
    rightShowState,
} from '../../../../helpers/team-game/divided-view.helper';
import StandardLabel from '../../../../components/team-game/labels/standard-label/standard-label';
import EditIcon from '../../../../components/team-game/icons/edit/edit.icon';
import UniversalShield from '../../../../components/team-game/shields/universalShield/universal.shield';
import StandardTextbox from '../../../../components/team-game/inputs/standard-text-box/standard-text-box-input';
import ErrorBanner from '../../../../components/team-game/banners/error-banner/error-banner';
import _3dButton from '../../../../components/team-game/buttons/3d-button/3d-button';
import userSingleton from '../../../../singletons/user.singleton';
import {
    battleChange,
    battleInfoState,
    getCreateTeamConfigs,
    teamInfoState,
    teamStatistics,
    teamMember,
} from '../../../../redux/team-game/team-game.slice';

const JoiningConditionsSection = ({ chosenIcons }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [showState, setShowState] = useState(0);
    const [globalError, setGlobalError] = useState('');
    const [minLevel, setMinLevel] = useState('');
    const [minWinRate, setMinWinRate] = useState('');
    const [minLevelError, setMinLevelError] = useState(false);
    const [minWinRateError, setMinWinRateError] = useState(false);

    const createTeamConfigs = useSelector(
        (state) => state.teamGame.createTeamConfigs
    );
    const navigateData = useSelector((state) => state.global.navigateData);

    const teamName = userSingleton.teamName;

    useEffect(() => {
        setShowState(1);
    }, []);

    const _onMinLevelChange = (e) => {
        const _minLevel =
            e.target.value === ''
                ? e.target.value
                : parseInt(e.target.value) || 0;
        setMinLevel(_minLevel);
        setMinLevelError(Number(_minLevel) < createTeamConfigs.minJoinLevel);
    };

    const _onMinWinRateChange = (e) => {
        const _minWinRate =
            e.target.value === ''
                ? e.target.value
                : parseInt(e.target.value) || 0;

        setMinWinRate(_minWinRate);
        setMinWinRateError(
            _minWinRate === '' ||
                Number(_minWinRate) < 0 ||
                Number(_minWinRate) > 100
        );
    };

    const prevPage = () => {
        setShowState(0);
        dispatch(navigate(routesEnum.TEAM_LOGO_SECTION));
    };

    const _cancelAction = () => {
        dispatch(
            openPopups({
                popupIds: [PopupsEnum.PROMPT_POPUP],
                popupData: [
                    {
                        title: intl.formatMessage({
                            id: 'discard_changes',
                        }),
                        text: intl.formatMessage({
                            id: 'do_you_really_want_to_cancel_team_creation',
                        }),
                        // _positiveAction: () => {
                        //     if (
                        //         pageData &&
                        //         pageData.data &&
                        //         pageData.data._cancelAction
                        //     ) {
                        //         pageData.data._cancelAction();
                        //     } else {
                        //         dispatch(navigate(routesEnum.JOIN_TEAM));
                        //     }
                        // },
                    },
                ],
            })
        );
    };

    const next = async () => {
        try {
            if (minLevelError || minWinRateError) {
                return;
            }

            const _minWinRateError =
                minWinRate === '' ||
                Number(minWinRate) < 0 ||
                Number(minWinRate) > 100;
            const _minLevelError =
                Number(minLevel) < createTeamConfigs.minJoinLevel;

            if (_minWinRateError) {
                setMinWinRateError(true);
            }

            if (_minLevelError) {
                setMinLevelError(true);
            }

            if (_minWinRateError || _minLevelError) {
                return;
            }

            const isValid = await validateCreateTeam_req(
                createTeamEnum.joinConditions,
                {
                    level: minLevel,
                    winRate: minWinRate,
                }
            );

            if (isValid && isValid.success) {
                const created = await createTeam_req(
                    teamName,
                    navigateData &&
                        navigateData.chosenIcons &&
                        navigateData.chosenIcons.map((data) => data.icon),
                    {
                        level: minLevel,
                        winRate: minWinRate,
                    }
                );

                if (created && created.success) {
                    apiStore.setJWT(created.token);
                    userSingleton.createTeamJwt = created.token;

                    const teamInfoData = await teamInfo_req();

                    if (teamInfoData) {
                        dispatch(battleChange(teamInfoData.configs));
                        dispatch(teamInfoState(teamInfoData.teamData));
                        dispatch(battleInfoState(teamInfoData.battleInfo));
                        dispatch(teamStatistics(teamInfoData.teamStats));
                        dispatch(
                            getCreateTeamConfigs(teamInfoData.teamCreateConfigs)
                        );
                        dispatch(teamMember(teamInfoData.teamUsers));
                    }
                    setShowState(2);
                    dispatch(navigate(routesEnum.CONGRATS));
                }
            }
        } catch (e) {
            if (
                e.response &&
                e.response.data &&
                e.response.data.response &&
                !e.response.data.response.success
            ) {
                const { response } = e.response.data.response;
                if (response) {
                    switch (response.message) {
                        case createErrorCodes.NAME_REGEXP_NOT_MATCHED:
                            setGlobalError(
                                intl.formatMessage({ id: 'latin_characters' })
                            );
                            break;
                        case createErrorCodes.NAME_IN_USE:
                            setGlobalError(
                                intl.formatMessage({
                                    id: 'this_name_already_in_use',
                                })
                            );
                            break;
                    }
                }
            }
        }
    };

    return (
        <div className={'joining-conditions-content divided-view-content'}>
            <div className="left-side-wrapper">
                <div className={'left-side' + leftShowState(showState)}>
                    <EditIcon _onClick={prevPage} />
                    <UniversalShield
                        text={teamName}
                        icons={
                            navigateData &&
                            navigateData.chosenIcons &&
                            navigateData.chosenIcons.map((data) => data.url)
                        }
                    />
                </div>
            </div>
            <div className="right-side-wrapper with-shield">
                <div className={'right-side' + rightShowState(showState)}>
                    <div className="conditions-text">
                        <StandardLabel
                            text={intl.formatMessage({
                                id: 'set_joining_conditions',
                            })}
                        />
                        <p>
                            <FormattedMessage
                                id={'set_conditions_close_to_your_rating'}
                            />
                        </p>
                    </div>
                    <div className="set-conditions">
                        <div className="condition-wrapper">
                            <StandardLabel
                                text={intl.formatMessage({
                                    id: 'minimum_level',
                                })}
                            />
                            <StandardTextbox
                                type={'numeric'}
                                value={minLevel}
                                _onChange={_onMinLevelChange}
                                withError={minLevelError}
                            />
                            {minLevelError ? (
                                <ErrorBanner
                                    text={
                                        intl.formatMessage({
                                            id: 'minimum_player_level',
                                        }) +
                                        ' ' +
                                        createTeamConfigs.minJoinLevel
                                    }
                                />
                            ) : (
                                <div className={'error-empty-space'} />
                            )}
                        </div>
                        <div className="condition-wrapper">
                            <StandardLabel
                                text={intl.formatMessage({
                                    id: 'min_win_rate',
                                })}
                            />
                            <StandardTextbox
                                type={'numeric'}
                                value={minWinRate}
                                _onChange={_onMinWinRateChange}
                                withError={minWinRateError}
                            />
                            {minWinRateError ? (
                                <ErrorBanner
                                    text={intl.formatMessage(
                                        {
                                            id: 'should_be_from_to',
                                        },
                                        {
                                            from: 0,
                                            to: 100,
                                        }
                                    )}
                                />
                            ) : (
                                <div className={'error-empty-space'} />
                            )}
                        </div>
                    </div>
                    <div className="buttons-wrapper">
                        <_3dButton
                            text={intl.formatMessage({ id: 'cancel' })}
                            type={2}
                            _onClick={_cancelAction}
                        />
                        <_3dButton
                            text={intl.formatMessage({
                                id: 'confirm_and_continue',
                            })}
                            _onClick={next}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JoiningConditionsSection;
