const routesEnum = {
    LOADING: 0,
    LANGUAGE: 1,
    LOBBY: 2,
    GAMEPLAY: 3,
    BADGES: 4,
    STORE: 5,
    WHEEL: 6,
    TOURNAMENT: 7,
    TOURNAMENT_DASHBOARD: 8,
    TOURNAMENT_INFO: 9,
    TOURNAMENT_PARTISIPATION: 10,

    LEAGUE_INTRODUCTION: 20,
    JOIN_TEAM: 21,
    TEAM_DASHBOARD: 22,
    MORE_INFO_SECTION: 23,
    TEAM_LOGO_SECTION: 24,
    CHOOSE_TEAM: 25,
    JOINING_CONDITION: 26,
    CONGRATS: 27,
};

Object.freeze(routesEnum);

export default routesEnum;
