import React from 'react';
import './red-tab.scss';
import {
    red_tab,
    chip_1000,
    chip_2500,
    chip_5500,
    chip_15500,
    store_x2,
    store_x3,
    store_x4,
    store_x5,
} from '../../../assets/image.assets';

const getRibbonDataForType = (type) => {
    switch (type) {
        case 1:
            return {};
        case 2:
            return {};
        case 3:
            return {};
        default:
            return {
                divClass: 'gray',
                img1: 'ribbon_g',
                img2: 'ribbon_g_1',
            };
    }
};

const RedTabPopup = ({ data }) => {
    if (!data || data.length < 1) {
        return null;
    }

    const filter = data.packs.chips.filter((el) => el.chipType === 'red');

    return (
        <div className="red-tab-popup">
            {filter.map((item, index) => (
                <div className="red-tab-item" key={item.id}>
                    <div className="red-tab-bg">
                        <img
                            src={red_tab}
                            className={`${
                                data.discount.discountTimer > 0
                                    ? 'red-tab-discount-img'
                                    : 'red-tab-chip'
                            }`}
                        />
                        <div className="red-tab-chip-block">
                            {data.discount.discountTimer > 0 ? (
                                <div className="store-discount">
                                    {data.discount.discountType === 2 && (
                                        <img
                                            src={store_x2}
                                            alt="#"
                                            className="red-tab-discount-img"
                                        />
                                    )}
                                    {data.discount.discountType === 3 && (
                                        <img
                                            src={store_x3}
                                            alt="#"
                                            className="red-tab-discount-img"
                                        />
                                    )}
                                    {data.discount.discountType === 4 && (
                                        <img
                                            src={store_x4}
                                            alt="#"
                                            className="red-tab-discount-img"
                                        />
                                    )}
                                    {data.discount.discountType === 5 && (
                                        <img
                                            src={store_x5}
                                            alt="#"
                                            className="red-tab-discount-img"
                                        />
                                    )}
                                    <div className="red-tab-text-block">
                                        <div className="red-tab-after-block">
                                            <p className="red-tab-text-after">
                                                {item.chipAmount}
                                            </p>
                                            <div className="red-tab-border-block"></div>
                                        </div>
                                        <div className="red-tab-before-block">
                                            <p className="red-tab-text">
                                                {item.chipAmount *
                                                    data.discount.discountType}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <p className="red-tab-text">
                                    {item.chipAmount}
                                </p>
                            )}

                            {index === 0 && (
                                <img src={chip_1000} className="chip-1000" />
                            )}

                            {index === 1 && <img src={chip_2500} />}
                            {index === 2 && (
                                <img src={chip_5500} className="content-img" />
                            )}
                            {index === 3 && <img src={chip_15500} />}
                            <div className="red-tab-item-buy-now">
                                <p>Gnel</p>
                                <span>
                                    {item.currency} {item.amount}
                                </span>
                            </div>
                            <div className="button-layer"></div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default RedTabPopup;
