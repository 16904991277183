import React from 'react';

import './user-panel-avatar.scss';
import {useDispatch} from 'react-redux';
import {setCurrentUserInfo} from '../../../redux/userinfo.slice';
import {user_avatar, vip} from '../../../assets/image.assets';

const UserPanelAvatar = ({userInfo, userLevel}) => {
    const dispatch = useDispatch();
    const openUserInfoMenu = () => {
        dispatch(setCurrentUserInfo(userInfo));
    };

    return (
        <div className={'user_image_info'} onClick={openUserInfoMenu}>
            <img className="user_img"
                 src={userInfo.avatar ? userInfo.avatar :user_avatar}
                 alt="fb"
            />
            {userInfo.vip && (
                <img className="user-vip-img" src={vip} alt="fb"/>
            )}
            <div className="level_wrapper">
                <span>{userLevel}</span>
            </div>
        </div>
    );
};

export default UserPanelAvatar;
