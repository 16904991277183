import React, { createRef, useEffect } from 'react';
import StandardLabel from '../../team-game/labels/standard-label/standard-label';

import './pie-chart.scss';

function getCoordinatesForPercent(percent) {
    const x = Math.cos(2 * Math.PI * percent);
    const y = Math.sin(2 * Math.PI * percent);
    return [x, y];
}

const createChart = (svgEl, slices) => {
    let cumulativePercent = 0;

    slices.forEach((slice) => {
        // destructuring assignment sets the two variables at once
        const [startX, startY] = getCoordinatesForPercent(cumulativePercent);

        // each slice starts where the last slice ended, so keep a cumulative percent
        cumulativePercent += slice.percent;

        const [endX, endY] = getCoordinatesForPercent(cumulativePercent);

        // if the slice is more than 50%, take the large arc (the long way around)
        const largeArcFlag = slice.percent > 0.5 ? 1 : 0;

        // create an array and join it just for code readability
        const pathData = [
            `M ${startX} ${startY}`, // Move
            `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc
            `L 0 0`, // Line
        ].join(' ');

        // create a <path> and append it to the <svg> element
        const pathEl = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'path'
        );

        pathEl.setAttribute('d', pathData);
        pathEl.setAttribute('fill', slice.color);

        svgEl.appendChild(pathEl);
    });
};

const PieChart = ({
    slices = [],
    size = 54,
    showPercent,
    borderColor = '#CB820A',
    showAnimation,
}) => {
    const svgEl = createRef();

    if (typeof showAnimation === 'undefined') {
        showAnimation = true;
    }

    useEffect(() => {
        if (svgEl && svgEl.current) {
            createChart(svgEl.current, slices);
        }
    }, []);

    return (
        <div
            className={'pie_chart' + (showAnimation ? ' animate' : '')}
            style={{
                width: size + 'px',
                height: size + 'px',
                border: borderColor && '3px solid ' + borderColor,
            }}
        >
            <svg
                ref={svgEl}
                viewBox="-1 -1 2 2"
                style={{
                    width: size + 'px',
                    height: size + 'px',
                }}
            />
            {showPercent && slices && slices.length === 1 ? (
                <StandardLabel
                    text={slices[0].percent * 100 + '%'}
                    fontSize={20}
                />
            ) : (
                <></>
            )}
        </div>
    );
};

export default PieChart;
