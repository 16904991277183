import React, { useState, useEffect } from 'react';
import './level.bar.scss';

const LevelBar = ({ percent, vip }) => {
    const [animating, setAnimating] = useState(false);
    const [animatedPercent, setAnimatedPercent] = useState(0);
    useEffect(() => {
        setTimeout(() => {
            setAnimating(true);
            setAnimatedPercent(percent);
            setTimeout(() => {
                setAnimating(false);
            }, 500);
        }, 1000);
    }, []);

    return (
        <div className={vip ? 'level-bar-vip' : 'level-bar'}>
            <div
                className={
                    vip
                        ? `level-full-vip${animating ? ' animating-vip' : ``}`
                        : `level-full${animating ? ' animating' : ''}`
                }
                style={{ width: animatedPercent + '%' }}
            />
        </div>
    );
};

export default LevelBar;
