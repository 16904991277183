import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import './choose-team-name-section.scss';

// import EmptyShieldImg from '../../../../assets/images/shields/empty.png';

import { validateCreateTeam_req } from '../../../../api/team.game/team.game.api';
import { empty } from '../../../../assets/image.assets';
import UniversalShield from '../../../../components/team-game/shields/universalShield/universal.shield';
import StandardLabel from '../../../../components/team-game/labels/standard-label/standard-label';
import StandardTextbox from '../../../../components/team-game/inputs/standard-text-box/standard-text-box-input';
import ErrorBanner from '../../../../components/team-game/banners/error-banner/error-banner';
import _3dButton from '../../../../components/team-game/buttons/3d-button/3d-button';
import createTeamEnum from '../../../../enums/team-game/createTeam.enum';
import {
    leftShowState,
    rightShowState,
} from '../../../../helpers/team-game/divided-view.helper';
import PopupsEnum from '../../../../enums/popups.enum';
import { openPopups } from '../../../../redux/popup.slice';
import { useDispatch, useSelector } from 'react-redux';
import routesEnum from '../../../../enums/routes.enum';
import { navigate } from '../../../../redux/global.slice';
import { tournamentColor } from '../../../../redux/theme.change.slice';
import themeEnum from '../../../../enums/theme.enum';
import userSingleton from '../../../../singletons/user.singleton';

const ChooseTeamNameSection = () => {
    const [teamName, setTeamName] = useState('');
    const [error, setError] = useState('');
    const [showState, setShowState] = useState(0);
    const dispatch = useDispatch();
    const intl = useIntl();
    const createTeamConfigs = useSelector(
        (state) => state.teamGame.createTeamConfigs
    );

    useEffect(() => {
        setShowState(1);
    }, []);
    const openPrompt = () => {
        dispatch(
            openPopups({
                popupIds: [PopupsEnum.PROMPT_POPUP],
                popupData: [
                    {
                        title: intl.formatMessage({
                            id: 'discard_changes',
                        }),
                        text: intl.formatMessage({
                            id: 'do_you_really_want_to_cancel_team_creation',
                        }),
                        _positiveAction: () => {
                            dispatch(navigate(routesEnum.JOIN_TEAM));
                        },
                    },
                ],
            })
        );
    };
    const changeTeamName = (e) => {
        const nameVal = (e.target.value || '').toUpperCase();

        if (
            nameVal.length > createTeamConfigs.nameMaxLength ||
            nameVal.length < createTeamConfigs.nameMinLength
        ) {
            setError(
                intl.formatMessage(
                    {
                        id: 'name_length_should_be_from_to',
                    },
                    {
                        from: createTeamConfigs.nameMinLength,
                        to: createTeamConfigs.nameMaxLength,
                    }
                )
            );
            if (nameVal.length < createTeamConfigs.nameMinLength) {
                setTeamName(nameVal);
            }
            return;
        }

        setTeamName(nameVal);

        const exp = new RegExp(createTeamConfigs.nameRegExp);
        if (!exp.test(nameVal)) {
            setError(intl.formatMessage({ id: 'latin_characters' }));
            return;
        }

        setError('');
    };

    const next = async () => {
        const showError = () => {
            setError(
                intl.formatMessage(
                    {
                        id: 'name_length_should_be_from_to',
                    },
                    {
                        from: createTeamConfigs.nameMinLength,
                        to: createTeamConfigs.nameMaxLength,
                    }
                )
            );
        };

        // if (teamName.length < createTeamConfigs.nameMinLength) {
        //     showError();
        //     return;
        // }

        // if (error) {
        //     return;
        // }

        try {
            const isValid = await validateCreateTeam_req(
                createTeamEnum.name,
                teamName
            );

            if (isValid && isValid.success) {
                userSingleton.teamName = teamName;
                setShowState(2);
                setTimeout(() => {
                    dispatch(
                        navigate({
                            route: routesEnum.TEAM_LOGO_SECTION,
                            navigateData: {
                                teamName: teamName,
                            },
                        })
                    );
                }, 300);
            } else {
                showError();
            }
        } catch (e) {
            if (
                e.response.data &&
                e.response.data.response &&
                !e.response.data.response.success
            ) {
                const { message } = e.response.data.response;

                switch (message) {
                    case 0:
                        setError(
                            intl.formatMessage({ id: 'latin_characters' })
                        );
                        break;
                    case 1:
                        setError(
                            intl.formatMessage({
                                id: 'this_name_already_in_use',
                            })
                        );
                        break;
                    case 8:
                        setError(
                            intl.formatMessage({
                                id: 'this_name_is_not_allowed',
                            })
                        );
                        break;
                }
            }
        }
    };

    return (
        <div className={'choose-team-name divided-view-content'}>
            <div className="left-side-wrapper">
                <div className={'left-side' + leftShowState(showState)}>
                    <UniversalShield shield={empty} text={teamName} />
                </div>
            </div>
            <div className="right-side-wrapper with-shield">
                <div className={'right-side' + rightShowState(showState)}>
                    <div className="team-name-content">
                        <StandardLabel
                            text={intl.formatMessage({
                                id: 'choose_team_name',
                            })}
                        />
                        <p>
                            <FormattedMessage
                                id={
                                    'remember_you_will_not_be_able_to_change_the_name'
                                }
                            />
                        </p>
                        <StandardTextbox
                            placeholder={intl.formatMessage({
                                id: 'enter_team_name',
                            })}
                            value={teamName}
                            _onChange={changeTeamName}
                        />
                        {error && <ErrorBanner text={error} />}
                    </div>
                    <div className="buttons-wrapper">
                        <_3dButton
                            text={intl.formatMessage({ id: 'cancel' })}
                            type={2}
                            _onClick={openPrompt}
                        />
                        <_3dButton
                            text={intl.formatMessage({
                                id: 'confirm_and_continue',
                            })}
                            _onClick={next}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

// const mapDispatchToProps = (dispatch) => {
//     return {
//         navigate: (routeData) => dispatch(routerActions.navigate(routeData)),
//         openPopups: (popupData) => dispatch(popupActions.openPopups(popupData)),
//     };
// };

export default ChooseTeamNameSection;
