import { createSlice } from '@reduxjs/toolkit';
import localeEnum from '../enums/locale.enum';
import routesEnum from '../enums/routes.enum';
import { getCookie } from '../helpers/cookie.helpers';

export const globalSlice = createSlice({
    name: 'global',
    initialState: {
        locale: getCookie('locale') || localeEnum.RU,
        route: routesEnum.GUEST,
        menuOpened: false,
        initiated: false,
        navigateData: null,
        currentTab: -1,
        joined: false,
        badgesData: [],
    },
    reducers: {
        changeLocale: (state, action) => {
            state.locale = action.payload;
        },
        navigate(state, action) {
            if (typeof action.payload === 'number') {
                state.route = action.payload;
            } else {
                state.route = action.payload.route;
                state.navigateData = action.payload.navigateData || null;
                state.currentTab = action.payload.currentTab || -1;
                state.joined = action.payload.joined || false;
                state.badgesData = action.payload.badgesData || [];
            }
        },

        openCloseMenu: (state, action) => {
            if (typeof action === 'undefined') {
                state.menuOpened = !state.menuOpened;
            } else {
                state.menuOpened = !state.menuOpened;
            }
        },
        setInitiated: (state, action) => {
            state.initiated = !!action.payload;
        },
    },
});

export const {
    changeLocale,
    navigate,
    openCloseMenu,
    setInitiated,
} = globalSlice.actions;

export default globalSlice.reducer;
