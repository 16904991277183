import React from 'react';
import { useIntl } from 'react-intl';

import './team-member.listItem.scss';

import HexagonInfo from '../../info/hexagon/hexagon.info';
import {
    checked,
    cup,
    empty_blue,
    star,
    user,
} from '../../../../assets/image.assets';
import { formatNumberWithSpaces } from '../../../../utils/formatter.util';
import StandardLabel from '../../labels/standard-label/standard-label';
import SecondaryLabel from '../../labels/secondary-label/secondary-label';

const TeamMemberListItem = ({ position, member, setCurrentUserInfo }) => {
    const intl = useIntl();
    return (
        <div
            className={'team-member-list-item' + (member.rated ? ' rated' : '')}
            onClick={() => setCurrentUserInfo(member)}
        >
            <div className={'place-hex'}>
                <HexagonInfo width={54} height={58} hex={empty_blue} />
                <StandardLabel text={position} fontSize={16} />
            </div>
            <div className={'user-content'}>
                <div className="user-personal">
                    <div className="user-img">
                        <img
                            src={member.avatar || user}
                            alt="user"
                            onError={(ev) => {
                                ev.target.onerror = null;
                                ev.target.src = user;
                            }}
                        />
                        <div className="user-level">
                            <span>{member.level}</span>
                        </div>
                    </div>
                    <div className="user-name">
                        <StandardLabel text={member.name} />

                        <div className="position">
                            <span>
                                {member.leader
                                    ? intl.messages.leader
                                    : intl.messages.member}
                            </span>
                            {member.leader && (
                                <img src={checked} alt="leader" />
                            )}
                        </div>
                    </div>
                </div>
                {member.stars ? (
                    <div className="user-stars">
                        <img src={star} alt="" className={'star'} />
                        <div className="star-info">
                            <StandardLabel
                                text={formatNumberWithSpaces(member.stars)}
                                fontSize={15}
                            />
                            <SecondaryLabel
                                text={intl.messages.past_battle}
                                fontSize={12}
                            />
                        </div>
                        {member.rated && (
                            <div className={'user-rating'}>
                                <img src={cup} alt="cup" className={'cup'} />
                                <div className="star-info">
                                    <StandardLabel
                                        text={formatNumberWithSpaces(
                                            member.start
                                        )}
                                        fontSize={15}
                                    />
                                    <SecondaryLabel
                                        text={intl.messages.rating}
                                        fontSize={12}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default TeamMemberListItem;
