import { createSlice } from '@reduxjs/toolkit';

export const userMeTournamentSlice = createSlice({
    name: 'userMeTour',
    initialState: {
        userMeTournament: '',
    },
    reducers: {
        setUserMeTournament: (state, action) => {
            state.userMeTournament = action.payload;
        },
    },
});
export const { setUserMeTournament } = userMeTournamentSlice.actions;

export default userMeTournamentSlice.reducer;
