/* eslint-disable import/no-dynamic-require, global-require */

import React from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import './app.scss';
import UserPanel from './components/panels/userPanel/user.panel';
import themeEnum from './enums/theme.enum';

// INIT
import './loaders/audio.loader';

import BasePage from './pages/base.page';
import PopupBase from './popups/popups.base';
import userSingleton from './singletons/user.singleton';

function getLocaleString(locale) {
    switch (locale) {
        case 1:
            return 'ru';
        case 2:
            return 'am';
        case 3:
            return 'en';
        default:
            return 'ru';
    }
}

function App() {
    const locale = useSelector((state) => state.global.locale);
    const currentPopupIndex = useSelector(
        (state) => state.popup.currentPopupIndex
    );
    const popupIds = useSelector((state) => state.popup.popupIds);
    const popupData = useSelector((state) => state.popup.popupData);
    const navigateRoute = useSelector((state) => state.global.route);
    const data = useSelector((state) => state.themeChange);
    const localeString = getLocaleString(locale);
    const me = useSelector((state) => state.me.data);
    if (me && me.jwt && me.userId) {
        userSingleton.jwt = me.jwt;
        userSingleton.userId = me.userId;
        userSingleton.teamJwt = me.teamInfo.jwt;
    }
    const tournament = (data) => {
        switch (data.theme) {
            case themeEnum.LOBBY:
                return '';
            case themeEnum.TOURNAMENT:
                return 'tournament-temp';
            case themeEnum.TEAMGAME:
                return 'team-game-inner';
            default:
                return '';
        }
    };
    return (
        <IntlProvider
            locale={localeString}
            messages={require(`./localization/${localeString}.json`)}
        >
            <div
                // className={`app ${data.tournament === 2 && 'tournament-temp'}`}
                className={`app ${tournament(data)}`}
            >
                <BasePage />
                {popupIds &&
                    popupIds.length !== 0 &&
                    popupIds[currentPopupIndex] && (
                        <PopupBase
                            popupId={popupIds[currentPopupIndex]}
                            popupData={popupData[currentPopupIndex]}
                        />
                    )}
            </div>
        </IntlProvider>
    );
}

export default App;
