const messagesEnum = {
    MESSAGE: 0,
    JOIN: 1,
    LEFT: 2,
    ANNOUNCEMENT: 3,
    TEAM_CREATE: 4,
};

Object.freeze(messagesEnum);

export default messagesEnum;
