import React, { useState } from 'react';
import './bazar-window.scss';
import { useIntl } from 'react-intl';
import SayButton from '../../../../components/buttons/say-button/say-button';
import Suit from '../../suit/suit';
import ButtonTreeD from '../../../../components/buttons/button_3d/button-3d';
import { RoomChannelEmittingEvents } from '../../../../socket/socket.events';
import socketBridge from '../../../../socket';
import roomSingleton from '../../../../singletons/room.singleton';
import { useSelector } from 'react-redux';
import { usersInfoSlice } from '../../../../redux/user.info.game.slice';
import userSingleton from '../../../../singletons/user.singleton';

const BazarWindow = ({
    setBazar,
    talk,
    bazarValue,
    changeBazarValue,
    stateIndex,
    isCapout,
    allowContra,
    bazarSuit,
    talkUserId,
}) => {
    const intl = useIntl();

    const bazarData = Number(bazarSuit);
    const me = useSelector((state) => state.me);

    const [selected, setSelected] = useState(
        bazarData > -1 && talkUserId === me.data.userId ? bazarData : -1
    );
    const [sayIsCapout, setSayIsCapout] = useState(isCapout);

    const roomIdGet = roomSingleton.roomId;

    const allowUser = allowContra[me.data.userId];

    const suit = [
        { imageId: 'Hearts-icon', id: 0 },
        { imageId: 'Spade-icon', id: 2 },
        { imageId: 'Dimonds-icon', id: 1 },
        { imageId: 'Clubs-icon', id: 3 },
        { imageId: 'A', id: 4 },
    ];
    //  sirt xar kyap xach
    const plusButton = () => {
        if (bazarValue < 63) {
            changeBazarValue(bazarValue + 1);
        }
    };
    const minusButton = () => {
        if (bazarValue > talk + 1) {
            changeBazarValue(bazarValue - 1);
        }
        if (bazarValue < 26) {
            setSayIsCapout(false);
        }
    };

    const saySuit = (index) => {
        setSelected(index);
    };
    const capout = () => {
        setSayIsCapout(true);
        changeBazarValue(25);
    };
    const take = (selected) => {
        if (selected >= 0) {
            socketBridge.emitToRoomChannel({
                event: RoomChannelEmittingEvents.TALK,
                bazarInfo: {
                    talkNumber: bazarValue,
                    cardType: selected,
                    isCapout: sayIsCapout || undefined,
                },
                roomId: roomIdGet,
                userId: me.data.userId,
                state: stateIndex || 0,
            });
            setBazar();
        }
    };
    const sayContra = (sayIsContra) => {
        socketBridge.emitToRoomChannel({
            event: RoomChannelEmittingEvents.TALK,
            bazarInfo: {
                isContra: sayIsContra || undefined,
            },
            state: stateIndex || 0,
        });
        setBazar();
    };
    const sayPass = (pass) => {
        socketBridge.emitToRoomChannel({
            event: RoomChannelEmittingEvents.TALK,
            bazarInfo: {
                Pass: pass || undefined,
            },
            state: stateIndex || 0,
        });
        setBazar();
    };

    return (
        <div className="bazar-window">
            <div className="bazar-say-score">
                <div className="say-score-block">
                    <div className="say-score">
                        <span className="icon-Minus" onClick={minusButton} />
                        {sayIsCapout ? (
                            <span className="icon-text-capout">
                                {bazarValue}
                                {sayIsCapout && (
                                    <span className="capout-text">CP</span>
                                )}
                            </span>
                        ) : (
                            <span className="icon-text">{bazarValue}</span>
                        )}

                        <span className="icon-Plus" onClick={plusButton} />
                    </div>
                </div>
                {isCapout ? (
                    <div className="capout-button">
                        <div className="capout-text-block">
                            <span>Capout</span>
                        </div>
                    </div>
                ) : (
                    <div className="iscapout-button" onClick={capout}>
                        <ButtonTreeD
                            text={'Capot'}
                            changeBazarValue={changeBazarValue}
                        />
                    </div>
                )}
            </div>
            <div className="bazar-suit">
                {suit.map((item, index) => (
                    <Suit
                        key={index}
                        index={item.id}
                        imageId={item.imageId}
                        selected={item.id === selected}
                        setSelected={setSelected}
                        onClick={() => saySuit(item.id)}
                        bazarSuit={bazarSuit}
                    />
                ))}
            </div>
            <div className="bazar-say-button">
                <SayButton
                    text={intl.messages.gamePlay.take}
                    onClick={() => take(selected)}
                />
                {allowUser ? (
                    <SayButton
                        text={intl.messages.gamePlay.contra}
                        onClick={() => sayContra(true)}
                    />
                ) : (
                    <div className="conta-button">
                        <SayButton text={intl.messages.gamePlay.contra} />
                    </div>
                )}

                <SayButton
                    text={intl.messages.gamePlay.pass}
                    onClick={() => sayPass(true)}
                />
            </div>
        </div>
    );
};

export default BazarWindow;
