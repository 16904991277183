import React from 'react';
import './suit.scss';

const Suit = ({ imageId, selected, onClick = () => {} }) => {
    return (
        <div
            className={selected ? 'suit-button-selected' : 'suit-button'}
            onClick={onClick}
        >
            {imageId && <span className={`icon-${imageId}`}></span>}
        </div>
    );
};

export default Suit;
