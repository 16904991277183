import React from 'react';
import { oval } from '../../../../assets/image.assets';

import './team-button.scss';

const TeamButton = ({
    type = 1,
    text,
    _onClick = () => {},
    icon,
    isLoading,
}) => {
    let typeClass = 'red';

    switch (type) {
        case 2:
            typeClass = 'green';
            break;
        case 3:
            typeClass = 'blue';
            break;
    }

    const innerContent = () => {
        if (isLoading) {
            return <img src={oval} alt="oval loader" />;
        }

        return (
            <>
                {icon && <img src={icon} className={'btn-icon'} />}
                <span>{text}</span>
            </>
        );
    };

    return (
        <button className={'team-button ' + typeClass} onClick={_onClick}>
            {innerContent()}
        </button>
    );
};

export default TeamButton;
