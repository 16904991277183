import React, { useState, useEffect } from 'react';

import { useIntl } from 'react-intl';

import './battles-section.scss';

import ChipRewardBanner from '../../../../components/team-game/banners/chip-reward/chip-reward-banner';
import ThinButton from '../../../../components/team-game/buttons/thin-button/thin-button';
import FillYourTeamInfo from '../../../../components/team-game/info/fill-your-team/fill-your-team-info';
import JustHoldInfo from '../../../../components/team-game/info/just-hold/just-hold-info';
import TriangleLoader from '../../../../components/team-game/loaders/triangle-loader/triangle-loader';
import DotsTabChanger from '../../../../components/team-game/tab-changers/dots-tab-changer/dots-tab-changer';
import TextTabChanger from '../../../../components/team-game/tab-changers/text-tab-changer/text-tab-changer';
import UniversalTable from '../../../../components/team-game/tables/universal-table/universal-table';
import LeaderboardTableItem from './leadeboard-table-item/leadeboard-table-item';
import MyTeamTableItem from './my-team-tabl-item/my-team-tabl-item';
import { useSelector } from 'react-redux';

const tabs = ['leaderboard', 'myTeam'];
const battlePeriods = ['current', 'past'];

const tabContentHeight = 346;
const tableItemHeight = 61;

const tabContent = (props, currentTab, battlePeriod) => {
    if (
        (!props.currentLeaderboard && props.isCurrentLeaderboardLoading) ||
        (!props.currentMyTeam && props.isCurrentMyTeamLoading) ||
        (!props.pastLeaderboard && props.isPastLeaderboardLoading) ||
        (!props.pastMyTeam && props.isPastMyTeamLoading)
    ) {
        return <TriangleLoader />;
    }

    let itemsToMap;

    if (tabs[currentTab] === 'leaderboard') {
        if (battlePeriods[battlePeriod] === 'current') {
            itemsToMap = props.currentLeaderboard;
        } else {
            itemsToMap = props.pastLeaderboard;
        }
    } else {
        if (battlePeriods[battlePeriod] === 'current') {
            itemsToMap = props.currentMyTeam;
        } else {
            itemsToMap = props.pastMyTeam;
        }
    }

    let emptyItemHeight = 0;
    if (itemsToMap) {
        emptyItemHeight =
            tabContentHeight - itemsToMap.length * tableItemHeight;
    }

    const members = props.memberList;

    if (tabs[currentTab] === 'leaderboard') {
        return (
            <UniversalTable>
                <tbody>
                    {itemsToMap && itemsToMap.length ? (
                        <>
                            {itemsToMap.map((item, index) => {
                                return (
                                    <LeaderboardTableItem
                                        key={
                                            item.name +
                                            tabs[currentTab] +
                                            battlePeriods[battlePeriod]
                                        }
                                        item={item}
                                        index={index}
                                        highlighted={index + 1 <= 10}
                                        configs={props.configs}
                                        battlePeriod={
                                            battlePeriods[battlePeriod]
                                        }
                                    />
                                );
                            })}
                            {emptyItemHeight > 0 && (
                                <tr
                                    className={'empty'}
                                    style={{ height: emptyItemHeight + 'px' }}
                                    key={'empty-line'}
                                >
                                    <td />
                                    <td />
                                    <td />
                                    <td />
                                </tr>
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </tbody>
            </UniversalTable>
        );
    }

    let totalPrize = 0;

    if (props.pastLeaderboard) {
        const myTeamData = props.pastLeaderboard.filter(
            (team) => team.teamId === props.teamInfo.teamId
        );

        if (myTeamData.length) {
            totalPrize = myTeamData[0].prize || 0;
        }
    }

    const showReward = currentTab === 1 && battlePeriod === 1 && totalPrize > 0;

    return (
        <>
            <div
                className={
                    'table-with-reward' + (showReward ? ' reward-included' : '')
                }
            >
                <UniversalTable>
                    <tbody>
                        {itemsToMap && itemsToMap.length ? (
                            <>
                                {itemsToMap.map((item, index) => {
                                    const member = members[item.userId];

                                    return (
                                        <MyTeamTableItem
                                            key={
                                                !member
                                                    ? 'dead-body-' +
                                                      tabs[currentTab] +
                                                      battlePeriods[
                                                          battlePeriod
                                                      ]
                                                    : member.userId +
                                                      tabs[currentTab] +
                                                      battlePeriods[
                                                          battlePeriod
                                                      ]
                                            }
                                            item={item}
                                            member={member}
                                            index={index}
                                            battlePeriod={
                                                battlePeriods[battlePeriod]
                                            }
                                        />
                                    );
                                })}
                                {emptyItemHeight > 0 && (
                                    <tr
                                        className={'empty'}
                                        style={{
                                            height: emptyItemHeight + 'px',
                                        }}
                                        key={'empty-table-end'}
                                    >
                                        <td />
                                        <td />
                                        <td />
                                        <td />
                                    </tr>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </tbody>
                </UniversalTable>
            </div>
            {showReward && <ChipRewardBanner reward={totalPrize} />}
        </>
    );
};

const loadData = (currentTab, battlePeriod, props) => {
    if (currentTab === 0) {
        if (battlePeriod === 0) {
            props.leaderBoardBattleReq();
        } else {
            props.leaderBoardBattleLastReq('past');
        }
    } else {
        if (battlePeriod === 0) {
            props.myTeamBattleReq();
        } else {
            props.leaderBoardBattleLastReq('past');
            props.myTeamBattleLastReq();
        }
    }
};

const BattlesSection = ({
    leaderBoardBattleLastReq,
    leaderBoardBattleReq,
    myTeamBattleReq,
    myTeamBattleLastReq,
}) => {
    const [currentTab, setCurrentTab] = useState(0);
    const [battlePeriod, setBattlePeriod] = useState(0);
    const teamInfo = useSelector((state) => state.teamGame.teamInfo);
    const configs = useSelector((state) => state.teamGame.configs);
    const pastLeaderboard = useSelector(
        (state) => state.teamGame.pastLeaderboard
    );
    const memberList = useSelector((state) => state.teamGame.memberList);
    const currentLeaderboard = useSelector(
        (state) => state.teamGame.currentLeaderboard
    );
    const pastMyTeam = useSelector((state) => state.teamGame.pastMyTeam);
    const currentMyTeam = useSelector((state) => state.teamGame.currentMyTeam);

    const intl = useIntl();
    const props = {
        currentLeaderboard: currentLeaderboard,
        configs: configs,
        currentMyTeam: currentMyTeam,
        teamInfo: teamInfo,
        memberList: memberList,
        pastLeaderboard: pastLeaderboard,
        pastMyTeam: pastMyTeam,
        leaderBoardBattleLastReq: leaderBoardBattleLastReq,
        leaderBoardBattleReq: leaderBoardBattleReq,
        myTeamBattleReq: myTeamBattleReq,
        myTeamBattleLastReq: myTeamBattleLastReq,
    };

    useEffect(() => {
        loadData(currentTab, battlePeriod, props);
        loadData(1, 1, props);
    }, []);

    useEffect(() => {
        loadData(currentTab, battlePeriod, props);
    }, [currentTab, battlePeriod]);

    const members = props.memberList ? Object.keys(props.memberList) : [];

    if (
        props.currentMyTeam &&
        !props.currentMyTeam.length &&
        !props.isCurrentMyTeamLoading &&
        props.pastMyTeam &&
        !props.pastMyTeam.length &&
        !props.isPastMyTeamLoading &&
        members.length < 10
    ) {
        return (
            <div className={'battles-fill-center'}>
                {(props.teamInfo || {}).leader ? (
                    <FillYourTeamInfo withoutBack />
                ) : (
                    <JustHoldInfo />
                )}
            </div>
        );
    }

    return (
        <div className={'battles-section'}>
            <div className="tab-wrapper">
                <div className="tab-sizer">
                    <TextTabChanger
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        color={battlePeriod && '#C1BCAC'}
                        fromColor={!battlePeriod && '#AD2125'}
                        toColor={!battlePeriod && '#871B24'}
                        tabs={[
                            {
                                text: intl.messages.team_game.leaderboard,
                            },
                            {
                                text: intl.messages.team_game.my_team,
                            },
                        ]}
                    />
                </div>
            </div>
            <div className="tab-content">
                {tabContent(props, currentTab, battlePeriod)}
            </div>
            <div className="bottom-bar">
                <ThinButton
                    text={intl.messages.team_game.current_battle}
                    type={battlePeriods[battlePeriod] === 'current' ? 1 : 2}
                    _onClick={() => setBattlePeriod(0)}
                    isActive={battlePeriods[battlePeriod] === 'current'}
                />
                <DotsTabChanger
                    dotsCount={2}
                    activeIndex={currentTab}
                    _onChange={(newTab) => setCurrentTab(newTab)}
                />
                <ThinButton
                    text={intl.messages.team_game.past_battle}
                    type={battlePeriods[battlePeriod] === 'past' ? 3 : 2}
                    _onClick={() => setBattlePeriod(1)}
                    isActive={battlePeriods[battlePeriod] === 'past'}
                />
            </div>
        </div>
    );
};

// const mapStateToProps = (state) => {
//     return {

//         isCurrentLeaderboardLoading: state.teamGame.isCurrentLeaderboardLoading,

//         isPastLeaderboardLoading: state.teamGame.isPastLeaderboardLoading,

//         isCurrentMyTeamLoading: state.teamGame.isCurrentMyTeamLoading,
//         pastMyTeam: state.teamGame.pastMyTeam,
//         isPastMyTeamLoading: state.teamGame.isPastMyTeamLoading,
//     };
// };

// const mapDispatchToProps = (dispatch) => {
//     return {
//         getCurrentLeaderboard: () =>
//             dispatch(teamGameActions.getCurrentLeaderboard('current')),
//         getPastLeaderboard: () =>
//             dispatch(teamGameActions.getPastLeaderboard('past')),
//         getCurrentMyTeam: () =>
//             dispatch(teamGameActions.getCurrentMyTeam('current')),
//         getPastMyTeam: () => dispatch(teamGameActions.getPastMyTeam('past')),
//     };
// };

export default BattlesSection;
