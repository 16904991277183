import React from 'react';
import StandardLabel from '../../labels/standard-label/standard-label';

import './text-tab-changer.scss';

const TextTabChanger = ({
    currentTab,
    setCurrentTab = () => {},
    fromColor,
    toColor,
    color,
    tabs = [],
}) => {
    if (!tabs || !tabs.length) {
        return <></>;
    }

    const width = 100 / tabs.length;

    return (
        <div className={'text-tab-changer'}>
            <div
                className="detached"
                style={{
                    left: currentTab * width + '%',
                    width: width + '%',
                    background: color
                        ? color
                        : `linear-gradient(180deg, ${fromColor} 0%,${toColor} 100%)`,
                }}
            />
            {tabs.map((tab, index) => {
                return (
                    <div
                        key={tab.text + index}
                        className={'changer-tab'}
                        onClick={() => setCurrentTab(index)}
                        style={{
                            width: width + '%',
                        }}
                    >
                        <StandardLabel text={tab.text} fontSize={12} />
                    </div>
                );
            })}
        </div>
    );
};

export default TextTabChanger;
