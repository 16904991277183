import React from 'react';

import './share-hex.scss';

import { share } from '../../../../assets/image.assets';
import socialHelper from '../../../../helpers/team-game/social.helper';
import StandardLabel from '../../labels/standard-label/standard-label';
import SecondaryLabel from '../../labels/secondary-label/secondary-label';
import PopupsEnum from '../../../../enums/popups.enum';
import { useDispatch } from 'react-redux';
import { openPopups } from '../../../../redux/popup.slice';

const ShareHex = ({ firstLine, secondLine, isLeader }) => {
    const dispatch = useDispatch();

    return (
        <div
            className={'share-hex'}
            onClick={() => {
                isLeader
                    ? dispatch(
                          openPopups({
                              popupIds: [PopupsEnum.INVITE_FRIENDS],
                          })
                      )
                    : socialHelper.shareOnFB();
            }}
        >
            <div className="share-text">
                {firstLine && <StandardLabel text={firstLine} fontSize={19} />}
                {secondLine && <SecondaryLabel text={secondLine} />}
            </div>
            <img src={share} alt="share hex" />
        </div>
    );
};

// const mapDispatchToProps = (dispatch) => {
//     return {
//         openPopups: (popupData) =>
//             dispatch(popupsActions.openPopups(popupData)),
//     };
// };

export default ShareHex;
