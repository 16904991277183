import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage } from 'react-intl/lib';
import {
    battle,
    flag,
    red_chips_reward,
    star,
    yellow_flag,
} from '../../../../../assets/image.assets';
import ThinBar from '../../../../../components/team-game/bars/thin-bar/thin-bar';
import HexFlipper from '../../../../../components/team-game/flippers/hex-flipper/hex-flipper';
import SecondaryLabel from '../../../../../components/team-game/labels/secondary-label/secondary-label';
import StandardLabel from '../../../../../components/team-game/labels/standard-label/standard-label';
import { formatNumberWithSpaces } from '../../../../../utils/formatter.util';

const LeaderboardTableItem = ({
    item,
    index,
    highlighted,
    battlePeriod,
    configs,
}) => {
    const intl = useIntl();
    const [flippedSide, setFlippedSide] = useState(1);

    return (
        <tr>
            <td>
                <div className={'place' + (highlighted ? ' highlighted' : '')}>
                    {!highlighted && (
                        <img src={flag} alt="flag" className={'flag'} />
                    )}
                    <StandardLabel text={index + 1} fontSize={15} />
                    {highlighted && (
                        <img src={yellow_flag} alt="flag" className={'flag'} />
                    )}
                </div>
            </td>
            <td>
                <div className={'name-players'}>
                    <StandardLabel text={item.name} fontSize={15} />
                    <div className={'players-info'}>
                        <SecondaryLabel
                            text={
                                <FormattedMessage
                                    id={'count_players'}
                                    values={{
                                        count: item.usersCount || 0,
                                    }}
                                />
                            }
                            fontSize={12}
                        />
                        {/*<SecondaryLabel text={' • '} fontSize={12} />*/}
                        {/*<img*/}
                        {/*    src={ShieldIcon}*/}
                        {/*    alt="shield icon"*/}
                        {/*    className={'shield-icon'}*/}
                        {/*/>*/}
                        {/*<SecondaryLabel*/}
                        {/*    text={intl.messages.bronze}*/}
                        {/*    fontSize={12}*/}
                        {/*/>*/}
                    </div>
                </div>
            </td>
            <td>
                <div className={'battle-data'}>
                    <img src={battle} alt="battle hex" />
                    <div className="game-count-wrapper">
                        <StandardLabel
                            text={item.games + ' / ' + configs.maxGames}
                            fontSize={15}
                        />
                        <ThinBar
                            points={item.games}
                            maxPoints={configs.maxGames}
                            color={'#871B24'}
                        />
                    </div>
                </div>
            </td>
            <td>
                <div className={'battle-data'}>
                    <HexFlipper
                        allowFlip={battlePeriod === 'past'}
                        firstImage={star}
                        secondImage={
                            battlePeriod === 'past' && red_chips_reward
                        }
                        _onFlip={battlePeriod === 'past' && setFlippedSide}
                    />
                    <div className="game-count-wrapper">
                        <div
                            className={
                                'count-wrapper star-count' +
                                (flippedSide === 1 ? ' active' : '')
                            }
                        >
                            <StandardLabel
                                text={formatNumberWithSpaces(item.stars)}
                                fontSize={15}
                            />
                            <SecondaryLabel
                                text={intl.messages.stars}
                                fontSize={12}
                            />
                        </div>
                        <div
                            className={
                                'count-wrapper chip-count' +
                                (flippedSide === 2 ? ' active' : '')
                            }
                        >
                            <StandardLabel
                                text={formatNumberWithSpaces(item.prize)}
                                fontSize={15}
                            />
                            <SecondaryLabel
                                text={intl.messages.chips}
                                fontSize={12}
                            />
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default LeaderboardTableItem;
