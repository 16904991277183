import React from 'react';

import './3d-button.scss';

const _3dButton = ({
    type,
    text = '',
    icon,
    _onClick = () => {},
    disabledClick,
}) => {
    let typeClass = 'green';

    switch (type) {
        case 2:
            typeClass = 'silver';
            break;
        case 3:
            typeClass = 'red';
            break;
    }

    return (
        <div
            className={
                '_3d-button ' +
                typeClass +
                (disabledClick ? ' disabled-click' : '')
            }
            onClick={_onClick}
        >
            {icon ? (
                <img src={icon} alt="button-icon" className={'button-icon'} />
            ) : (
                text
            )}
        </div>
    );
};

export default _3dButton;
