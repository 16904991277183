import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import './team-info-section.scss';

import { battles } from '../../../assets/image.assets';
import StandardLabel from '../../../components/team-game/labels/standard-label/standard-label';
import SecondaryLabel from '../../../components/team-game/labels/secondary-label/secondary-label';
import MainTabChanger from '../../../components/team-game/tab-changers/main-tab-changer/main-tab-changer';
import { daysDifference } from '../../../helpers/team-game/calc.helper';
import BattlesSection from './battles/battles-section';
import TeamListSection from './team-list/team-list-section';
import { useDispatch, useSelector } from 'react-redux';
import {
    battleMyTeam_req,
    leaderboard_req,
} from '../../../api/team.game/team.game.api';
import {
    getCurrentLeaderboard,
    getCurrentMyTeam,
    getPastLeaderboard,
    getPastMyTeam,
} from '../../../redux/team-game/team-game.slice';

const TeamInfoSection = ({ listTeam, getMemberList, battleDetails }) => {
    const intl = useIntl();
    const [currentTab, setCurrentTab] = useState(0);
    const [myTeamBattle, setMyTeamBattle] = useState([]);
    const configs = useSelector((state) => state.teamGame.configs);
    const memberList = useSelector((state) => state.teamGame.memberList);

    const dispatch = useDispatch();
    if (!configs) {
        configs = {};
    }

    const members = memberList ? Object.keys(memberList) : [];

    const changeTab = (tab) => {
        if (tab === 0) {
            getMemberList();
        }
        setCurrentTab(tab);
    };

    let days = 0;
    const myTeamBattleReq = async () => {
        const battleTeam = await battleMyTeam_req();
        dispatch(getCurrentMyTeam(battleTeam));
        setMyTeamBattle(battleTeam);
    };
    const myTeamBattleLastReq = async (type) => {
        const battleTeamLast = await battleMyTeam_req(type);
        dispatch(getPastMyTeam(battleTeamLast));
        setMyTeamBattle(battleTeamLast);
    };
    const leaderBoardBattleReq = async () => {
        const battleTeamLeaderBoard = await leaderboard_req();
        dispatch(getCurrentLeaderboard(battleTeamLeaderBoard));
    };

    const leaderBoardBattleLastReq = async (type) => {
        const battleTeamLeaderBoardLast = await leaderboard_req(type);
        dispatch(getPastLeaderboard(battleTeamLeaderBoardLast));
    };
    useEffect(() => {
        myTeamBattleReq();
        leaderBoardBattleReq();
    }, []);

    if (battleDetails && battleDetails.endDate) {
        const endDate = new Date(battleDetails.endDate);

        if (!isNaN(endDate)) {
            days = daysDifference(new Date(), endDate);
        }
    }
    if (listTeam.length === 0) {
        return null;
    }
    return (
        <div className={'team-info-section'}>
            <MainTabChanger
                currentTab={currentTab}
                setCurrentTab={changeTab}
                fromColor={'#AD2125'}
                toColor={'#871B24'}
                tabs={[
                    <div className={'main-first'}>
                        <StandardLabel
                            text={intl.messages.team_game.team}
                            fontSize={24}
                        />
                        <div className="players-info">
                            <StandardLabel
                                text={
                                    members.length +
                                    '/' +
                                    configs.maxMembersCount
                                }
                                fontSize={19}
                            />
                            <SecondaryLabel
                                text={intl.messages.team_game.players}
                                fontSize={14}
                            />
                        </div>
                    </div>,
                    <div className={'main-second'}>
                        <StandardLabel
                            text={intl.messages.team_game.battle}
                            fontSize={24}
                        />
                        <div className="days-left">
                            <img src={battles} alt="battle" />
                            <div className={'day-info'}>
                                <StandardLabel
                                    text={parseInt(days)}
                                    fontSize={24}
                                />
                                <div className={'new-line'}>
                                    <StandardLabel
                                        text={intl.messages.team_game.days}
                                        fontSize={11}
                                    />
                                    <StandardLabel
                                        text={intl.messages.team_game.left}
                                        fontSize={11}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>,
                ]}
            >
                {[
                    <TeamListSection
                        key={'first'}
                        memberList={listTeam.teamUsers}
                    />,
                    <BattlesSection
                        key={'second'}
                        leaderBoardBattleLastReq={leaderBoardBattleLastReq}
                        leaderBoardBattleReq={leaderBoardBattleReq}
                        myTeamBattleReq={myTeamBattleReq}
                        myTeamBattleLastReq={myTeamBattleLastReq}
                    />,
                ]}
            </MainTabChanger>
        </div>
    );
};

// const mapStateToProps = (state) => {
//     return {
//         battleDetails: state.teamGame.battleDetails,
//     };
// };

// const mapDispatchToProps = (dispatch) => {
//     return {
//         getMemberList: () => dispatch(teamGameActions.getMemberList()),
//         getBattleDetails: () => dispatch(teamGameActions.getBattleDetails()),
//     };
// };

export default TeamInfoSection;
