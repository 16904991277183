export const leftShowState = (showState) => {
    switch (showState) {
        case 0:
            return ' hide-top';
        case 1:
            return '';
        case 2:
            return ' hide-bottom';
    }
};

export const rightShowState = (showState) => {
    switch (showState) {
        case 0:
            return ' hide-right';
        case 1:
            return '';
        case 2:
            return ' hide-left';
    }
};
