import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    first_reward,
    red,
    tour_reward_orange,
    tour_reward_grey,
    tour_reward_blue,
    user_navigator,
    vip_store,
    vip,
    tour_vip,
    reward_first,
    reward_second,
    reward_third,
    reward_four,
} from '../../../../../assets/image.assets';
import TourRewardsTooltip from '../tour-rewards-tooltip/tour-rewards-tooltip';
import RewardPrize from './reward-prize/reward-prize';
import './tour-rewards-line.scss';

function vipDescription(type, intl) {
    switch (type) {
        case 111:
            return <span>1 {intl.messages.month}</span>;
        case 112:
            return <span>1 {intl.messages.weel}</span>;
        case 113:
            return <span>1 {intl.messages.year}</span>;
        default:
            return <span>1 {intl.messages.year}</span>;
    }
}
const getImageReward = (index) => {
    switch (index) {
        case 0:
            return <img src={reward_first} alt="#" />;
        case 1:
            return <img src={reward_second} alt="#" />;
        case 2:
            return <img src={reward_third} alt="#" />;
        default:
            return <img src={reward_four} alt="#" />;
    }
};
const redImgIcon = (index) => {
    switch (index) {
        case 0:
            return (
                <div className="red-first">
                    <img src={red} alt="#" className={`red-first-img-first`} />
                    <img src={red} alt="#" className={`red-first-img-second`} />
                    <img src={red} alt="#" className={`red-first-img-third`} />
                    <img src={red} alt="#" className={`red-first-img-four`} />
                </div>
            );
        case 1:
            return (
                <div className="red-second">
                    <img src={red} alt="#" className="red-second-img-first" />
                    <img src={red} alt="#" className="red-second-img-second" />
                    <img src={red} alt="#" className="red-second-img-third" />
                </div>
            );
        case 2:
            return (
                <div className="red-third">
                    <img src={red} alt="#" className="red-third-img-first" />
                    <img src={red} alt="#" className="red-third-img-second" />
                </div>
            );
        default:
            return <img src={red} alt="#" />;
    }
};
const TourRewardsLine = ({ item, joined, index }) => {
    const intl = useIntl();
    const userPositionData = useSelector((state) => state.userMeTour);

    return (
        <div className="tour-rewards-line">
            <div className="rewards-left-block">
                <div className="item-img">{getImageReward(index)}</div>
                <div className="rewards-img">{item.places}</div>
                <div className="last-reward-user">
                    {item.min !== item.max &&
                        item.min <= userPositionData.position &&
                        userPositionData.position <= item.max &&
                        joined && (
                            <>
                                <img
                                    src={user_navigator}
                                    className="user-navigator"
                                />
                                <TourRewardsTooltip />
                            </>
                        )}
                    <span>
                        {item.min !== item.max
                            ? `${item.min} - ${item.max}`
                            : item.min}
                    </span>
                </div>
            </div>
            <div className="rewards-right-block">
                {item.amount && (
                    <div className="reward-red-block">
                        {' '}
                        <span>{item.amount} </span>
                        {redImgIcon(index)}
                    </div>
                )}
                {item.vip && (
                    <div>
                        {' '}
                        <span>{vipDescription(item.amount, intl)} </span>
                        <img src={tour_vip} alt="#" />
                    </div>
                )}
            </div>
            {/* <div className="rewards-right-block">
                {item.prizes.map((item) => (
                    <>
                        {item.type === 'red' && (
                            <div className="reward-red-block">
                                {' '}
                                <span>{item.amount} </span>
                                {redImgIcon(index)}
                            </div>
                        )}

                        {item.type === 'vip' && (
                            <div>
                                {' '}
                                <span>
                                    {vipDescription(item.amount, intl)}{' '}
                                </span>
                                <img src={tour_vip} alt="#" />
                            </div>
                        )}
                    </>
                ))}
            </div> */}
        </div>
    );
};
export default TourRewardsLine;
