import React from 'react';
import { dead_body } from '../../../../assets/image.assets';

import './dead-body-user.scss';

const DeadBodyUser = () => {
    return (
        <div className={'dead-body-user'}>
            <img src={dead_body} alt="" className={'dead-body-img'} />
        </div>
    );
};

export default DeadBodyUser;
