import React from 'react';
import './say-button.scss';

const SayButton = ({ type = 'button', text, onClick = () => {} }) => {
    return (
        <button type={type} className="say-button" onClick={onClick}>
            <span>{text}</span>
        </button>
    );
};
export default SayButton;
