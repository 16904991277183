import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl/lib';

import '../../../assets/styles/divided-view.scss';
import './league-introduction.scss';

import UniversalShield from '../../../components/team-game/shields/universalShield/universal.shield';
import StandardLabel from '../../../components/team-game/labels/standard-label/standard-label';
import SecondaryLabel from '../../../components/team-game/labels/secondary-label/secondary-label';
import _3dButton from '../../../components/team-game/buttons/3d-button/3d-button';
import LockButton from '../../../components/team-game/buttons/lock-button/lock-button';

import { seenIntro_req } from '../../../api/team.game/team.game.api';
import {
    battles,
    cup_hex,
    user_hex_with_back,
} from '../../../assets/image.assets';
import ftueEnum from '../../../enums/team-game/ftue.enum';
import { closeTeamGame } from '../../../helpers/team-game/close.helper';
import {
    leftShowState,
    rightShowState,
} from '../../../helpers/team-game/divided-view.helper';
import { useDispatch } from 'react-redux';
import { navigate } from '../../../redux/global.slice';
import routesEnum from '../../../enums/routes.enum';
import { removeFTUE } from '../../../redux/team-game/team-game.slice';

const LeagueIntroduction = ({ blocked }) => {
    const dispatch = useDispatch();
    const [showState, setShowState] = useState(0);

    const intl = useIntl();

    useEffect(() => {
        setShowState(1);
        seenIntro_req(ftueEnum.INTRO);
    }, []);

    const _goToDashboard = () => {
        dispatch(navigate(routesEnum.JOIN_TEAM));
        dispatch(removeFTUE());
    };

    return (
        <div className={'divided-view league-introduction'}>
            <div className={'more-info-section divided-view-content'}>
                <div className="left-side-wrapper">
                    <div className={'left-side' + leftShowState(showState)}>
                        <UniversalShield text={'LEAGUE'} />
                    </div>
                </div>
                <div className="right-side-wrapper with-shield">
                    <div className={'right-side' + rightShowState(showState)}>
                        <div className="introduction-info">
                            <StandardLabel
                                text={intl.messages.team_game.we_introduce}
                            />
                            <SecondaryLabel
                                text={intl.messages.team_game.be_a_part}
                            />
                        </div>
                        <div className="intro-list">
                            <div className="intro-list-item">
                                <img src={user_hex_with_back} alt="user" />
                                <p>
                                    {intl.messages.team_game.introduce_list[0]}
                                </p>
                            </div>
                            <div className="intro-list-item">
                                <img src={cup_hex} alt="cup" />
                                <p>
                                    {intl.messages.team_game.introduce_list[1]}
                                </p>
                            </div>
                            <div className="intro-list-item">
                                <img src={battles} alt="battle" />
                                <p>
                                    {intl.messages.team_game.introduce_list[2]}
                                </p>
                            </div>
                        </div>
                        {blocked ? (
                            <div className={'access-lock-wrapper'}>
                                <LockButton _onClick={closeTeamGame} />
                            </div>
                        ) : (
                            <_3dButton
                                text={intl.messages.team_game.continue}
                                _onClick={_goToDashboard}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LeagueIntroduction;
