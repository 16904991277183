const joinTeamEnum = {
    JOIN: 0,
    ACCEPT: 1,
    WAITING: 2,
    LEAVE: 3,
    KICK: 4,
    REJECT: 5,
    MAX_MEMBERS_REACHED: 6,
};

Object.freeze(joinTeamEnum);

export default joinTeamEnum;
