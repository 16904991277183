import React from 'react';
import {
    yellow_bg,
    yellow_30,
    yellow_110,
    yellow_chest,
} from '../../../assets/image.assets';
import './yellow-tab.scss';

const YellowTab = ({ data }) => {
    if (!data || data.length < 1) {
        return null;
    }

    const filter = data.packs.chips.filter((el) => el.chipType === 'yellow');

    return (
        <div className="yellow-tab">
            {filter.map((data, index) => (
                <div className="yellow-tab-item" key={data.id}>
                    <div className="yellow-tab-bg">
                        <img src={yellow_bg} className="yellow-tab-chip" />
                        <div className="yellow-tab-chip-block">
                            <p className="yellow-tab-text">{data.chipAmount}</p>
                            {index === 0 && <img src={yellow_30} alt="#" />}
                            {index === 1 && (
                                <img
                                    src={yellow_110}
                                    alt="#"
                                    className="yellow-content-img"
                                />
                            )}
                            {index === 2 && <img src={yellow_chest} alt="#" />}

                            <div className="yellow-tab-item-buy-now">
                                <p>Gnel</p>
                                <span>
                                    {data.currency} {data.amount}
                                </span>
                            </div>
                            <div className="button-layer"></div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default YellowTab;
