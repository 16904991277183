const notificationsEnum = {
    USER_ACCEPTED_INVITE: 0,
    USER_ACCEPTED_JOIN_REQUEST: 1,
    USER_LEFT: 2,
    USER_KICKED: 3,
    INVITATION_FROM_TEAM: 4,
    REQUEST_TO_JOIN_TEAM: 5,
    TEAM_CREATED: 6,
};

Object.freeze(notificationsEnum);

export default notificationsEnum;
