import { createSlice } from '@reduxjs/toolkit';
import { getUser_req } from '../api/user.api';
import userSingleton from '../singletons/user.singleton';

export const meSlice = createSlice({
    name: 'me',
    initialState: {
        isLoading: false,
        error: false,
        data: null,
    },
    reducers: {
        getLoading: (state) => {
            state.isLoading = true;
        },
        getSuccess: (state, { payload }) => {
            state.data = payload;
            state.isLoading = false;
            state.error = false;
        },
        getError: (state, { payload }) => {
            state.isLoading = false;
            state.error = payload;
        },
    },
});

export const { getLoading, getSuccess, getError } = meSlice.actions;

export function getUser() {
    return async (dispatch) => {
        dispatch(getLoading());
        try {
            dispatch(getSuccess(await getUser_req()));
        } catch (error) {
            dispatch(getError(error.toString()));
        }
    };
}

export default meSlice.reducer;
