import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import './top-panel.scss';

import ShieldInfo from '../../info/shield/shield-info';

import StandardFillBar from '../../bars/standard-fill-bar/standard-fill-bar';
import HexFlipper from '../../flippers/hex-flipper/hex-flipper';
import TeamButton from '../../buttons/team-button/team-button';

import {
    battles,
    home_icon_home,
    star_with_back,
} from '../../../../assets/image.assets';
import { daysDifference } from '../../../../helpers/team-game/calc.helper';
import { formatNumberWithSpaces } from '../../../../utils/formatter.util';
import StandardLabel from '../../labels/standard-label/standard-label';
import SecondaryLabel from '../../labels/secondary-label/secondary-label';
import { useDispatch, useSelector } from 'react-redux';
import routesEnum from '../../../../enums/routes.enum';
import { navigate } from '../../../../redux/global.slice';
import PopupsEnum from '../../../../enums/popups.enum';
import { openCloseStartBattle } from '../../../../redux/team-game/team-game.slice';
import themeEnum from '../../../../enums/theme.enum';
import { tournamentColor } from '../../../../redux/theme.change.slice';
import { openPopups } from '../../../../redux/popup.slice';

const TopPanel = ({ battleDetails }) => {
    let canPlay = false;

    const intl = useIntl();
    const [battleInfoType, setBattleInfoType] = useState(1);
    const dispatch = useDispatch();
    const teamInfo = useSelector((state) => state.teamGame.teamInfo);
    const teamStats = useSelector((state) => state.teamGame.teamStatistic);

    const battleInfo = useSelector((state) => state.teamGame.battleInfo);

    const configs = useSelector((state) => state.teamGame.configs);

    const memberList = useSelector((state) => state.teamGame.memberList);
    const members = memberList ? Object.values(memberList) : [];

    if (battleDetails && battleDetails.endDate) {
        const endDate = new Date(battleDetails.endDate);

        if (!isNaN(endDate)) {
            canPlay = !!daysDifference(new Date(), endDate);
        }
    }
    const goLobbyPage = () => {
        dispatch(navigate(routesEnum.LOBBY));
        dispatch(tournamentColor(themeEnum.LOBBY));
    };

    const animateBattlesInfo = (newType) => {
        setBattleInfoType(newType);
    };
    const goMoreInfoPage = () => {
        dispatch(navigate(routesEnum.MORE_INFO_SECTION));
    };

    const cantPlayCheck =
        members.length < 10 ||
        teamStats.totalGames >= configs.maxGames ||
        !canPlay;

    const openGotIt = () => {
        if (members.length < 10) {
            dispatch(
                openPopups({
                    popupIds: [PopupsEnum.GOT_IT],
                    popupData: [
                        {
                            title: intl.formatMessage(
                                {
                                    id: 'less_than_players',
                                },
                                {
                                    count: 10,
                                }
                            ),
                            text: intl.formatMessage(
                                {
                                    id: 'your_team_has_less_than_players',
                                },
                                {
                                    count: 10,
                                }
                            ),
                        },
                    ],
                })
            );
        } else {
            dispatch(openCloseStartBattle(true));
        }
    };
    // if (teamInfo === null) {
    //     return null;
    // }
    return (
        <div className={'top-panel'}>
            <div className={'first-col'} onClick={goMoreInfoPage}>
                <ShieldInfo image={configs.logoBaseUrl + teamInfo.logoUrl} />
                <div className={'team-info'}>
                    <StandardLabel
                        text={teamInfo.name}
                        fontSize={teamInfo.name.length > 10 ? 18 : 21}
                    />
                    <SecondaryLabel text={'ID: ' + teamInfo.teamId} />
                </div>
            </div>
            <div className={'panel-align'}>
                <div className="level-info">
                    {/*<div className={'league'}>*/}
                    {/*    <HexFlipper firstImage={BronzeHex} />*/}
                    {/*    <div className={'info'}>*/}
                    {/*        <StandardLabel text={'bronze'} fontSize={18} />*/}
                    {/*        <StandardFillBar*/}
                    {/*            maxPoints={200000}*/}
                    {/*            points={180000}*/}
                    {/*            fromColor={'#b46643'}*/}
                    {/*            toColor={'#7593b0'}*/}
                    {/*            icon={LeagueLogo}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div
                        className={
                            'battles' +
                            (members.length < 10 ? ' grayscale' : '')
                        }
                    >
                        <HexFlipper
                            allowFlip={members.length >= 10}
                            firstImage={battles}
                            secondImage={star_with_back}
                            _onFlip={(type) => {
                                if (members.length >= 10) {
                                    animateBattlesInfo(type);
                                }
                            }}
                        />
                        <div className={'info'}>
                            <div
                                className={`battles-info${
                                    battleInfoType === 1 ? ' active' : ''
                                }`}
                            >
                                <StandardLabel
                                    text={intl.messages.team_game.battles}
                                    fontSize={18}
                                />
                                <StandardFillBar
                                    maxPoints={configs.maxGames}
                                    points={battleInfo.games}
                                    fromColor={'#AD2125'}
                                    toColor={'#871B24'}
                                />
                            </div>
                            <div
                                className={`stars-info${
                                    battleInfoType === 2 ? ' active' : ''
                                }`}
                            >
                                <StandardLabel
                                    text={formatNumberWithSpaces(
                                        battleInfo.stars
                                    )}
                                    fontSize={22}
                                />
                                <StandardLabel
                                    text={intl.messages.team_game.stars}
                                    fontSize={18}
                                    color={'#EAA844'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={'last-col' + (cantPlayCheck ? ' grayscale' : '')}
                >
                    <TeamButton
                        text={intl.messages.team_game.play}
                        _onClick={openGotIt}
                    />
                    <div className="vertical-line" />
                    <img
                        src={home_icon_home}
                        alt="home"
                        className={'home-icon'}
                        onClick={goLobbyPage}
                    />
                </div>
            </div>
        </div>
    );
};

// const mapStateToProps = (state) => {
//     return {
//         configs: state.teamGame.configs,
//         teamInfo: state.teamGame.teamInfo,
//         battleInfo: state.teamGame.battleInfo,
//         memberList: state.teamGame.memberList,
//         battleDetails: state.teamGame.battleDetails,
//     };
// };

// const mapDispatchToProps = (dispatch) => {
//     return {
//         navigate: (routeData) => dispatch(routerActions.navigate(routeData)),
//         openCloseStartBattle: (opened) =>
//             dispatch(teamGameActions.openCloseStartBattle(opened)),
//         openPopups: (popupData) =>
//             dispatch(popupsActions.openPopups(popupData)),
//     };
// };

export default TopPanel;
