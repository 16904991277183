import React, { useState, useEffect } from 'react';

import './dots-tab-changer.scss';

const DotsTabChanger = ({ dotsCount, activeIndex = 0, _onChange }) => {
    const [current, setCurrent] = useState(activeIndex);

    useEffect(() => {
        setCurrent(activeIndex);
    }, [activeIndex]);

    if (!dotsCount) {
        return;
    }

    const makeActive = (currentIndex) => {
        setCurrent(currentIndex);
        if (typeof _onChange === 'function') {
            _onChange(currentIndex);
        }
    };

    const dotComponents = [];

    for (let i = 0; i < dotsCount; i++) {
        dotComponents.push(
            <div
                className={'dot' + (i === current ? ' active' : '')}
                key={'dot-' + i}
                onClick={() => makeActive(i)}
            />
        );
    }

    return <div className={'dots-tab-changer'}>{dotComponents}</div>;
};

export default DotsTabChanger;
