import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ftu } from '../../../assets/image.assets';
import CloseButton from '../../../components/buttons/close-button/close-button';
import _3dButton from '../../../components/team-game/buttons/3d-button/3d-button';
import CloseButtonTeam from '../../../components/team-game/buttons/close-button-team/close-button-team';
import StandardLabel from '../../../components/team-game/labels/standard-label/standard-label';
import { closePopup } from '../../../redux/popup.slice';

import './first-time-use.popup.scss';

const FirstTimeUsePopup = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const closeFirstUsePopup = () => {
        dispatch(closePopup());
    };
    return (
        <div className={'first-time-use-popup'}>
            <div className="close-popup" onClick={closeFirstUsePopup}>
                <CloseButtonTeam />
            </div>

            <img src={ftu} alt="ftu" className={'tfu-image'} />
            <div className="ftu-info">
                <div className="label-wrapper">
                    <StandardLabel
                        text={intl.messages.its_best_to_invite_players}
                    />
                </div>
                <div className="first-time-button-wrapper">
                    <_3dButton
                        text={intl.messages.team_game.invite}
                        disabledClick
                    />
                </div>
            </div>
        </div>
    );
};

export default FirstTimeUsePopup;
