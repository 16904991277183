import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl/lib';
import { useDispatch, useSelector } from 'react-redux';

import { menu_angle, menu_home, sound, music } from '../../assets/image.assets';
import Toggle from '../../components/toggle/toggle';
import PopupsEnum from '../../enums/popups.enum';
import routesEnum from '../../enums/routes.enum';
import { navigate } from '../../redux/global.slice';
import { openGamePlayMenu } from '../../redux/menu-game.slice';
import { openPopups } from '../../redux/popup.slice';
import roomSingleton from '../../singletons/room.singleton';
import socketBridge from '../../socket';
import {
    RoomChannelEmittingEventNames,
    RoomChannelEmittingEvents,
    RoomChannelHandlingEvents,
    UserChannelEmittingEvents,
} from '../../socket/socket.events';
import './menu-game-play.scss';

const MenuGamePlay = () => {
    const intl = useIntl();
    const [menuOpened, setMenuOpened] = useState(false);
    const [musicToggleActive, setMusicToggleActive] = useState(false);
    const [soundToggleActive, setSoundToggleActive] = useState(false);
    const dispatch = useDispatch();
    const me = useSelector((state) => state.me);
    const partnerIdData = useSelector((state) => state.userInfo.partnerId);
    const openedState = useSelector(
        (state) => state.userMenuGame.rightMenu.rightMenuOpenedState
    );
    const musicToggle = () => {
        setMusicToggleActive(!musicToggleActive);
    };
    const soundToggle = () => {
        setSoundToggleActive(!soundToggleActive);
    };
    useEffect(() => {
        if (openedState === 1) {
            setMenuOpened(true);
        } else if (openedState === 2) {
            setMenuOpened(false);
            setTimeout(() => {
                dispatch(openGamePlayMenu({ rightMenuOpenedState: 3 }));
            }, 500);
        }
    }, [openedState]);
    const openExitPopup = () => {
        if (!roomSingleton.roomId) {
            socketBridge.emitToUserChannel({
                event: UserChannelEmittingEvents.FORCE_LEAVE_USER,
                partnerId: partnerIdData,
            });

            dispatch(openGamePlayMenu({ rightMenuOpenedState: 3 }));
            dispatch(navigate(routesEnum.LOBBY));
            return;
        } else {
            dispatch(
                openPopups({
                    popupIds: [PopupsEnum.EXIT_GAME],
                })
            );
            dispatch(openGamePlayMenu({ rightMenuOpenedState: 3 }));
        }
    };
    const goLobby = () => {
        if (!roomSingleton.roomId) {
            socketBridge.emitToUserChannel({
                event: UserChannelEmittingEvents.FORCE_LEAVE_USER,
                partnerId: partnerIdData,
            });

            dispatch(openGamePlayMenu({ rightMenuOpenedState: 3 }));
            dispatch(navigate(routesEnum.LOBBY));
            return;
        }
    };

    return (
        <div className={`menu_game_play${menuOpened ? ' open' : ''}`}>
            <img src={menu_angle} alt="menu-angle" className="menu_angle" />
            <p className="menu_game_play_text">fx</p>
            <div className="menu_game_play_item">
                <div
                    className={`menu_item${musicToggleActive ? '_active' : ''}`}
                >
                    <div className="menu_item_block">
                        <img src={music} alt="menu_music" />
                        <p
                            className={`menu_item_text${
                                musicToggleActive ? '_active' : ''
                            }`}
                        >
                            {intl.messages.popups.settings.music}
                        </p>
                    </div>

                    <Toggle
                        musicToggleActive={musicToggleActive}
                        _onClick={() => musicToggle()}
                    />
                </div>

                <div
                    className={`menu_item${soundToggleActive ? '_active' : ''}`}
                >
                    <div className="menu_item_block">
                        <img src={sound} alt="music_sound" />
                        <p
                            className={`menu_item_text${
                                soundToggleActive ? '_active' : ''
                            }`}
                        >
                            {intl.messages.popups.settings.sound}
                        </p>
                    </div>

                    <Toggle
                        soundToggleActive={soundToggleActive}
                        _onClick={() => soundToggle()}
                    />
                </div>
            </div>
            <div className="menu_game_play_home" onClick={openExitPopup}>
                <img src={menu_home} alt="menu_home" />
                <span className="menu_game_play_leave">
                    {intl.messages.gamePlay.leave}
                </span>
            </div>
        </div>
    );
};

export default MenuGamePlay;
