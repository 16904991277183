import React from 'react';
import { useIntl } from 'react-intl/lib';
import { greenchip_small, redchip_small } from '../../../assets/image.assets';
import './lobby-bet.scss';

const getRoomChip = (type) => {
    switch (type) {
        case 1:
            return redchip_small;
        case 2:
            return greenchip_small;

        default:
            return redchip_small;
    }
};
const LobbyBet = ({ bet, win, type }) => {
    const intl = useIntl();

    return (
        <div className="lobby_bet">
            <img src={getRoomChip(type)} alt="#" className="yellow_rectangle" />
            <p>
                {intl.messages.bet} {bet}
            </p>
            <span>
                {intl.messages.win} {win}
            </span>
        </div>
    );
};

export default LobbyBet;
