import React, { useEffect, useState, useRef, createRef } from 'react';
import { useIntl } from 'react-intl/lib';

import './team-notifications.scss';

import apiStore from '../../api/team.game/store';

import NotificationItem from './notification-item/notification-item';
import {
    acceptInvite_req,
    acceptRequest_req,
    getNotifications_req,
} from '../../api/team.game/team.game.api';
import notificationsEnum from '../../enums/team-game/notifications.enum';

import { removeLobbyButton } from '../../helpers/team-game/elements.helper';
import { closeTeamGame } from '../../helpers/team-game/close.helper';
import NotificationsCircle from '../../components/team-game/info/notifications-circle/notifications-circle.info';
import StandardLabel from '../../components/team-game/labels/standard-label/standard-label';
import jwtChangeEnum from '../../enums/team-game/jwtChange.enum';
import { useDispatch, useSelector } from 'react-redux';
import {
    setNotificationExists,
    teamNotificationOpen,
} from '../../redux/team-game/team-game.slice';

const getNotifications = async (setNotifications) => {
    try {
        const notifications = await getNotifications_req();
        const filtered = [];

        for (const notification of notifications) {
            if (
                notification.seen &&
                notification.type !== notificationsEnum.INVITATION_FROM_TEAM &&
                notification.type !== notificationsEnum.REQUEST_TO_JOIN_TEAM
            ) {
                continue;
            }

            filtered.push(notification);
        }
        setNotifications(filtered);
    } catch (e) {}
};

const acceptRequest = async (
    requestId,
    accept,
    getNotifications,
    updateMembers,
    setNotifications,
    external,
    setExternalNotificationState,
    setExternalNotification
) => {
    try {
        const response = await acceptRequest_req(requestId, accept);
        if (response && response.success) {
            updateMembers();
        }
    } catch (e) {
    } finally {
        getNotifications(setNotifications);

        if (external) {
            setExternalNotificationState(0);
            setTimeout(() => {
                setExternalNotification(null);
            }, 300);
        }
    }
};

const acceptInvite = async (
    inviteId,
    accept,
    getNotifications,
    updateMembers,
    setNotifications,
    external,
    setExternalNotificationState,
    setExternalNotification
) => {
    try {
        const response = await acceptInvite_req(inviteId, accept);
        if (response && response.success) {
            updateMembers();
        }
    } catch (e) {
    } finally {
        getNotifications(setNotifications);

        if (external) {
            setExternalNotificationState(0);
            setTimeout(() => {
                setExternalNotification(null);
            }, 300);
        }
    }
};

let existsTimeout = 0;

const TeamNotifications = ({
    teamUserNotificataion,
    teamNotification,
    open,
    position,
    setOpen,
    updateMembers,
    battleChange,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [animated, setAnimated] = useState(teamNotification ? true : false);
    const [notifications, setNotifications] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [externalNotificationState, setExternalNotificationState] = useState(
        0
    );
    const [externalNotification, setExternalNotification] = useState();
    const teamInfo = useSelector((state) => state.teamGame.teamInfo);

    const fromRoute = useSelector((state) => state.global.route);

    useEffect(() => {
        const _totalCount = teamUserNotificataion.length;
        setTotalCount(_totalCount);

        clearTimeout(existsTimeout);

        if (_totalCount === 0) {
            closeNotifications();
            existsTimeout = setTimeout(() => {
                dispatch(setNotificationExists(false));
            }, 300);
        } else {
            dispatch(setNotificationExists(true));
        }
    }, [notifications]);
    // useEffect(() => {
    //     if (player && player.userChannel) {
    //         player.userChannel.watch(channelHandler);
    //     }

    //     getNotifications(setNotifications);

    //     return () => {
    //         if (player && player.userChannel) {
    //             player.userChannel.unwatch(channelHandler);
    //         }
    //     };
    // }, []);
    // if (!fromRoute) {
    //     return <></>;
    // }

    // const show = () => {
    //     setOpen(true);

    //     setTimeout(() => {
    //         setAnimated(true);
    //     }, 100);
    // };

    const closeNotifications = () => {
        setAnimated(false);
        dispatch(teamNotificationOpen(false));
        setTimeout(() => {
            setOpen(false);
        }, 300);
    };

    const _saw = (index) => {
        const _notifications = [...notifications];
        if (_notifications[index]) {
            _notifications.splice(index, 1);
        }
        setNotifications(_notifications);
    };

    // const channelHandler = async (data) => {
    //     if (data) {
    //         let notificationTimeout;
    //         switch (data.event) {
    //             case 68:
    //                 const _notifications = [...notifications];
    //                 _notifications.push(data);
    //                 setNotifications(_notifications);

    //                 if (
    //                     fromRoute === 'Scene' &&
    //                     (data.type === notificationsEnum.INVITATION_FROM_TEAM ||
    //                         data.type ===
    //                             notificationsEnum.REQUEST_TO_JOIN_TEAM)
    //                 ) {
    //                     if (externalNotification) {
    //                         setExternalNotificationState(0);

    //                         setTimeout(() => {
    //                             setExternalNotification(data);
    //                             setExternalNotificationState(1);
    //                         }, 300);
    //                     } else {
    //                         setExternalNotification(data);
    //                         setExternalNotificationState(1);
    //                     }

    //                     clearTimeout(notificationTimeout);
    //                     notificationTimeout = setTimeout(() => {
    //                         setExternalNotificationState(0);
    //                         setTimeout(() => {
    //                             setExternalNotification(null);
    //                         }, 300);
    //                     }, 3000);
    //                 }
    //                 break;
    //             case 67: {
    //                 if (data.jwt && data.type !== jwtChangeEnum.CREATE) {
    //                     apiStore.setJWT(data.jwt);
    //                     // getTeamInfo();
    //                     // closeNotifications();

    //                     // if (typeof navigate === 'function') {
    //                     //     navigate('/');
    //                     // }

    //                     if (typeof window.updateUserTeamInfo === 'function') {
    //                         window.updateUserTeamInfo(function () {
    //                             removeLobbyButton();

    //                             if (fromRoute !== 'Scene') {
    //                                 window.lobbyButton();
    //                             }

    //                             if (
    //                                 teamInfo &&
    //                                 !teamInfo.teamId &&
    //                                 window.openTeamGame &&
    //                                 fromRoute !== '/join-team' &&
    //                                 fromRoute !== 'Scene'
    //                             ) {
    //                                 window.openTeamGame();
    //                             }
    //                         });
    //                     }

    //                     getNotifications(setNotifications);
    //                 }
    //                 break;
    //             }
    //             case 21:
    //                 // allowPlay: true
    //                 // event: 21
    //                 // roomType: "micro"
    //                 if (
    //                     data.allowPlay ||
    //                     data.message === 1 ||
    //                     data.message === 4
    //                 ) {
    //                     closeTeamGame();
    //                 }
    //                 break;
    //             case 71:
    //                 if (typeof battleChange === 'function') {
    //                     battleChange({
    //                         maxGames: data.maxGames,
    //                         games: data.games,
    //                     });
    //                 }
    //                 break;
    //         }
    //     }
    // };

    // if (
    //     !totalCount ||
    //     (fromRoute === '/dashboard' && !(teamInfo || {}).leader)
    // ) {
    //     return <></>;
    // }

    // const renderExternalNotifications = () => {
    //     // if (!externalNotification) {
    //     //     return <></>;
    //     // }
    //     return (
    //         <div
    //             className={
    //                 'external-notifications' +
    //                 (externalNotificationState ? ' show' : '')
    //             }
    //         >
    //             <NotificationItem
    //                 key={externalNotification._id}
    //                 item={externalNotification}
    //                 _acceptRequest={() =>
    //                     acceptRequest(
    //                         externalNotification._id,
    //                         true,
    //                         getNotifications,
    //                         updateMembers,
    //                         setNotifications,
    //                         true,
    //                         setExternalNotificationState,
    //                         setExternalNotification
    //                     )
    //                 }
    //                 _rejectRequest={() =>
    //                     acceptRequest(
    //                         externalNotification._id,
    //                         false,
    //                         getNotifications,
    //                         updateMembers,
    //                         setNotifications,
    //                         true,
    //                         setExternalNotificationState,
    //                         setExternalNotification
    //                     )
    //                 }
    //                 _acceptInvite={() =>
    //                     acceptInvite(
    //                         externalNotification._id,
    //                         true,
    //                         getNotifications,
    //                         updateMembers,
    //                         setNotifications,
    //                         true,
    //                         setExternalNotificationState,
    //                         setExternalNotification
    //                     )
    //                 }
    //                 _rejectInvite={() =>
    //                     acceptInvite(
    //                         externalNotification._id,
    //                         false,
    //                         getNotifications,
    //                         updateMembers,
    //                         setNotifications,
    //                         true,
    //                         setExternalNotificationState,
    //                         setExternalNotification
    //                     )
    //                 }
    //                 //index e sawi mej
    //                 _saw={() => _saw(true)}
    //                 teamInfo={teamInfo}
    //             />
    //         </div>
    //     );
    // };

    // if (!open) {
    //     return (
    //         <>
    //             <NotificationsCircle
    //                 position={position}
    //                 count={totalCount}
    //                 _onClick={show}
    //             />
    //             {renderExternalNotifications()}
    //         </>
    //     );
    // } else {
    return (
        <div className={`team-notifications ${position || 'left'}`}>
            <div
                className={`notifications-wrapper ${
                    animated ? 'animated' : ''
                }`}
            >
                <div className="notification-title">
                    <StandardLabel
                        text={intl.messages.team_game.notifications}
                    />
                </div>

                <div className="notification-items">
                    {[...teamUserNotificataion].reverse().map((item, index) => {
                        return (
                            <NotificationItem
                                key={item._id}
                                item={item}
                                _acceptRequest={() =>
                                    acceptRequest(
                                        item._id,
                                        true,
                                        getNotifications,
                                        updateMembers,
                                        setNotifications
                                    )
                                }
                                _rejectRequest={() =>
                                    acceptRequest(
                                        item._id,
                                        false,
                                        getNotifications,
                                        updateMembers,
                                        setNotifications
                                    )
                                }
                                _acceptInvite={() =>
                                    acceptInvite(
                                        item._id,
                                        true,
                                        getNotifications,
                                        updateMembers,
                                        setNotifications
                                    )
                                }
                                _rejectInvite={() =>
                                    acceptInvite(
                                        item._id,
                                        false,
                                        getNotifications,
                                        updateMembers,
                                        setNotifications
                                    )
                                }
                                _saw={() => _saw(index)}
                                teamInfo={teamInfo}
                            />
                        );
                    })}
                </div>
            </div>
            <div className="empty-space" onClick={closeNotifications} />
            <NotificationsCircle
                position={position}
                count={totalCount}
                _onClick={closeNotifications}
            />
        </div>
    );
};

export default TeamNotifications;
