import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tournamentUserFollow_req } from '../../../../api/tournament/tournament.api';
import LevelBar from '../../../../components/bars/level-bar/level.bar';
import { setUserMeTournament } from '../../../../redux/tournament/user-me-tournament.slice';
import FollowedContent from '../followed-content/followed-content';
import LeaderBoardContent from '../leaderboard-content/leaderboard-content';

import './dashboard-content.scss';

function groupFn(group, itemIndex) {
    let obj = { divClass: '' };
    for (const item of group) {
        if (
            item.min === item.max &&
            itemIndex + 1 <= item.max &&
            item.max <= 4
        ) {
            if (itemIndex + 1 === 1) {
                return { divClass: 'orange' };
            } else if (itemIndex + 1 === 2) {
                return { divClass: 'grey' };
            } else if (itemIndex + 1 === 3) {
                return { divClass: 'bronze' };
            } else {
                return { divClass: 'hardGrey' };
            }
        } else {
            obj = { divClass: 'black' };
        }
    }
    return obj;
}
const DashboardContent = ({ tourTabIndex }) => {
    const dispatch = useDispatch();
    const tourData = useSelector((state) => state.global.navigateData);

    const tourGames = tourData.tourData[0];
    const me = useSelector((state) => state.me.data);

    return (
        <>
            {tourTabIndex === 0 ? (
                <LeaderBoardContent tournament={tourGames} />
            ) : (
                <FollowedContent tournament={tourGames} />
            )}
        </>
    );
};
export default DashboardContent;
