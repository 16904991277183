import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl/lib';
import { useDispatch } from 'react-redux';

import {
    editTeam_req,
    teamInfo_req,
} from '../../../../../api/team.game/team.game.api';
import {
    chart_hex,
    cup_hex,
    cup_icon,
    level_arrow,
    oval,
} from '../../../../../assets/image.assets';
import ErrorBanner from '../../../../../components/team-game/banners/error-banner/error-banner';
import _3dButton from '../../../../../components/team-game/buttons/3d-button/3d-button';
import GradientLineInfo from '../../../../../components/team-game/info/gradient-line/gradient-line-info';
import StandardTextbox from '../../../../../components/team-game/inputs/standard-text-box/standard-text-box-input';
import StandardLabel from '../../../../../components/team-game/labels/standard-label/standard-label';
import routesEnum from '../../../../../enums/routes.enum';
import editTeamEnum from '../../../../../enums/team-game/editTeam.enum';
import { navigate } from '../../../../../redux/global.slice';
import {
    battleChange,
    battleInfoState,
    getCreateTeamConfigs,
    teamInfoState,
    teamStatistics,
} from '../../../../../redux/team-game/team-game.slice';

const MoreInfoConditions = ({
    createTeamConfigs,
    teamInfo = {},
    getTeamInfoSilent = () => {},
}) => {
    const intl = useIntl();

    const dispatch = useDispatch();

    const [minLevel, setMinLevel] = useState(
        (teamInfo.joinConditions || {}).level || createTeamConfigs.minJoinLevel
    );
    const [minWinRate, setMinWinRate] = useState(
        (teamInfo.joinConditions || {}).winRate || 0
    );
    const [minLevelError, setMinLevelError] = useState(false);
    const [minWinRateError, setMinWinRateError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [saved, setSaved] = useState(false);
    const [teamInfoUpdate, setTeamInfoUpdate] = useState(null);

    const _onMinLevelChange = (e) => {
        const _minLevel =
            e.target.value === ''
                ? e.target.value
                : parseInt(e.target.value) || 0;

        setMinLevel(_minLevel);
        setMinLevelError(Number(_minLevel) < createTeamConfigs.minJoinLevel);
    };

    const _onMinWinRateChange = (e) => {
        const _minWinRate =
            e.target.value === ''
                ? e.target.value
                : parseInt(e.target.value) || 0;

        setMinWinRate(_minWinRate);
        setMinWinRateError(
            Number(_minWinRate) < 0 || Number(_minWinRate) > 100
        );
    };

    const _saveConditions = async () => {
        try {
            if (!minLevel && !minWinRate) {
                return;
            }

            setIsLoading(true);
            await editTeam_req(editTeamEnum.joinConditions, {
                level: minLevel,
                winRate: minWinRate,
            });
            setSaved(true);

            const teamInfoData = await teamInfo_req();
            setIsLoading(false);
            if (teamInfoData) {
                setTeamInfoUpdate(teamInfoData);
            }
            dispatch(battleChange(teamInfoData.configs));
            dispatch(teamInfoState(teamInfoData.teamData));
            dispatch(battleInfoState(teamInfoData.battleInfo));
            dispatch(teamStatistics(teamInfoData.teamStats));
            dispatch(getCreateTeamConfigs(teamInfoData.teamCreateConfigs));

            // if (teamInfoData && teamInfoData.teamData) {
            //     dispatch(navigate(routesEnum.TEAM_DASHBOARD));
            // }

            setTimeout(() => {
                setSaved(false);
            }, 2000);
        } catch (e) {
        } finally {
            setIsLoading(false);
        }
    };

    let saveText = '';

    if (!isLoading) {
        saveText = saved
            ? intl.messages.team_game.saved
            : intl.messages.team_game.save;
    }

    return (
        <div className={'conditions-content'}>
            {teamInfo.leader ? (
                <>
                    <div className="level-win-rate">
                        <div className="condition-wrapper">
                            <StandardLabel
                                text={intl.messages.team_game.minimum_level}
                            />
                            <StandardTextbox
                                type={'numeric'}
                                value={minLevel}
                                _onChange={_onMinLevelChange}
                                withError={minLevelError}
                            />
                            <div
                                className={
                                    'conditions-error-wrapper' +
                                    (!minLevelError ? ' without-error' : '')
                                }
                            >
                                <ErrorBanner
                                    text={
                                        intl.messages.team_game
                                            .minimum_player_level +
                                        ' ' +
                                        createTeamConfigs.minJoinLevel
                                    }
                                />
                            </div>
                        </div>
                        <div className="condition-wrapper">
                            <StandardLabel
                                text={intl.messages.team_game.min_win_rate}
                            />
                            <StandardTextbox
                                type={'numeric'}
                                value={minWinRate}
                                _onChange={_onMinWinRateChange}
                                withError={minWinRateError}
                            />
                            <div
                                className={
                                    'conditions-error-wrapper' +
                                    (!minWinRateError ? ' without-error' : '')
                                }
                            >
                                <ErrorBanner
                                    text={
                                        <FormattedMessage
                                            id={'should_be_from_to'}
                                            values={{ from: 0, to: 100 }}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className="save-section">
                        <p>
                            <FormattedMessage
                                id={'changes_will_not_affect_players'}
                            />
                        </p>
                        <_3dButton
                            text={saveText}
                            icon={isLoading && oval}
                            _onClick={_saveConditions}
                        />
                    </div>
                </>
            ) : (
                <div className={'not-leader-more-info'}>
                    <p className={'support-text'}>
                        {intl.messages.team_game.you_may_share_to_support}
                    </p>
                    <div className="gradient-lines">
                        <GradientLineInfo
                            leftSide={{
                                image: cup_hex,
                                text:
                                    intl.messages.team_game
                                        .min_win_rate_to_join_your_team,
                            }}
                            rightSide={[
                                {
                                    key: 'win-rate',
                                    top: {
                                        icon: cup_icon,
                                        value: minWinRate + '%',
                                    },
                                    bottomValue:
                                        intl.messages.team_game.win_rate,
                                },
                            ]}
                        />
                        <GradientLineInfo
                            leftSide={{
                                image: chart_hex,
                                text:
                                    intl.messages.team_game
                                        .min_level_to_join_your_team,
                            }}
                            rightSide={[
                                {
                                    key: 'win-level',
                                    top: {
                                        icon: level_arrow,
                                        value: minLevel,
                                    },
                                    bottomValue: intl.messages.team_game.level,
                                },
                            ]}
                        />
                    </div>
                    {/*<ShareHex*/}
                    {/*    firstLine={intl.messages.share_your_team}*/}
                    {/*    secondLine={*/}
                    {/*        teamInfo.leader &&*/}
                    {/*        intl.messages.invite_friends_to_join_your_team*/}
                    {/*    }*/}
                    {/*    isLeader={teamInfo.leader}*/}
                    {/*/>*/}
                </div>
            )}
        </div>
    );
};

export default MoreInfoConditions;
