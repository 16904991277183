export { default as fb } from './images/additional/fb.jpeg';
export { default as achievement } from './images/badges/achievement.svg';
export { default as badge_bg } from './images/badges/badge-bg.png';
export { default as badges_level } from './images/badges/badges-level.svg';
export { default as empty_star } from './images/badges/empty_star.png';
export { default as first } from './images/badges/first.svg';
export { default as icon_check } from './images/badges/icon-check.svg';
export { default as icon_complete } from './images/badges/icon-complete.svg';
export { default as red_small } from './images/badges/red-small.svg';
export { default as yellow_small } from './images/badges/yellow-small.svg';
export { default as backgrounds_pattern } from './images/bg/backgrounds-pattern.svg';
export { default as big } from './images/bg/big.png';
export { default as bonus } from './images/bg/bonus.png';
export { default as lobby_bg } from './images/bg/lobby_bg.svg';
export { default as micro } from './images/bg/micro.png';
export { default as prof } from './images/bg/prof.png';
export { default as training } from './images/bg/training.png';
export { default as blot_bg_img } from './images/blot_bg_img.svg';
export { default as bunny } from './images/bunny.png';
export { default as candy_wings_logo } from './images/candy-wings-logo.svg';
export { default as game_end_red_chip } from './images/chips/game-end-red-chip.svg';
export { default as green } from './images/chips/green.svg';
export { default as green_popup } from './images/chips/green_popup.png';
export { default as greenchip_small } from './images/chips/greenchip_small.svg';
export { default as red } from './images/chips/red.svg';
export { default as red_popup } from './images/chips/red_popup.png';
export { default as redchip_plus } from './images/chips/redchip_plus.svg';
export { default as redchip_small } from './images/chips/redchip_small.svg';
export { default as yellow } from './images/chips/yellow.svg';
export { default as discount_gradLine } from './images/discount/discount_gradLine.svg';
export { default as discount_offer } from './images/discount/discount_offer.svg';
export { default as discount_tournament } from './images/discount/discount_tournament.svg';
export { default as whell_animation_store } from './images/discount/whell-animation-store.svg';
export { default as x2_icon } from './images/discount/x2_icon.svg';
export { default as x3_icon } from './images/discount/x3_icon.svg';
export { default as x4_icon } from './images/discount/x4_icon.svg';
export { default as x5_icon } from './images/discount/x5_icon.svg';
export { default as elipse_tour } from './images/elipse-tour.png';
export { default as elipse } from './images/elipse.svg';
export { default as ellipse } from './images/ellipse.svg';
export { default as chat_tip } from './images/game-info/chat_tip.png';
export { default as chat_tip_blur } from './images/game-info/chat_tip_blur.png';
export { default as contra } from './images/game-info/contra.svg';
export { default as dialogue_box_right } from './images/game-info/dialogue_box_right.png';
export { default as dialogue_box_up } from './images/game-info/dialogue_box_up.png';
export { default as green_chips_game_end } from './images/game-info/green_chips_game_end.svg';
export { default as last_hand_bg } from './images/game-info/last-hand-bg.svg';
export { default as last_hand_card } from './images/game-info/last-hand-card.svg';
export { default as nex } from './images/game-info/nex.png';
export { default as order } from './images/game-info/order.svg';
export { default as recontra } from './images/game-info/recontra.svg';
export { default as red_chips_waiting } from './images/game-info/red_chips_waiting.svg';
export { default as user_say } from './images/game-info/user_say.svg';
export { default as vip_ribbon } from './images/game-info/vip_ribbon.svg';
export { default as vip_ring } from './images/game-info/vip_ring.svg';
export { default as gift_img } from './images/gifts/gift_img.png';
export { default as arrow_left_icon } from './images/icons/arrow-left-icon.svg';
export { default as arrow_icon } from './images/icons/arrow_icon.svg';
export { default as close_icon_hover } from './images/icons/close-icon-hover.png';
export { default as close_icon } from './images/icons/close-icon.png';
export { default as close } from './images/icons/close.svg';
export { default as close_button } from './images/icons/close_button.svg';
export { default as edit } from './images/icons/edit.png';
export { default as error } from './images/icons/error.svg';
export { default as exit_question } from './images/icons/exit-question.svg';
export { default as game_play_menu } from './images/icons/game-play-menu.svg';
export { default as green_chip_icon } from './images/icons/green_chip_icon.svg';
export { default as home_icon_hover } from './images/icons/home-icon-hover.png';
export { default as home_icon } from './images/icons/home-icon.png';
export { default as icon_red } from './images/icons/icon-red.png';
export { default as icon_tournament } from './images/icons/icon-tournament.png';
export { default as icon_vip } from './images/icons/icon-vip.svg';
export { default as icon_yellow } from './images/icons/icon-yellow.png';
export { default as menu } from './images/icons/menu.svg';
export { default as plus_icon } from './images/icons/plus-icon.svg';
export { default as send_gift } from './images/icons/send_gift.svg';
export { default as star_active } from './images/icons/star_active.svg';
export { default as star_inActive } from './images/icons/star_inActive.svg';
export { default as tour_points_icon } from './images/icons/tour_points_icon.svg';
export { default as user_avatar } from './images/icons/user-avatar.png';
export { default as user_chat } from './images/icons/user-chat.svg';
export { default as vip_game_play } from './images/icons/vip-game-play.svg';
export { default as xp_icon } from './images/icons/xp_icon.svg';
export { default as full_circle } from './images/loader/full_circle.svg';
export { default as loading } from './images/loader/loading.svg';
export { default as waiting_loader } from './images/loader/waiting_loader.png';
export { default as big_game } from './images/lobby_item/big-game.png';
export { default as bonus_game } from './images/lobby_item/bonus-game.png';
export { default as cash_game } from './images/lobby_item/cash_game.png';
export { default as green_rectangle } from './images/lobby_item/green_rectangle.svg';
export { default as micro_game } from './images/lobby_item/micro-game.png';
export { default as proffesional } from './images/lobby_item/proffesional.png';
export { default as red_rectangle } from './images/lobby_item/red_rectangle.svg';
export { default as team_game } from './images/lobby_item/team-game.png';
export { default as tournament_lobby_last } from './images/lobby_item/tournament-lobby-last.png';
export { default as tournament_lobby } from './images/lobby_item/tournament-lobby.png';
export { default as tournament } from './images/lobby_item/tournament.svg';
export { default as yellow_rectangle } from './images/lobby_item/yellow_rectangle.png';
export { default as bazar_blot_logo } from './images/logo/bazar-blot-logo.png';
export { default as menu_angle } from './images/menu/menu-angle.svg';
export { default as menu_chat } from './images/menu/menu_chat.svg';
export { default as menu_home } from './images/menu/menu_home.svg';
export { default as menu_offer_gradline } from './images/menu/menu_offer_gradline.svg';
export { default as music } from './images/menu/music.svg';
export { default as music_dis } from './images/menu/music_dis.svg';
export { default as sound } from './images/menu/sound.svg';
export { default as sound_dis } from './images/menu/sound_dis.svg';
export { default as vip } from './images/menu/vip.svg';
export { default as admin_chip } from './images/popups/admin-chip/admin-chip.png';
export { default as close_popup } from './images/popups/close_popup.svg';
export { default as disc } from './images/popups/disc.png';
export { default as backspace } from './images/popups/gifts_form/backspace.svg';
export { default as gift_card } from './images/popups/gifts_form/gift_card.png';
export { default as ribbon_gifts } from './images/popups/gifts_form/ribbon_gifts.png';
export { default as warning } from './images/popups/gifts_form/warning.svg';
export { default as greenChip } from './images/popups/greenChip.svg';
export { default as level_up_bg_layer } from './images/popups/level-up/level-up-bg-layer.png';
export { default as level_up_bg } from './images/popups/level-up/level-up-bg.png';
export { default as level_up_circle } from './images/popups/level-up/level-up-circle.png';
export { default as level_up_ribbon } from './images/popups/level-up/level-up-ribbon.png';
export { default as me_avatar } from './images/popups/level-up/me-avatar.svg';
export { default as wreath } from './images/popups/level-up/wreath.png';
export { default as monthVip } from './images/popups/monthVip.png';
export { default as settings_chat } from './images/popups/settings/settings_chat.svg';
export { default as settings_close } from './images/popups/settings/settings_close.svg';
export { default as red_content } from './images/popups/store/red_content.svg';
export { default as best } from './images/popups/store/red_tab/best.svg';
export { default as red_tab } from './images/popups/store/red_tab/red_tab.svg';
export { default as store_best } from './images/popups/store/store_best.svg';
export { default as store_bg } from './images/popups/store/store_bg.svg';
export { default as store_red_icon } from './images/popups/store/store_red_icon.svg';
export { default as store_vip_icon } from './images/popups/store/store_vip_icon.png';
export { default as free } from './images/popups/store/tabs/free.png';
export { default as red_tabs } from './images/popups/store/tabs/red_tabs.svg';
export { default as vip_tabs } from './images/popups/store/tabs/vip_tabs.png';
export { default as yellow_tabs } from './images/popups/store/tabs/yellow_tabs.png';
export { default as green_vip } from './images/popups/store/vip_tab/green_vip.svg';
export { default as vip_content } from './images/popups/store/vip_tab/vip_content.png';
export { default as vip_tab } from './images/popups/store/vip_tab/vip_tab.png';
export { default as yellow_tab } from './images/popups/store/yellow_tab.png';
export { default as check } from './images/popups/vip/check.svg';
export { default as noun_check } from './images/popups/vip/noun_check.svg';
export { default as vip_big } from './images/popups/vip/vip_big.png';
export { default as ribbon_g } from './images/ribbon/ribbon_g.svg';
export { default as ribbon_g_1 } from './images/ribbon/ribbon_g_1.svg';
export { default as ribbon_grey } from './images/ribbon/ribbon_grey.svg';
export { default as ribbon_r } from './images/ribbon/ribbon_r.svg';
export { default as ribbon_r_1 } from './images/ribbon/ribbon_r_1.svg';
export { default as ribbon_y } from './images/ribbon/ribbon_y.svg';
export { default as ribbon_y_1 } from './images/ribbon/ribbon_y_1.svg';
export { default as gold } from './images/store/gold.svg';
export { default as gold_vip_bg } from './images/store/gold_vip_bg.svg';
export { default as green_vip_bg } from './images/store/green_vip_bg.svg';
export { default as chip_1000 } from './images/store/red/chip-1000.png';
export { default as chip_15500 } from './images/store/red/chip-15500.png';
export { default as chip_2500 } from './images/store/red/chip-2500.png';
export { default as chip_5500 } from './images/store/red/chip-5500.png';
export { default as red_navbar } from './images/store/red-navbar.svg';
export { default as store_week_am } from './images/store/store_week_am.svg';
export { default as store_week_en } from './images/store/store_week_en.svg';
export { default as store_week_ru } from './images/store/store_week_ru.svg';
export { default as store_x2 } from './images/store/store_x2.svg';
export { default as store_x3 } from './images/store/store_x3.svg';
export { default as store_x4 } from './images/store/store_x4.svg';
export { default as store_x5 } from './images/store/store_x5.svg';
export { default as vip_month } from './images/store/vip/vip-month.png';
export { default as vip_week } from './images/store/vip/vip-week.png';
export { default as vip_year } from './images/store/vip/vip-year.png';
export { default as vip_store } from './images/store/vip-store.svg';
export { default as sector0 } from './images/store/wheel/sector0.png';
export { default as sector1 } from './images/store/wheel/sector1.png';
export { default as wheel } from './images/store/wheel.svg';
export { default as yellow_110 } from './images/store/yellow/yellow-110.png';
export { default as yellow_30 } from './images/store/yellow/yellow-30.png';
export { default as yellow_chest } from './images/store/yellow/yellow-chest.png';
export { default as yellow_bg } from './images/store/yellow/yellow_bg.svg';
export { default as boy } from './images/suits/boy.svg';
export { default as clubs_icon } from './images/suits/clubs-icon.svg';
export { default as clubs } from './images/suits/clubs.svg';
export { default as diamonds_icon } from './images/suits/diamonds-icon.svg';
export { default as diamonds } from './images/suits/diamonds.svg';
export { default as hearts_icon } from './images/suits/hearts-icon.svg';
export { default as hearts } from './images/suits/hearts.svg';
export { default as spade_icon } from './images/suits/spade-icon.svg';
export { default as spade } from './images/suits/spade.svg';
export { default as main_user_info_back_team } from './images/team-game/backgrounds/main-user-info-back-team.png';
export { default as shield_frame } from './images/team-game/backgrounds/shield-frame.svg';
export { default as badge } from './images/team-game/badges/badge.png';
export { default as butcher_0 } from './images/team-game/badges/butcher/butcher-0.png';
export { default as butcher_1 } from './images/team-game/badges/butcher/butcher-1.png';
export { default as butcher_2 } from './images/team-game/badges/butcher/butcher-2.png';
export { default as butcher_3 } from './images/team-game/badges/butcher/butcher-3.png';
export { default as butcher_4 } from './images/team-game/badges/butcher/butcher-4.png';
export { default as chiefAccountant_0 } from './images/team-game/badges/chiefAccountant/chiefAccountant-0.png';
export { default as chiefAccountant_1 } from './images/team-game/badges/chiefAccountant/chiefAccountant-1.png';
export { default as chiefAccountant_2 } from './images/team-game/badges/chiefAccountant/chiefAccountant-2.png';
export { default as chiefAccountant_3 } from './images/team-game/badges/chiefAccountant/chiefAccountant-3.png';
export { default as chiefAccountant_4 } from './images/team-game/badges/chiefAccountant/chiefAccountant-4.png';
export { default as don_0 } from './images/team-game/badges/don/don-0.png';
export { default as don_1 } from './images/team-game/badges/don/don-1.png';
export { default as don_2 } from './images/team-game/badges/don/don-2.png';
export { default as don_3 } from './images/team-game/badges/don/don-3.png';
export { default as don_4 } from './images/team-game/badges/don/don-4.png';
export { default as fourWarriors_0 } from './images/team-game/badges/fourWarriors/fourWarriors-0.png';
export { default as fourWarriors_1 } from './images/team-game/badges/fourWarriors/fourWarriors-1.png';
export { default as fourWarriors_2 } from './images/team-game/badges/fourWarriors/fourWarriors-2.png';
export { default as fourWarriors_3 } from './images/team-game/badges/fourWarriors/fourWarriors-3.png';
export { default as fourWarriors_4 } from './images/team-game/badges/fourWarriors/fourWarriors-4.png';
export { default as godOfMars_0 } from './images/team-game/badges/godOfMars/godOfMars-0.png';
export { default as godOfMars_1 } from './images/team-game/badges/godOfMars/godOfMars-1.png';
export { default as godOfMars_2 } from './images/team-game/badges/godOfMars/godOfMars-2.png';
export { default as godOfMars_3 } from './images/team-game/badges/godOfMars/godOfMars-3.png';
export { default as godOfMars_4 } from './images/team-game/badges/godOfMars/godOfMars-4.png';
export { default as greateVictory_0 } from './images/team-game/badges/greateVictory/greateVictory-0.png';
export { default as greateVictory_1 } from './images/team-game/badges/greateVictory/greateVictory-1.png';
export { default as greateVictory_2 } from './images/team-game/badges/greateVictory/greateVictory-2.png';
export { default as greateVictory_3 } from './images/team-game/badges/greateVictory/greateVictory-3.png';
export { default as greateVictory_4 } from './images/team-game/badges/greateVictory/greateVictory-4.png';
export { default as hasher_0 } from './images/team-game/badges/hasher/hasher-0.png';
export { default as hasher_1 } from './images/team-game/badges/hasher/hasher-1.png';
export { default as hasher_2 } from './images/team-game/badges/hasher/hasher-2.png';
export { default as hasher_3 } from './images/team-game/badges/hasher/hasher-3.png';
export { default as hasher_4 } from './images/team-game/badges/hasher/hasher-4.png';
export { default as kingOfCapot_0 } from './images/team-game/badges/kingOfCapot/kingOfCapot-0.png';
export { default as kingOfCapot_1 } from './images/team-game/badges/kingOfCapot/kingOfCapot-1.png';
export { default as kingOfCapot_2 } from './images/team-game/badges/kingOfCapot/kingOfCapot-2.png';
export { default as kingOfCapot_3 } from './images/team-game/badges/kingOfCapot/kingOfCapot-3.png';
export { default as kingOfCapot_4 } from './images/team-game/badges/kingOfCapot/kingOfCapot-4.png';
export { default as luckyThree_0 } from './images/team-game/badges/luckyThree/luckyThree-0.png';
export { default as luckyThree_1 } from './images/team-game/badges/luckyThree/luckyThree-1.png';
export { default as luckyThree_2 } from './images/team-game/badges/luckyThree/luckyThree-2.png';
export { default as luckyThree_3 } from './images/team-game/badges/luckyThree/luckyThree-3.png';
export { default as luckyThree_4 } from './images/team-game/badges/luckyThree/luckyThree-4.png';
export { default as napoleon_0 } from './images/team-game/badges/napoleon/napoleon-0.png';
export { default as napoleon_1 } from './images/team-game/badges/napoleon/napoleon-1.png';
export { default as napoleon_2 } from './images/team-game/badges/napoleon/napoleon-2.png';
export { default as napoleon_3 } from './images/team-game/badges/napoleon/napoleon-3.png';
export { default as napoleon_4 } from './images/team-game/badges/napoleon/napoleon-4.png';
export { default as opposition_0 } from './images/team-game/badges/opposition/opposition-0.png';
export { default as opposition_1 } from './images/team-game/badges/opposition/opposition-1.png';
export { default as opposition_2 } from './images/team-game/badges/opposition/opposition-2.png';
export { default as opposition_3 } from './images/team-game/badges/opposition/opposition-3.png';
export { default as opposition_4 } from './images/team-game/badges/opposition/opposition-4.png';
export { default as pensioner_0 } from './images/team-game/badges/pensioner/pensioner-0.png';
export { default as pensioner_1 } from './images/team-game/badges/pensioner/pensioner-1.png';
export { default as pensioner_2 } from './images/team-game/badges/pensioner/pensioner-2.png';
export { default as pensioner_3 } from './images/team-game/badges/pensioner/pensioner-3.png';
export { default as pensioner_4 } from './images/team-game/badges/pensioner/pensioner-4.png';
export { default as shadow_attack_0 } from './images/team-game/badges/shadow-attack/shadow-attack-0.png';
export { default as shadow_attack_1 } from './images/team-game/badges/shadow-attack/shadow-attack-1.png';
export { default as shadow_attack_2 } from './images/team-game/badges/shadow-attack/shadow-attack-2.png';
export { default as shadow_attack_3 } from './images/team-game/badges/shadow-attack/shadow-attack-3.png';
export { default as shadow_attack_4 } from './images/team-game/badges/shadow-attack/shadow-attack-4.png';
export { default as sniper_0 } from './images/team-game/badges/sniper/sniper-0.png';
export { default as sniper_1 } from './images/team-game/badges/sniper/sniper-1.png';
export { default as sniper_2 } from './images/team-game/badges/sniper/sniper-2.png';
export { default as sniper_3 } from './images/team-game/badges/sniper/sniper-3.png';
export { default as sniper_4 } from './images/team-game/badges/sniper/sniper-4.png';
export { default as superBlot_0 } from './images/team-game/badges/superBlot/superBlot-0.png';
export { default as superBlot_1 } from './images/team-game/badges/superBlot/superBlot-1.png';
export { default as superBlot_2 } from './images/team-game/badges/superBlot/superBlot-2.png';
export { default as superBlot_3 } from './images/team-game/badges/superBlot/superBlot-3.png';
export { default as superBlot_4 } from './images/team-game/badges/superBlot/superBlot-4.png';
export { default as buy_red_big } from './images/team-game/buyChips/buy-red-big.png';
export { default as buy_red_extra } from './images/team-game/buyChips/buy-red-extra.png';
export { default as buy_red_little } from './images/team-game/buyChips/buy-red-little.png';
export { default as buy_red_medium } from './images/team-game/buyChips/buy-red-medium.png';
export { default as gold_chip } from './images/team-game/chips/gold-chip.svg';
export { default as gold_chips_big_img } from './images/team-game/chips/gold-chips-big-img.svg';
export { default as gold_chips_big } from './images/team-game/chips/gold-chips-big.png';
export { default as gold_chips_medium } from './images/team-game/chips/gold-chips-medium.svg';
export { default as gold_chips } from './images/team-game/chips/gold-chips.svg';
export { default as red_chip } from './images/team-game/chips/red-chip.svg';
export { default as red_chips_create } from './images/team-game/chips/red-chips-create.svg';
export { default as red_chips_reward_min } from './images/team-game/chips/red-chips-reward-min.svg';
export { default as red_chips_reward } from './images/team-game/chips/red-chips-reward.svg';
export { default as back_no_border } from './images/team-game/hexagons/back-no-border.png';
export { default as battle } from './images/team-game/hexagons/battle.svg';
export { default as battles } from './images/team-game/hexagons/battles.svg';
export { default as bronze } from './images/team-game/hexagons/bronze.svg';
export { default as chart_hex } from './images/team-game/hexagons/chart-hex.svg';
export { default as checked_hex } from './images/team-game/hexagons/checked-hex.svg';
export { default as cup_hex } from './images/team-game/hexagons/cup-hex.svg';
export { default as empty_blue } from './images/team-game/hexagons/empty-blue.svg';
export { default as hex_20 } from './images/team-game/hexagons/hex-20.svg';
export { default as hexagon_back } from './images/team-game/hexagons/hexagon-back.svg';
export { default as invite_hex } from './images/team-game/hexagons/invite-hex.svg';
export { default as league_logo } from './images/team-game/hexagons/league-logo.svg';
export { default as league_with_back } from './images/team-game/hexagons/league-with-back.svg';
export { default as player_hex } from './images/team-game/hexagons/player-hex.svg';
export { default as plus_hex } from './images/team-game/hexagons/plus-hex.svg';
export { default as progress_right } from './images/team-game/hexagons/progress-right.svg';
export { default as share } from './images/team-game/hexagons/share.svg';
export { default as star_with_back } from './images/team-game/hexagons/star-with-back.svg';
export { default as star } from './images/team-game/hexagons/star.svg';
export { default as user_hex_with_back } from './images/team-game/hexagons/user-hex-with-back.svg';
export { default as user_hex } from './images/team-game/hexagons/user-hex.svg';
export { default as active_bottom_shield } from './images/team-game/highlights/active-bottom-shield.svg';
export { default as active_left_shield } from './images/team-game/highlights/active-left-shield.svg';
export { default as active_right_shield } from './images/team-game/highlights/active-right-shield.svg';
export { default as bottom_shield_big } from './images/team-game/highlights/bottom-shield-big.svg';
export { default as inactive_bottom_shield } from './images/team-game/highlights/inactive-bottom-shield.svg';
export { default as inactive_left_shield } from './images/team-game/highlights/inactive-left-shield.svg';
export { default as inactive_right_shield } from './images/team-game/highlights/inactive-right-shield.svg';
export { default as left_shield_big } from './images/team-game/highlights/left-shield-big.svg';
export { default as right_shield_big } from './images/team-game/highlights/right-shield-big.svg';
export { default as Icon_hearts } from './images/team-game/icons/Icon-hearts.svg';
export { default as active_random } from './images/team-game/icons/active-random.svg';
export { default as arrow_left_team } from './images/team-game/icons/arrow-left-team.svg';
export { default as arrow_right_team } from './images/team-game/icons/arrow-right-team.svg';
export { default as calendar_icon } from './images/team-game/icons/calendar-icon.svg';
export { default as cards_team } from './images/team-game/icons/cards-team.svg';
export { default as chat_team } from './images/team-game/icons/chat-team.svg';
export { default as checked } from './images/team-game/icons/checked.svg';
export { default as circle_plus_blinking } from './images/team-game/icons/circle-plus-blinking.svg';
export { default as circle_plus } from './images/team-game/icons/circle-plus.svg';
export { default as circle } from './images/team-game/icons/circle.svg';
export { default as close_team } from './images/team-game/icons/close-team.svg';
export { default as cup_icon } from './images/team-game/icons/cup-icon.svg';
export { default as cup } from './images/team-game/icons/cup.svg';
export { default as flag } from './images/team-game/icons/flag.svg';
export { default as flame } from './images/team-game/icons/flame.svg';
export { default as gold_team } from './images/team-game/icons/gold-team.png';
export { default as home_icon_home } from './images/team-game/icons/home-icon-home.svg';
export { default as inactive_random } from './images/team-game/icons/inactive-random.svg';
export { default as level_arrow } from './images/team-game/icons/level-arrow.svg';
export { default as lock_icon } from './images/team-game/icons/lock-icon.svg';
export { default as match_icon } from './images/team-game/icons/match-icon.svg';
export { default as mismatch } from './images/team-game/icons/mismatch.svg';
export { default as pencil } from './images/team-game/icons/pencil.svg';
export { default as player_icon } from './images/team-game/icons/player-icon.svg';
export { default as remove_icon } from './images/team-game/icons/remove-icon.svg';
export { default as search } from './images/team-game/icons/search.svg';
export { default as send_icon } from './images/team-game/icons/send-icon.svg';
export { default as share_icon } from './images/team-game/icons/share-icon.svg';
export { default as shield } from './images/team-game/icons/shield.svg';
export { default as star_icon } from './images/team-game/icons/star-icon.svg';
export { default as swords_icon } from './images/team-game/icons/swords-icon.svg';
export { default as thumb_down } from './images/team-game/icons/thumb-down.svg';
export { default as x_icon } from './images/team-game/icons/x-icon.svg';
export { default as yellow_flag } from './images/team-game/icons/yellow-flag.svg';
export { default as ftu } from './images/team-game/info/ftu.png';
export { default as red_badge } from './images/team-game/info/red-badge.svg';
export { default as ball_triangle } from './images/team-game/loaders/ball-triangle.svg';
export { default as oval } from './images/team-game/loaders/oval.svg';
export { default as gold_ribbon } from './images/team-game/ribbons/gold-ribbon.svg';
export { default as red_ribbon } from './images/team-game/ribbons/red-ribbon.svg';
export { default as team_ribbon_back } from './images/team-game/ribbons/team-ribbon-back.png';
export { default as team_ribbon_front } from './images/team-game/ribbons/team-ribbon-front.png';
export { default as team_ribbon } from './images/team-game/ribbons/team-ribbon.svg';
export { default as diamond_shield } from './images/team-game/shields/diamond-shield.png';
export { default as empty } from './images/team-game/shields/empty.png';
export { default as wood_shield_whitespace } from './images/team-game/shields/wood-shield-whitespace.png';
export { default as wood_shield } from './images/team-game/shields/wood-shield.png';
export { default as gold_top } from './images/team-game/tapes/gold-top.svg';
export { default as lobby_tape } from './images/team-game/tapes/lobby-tape.svg';
export { default as dead_body } from './images/team-game/users/dead-body.svg';
export { default as user_team } from './images/team-game/users/user-team.jpg';
export { default as user } from './images/team-game/users/user.png';
export { default as tour_pattern } from './images/tour-pattern.png';
export { default as arrow_rewards } from './images/tournament/arrow-rewards.svg';
export { default as tour_rec_blue } from './images/tournament/bg/tour-rec-blue.svg';
export { default as tour_rec_blueGrey } from './images/tournament/bg/tour-rec-blueGrey.svg';
export { default as tour_rec_grey } from './images/tournament/bg/tour-rec-grey.svg';
export { default as tour_rec_orange } from './images/tournament/bg/tour-rec-orange.svg';
export { default as tour_rec_yellow } from './images/tournament/bg/tour-rec-yellow.svg';
export { default as tournament_bg_elipse } from './images/tournament/bg/tournament-bg-elipse.svg';
export { default as tournament_bg_layer } from './images/tournament/bg/tournament-bg-layer.svg';
export { default as tournament_bg } from './images/tournament/bg/tournament-bg.svg';
export { default as dashboard_little_img } from './images/tournament/dashboard-little-img.png';
export { default as final_tournament } from './images/tournament/final-tournament.png';
export { default as glow_green } from './images/tournament/glow-green.png';
export { default as glow_red } from './images/tournament/glow-red.png';
export { default as copper_medal } from './images/tournament/medal/copper_medal.png';
export { default as gold_medal } from './images/tournament/medal/gold_medal.png';
export { default as iron_medal } from './images/tournament/medal/iron_medal.png';
export { default as silver_medal } from './images/tournament/medal/silver_medal.png';
export { default as reward_first } from './images/tournament/reward/reward-first.svg';
export { default as reward_four } from './images/tournament/reward/reward-four.svg';
export { default as reward_second } from './images/tournament/reward/reward-second.svg';
export { default as reward_third } from './images/tournament/reward/reward-third.svg';
export { default as first_reward } from './images/tournament/rewards/first-reward.svg';
export { default as four_reward } from './images/tournament/rewards/four-reward.svg';
export { default as reward_red } from './images/tournament/rewards/reward-red.svg';
export { default as second_reward } from './images/tournament/rewards/second-reward.svg';
export { default as third_reward } from './images/tournament/rewards/third-reward.svg';
export { default as tour_reward_blue } from './images/tournament/rewards/tour-reward-blue.svg';
export { default as tour_reward_grey } from './images/tournament/rewards/tour-reward-grey.svg';
export { default as tour_reward_orange } from './images/tournament/rewards/tour-reward-orange.svg';
export { default as tour_reward } from './images/tournament/rewards/tour-reward.svg';
export { default as table_bg } from './images/tournament/table-bg.svg';
export { default as title_grow } from './images/tournament/title-grow.png';
export { default as tour_cup } from './images/tournament/tour-cup.svg';
export { default as tour_game } from './images/tournament/tour-game.png';
export { default as tour_home_icon } from './images/tournament/tour-home-icon.svg';
export { default as tour_info } from './images/tournament/tour-info.png';
export { default as tour_knifes } from './images/tournament/tour-knifes.svg';
export { default as tour_locked } from './images/tournament/tour-locked.svg';
export { default as tour_member } from './images/tournament/tour-member.svg';
export { default as tour_panel } from './images/tournament/tour-panel.png';
export { default as tour_partisipation } from './images/tournament/tour-partisipation.svg';
export { default as tour_prize_found } from './images/tournament/tour-prize-found.svg';
export { default as tour_red } from './images/tournament/tour-red.svg';
export { default as tour_time_green } from './images/tournament/tour-time-green.svg';
export { default as tour_time_member } from './images/tournament/tour-time-member.svg';
export { default as tour_time_red } from './images/tournament/tour-time-red.svg';
export { default as tour_time_yellow } from './images/tournament/tour-time-yellow.svg';
export { default as tour_time } from './images/tournament/tour-time.svg';
export { default as tour_timer_img } from './images/tournament/tour-timer-img.svg';
export { default as tour_vip } from './images/tournament/tour-vip.svg';
export { default as user_navigator } from './images/tournament/user-navigator.svg';
export { default as white_glow } from './images/tournament/white_glow.png';
export { default as chat } from './images/user-menu-icon/chat.svg';
export { default as emoji } from './images/user-menu-icon/emoji.svg';
export { default as gift } from './images/user-menu-icon/gift.svg';
export { default as user_icon } from './images/user-menu-icon/user-icon.svg';
export { default as add_panel } from './images/user-panel/add_panel.svg';
export { default as gold_panel } from './images/user-panel/gold_panel.png';
export { default as green_panel } from './images/user-panel/green_panel.svg';
export { default as red_panel } from './images/user-panel/red_panel.svg';
export { default as store } from './images/user-panel/store.svg';
export { default as arrow_left } from './images/user_info/arrow-left.svg';
export { default as arrow_right } from './images/user_info/arrow-right.svg';
export { default as battle_logo } from './images/user_info/battle-logo.svg';
export { default as cards } from './images/user_info/cards.svg';
export { default as cup_user_info } from './images/user_info/cup-user-info.svg';
export { default as main_user_info_back } from './images/user_info/main-user-info-back.png';
export { default as rating_image } from './images/user_info/rating-image.png';
export { default as star_logo } from './images/user_info/star-logo.svg';
export { default as user_info_medal } from './images/user_info/user-info-medal.svg';
export { default as user_info_tournament } from './images/user_info/user-info-tournament.png';
export { default as basementl } from './images/wheel/basementl.png';
export { default as center } from './images/wheel/center.png';
export { default as spin_bg } from './images/wheel/spin-bg.png';
export { default as wheel_arrow } from './images/wheel/wheel-arrow.png';
export { default as wheel_bg_layer } from './images/wheel/wheel-bg-layer.svg';
export { default as wheel_bg } from './images/wheel/wheel-bg.png';
export { default as wheel_bottom } from './images/wheel/wheel-bottom.png';
export { default as wheel_elipse_spin } from './images/wheel/wheel-elipse-spin.png';
export { default as wheel_elipse } from './images/wheel/wheel-elipse.svg';
export { default as wheel_frame } from './images/wheel/wheel-frame.png';
export { default as wheel_icon } from './images/wheel/wheel-icon.png';
export { default as wheel_not_active } from './images/wheel/wheel-not-active.png';
export { default as wheel_spin } from './images/wheel/wheel-spin.svg';
export { default as wheel_tab } from './images/wheel/wheel-tab.png';
export { default as wheel_dis } from './images/wheel/wheel_dis.png';
export { default as wheel_dis_button } from './images/wheel/wheel_dis_button.png';
export { default as wins_spectre_center } from './images/wins-spectre-center.png';
