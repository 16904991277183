import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
    tournamentLeaderboard_req,
    tournamentUserFollow_req,
    tournamentUserParticipants_req,
} from '../../../../api/tournament/tournament.api';
import LevelBar from '../../../../components/bars/level-bar/level.bar';
import { setUserMeTournament } from '../../../../redux/tournament/user-me-tournament.slice';

const LeaderBoardContent = ({ tournament, data }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [leaderBoard, setLeaderBoard] = useState([]);
    const tourId = tournament.id;
    const me = useSelector((state) => state.me.data);

    const tournamentLeaderBoard = async () => {
        const tourLeaderBoard = await tournamentLeaderboard_req(tourId);

        setLeaderBoard(tourLeaderBoard);
        const userMeTourData = tourLeaderBoard.data.leaderboard.filter(
            (item) => item.userId === me.userId
        );

        dispatch(setUserMeTournament(userMeTourData));
    };
    useEffect(() => {
        tournamentLeaderBoard();
    }, []);
    // const tournamentParticipants = async () => {
    //     const tournamentParticipantsData = await tournamentUserParticipants_req(
    //         tourId
    //     );
    //     setParticipants(tournamentParticipantsData);
    //     const userMeTourData = tournamentParticipantsData.users.filter(
    //         (item) => item.userId === me.userId
    //     );

    //     dispatch(setUserMeTournament(userMeTourData));
    // };
    // useEffect(() => {
    //     tournamentParticipants();
    // }, []);
    const followingUser = async (userId) => {
        try {
            const followingUserData = await tournamentUserFollow_req(
                tourId,
                userId
            );
            if (followingUserData.status === 'success') {
                tournamentLeaderBoard();
            }
        } catch (e) {}
    };

    return (
        <div className="dashboard-content">
            {leaderBoard &&
                leaderBoard.data &&
                leaderBoard.data.leaderboard.map((item, index) => {
                    return (
                        <div className="content-line">
                            <div className="user-leaderboard-img-block">
                                <div className="user-leaderboard-img">
                                    {item.avatar ? (
                                        <img src={item.avatar} alt="#" />
                                    ) : (
                                        <img
                                            src="https://cdn.blotclub.am/avatars/enky1.png"
                                            alt="#"
                                        />
                                    )}
                                </div>
                                <div className="user-leaderboard-level">
                                    <span>{item.level}</span>
                                </div>
                            </div>
                            <div className="user-position-block">
                                <span className="user-position-name">
                                    {item.username
                                        ? item.username.slice(0, 7)
                                        : 'agantagexos'}
                                </span>

                                <div className={`position-btn`}>
                                    <span>{index + 1}</span>
                                </div>
                            </div>
                            <div className="user-score-block">
                                <span>{intl.messages.tournament.scores}</span>
                                <p>{item.score}</p>
                            </div>
                            <div className="user-game-count">
                                <span>
                                    {item.playedGames}/{tournament.maxGames}
                                </span>
                                {/* <div className="user-game-count-rating">
                            <div className="user-game-progress"></div>
                        </div> */}
                                <LevelBar
                                    percent={
                                        (item.games * 100) / tournament.maxGames
                                    }
                                />
                            </div>
                            <div
                                className={`${
                                    item.follow
                                        ? 'tournament-followed-follow'
                                        : 'tournament-followed-btn'
                                }`}
                                onClick={() => followingUser(item.userId)}
                            >
                                {item.follow ? (
                                    <span>
                                        {intl.messages.tournament.unFollow}
                                    </span>
                                ) : (
                                    <span>
                                        {intl.messages.tournament.follow}
                                    </span>
                                )}
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};
export default LeaderBoardContent;
