import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    store_vip_icon,
    vip,
    yellow_small,
} from '../../../assets/image.assets';
import roomSingleton from '../../../singletons/room.singleton';
import { RoomChannelEmittingEvents } from '../../../socket/socket.events';
import './gifts.scss';
import socketBridge from '../../../socket';
import { openCloseGift } from '../../../redux/menu-game.slice';
import BackgroundPopup from '../../../components/background/background-popup/background-popup';
import { openPopups } from '../../../redux/popup.slice';
import PopupsEnum from '../../../enums/popups.enum';
import { setChip } from '../../../redux/chip.info.slice';

const Gifts = () => {
    const [giftAnimate, setGiftAnimate] = useState(false);
    const allGifts = useSelector((state) => state.gifts.allGifts);
    const openedState = useSelector(
        (state) => state.userMenuGame.gift.openedState
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (openedState === 1) {
            setGiftAnimate(true);
        } else if (openedState === 2) {
            setGiftAnimate(false);
            setTimeout(() => {
                dispatch(openCloseGift({ openedState: 3, userId: null }));
            }, 500);
        }
    }, [openedState]);

    const me = useSelector((state) => state.me);
    const giftOpened = useSelector((state) => state.userMenuGame.gift);

    const roomIdGet = roomSingleton.roomId;

    const sendGift = (item) => {
        if (item.price > me.data.yellow) {
            dispatch(
                openPopups({
                    popupIds: [PopupsEnum.NOT_ENOUGH_GOLD],
                })
            );
        }
        if (me.data.yellow > item.price) {
            dispatch(
                setChip([
                    {
                        chip: 'yellow',
                        value: -item.price,
                    },
                ])
            );
        }
        if (item.vip && !me.data.vip) {
            dispatch(
                openPopups({
                    popupIds: [PopupsEnum.VIP_POPUP],
                })
            );
        }
        socketBridge.emitToRoomChannel({
            event: RoomChannelEmittingEvents.SEND_GIFT,
            giftId: item.id,
            receiverId: giftOpened.userId,
        });
        dispatch(openCloseGift({ openedState: 3, userId: null }));
    };

    return (
        <div className={`gift-block${giftAnimate ? ' open' : ''}`}>
            <div className="gift-animate-block">
                <BackgroundPopup>
                    <p className="gifts_text">gifts</p>
                    <div className="menu-gradline"> </div>
                    <div className="gift_block">
                        {allGifts &&
                            allGifts.gifts.map((item) => (
                                <>
                                    {item.show && (
                                        <div
                                            className="gift"
                                            onClick={() => sendGift(item)}
                                        >
                                            <img
                                                src={item.src}
                                                alt="#"
                                                className="gift-img"
                                            />

                                            {item.vip && (
                                                <div className="gift-vip">
                                                    <img
                                                        src={store_vip_icon}
                                                        alt="#"
                                                    />
                                                </div>
                                            )}

                                            <div className="gift-currency">
                                                <img src={yellow_small} />
                                                <span>{item.price}</span>
                                            </div>
                                        </div>
                                    )}
                                </>
                            ))}
                    </div>
                </BackgroundPopup>
            </div>
        </div>
    );
};

export default Gifts;
