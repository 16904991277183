import React, { useState, useEffect } from 'react';
import './home.page.scss';
import LobbyItem from '../../components/lobby-item/lobby-item';
import Background from '../../components/background/background';
import { useDispatch, useSelector } from 'react-redux';
import {
    levelUp_req,
    checkNotification_req,
    userInfo_req,
} from '../../api/user.api';
import userSingleton from '../../singletons/user.singleton';
import PopupsEnum from '../../enums/popups.enum';
import { openPopups } from '../../redux/popup.slice';
import socketBridge from '../../socket';
import { UserChannelHandlingEvents } from '../../socket/socket.events';
import { setUserUpdate } from '../../redux/user.update.slice';

const HomePage = () => {
    const dispatch = useDispatch();
    const [shouldAnimate, setShouldAnimate] = useState(0);
    const [levelUp, setLevelUp] = useState([]);
    const [adminChipData, setAdminChipData] = useState([]);

    const me = useSelector((state) => state.me);
    const userLevel = userSingleton._userLevel;

    const updateUserChip = useSelector((state) => state.chipInfo.chipUpdate);
    const userId = userSingleton.userId;
    const chipInfo = useSelector((state) => state.chipInfo);
    const userUpdate = async () => {
        const userUpdateChip = await userInfo_req(me.data.userId);
        dispatch(setUserUpdate(userUpdateChip));
    };
    useEffect(() => {
        if (userLevel === 20) {
            dispatch(
                openPopups({
                    popupIds: [PopupsEnum.TOURNAMENT_UNLOCK],
                })
            );
        }
    }, [userLevel]);
    useEffect(() => {
        userUpdate();
    }, [me.data]);

    const checkNotification = async () => {
        const notificationAdmin = await checkNotification_req(userId);
        setAdminChipData(notificationAdmin);
        if (
            notificationAdmin &&
            notificationAdmin.notifications &&
            notificationAdmin.notifications.length > 0
        ) {
            dispatch(
                openPopups({
                    popupIds: [PopupsEnum.ADMIN_CHIP_POPUP],
                    popupData: [notificationAdmin],
                })
            );
        }
    };
    useEffect(() => {
        function watcher({ event, ...args }) {
            switch (event) {
                case UserChannelHandlingEvents.CHARGE_USER:
                    if (args.giftType === 'adminGift') {
                        checkNotification();
                    }
                    break;
                default:
                    break;
            }
        }

        socketBridge.watchUserChannel(watcher);

        return () => {
            socketBridge.unwatchUserChannel(watcher);
        };
    }, [dispatch]);

    useEffect(() => {
        const checkLevelUp = async () => {
            const userLevelUp = await levelUp_req(userId);
            setLevelUp(userLevelUp);
            if (userLevelUp.status) {
                dispatch(
                    openPopups({
                        popupIds: [PopupsEnum.LEVEL_UP],
                        popupData: [userLevelUp],
                    })
                );
            }
        };
        checkLevelUp();

        checkNotification();
    }, []);

    return (
        <React.Fragment>
            <Background />
            <LobbyItem shouldAnimate={shouldAnimate} />

            {/* <GameInfo /> */}
            {/* <GreenPopup /> */}
        </React.Fragment>
    );
};
export default HomePage;
