import React, { createRef, useState, useEffect } from 'react';
import StandardLabel from '../../labels/standard-label/standard-label';

import './floating-tab-changer.scss';

const FloatingTabChanger = ({
    currentTab = 0,
    setCurrentTab = () => {},
    imageTabs = [],
    tabs = [],
}) => {
    const [totalWidth, setTotalWidth] = useState(0);
    const [tabLineWidth, setTabLineWidth] = useState(0);
    const [lineLeft, setLineLeft] = useState(0);
    const tabRefs = tabs.map(() => createRef());

    useEffect(() => {
        if (tabRefs && tabRefs.length) {
            let _totalWidth = 0;

            for (const index in tabRefs) {
                const ref = tabRefs[index];
                if (ref && ref.current) {
                    if (Number(index) === currentTab) {
                        setTabLineWidth(ref.current.clientWidth);
                    }

                    _totalWidth += ref.current.clientWidth;
                }
            }

            if (_totalWidth) {
                setTotalWidth(_totalWidth + (tabRefs.length - 1) * 58);
            }
        }
    }, []);

    useEffect(() => {
        if (imageTabs && imageTabs.length) {
            return setLineLeft(currentTab * 106);
        }

        const currentTabRef = tabRefs[currentTab];

        if (currentTabRef && currentTabRef.current) {
            setTabLineWidth(currentTabRef.current.clientWidth);
        }

        if (tabs && tabs.length) {
            let left = 0;

            if (currentTab === 0) {
                return setLineLeft(left);
            }

            tabRefs.forEach((ref, index) => {
                if (index < currentTab) {
                    if (ref.current) {
                        left += ref.current.clientWidth;
                        left += currentTab * 58;
                    }

                    setLineLeft(left);
                }
            });
        }
    }, [currentTab]);

    return (
        <div className={'floating-tab-changer'}>
            <div
                className="tabs"
                style={{
                    width:
                        (totalWidth
                            ? totalWidth
                            : imageTabs.length > 1
                            ? imageTabs.length * 106
                            : 48) + 'px',
                }}
            >
                {imageTabs && imageTabs.length ? (
                    imageTabs.map((image, index) => {
                        return (
                            <div
                                key={'tab-img-' + index}
                                className={'tab-img-wrapper'}
                            >
                                <img
                                    src={image}
                                    alt="tab image"
                                    className={
                                        'tab-img' +
                                        (index === currentTab ? ' active' : '')
                                    }
                                    onClick={() => setCurrentTab(index)}
                                />
                            </div>
                        );
                    })
                ) : (
                    <></>
                )}
                {tabs && tabs.length ? (
                    tabs.map((text, index) => {
                        return (
                            <div
                                key={'tab-text-' + index}
                                className={'tab-text-wrapper'}
                                ref={tabRefs[index]}
                                onClick={() => setCurrentTab(index)}
                            >
                                <StandardLabel text={text} />
                            </div>
                        );
                    })
                ) : (
                    <></>
                )}
                <div
                    className="floating-tab-line"
                    style={{
                        left: lineLeft + 'px',
                        width: (tabLineWidth ? tabLineWidth : 48) + 'px',
                    }}
                />
            </div>
        </div>
    );
};

export default FloatingTabChanger;
