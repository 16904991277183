import React from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';

import './chat-message.listItem.scss';

import { chat, user, user_avatar } from '../../../../assets/image.assets';
import messagesEnum from '../../../../enums/team-game/messages.enum';
import StandardLabel from '../../labels/standard-label/standard-label';
import SecondaryLabel from '../../labels/secondary-label/secondary-label';

const ChatMessageListItem = ({ messageData, member = {} }) => {
    const intl = useIntl();

    const adminData = {
        img: user_avatar,
        name: 'BLOT CLUB',
        position: intl.messages.team_game.administration,
    };

    const formattedMessage = () => {
        switch (messageData.messageType) {
            case messagesEnum.MESSAGE:
            case messagesEnum.ANNOUNCEMENT:
                return messageData.message;
            case messagesEnum.JOIN:
                return intl.formatMessage(
                    {
                        id: 'user_joined_the_team',
                    },
                    {
                        username: member.name,
                    }
                );
            case messagesEnum.LEFT:
                return intl.formatMessage(
                    {
                        id: 'user_left_the_team',
                    },
                    {
                        username: member.name,
                    }
                );
            case messagesEnum.TEAM_CREATE:
                return intl.formatMessage({
                    id: 'welcome_this_is_your_team_chat',
                });
            default:
                return messageData.message;
        }
    };

    const getUserImage = () => {
        switch (messageData.messageType) {
            case messagesEnum.ANNOUNCEMENT:
            case messagesEnum.TEAM_CREATE:
                return adminData.img;
        }

        return member.avatar || chat;
    };

    const getPosition = () => {
        switch (messageData.messageType) {
            case messagesEnum.ANNOUNCEMENT:
            case messagesEnum.TEAM_CREATE:
                return adminData.position;
        }

        return intl.formatMessage({
            id: member.leader ? 'leader' : 'member',
        });
    };

    const getMemberName = () => {
        switch (messageData.messageType) {
            case messagesEnum.ANNOUNCEMENT:
            case messagesEnum.TEAM_CREATE:
                return adminData.name;
        }

        return member.name;
    };

    const getTypeClass = () => {
        if (messageData.messageType === messagesEnum.JOIN) {
            return ' joined';
        } else if (messageData.messageType === messagesEnum.LEFT) {
            return ' left';
        }
        return ' ';
    };

    return (
        <div className={'chat-message-list-item'}>
            <div className={'inner-wrapper' + getTypeClass()}>
                <div className={'top-wrapper'}>
                    <img
                        src={getUserImage()}
                        alt="user"
                        className={'user-img'}
                        onError={(ev) => {
                            ev.target.onerror = null;
                            ev.target.src = chat;
                        }}
                    />
                    <div className="text-section">
                        <StandardLabel text={getMemberName()} />
                        <SecondaryLabel text={getPosition()} />
                        <p className={'message'}>{formattedMessage()}</p>
                    </div>
                </div>
                <div className="date-wrapper">
                    <span>
                        {moment(messageData.date).format('MMM D, HH:mm')}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ChatMessageListItem;
