import React from 'react';

import './just-hold-info.scss';

import { FormattedMessage, useIntl } from 'react-intl/lib';
import StandardLabel from '../../labels/standard-label/standard-label';

const JustHoldInfo = () => {
    const intl = useIntl();
    return (
        <div className={'just-hold-info'}>
            <StandardLabel text={intl.messages.team_game.just_hold} />
            <p className={'just-hold-text'}>
                <FormattedMessage
                    id={'wait_until_the_team_leader_invites'}
                    values={{ count: 10 }}
                />
            </p>
        </div>
    );
};

export default JustHoldInfo;
