import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { user_navigator } from '../../../assets/image.assets';
import TournamentBg from '../../../components/background/tournament-bg/tournament-bg';
import TourUserPanel from '../../../components/panels/userPanel/tour-user-panel/tour-user-panel';

import DashboardHeader from './dashboard-header/dashboard-header';
import './tournament-dashboard.scss';
import DashboardContent from './dashboard-content/dashboard-content';
import DashboardGames from './dashboard-games/dashboard-games';
import {
    tournamentUserPosition_req,
    tournamentUserTop_req,
    tournamentUserParticipants_req,
    tournamentHistory_req,
    tournamentLeaderboard_req,
} from '../../../api/tournament/tournament.api';
import { setUserPositionData } from '../../../redux/tournament/reward-group.slice';
import { groupByPlaces } from '../../../helpers/groupBy-reward.helper';
import TournamentDashboardTab from './dashboard-tab/dashboard-tab';

const TournamentDashboard = () => {
    const dispatch = useDispatch();
    const me = useSelector((state) => state.me.data);
    const [leaderBoard, setLeaderBoard] = useState([]);
    const [tourTabIndex, setTourTabIndex] = useState(0);
    // const [positionUser, setPositionUser] = useState(null);
    // const [tournamentGame, setTournamentGame] = useState([]);
    // const [topUser, setTopUser] = useState([]);
    const tournamentData = useSelector((state) => state.global.navigateData);

    const tourData = tournamentData.tourData[0];
    const id = tourData.id;

    // const userId = me.userId;
    // const tourId = tourData.tournamentId;
    // const userPosition = async () => {
    //     const userPositionData = await tournamentUserPosition_req(tourId);
    //     setPositionUser(userPositionData);
    //     dispatch(setUserPositionData(userPositionData));
    // };
    // const userTop = async () => {
    //     const userTopData = await tournamentUserTop_req(tourId);
    //     setTopUser(userTopData);
    // };

    // const tournamentHistory = async () => {
    //     const tournamnetHistoryGame = await tournamentHistory_req(tourId);
    //     setTournamentGame(tournamnetHistoryGame);
    // };
    // useEffect(() => {
    //     userPosition();
    //     userTop();
    //     tournamentHistory();
    // }, []);
    const dashboardTab = (tourTabIndex) => {
        setTourTabIndex(tourTabIndex);
    };
    return (
        <div className="tournament-dashboard-page">
            <TourUserPanel data={tourData} />
            <TournamentBg>
                <div className="tournament-dashboard-wrap">
                    <div className="tournament-dashboard">
                        <DashboardHeader />
                        <div className="dashboard-wrapper">
                            <TournamentDashboardTab
                                tourTabIndex={tourTabIndex}
                                dashboardTab={dashboardTab}
                            />
                            <DashboardContent tourTabIndex={tourTabIndex} />
                        </div>
                    </div>
                    <DashboardGames tourData={tourData} />
                </div>
            </TournamentBg>
        </div>
    );
};

export default TournamentDashboard;
