import React from 'react';
import ButtonTreeD from '../../../../components/buttons/button_3d/button-3d';
import {
    settings_close,
    tour_partisipation,
    tour_prize_found,
} from '../../../../assets/image.assets';
import './tournament-partisipation.scss';
import TournamentPartisipationBlock from './tour-partisipation-block/tour-partisipation-block';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '../../../../redux/global.slice';
import routesEnum from '../../../../enums/routes.enum';
import { tournamentJoin_req } from '../../../../api/tournament/tournament.api';

const TournamentPartisipation = () => {
    const dispatch = useDispatch();
    const tourData = useSelector((state) => state.global.navigateData);
    const me = useSelector((state) => state.me.data);
    const joined = useSelector((state) => state.global.joined);
    const tourInfoData = tourData.tourData[0];
    const tourPartisipation = tourInfoData.info;
    // const tourRewardInfo = JSON.parse(tourInfoData.reward_info);

    const tourRewards = () => {
        dispatch(navigate(routesEnum.TOURNAMENT));
    };
    const tournamentPage = () => {
        dispatch(navigate(routesEnum.TOURNAMENT_DASHBOARD));
    };

    const tournamentJoin = async () => {
        const userId = me.userId;
        const tourId = tourInfoData.id;
        const tournamentJoinData = await tournamentJoin_req(userId, tourId);
        if (tournamentJoinData) {
            dispatch(
                navigate({
                    route: routesEnum.TOURNAMENT_DASHBOARD,
                    navigateData: tourData,
                })
            );
        }
    };

    return (
        <div className="partisipation">
            <div className="tour-partisipation-block">
                <TournamentPartisipationBlock
                    imageId={tour_partisipation}
                    title={'Participation Conditions'}
                    text={tourInfoData.info}
                />
                <TournamentPartisipationBlock
                    imageId={tour_prize_found}
                    title={'Prize found'}
                    text={tourInfoData.shortInfo}
                />
            </div>
            {joined ? (
                <div className="tour-info-btn">
                    <div className="tour-info-join" onClick={tournamentPage}>
                        <ButtonTreeD text="ok" />
                    </div>
                </div>
            ) : (
                <div className="tour-info-btn">
                    <div className="tour-info-more" onClick={tourRewards}>
                        <ButtonTreeD text="close" />
                    </div>
                    <div className="tour-info-join" onClick={tournamentJoin}>
                        <ButtonTreeD text="join" />
                    </div>
                </div>
            )}
        </div>
    );
};
export default TournamentPartisipation;
