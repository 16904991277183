import React from 'react';
import './waiting-loader.scss';
import { full_circle } from '../../../../../assets/image.assets';

const WaitingLoader = () => {
    return (
        <img
            src={full_circle}
            alt="waiting_loader"
            className="waiting_loader_img"
        />
    );
};

export default WaitingLoader;
