import React, { createRef, useState } from 'react';
import './second-animate.scss';
import { red, yellow } from '../../../../../assets/image.assets';
import { useIntl } from 'react-intl';

const SecondAnimate = ({ item, setLastBadgeAnimate, setSecondAnimate }) => {
    const [secondAnimateFade, setSecondAnimateFade] = useState(false);
    const intl = useIntl();
    const redChipRef = createRef();
    setTimeout(() => {
        setLastBadgeAnimate(true);
        setSecondAnimate(false);
    }, 2200);

    return (
        <div className="second-animate">
            <div className={'new-badges-section'}>
                <img
                    src={`https://testcdn.blotclub.am/tg/badges/${item.bage}/${
                        item.bage
                    }-${item.nextLevel - 1}.png`}
                    className="badge-img-animating"
                />
                <div className="new-badges-text-animating">
                    <span>
                        {intl.messages.badges.play} {item.nextLevelCount}{' '}
                        {intl.messages.badges.times}
                    </span>
                    <p>{intl.messages.badges.your_rewards}</p>

                    <div className="new-badges-block">
                        {item.nextLevelReward.map((item) => (
                            <div className="new-badges-chip">
                                {item.type === 'red' ? (
                                    <img src={red} alt="#" />
                                ) : (
                                    <img
                                        src={yellow}
                                        alt="#"
                                        ref={redChipRef}
                                        className="red-chip-badges"
                                    />
                                )}

                                <span>{item.chip}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SecondAnimate;
