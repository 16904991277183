import React from 'react';

import './search-text-box.input.scss';

import { remove_icon, search } from '../../../../assets/image.assets';

const SearchTextboxInput = ({
    placeholder,
    value = '',
    _onChange = () => {},
}) => {
    return (
        <div className={'search-textbox-input'}>
            <input
                type={'text'}
                placeholder={placeholder}
                value={value}
                onChange={_onChange}
            />
            <img
                src={value ? remove_icon : search}
                alt="search action"
                className={'action-icon' + (value ? ' cursored' : '')}
                onClick={() => {
                    if (value) {
                        _onChange({ target: { value: '' } });
                    }
                }}
            />
        </div>
    );
};

export default SearchTextboxInput;
