import React from 'react';
import './vip-tab.scss';
import {
    vip_year,
    vip_month,
    vip_week,
    vip_tab,
    gold_vip_bg,
    green_vip_bg,
    red,
} from '../../../assets/image.assets';

const VipTabPopup = ({ data }) => {
    return (
        <div className="vip_tab_popup">
            {data.map((data) => (
                <div className="vip_tab_item" key={data.id}>
                    <div className="vip_tab_bg">
                        <img src={vip_tab} className="vip_tab_chip" />
                        <div className="vip_tab_chip_block">
                            {data.id === 111 && (
                                <div className="vip-content">
                                    <p className="vip_tab_text">1 week</p>
                                    <img src={vip_week} className="vip-week" />
                                </div>
                            )}
                            {data.id === 112 && (
                                <div className="vip-content">
                                    <p className="vip_tab_text">1 month</p>
                                    <img
                                        src={vip_month}
                                        className="vip-month"
                                    />
                                </div>
                            )}
                            {data.id === 113 && (
                                <div className="vip-content">
                                    <p className="vip_tab_text">1 year</p>
                                    <img src={vip_year} className="vip-year" />
                                </div>
                            )}

                            <div className="vip_tab_buy_chip">
                                {data.bonus.map((item) => (
                                    <div
                                        className="vip_tab_buy_chip_block"
                                        key={item.type}
                                    >
                                        {item.type === 'yellow' && (
                                            <img
                                                src={gold_vip_bg}
                                                className="yellow-bg"
                                                alt="#"
                                            />
                                        )}
                                        {item.type === 'green' && (
                                            <img
                                                src={green_vip_bg}
                                                alt="#"
                                                className="green-bg"
                                            />
                                        )}
                                        {item.type === 'red' && (
                                            <div className="red-bg">
                                                <img
                                                    src={red}
                                                    alt="#"
                                                    className="red-img"
                                                />
                                            </div>
                                        )}

                                        <p>+{item.amount}</p>
                                    </div>
                                ))}
                            </div>
                            <div className="vip_tab_item_buy_now">
                                <p>Gnel</p>
                                <span>
                                    {data.currency} {data.amount}
                                </span>
                            </div>
                            <div className="button-layer"></div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default VipTabPopup;
