import React from 'react';
import './button-3d.scss';

const ButtonTreeD = ({ text = '' ,_onClick}) => {
    // const capot = () => {
    //   setBazarValue(25);
    // };
    return (
        <div className="button-tree">
            <button onClick={_onClick}>
                <span>{text}</span>
            </button>
            <div className="button-tree-under"></div>
        </div>
    );
};
export default ButtonTreeD;
