import React, { useEffect, useState } from 'react';
import './new-badges.scss';

import { red, yellow } from '../../../../assets/image.assets';

import ButtonTreeD from '../../../../components/buttons/button_3d/button-3d';
import { useIntl } from 'react-intl';

const NewBadges = ({
    ref,
    badgesRefs = [],
    item,
    animatingChip,
    newBadges,
    animatedBadges,
    redChipRef,
    yellowChipRef,
    newBadgeIndex,
}) => {
    const intl = useIntl();

    const data = newBadges && newBadges[newBadgeIndex];

    useEffect(() => {}, []);
    const [movedPosition, setMovedPosition] = useState(null);
    const [animateBadges, setAnimateBadges] = useState(null);

    // useEffect(() => {
    //     if (badgesRefs.length && animatingChip && item.nextLevel) {
    //         const neededBadgeRef = badgesRefs[item.nextLevel - 1];
    //         if (neededBadgeRef && neededBadgeRef.current) {
    //             setTimeout(() => {
    //                 setMovedPosition({
    //                     marginLeft: neededBadgeRef.current.offsetLeft - 15,
    //                     marginTop: neededBadgeRef.current.offsetTop - 15,
    //                 });
    //                 setAnimateBadges({});
    //             }, 200);
    //         }
    //     }
    // }, [animatingChip]);

    return (
        <div
            className={animatingChip ? 'new-badges-animating' : 'new-badges'}
            ref={ref}
        >
            {/* <button onClick={executeScroll}>sxmi</button> */}
            <div className="new-badges-section">
                <img
                    src={`https://testcdn.blotclub.am/tg/badges/${data.bage}/${
                        data.bage
                    }-${data.level - 1}.png`}
                    className={'badge-img'}
                    style={movedPosition}
                />
                <div
                    className={
                        animatingChip
                            ? 'new-badges-text-animating'
                            : 'new-badges-text'
                    }
                >
                    <span>
                        {intl.messages.badges.play} {data.currentProgress}{' '}
                        {intl.messages.badges.times}
                    </span>
                    <p>{intl.messages.badges.your_rewards}</p>

                    <div className="new-badges-block">
                        {data.reward.map((item) => (
                            <div className="new-badges-chip">
                                {item.type === 'red' ? (
                                    <img src={red} alt="#" ref={redChipRef} />
                                ) : (
                                    <img
                                        src={yellow}
                                        alt="#"
                                        ref={yellowChipRef}
                                        className="red-chip-badges"
                                    />
                                )}

                                <span>{item.chip}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div
                    className={animatingChip ? 'claim-animating' : 'claim'}
                    onClick={animatedBadges}
                >
                    <ButtonTreeD text={intl.messages.badges.claim} />
                </div>
            </div>
        </div>
    );
};

export default NewBadges;
