import React from 'react';
import { useIntl } from 'react-intl';
import { red_tabs, gold, wheel, vip_store } from '../../../assets/image.assets';
import './chip-navbar-tab.scss';

const ChipNavbarTab = ({ currentTab, setCurrentTab }) => {
    const intl = useIntl();

    const data = [
        {
            text: 'red chips',
            imgSrc: red_tabs,
        },
        { text: 'gold', imgSrc: gold },
        { text: 'vip', imgSrc: vip_store },
        { text: 'free', imgSrc: wheel },
    ];
    return (
        <div className={`chip-navbar-tab chip-navbar-tab-${currentTab}`}>
            {data.map((item, i) => (
                <div
                    className={`navbar${currentTab === i ? ' active' : ''}`}
                    onClick={() => setCurrentTab(i)}
                >
                    <img src={item.imgSrc} alt="img" />
                    <p className="store-chip-text">{item.text}</p>
                </div>
            ))}
        </div>
    );
};

export default ChipNavbarTab;
