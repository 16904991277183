import { createSlice } from '@reduxjs/toolkit';

export const rewardSlice = createSlice({
    name: 'rewardData',
    initialState: {
        data: [],
        userPosition: [],
    },
    reducers: {
        setReward: (state, action) => {},
        setUserPositionData: (state, action) => {
            state.userPosition = action.payload;
        },
    },
});
export const { setReward, setUserPositionData } = rewardSlice.actions;

export default rewardSlice.reducer;
