import React, { useState, useEffect } from 'react';
import TournamentBg from '../../components/background/tournament-bg/tournament-bg';
import userSingleton from '../../singletons/user.singleton';
import './tournament.scss';

import {
    tournamentInfo_req,
    tournamentList_req,
} from '../../api/tournament/tournament.api';
import { tournament } from '../../assets/image.assets';
import LobbyName from '../../components/lobby-item/lobby-name/lobby-name';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '../../redux/global.slice';
import routesEnum from '../../enums/routes.enum';
import BackButton from '../../components/buttons/back-button/back-button';
import TournamentJoinTimer from './tournament-join-timer/tournament-join-timer';
import TournamentRegisterTimer from './tournament-join-timer/tournament-register-timer/tournament-register-timer';
import themeEnum from '../../enums/theme.enum';
import { tournamentColor } from '../../redux/theme.change.slice';
import { useIntl } from 'react-intl';
import { userInfo_req } from '../../api/user.api';
import { setUserUpdate } from '../../redux/user.update.slice';
import { setUpdateBoolean } from '../../redux/chip.info.slice';
import {getCookie} from "../../helpers/cookie.helpers";

const Tournament = () => {
    const intl = useIntl();
    const [tourList, setTourList] = useState([]);
    const [userTourData, setUserTourData] = useState({});
    const userId = userSingleton.userId;
    const me = useSelector((state) => state.me.data);
    const dispatch = useDispatch();

    const tournamentList = async () => {
        try {
            let lang=getCookie("locale")
            const tournamentListData = await tournamentList_req(lang);
            setTourList(tournamentListData.data);
        } catch (e) {}
    };

    useEffect(() => {
        tournamentList();
    }, []);
    const userUpdateReq = async () => {
        const userUpdateChip = await userInfo_req(me.userId);
        dispatch(setUpdateBoolean(true));
        dispatch(setUserUpdate(userUpdateChip));
    };

    const goDashboard = async (id, joined) => {
        const tourInfo = await tournamentInfo_req(id);
        userUpdateReq();
        const tourData = tourList.filter((el) => el.id === id);

        if (joined === 1) {
            dispatch(
                navigate({
                    route: routesEnum.TOURNAMENT_DASHBOARD,
                    navigateData: { tourData },
                    joined: true,
                })
            );
        } else {
            userSingleton._currentTabUser = 0;
            dispatch(
                navigate({
                    route: routesEnum.TOURNAMENT_INFO,
                    navigateData: { tourData },
                })
            );
        }
    };
    const backInfo = () => {
        dispatch(navigate(routesEnum.LOBBY));
        dispatch(tournamentColor(themeEnum.LOBBY));
    };

    return (
        <div className="tournament-page">
            <TournamentBg>
                <div className="tournament">
                    <div className="tour-lobby" onClick={backInfo}>
                        <BackButton text={intl.messages.lobby} />
                    </div>
                    <div className="tour-table-game">
                        {tourList &&
                            tourList.map((item) => {
                                console.log(item)
                                // let joined = false;
                                // if (
                                //     userTourData &&
                                //     userTourData.tournaments &&
                                //     userTourData.tournaments.length
                                // ) {
                                //     const userTournamentIndex = userTourData.tournaments.findIndex(
                                //         (userData) => userData._id === item._id
                                //     );

                                //     if (userTournamentIndex !== -1) {
                                //         joined = true;
                                //     }
                                // }
                                // const tournamentName = JSON.parse(item.name);
                                return (<>
                                    <div
                                        className="tour-game"
                                        onClick={() =>
                                            goDashboard(item.id, item.joined)
                                        }
                                    >
                                        {item.joined ? (
                                            <TournamentJoinTimer
                                                endDate={item.endDate}
                                            />
                                        ) : (
                                            <TournamentRegisterTimer
                                                endDate={item.endDate}
                                            />
                                        )}
                                        <img
                                            className="tour-game-img"
                                            src={tournament}
                                            alt="tour-game"
                                        />
                                        <LobbyName text={item.name} type={3} />
                                    </div>


                                    </>
                                );
                            })}
                    </div>
                </div>
            </TournamentBg>
        </div>
    );
};

export default Tournament;
