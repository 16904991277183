import React from 'react';
import BadgeItem from '../badge-item/badge-item';
import LastAnimateBadge from '../last-animate-badge/last-animate-badge';
import BadgesImgBlock from '../new-badges/badges-img-block/badges-img-block';
import NewBadges from '../new-badges/new-badges';
import SecondAnimate from '../new-badges/second-animate/second-animate';
import StandardBadges from '../standard-badges/standard-badges';
import './right-section.scss';

function changeAnimateScreen({
    ref,
    animateScreen,
    badgesRefs,
    item,
    animatingChip,
    standardBadgeAnimate,
    newBadges,
    animatedBadges,
    redChipRef,
    yellowChipRef,
    setSecondAnimate,
    lastBadgeAnimate,
    setLastBadgeAnimate,
    newBadgeIndex,
}) {
    switch (animateScreen) {
        case 0:
            return (
                <StandardBadges
                    badgesRefs={badgesRefs}
                    item={item}
                    animatingChip={animatingChip}
                    standardBadgeAnimate={standardBadgeAnimate}
                />
            );
        case 1:
            return (
                <NewBadges
                    ref={ref}
                    badgesRefs={badgesRefs}
                    item={item}
                    animatingChip={animatingChip}
                    newBadges={newBadges}
                    animatedBadges={animatedBadges}
                    redChipRef={redChipRef}
                    yellowChipRef={yellowChipRef}
                    newBadgeIndex={newBadgeIndex}
                />
            );
        case 2:
            return (
                <SecondAnimate
                    item={item}
                    setLastBadgeAnimate={setLastBadgeAnimate}
                    setSecondAnimate={setSecondAnimate}
                />
            );
        case 3:
            return (
                <BadgesImgBlock
                    item={item}
                    badgesRefs={badgesRefs}
                    setSecondAnimate={setSecondAnimate}
                />
            );
        case 4:
            return (
                <LastAnimateBadge
                    item={item}
                    badgesRefs={badgesRefs}
                    lastBadgeAnimate={lastBadgeAnimate}
                />
            );

        default:
            return (
                <StandardBadges
                    badgesRefs={badgesRefs}
                    item={item}
                    animatingChip={animatingChip}
                    standardBadgeAnimate={standardBadgeAnimate}
                />
            );
    }
}
const RightSection = ({
    ref,
    item,
    toPositionInited,
    newBadges,
    animateScreen,
    animatingChip,
    standardBadgeAnimate,
    animatedBadges,
    redChipRef,
    yellowChipRef,
    setSecondAnimate,
    lastBadgeAnimate,
    setLastBadgeAnimate,
    newBadgeIndex,
}) => {
    const badgesRefs = [];

    return (
        <div className="right-section">
            {changeAnimateScreen({
                ref,
                animateScreen,
                badgesRefs,
                item,
                animatingChip,
                standardBadgeAnimate,
                newBadges,
                animatedBadges,
                redChipRef,
                yellowChipRef,
                setSecondAnimate,
                lastBadgeAnimate,
                setLastBadgeAnimate,
                newBadgeIndex,
            })}
            {/* {animateScreen === 1 && toPositionInited && (
                <BadgeItem item={item} toPositionInited={toPositionInited} />
            )} */}
        </div>
    );
};
export default RightSection;
