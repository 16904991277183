import React, { useState, useEffect, createRef } from 'react';
import { useIntl } from 'react-intl/lib';

import './chat-section.scss';

import socketBridge from '../../../socket';

import { getChatHistory_req } from '../../../api/team.game/team.game.api';
import {
    chat,
    chat_team,
    dead_body,
    user_avatar,
} from '../../../assets/image.assets';
import ChatMessageListItem from '../../../components/team-game/list-items/chat-message/chat-message.listItem';
import messagesEnum from '../../../enums/team-game/messages.enum';
import { useSelector } from 'react-redux';

const scrollToBottom = (messagesRef, count, perTick) => {
    if (messagesRef && messagesRef.current) {
        const { current } = messagesRef;
        const scrollPlace = current.scrollHeight - current.clientHeight;
        const difference = scrollPlace - current.scrollTop;

        if (!perTick) {
            perTick = (difference / 5) * count;
        }

        setTimeout(() => {
            current.scrollTop += perTick;

            if (current.scrollTop >= scrollPlace) {
                return;
            }

            scrollToBottom(messagesRef, count, perTick);
        }, 50);
    }
};

let prevHeight = 0;

const ChatSection = ({ notificationExists, getMemberList }) => {
    const messagesRef = createRef();
    const [newMessage, setNewMessage] = useState('');
    const teamInfo = useSelector((state) => state.teamGame.teamInfo);
    const intl = useIntl();
    const memberList = useSelector((state) => state.teamGame.memberUsers);

    const [messages, setMessages] = useState([]);
    const messageListRef = React.useRef(messages);

    const limit = 10;
    const [skip, setSkip] = useState(0);
    const [allLoaded, setAllLoaded] = useState(false);

    const receivedMessage = (data) => {
        const _messages = [...messageListRef.current];
        _messages.push(data);
        setMessages(_messages);
        messageListRef.current = _messages;
        setNewMessage('');

        if (data && data.messageType === messagesEnum.JOIN) {
            getMemberList();
        }
    };

    const listenEvents = () => {
        if (socketBridge.client && socketBridge.teamGameChannel) {
            socketBridge.teamGameChannel.watch((data) => {
                if (data) {
                    switch (data.event) {
                        case 69:
                        case 68:
                            receivedMessage(data);
                    }
                }
            });
        }
    };

    const getChatHistory = async () => {
        const chatHistory = await getChatHistory_req(limit, skip);
        if (!chatHistory.length) {
            setAllLoaded(true);
        } else {
            let allMessages = [
                ...chatHistory,
                ...messageListRef.current,
            ].filter((message) => message.date);

            allMessages = allMessages.sort((a, b) => {
                const aDate = new Date(a.date);
                const bDate = new Date(b.date);

                return aDate.getTime() - bDate.getTime();
            });

            setMessages(allMessages);

            if (skip === 0) {
                listenEvents();
            }

            // messageListRef.current = allMessages;
            // prevHeight = messagesRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        if (skip === 0) {
            scrollToBottom(messagesRef, messages.length);
        } else {
            messagesRef.current.scrollTop =
                messagesRef.current.scrollHeight - prevHeight;
        }
    }, [messages]);

    useEffect(() => {
        if (allLoaded) {
            return;
        }

        getChatHistory();
    }, [skip]);

    const sendMessage = () => {
        if (!newMessage) {
            return;
        }

        if (!newMessage.trim()) {
            return;
        }

        if (socketBridge.client) {
            socketBridge.client.publishToChannel(
                teamInfo.teamId + '_team_game_server',
                {
                    message: newMessage,
                },
                (err) => {}
            );
        }
    };

    const handleScroll = (e) => {
        let element = e.target;

        if (element.scrollTop === 0 && !allLoaded) {
            setSkip(skip + limit);
        }
    };

    // if (!memberList && !memberList[0]) {
    //     return null;
    // }
    return (
        <div className={'chat-section'}>
            <div
                className="messages-wrapper"
                ref={messagesRef}
                onScroll={handleScroll}
            >
                {messages.map((data, key) => {
                    let member = memberList[data && data.userId];

                    if (!member) {
                        member = { name: data.message, avatar: user_avatar };
                    }

                    return (
                        <ChatMessageListItem
                            key={data.message + member.name + key}
                            messageData={data}
                            member={member}
                        />
                    );
                })}
            </div>
            <div
                className={`write-message-wrapper${
                    notificationExists ? ' notification-exists' : ''
                }`}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        sendMessage();
                    }}
                >
                    <input
                        type="text"
                        className={'message-input'}
                        placeholder={intl.messages.team_game.write_msg}
                        value={newMessage}
                        onChange={(e) => {
                            setNewMessage(e.target.value);
                        }}
                    />
                    <img src={chat_team} alt="chat" onClick={sendMessage} />
                </form>
            </div>
        </div>
    );
};

// const mapStateToProps = (state) => {
//     return {
//         teamInfo: state.teamGame.teamInfo,
//         player: state.teamGame.player,

//         notificationExists: state.teamGame.notificationExists,
//     };
// };

// const mapDispatchToProps = (dispatch) => {
//     return {
//         getMemberList: () => dispatch(teamGameActions.getMemberList()),
//     };
// };

export default ChatSection;
