import React from 'react';
import './tour-partisipation-block.scss';
const TournamentPartisipationBlock = ({ imageId, title, text }) => {
    return (
        <div className="tour-partisipation">
            <div className="tour-partisipation-img">
                <img src={imageId} alt="#" />
            </div>

            <div className="tour-partisipation-text">
                <span>{title}</span>
                <p>{text}</p>
            </div>
        </div>
    );
};
export default TournamentPartisipationBlock;
