import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import './join-team.scss';

import JoinTeamPanel from '../../../components/team-game/panels/join-team-panel/join-team-panel';

import TeamInfoListItem from '../../../components/team-game/list-items/team-info/team-info.listItem';
import TriangleLoader from '../../../components/team-game/loaders/triangle-loader/triangle-loader';
import ErrorBanner from '../../../components/team-game/banners/error-banner/error-banner';

import {
    joinTeam_req,
    acceptInvite_req,
    getTeamsList_req,
    searchTeam_req,
    validateCreateTeam_req,
    teamInfo_req,
} from '../../../api/team.game/team.game.api';
import createTeamEnum from '../../../enums/team-game/createTeam.enum';
import {
    createErrorCodes,
    joinErrorCodes,
} from '../../../enums/team-game/errors.enum';
import TeamNotifications from '../../../global-components/team-notifications/team-notifications';
import { useDispatch, useSelector } from 'react-redux';
import {
    battleChange,
    battleInfoState,
    getCreateTeamConfigs,
    teamInfoState,
    teamStatistics,
} from '../../../redux/team-game/team-game.slice';
import routesEnum from '../../../enums/routes.enum';
import { navigate } from '../../../redux/global.slice';
import PopupsEnum from '../../../enums/popups.enum';
import { openPopups } from '../../../redux/popup.slice';

let searchTimeout = 0;

const joinTeamAction = async (team, teamList, setTeamList, openPopups) => {
    try {
        const joined = await joinTeam_req(team.teamId);
        if (joined && joined.success) {
            const _teamList = [...teamList];
            _teamList.forEach((teamData) => {
                if (teamData.teamId === team.teamId) {
                    teamData.state = 2;
                }
            });
            setTeamList(_teamList);
        }
    } catch (e) {
        if (e && e.response && e.response.data) {
            const { errorCode } = e.response.data;

            if (
                errorCode === joinErrorCodes.MAX_MEMBERS_REACHED ||
                errorCode === joinErrorCodes.CONDITIONS_NOT_MET
            ) {
                openPopups({
                    popupIds: [2],
                    popupData: [e.response.data],
                });
            } else if (errorCode === joinErrorCodes.MAX_REQUEST_LIMIT_REACHED) {
                openPopups({
                    popupIds: [3],
                    popupData: {
                        errorCode,
                    },
                });
            }
        }
    }
};

const acceptTeamAction = async (team, openPopups) => {
    try {
        await acceptInvite_req(team.inviteId, true);
    } catch (e) {
        if (e && e.response && e.response.data) {
            const { errorCode } = e.response.data;

            if (
                errorCode === joinErrorCodes.MAX_MEMBERS_REACHED ||
                errorCode === joinErrorCodes.CONDITIONS_NOT_MET
            ) {
                openPopups({
                    popupIds: [2],
                    popupData: [e.response.data],
                });
            }
        }
    }
};

const JoinTeam = ({ createTeamConfigs }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [canCreateTeam, setCanCreateTeam] = useState(false);
    const [teamList, setTeamList] = useState([]);
    const [teamInfo, setTeamInfo] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [searchedTeams, setSearchedTeams] = useState([]);
    const [searchError, setSearchError] = useState(false);
    const [createErrorCode, setCreateErrorCode] = useState(-1);
    const intl = useIntl();
    const dispatch = useDispatch();
    const data = useSelector((state) => state.teamGame);
    const myUserInfo = useSelector((state) => state.me.data);
    // const createTeamConfigs = useSelector(
    //     (state) => state.teamGame.createTeamConfigs
    // );

    useEffect(() => {
        const getTeamsList = async () => {
            setIsLoading(true);

            const teamListData = await getTeamsList_req();
            setIsLoading(false);
            if (teamListData && teamListData.length) {
                setTeamList(teamListData);
            }
        };

        const getTeamInfo = async () => {
            setIsLoading(true);

            const teamInfoData = await teamInfo_req();
            setIsLoading(false);
            if (teamInfoData) {
                setTeamInfo(teamInfoData);
            }
            dispatch(battleChange(teamInfoData.configs));
            dispatch(teamInfoState(teamInfoData.teamData));
            dispatch(battleInfoState(teamInfoData.battleInfo));
            dispatch(teamStatistics(teamInfoData.teamStats));
            dispatch(getCreateTeamConfigs(teamInfoData.teamCreateConfigs));

            if (teamInfoData && teamInfoData.teamData) {
                dispatch(navigate(routesEnum.TEAM_DASHBOARD));
            }
        };

        const checkForCreate = async () => {
            try {
                const canCreateTeam = await validateCreateTeam_req(
                    createTeamEnum.user
                );

                if (canCreateTeam && canCreateTeam.success) {
                    setCanCreateTeam(true);
                    setCreateErrorCode(-1);
                }
            } catch (e) {
                if (e && e.response && e.response.data) {
                    const { errorCode, response } = e.response.data;

                    if (errorCode === 0 && response) {
                        setCreateErrorCode(response.message);
                    }
                }
            }
        };

        checkForCreate();
        getTeamsList();
        getTeamInfo();
    }, []);

    useEffect(() => {
        if (!searchValue) {
            setSearchError(false);
            setSearchedTeams([]);
            return;
        }

        if (searchValue.length < 3) {
            return;
        }

        const searchTeams = async () => {
            const searchedTeamsData = await searchTeam_req(searchValue);
            if (searchedTeamsData && searchedTeamsData.length) {
                setSearchError(false);
                setSearchedTeams(searchedTeamsData);
            } else {
                setSearchError(true);
            }
        };

        clearTimeout(searchTimeout);

        searchTimeout = setTimeout(() => {
            searchTeams();
        }, 100);
    }, [searchValue]);

    const handleSearchInput = (e) => {
        setSearchValue(e.target.value);
    };

    const _createErrorAction = () => {
        if (createErrorCode !== -1) {
            if (createErrorCode === createErrorCodes.NOT_ENOUGH_RED) {
                openPopups({
                    popupIds: [PopupsEnum.NOT_ENOUGH_GOLD],
                    popupData: [
                        {
                            type: 'red',
                            text: intl.formatMessage({
                                id: 'you_need_red_chips_to_create_team',
                            }),
                            boldText: intl.formatMessage({
                                id: 'buy_now',
                            }),
                        },
                    ],
                });
            }
        }
    };

    if (!teamInfo) {
        return null;
    }
    const enoughLevel =
        (myUserInfo || {}).level >= teamInfo.teamCreateConfigs.minCreateLevel;

    return (
        <div id={'join-team'}>
            <JoinTeamPanel
                searchValue={searchValue}
                handleSearchInput={handleSearchInput}
                canCreateTeam={enoughLevel}
                _createTeam={() => {
                    if (!enoughLevel) {
                        openPopups({
                            popupIds: [PopupsEnum.JOIN_TEAM_POPUP],
                            popupData: [
                                {
                                    requirements: {
                                        level: {
                                            match: false,
                                            conditionValue:
                                                createTeamConfigs.minCreateLevel,
                                        },
                                    },
                                },
                            ],
                        });
                    } else {
                        if (canCreateTeam) {
                            openPopups({
                                popupIds: [PopupsEnum.JOIN_TEAM_POPUP],
                                popupData: [
                                    {
                                        chips: 500,
                                    },
                                ],
                            });
                        } else {
                            _createErrorAction();
                        }
                    }
                }}
            />
            <div className="join-team-content">
                {searchError && (
                    <div className={'error-wrapper'}>
                        <ErrorBanner
                            text={
                                <FormattedMessage
                                    id={'sorry_nothing_found_by_keyword'}
                                    values={{
                                        keyword: searchValue,
                                    }}
                                />
                            }
                        />
                    </div>
                )}
                {isLoading ? (
                    <div className={'loader-wrapper'}>
                        <TriangleLoader />
                    </div>
                ) : (
                    (searchedTeams.length ? searchedTeams : teamList).map(
                        (team) => {
                            return (
                                <TeamInfoListItem
                                    key={team.name + team.teamId}
                                    team={team}
                                    configs={teamInfo && teamInfo.configs}
                                    joinTeamAction={(team) =>
                                        joinTeamAction(
                                            team,
                                            teamList,
                                            setTeamList,
                                            openPopups
                                        )
                                    }
                                    acceptTeamAction={(team) => {
                                        acceptTeamAction(team, openPopups);
                                    }}
                                />
                            );
                        }
                    )
                )}
            </div>
            {/* <TeamNotifications
                position={'left'}
                player={player}
                getTeamInfo={getTeamInfo}
                fromRoute={'/join-team'}
            /> */}
        </div>
    );
};

export default JoinTeam;
