import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
    green_chips_game_end,
    game_end_red_chip,
    xp_icon,
} from '../../../../assets/image.assets';
import ButtonTreeD from '../../../../components/buttons/button_3d/button-3d';
import GameChipInfo from '../../../../components/info/game-chip-info/game-chip-info';
import PrimaryRibbon from '../../../../components/ribbons/primary.ribbon';

import routesEnum from '../../../../enums/routes.enum';
import { navigate } from '../../../../redux/global.slice';
import BestScore from './best-score/best-score';
import socketBridge from '../../../../socket';
import './game-end.scss';
import { UserChannelEmittingEvents } from '../../../../socket/socket.events';
import { welcomeUserRefresh } from '../../../../redux/welcome.user.cb.slice';
import roomSingleton from '../../../../singletons/room.singleton';
import { setUsersInfo } from '../../../../redux/user.info.game.slice';
import { setChip } from '../../../../redux/chip.info.slice';

function getContractStatusForTeam(intl, data, team) {
    const normalized = {
        text: '',
        type: 1,
    };
    if (team === 0) {
        if (data.winnerTeam === 0) {
            normalized.text = intl.messages.we_won;
            normalized.type = 3;
        } else {
            normalized.text = intl.messages.we_lost;
            normalized.type = 1;
        }
    } else {
        if (data.winnerTeam === 1) {
            normalized.text = intl.messages.we_won;
            normalized.type = 3;
        } else {
            normalized.text = intl.messages.we_lost;
            normalized.type = 1;
        }
    }
    return normalized;
}

const GameEnd = ({ data, replayGame, replayData, leaveRoom, leaveGame }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const me = useSelector((state) => state.me.data);
    const usersInfo = useSelector((state) => state.usersInfo.data);
    const mySeat = usersInfo.findIndex((data) => data.userId === me.userId);
    const team = mySeat % 2;
    const usersInfoData = data.usersInfo.usersInfo[me.userId];

    if (usersInfoData) {
        dispatch(
            setChip([
                {
                    chip: 'red',
                    value: usersInfoData.chipsInfo.red,
                },
                {
                    chip: 'green',
                    value: usersInfoData.chipsInfo.green,
                },
                {
                    chip: 'yellow',
                    value: usersInfoData.chipsInfo.yellow,
                },
            ])
        );
    }
    const contractStatusForTeam = getContractStatusForTeam(intl, data, team);
    useEffect(() => {
        leaveRoom(replayData);
    }, [replayData]);

    return (
        <div className="game-end">
            <div className="game-end-ribbon">
                <PrimaryRibbon
                    text={contractStatusForTeam.text}
                    type={contractStatusForTeam.type}
                />
            </div>
            <div className="best-score-block">
                <BestScore bestScore={usersInfoData.bestScore} />
            </div>
            {/* <div className="star-block">
                <Star />
                <Star type="active" />
                <Star type="active" />
            </div> */}

            <div className="final-score">
                {data.forceEnd ? (
                    <div className="game-end-force-end">
                        {intl.messages.force_end}
                    </div>
                ) : (
                    <div>
                        <span className="final-score-text">FINAL SCORE</span>
                        <div className="score">
                            <span className="score-me">{data.score[0]}</span>
                            <div className="score-square">
                                <span></span>
                                <span></span>
                            </div>
                            <span className="score-us">{data.score[1]}</span>
                        </div>
                    </div>
                )}
            </div>

            <span className="game-end-rewards">Your Rewards</span>
            <div className="game-end-chip-block">
                <GameChipInfo
                    imageId={green_chips_game_end}
                    count={usersInfoData.green - me.green}
                    text={'GREEN CHIPS'}
                />
                {usersInfoData.red - me.red > 0 && (
                    <GameChipInfo
                        imageId={game_end_red_chip}
                        count={usersInfoData.red - me.red}
                        text={'RED CHIPS'}
                    />
                )}

                <GameChipInfo
                    imageId={xp_icon}
                    count={usersInfoData.gainedPoints}
                    text={'EXPERIENCE'}
                />
                {/* <GameChipInfo
                    imageId={'tour_points_icon'}
                    count={'268'}
                    text={'POINTS'}
                /> */}
            </div>
            <div className="game-end-button" onClick={replayGame}>
                <ButtonTreeD text={'Replay'} />
            </div>
            <span className="leave-game" onClick={leaveGame}>
                leave game
            </span>
        </div>
    );
};

export default GameEnd;
