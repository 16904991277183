import React from 'react';
import StandardLabel from '../../labels/standard-label/standard-label';

import './notifications-circle.info.scss';

const NotificationsCircle = ({
    position = 'left',
    count,
    _onClick = () => {},
}) => {
    return (
        <div className={'notifications-circle ' + position} onClick={_onClick}>
            <StandardLabel text={count} />
        </div>
    );
};

export default NotificationsCircle;
