import React from 'react';

import './error-banner.scss';

const ErrorBanner = ({ text }) => {
    return (
        <div className={'error-banner'}>
            <span className={'error-text'}>{text}</span>
        </div>
    );
};

export default ErrorBanner;
