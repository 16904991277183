import React from 'react';
import './tour-locked-line.scss';

const TourLocked = ({ imageId, text }) => {
    return (
        <div className="tour-locked">
            <img src={imageId} alt="#" />
            <span>{text}</span>
        </div>
    );
};

export default TourLocked;
