import React from 'react';
import './first-start.scss';

const FirstStart = ({ seat = '1' }) => {
  return (
    <div className={`first_start_${seat}`}>
      <span>1st</span>
    </div>
  );
};

export default FirstStart;
