import React from 'react';
import { useIntl } from 'react-intl/lib';
import { useSelector } from 'react-redux';
import LevelBar from '../../../../components/bars/level-bar/level.bar';
import TournamentGame from '../tournament-game/tournament-game';
import './dashboard-games.scss';

const DashboardGames = ({ tourData }) => {
    const intl = useIntl();
    const userMeTourData = useSelector(
        (state) => state.userMeTour.userMeTournament
    );

    const tourGamesData = userMeTourData[0];

    return (
        <div className="dashboard-games">
            <div className="games-count">
                <span>{intl.messages.tournament.games}</span>
                <div className="games-count-right">
                    <span>
                        {tourGamesData && tourGamesData.playedGames} /{' '}
                        {tourData.maxGames}
                    </span>
                    {/* <div className="games-count-progress">
                        <div className="games-count-progress-animation"></div>
                    </div> */}

                    <LevelBar
                        percent={
                            (tourGamesData
                                ? tourGamesData.playedGames * 100
                                : 0) / tourData.maxGames
                        }
                    />
                </div>
            </div>
            <div className="tour-game-count-block">
                <TournamentGame data={tourData} />
            </div>
        </div>
    );
};

export default DashboardGames;
