import React from 'react';
import imageLoader from '../../../loaders/image.loader';
import './close-button.scss';

const CloseButton = ({ _onClose = () => {}, imageId }) => {
    return (
        <div className="close_button" onClick={_onClose}>
            {imageId && <img src={imageLoader.getImage(imageId)} />}
        </div>
    );
};

export default CloseButton;
