import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import routesEnum from '../../../../enums/routes.enum';
import { navigate } from '../../../../redux/global.slice';

import userSingleton from '../../../../singletons/user.singleton';

import './dashboard-header.scss';

const DashboardHeader = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const me = useSelector((state) => state.me.data);
    const tourData = useSelector((state) => state.global.navigateData);
    const data = useSelector((state) => state.userMeTour.userMeTournament);
    const languageData = useSelector((state) => state.global.locale);
    const langData = Number(languageData);
    if (!data) {
        return null;
    }

    const goTourRewards = () => {
        // dispatch(
        //     openPopups({
        //         popupIds: [PopupsEnum.TOURNAMENT_FINISHED],
        //     })
        // );
        userSingleton._currentTabUser = 1;
        dispatch(
            navigate({
                route: routesEnum.TOURNAMENT_INFO,
                navigateData: tourData,
                joined: true,
            })
        );
    };

    return (
        <div className="dashboard-header">
            <div className="tour-avatar-img-block">
                <div className="tour-avatar">
                    <img
                        src={me.avatar}
                        alt="#"
                        onError={(ev) => {
                            ev.target.onerror = null;
                            ev.target.src =
                                'https://cdn.blotclub.am/avatars/enky1.png';
                        }}
                    />
                    <div className="tour-level">
                        <span>{me.level}</span>
                    </div>
                </div>
                <div className="tour-my-score">
                    <span>{intl.messages.tournament.totalScore}</span>
                    <p>{data && data[0] && data[0].score}</p>
                </div>
            </div>

            <div className="tour-dashboard-position">
                <div
                    className={
                        langData === 3 ? 'my-position-lang' : 'my-position'
                    }
                >
                    <span>{intl.messages.tournament.myPosition}</span>
                    <p>{data && data[0] && data[0].position}</p>
                </div>
                <div className="tour-dashboard-reward" onClick={goTourRewards}>
                    {/* <div className="tour-reward-img">
                        <div
                            className={`tour-bg-img ${
                                tourData.tourData[0].rewards.length >=
                                data.position
                                    ? 'tour-animate-rewards'
                                    : ''
                            }`}
                        ></div>
                        <img
                            src={red_chips_waiting}
                            alt="#"
                            className={` ${
                                tourData.tourData[0].rewards.length >=
                                data.position
                                    ? 'tour-img-rewards-animate'
                                    : 'tour-img-rewards'
                            }`}
                        />
                    </div> */}
                    <div className="tour-my-reward">
                        <span>{intl.messages.tournament.rewardList}</span>
                        <p>{intl.messages.tournament.listReward}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardHeader;
