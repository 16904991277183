import React, { PureComponent, createRef } from 'react';
import { connect } from 'react-redux';

import './game.play.scss';
import UserMenu from '../../components/user/user-menu/user-menu';
import UserImageInfo from '../../components/user/user-image-info/user-image-info';
import LastHand from './last-hand/last-hand';
import ScoreInfo from './score-info/score-info';

import PixiCards, { pixiCardsEvents } from '../../pixi/pixiCards';

import socketBridge from '../../socket';
import {
    RoomChannelEmittingEvents,
    RoomChannelHandlingEvents,
    UserChannelEmittingEvents,
    UserChannelHandlingEvents,
} from '../../socket/socket.events';
import {micro, user} from '../../assets/image.assets';
import BazarWindow from './game-play-popups/bazar-window/bazar-window';
import WaitingPlayers from './game-play-popups/waiting-players/waiting-players';
import RoundScore from './game-play-popups/round-score/round-score';
import GameEnd from './game-play-popups/game-end/game-end';
import { seatOffset } from '../../helpers/user.helper';
import roomSingleton from '../../singletons/room.singleton';
import { getAllGifts_req } from '../../api/all-gifts';
import { setAllGifts } from '../../redux/gifts.slice';
import { setUsersInfo } from '../../redux/user.info.game.slice';
import SendGift from './gifts/send-gift/send-gift';
import StaticGift from './gifts/static-gift/static-gift';
import routesEnum from '../../enums/routes.enum';
import { welcomeUserRefresh } from '../../redux/welcome.user.cb.slice';
import { navigate } from '../../redux/global.slice';
import { setChip } from '../../redux/chip.info.slice';
import userSingleton from '../../singletons/user.singleton';

class GamePlay extends PureComponent {
    constructor(props) {
        super(props);
        const { refreshData } = this.props;
        console.log('reFreshData',refreshData)


        this.giftsRefresh =
            refreshData &&
            refreshData.usersInfo &&
            refreshData.usersInfo.map((item) => item.gifts);

        this.usersCount = 4;
        this.cardsCount = 32;
        this.mySeat = 0;
        this.initialState = {
            currentBazarUsers: refreshData ? refreshData.nextUser : [],
            currentPlayUser: null,
            bazarFinish: refreshData ? refreshData.bazarInfo : null,
            fourPass: false,
            // cards: [],
            contractSuit: refreshData ? refreshData.bazarInfo.cardType : -1,
            contractValue: refreshData ? refreshData.bazarInfo.talkNumber : -1,
            bazarFinishUserId: refreshData ? refreshData.bazarInfo.user : 0,
            bazar: refreshData && refreshData.cardType,
            talk: refreshData
                ? refreshData.bazarInfo.talkNumber === -1
                    ? 8
                    : refreshData.bazarInfo.talkNumber
                : 8,
            bazarValue: refreshData
                ? refreshData.bazarInfo.talkNumber === -1
                    ? 8
                    : refreshData.bazarInfo.talkNumber
                : 8,
            waiting: !refreshData,

            roundScoreData: {},
            userSeats: refreshData ? refreshData.users : [],
            usersInfo: refreshData ? refreshData.usersInfo : [],
            connectedUser: refreshData ? refreshData.connectedUser : [],
            team: 0,
            handScoreData: {},
            lastHandCards: refreshData ? refreshData.lastHand.cards : [],
            popupId: -1,
            userSaySuits: [],
            bazarState: refreshData ? refreshData.bazarState : -1,
            score: refreshData
                ? [refreshData.team0.score, refreshData.team1.score]
                : [0, 0],
            roundScore: refreshData
                ? [refreshData.team0.globalScore, refreshData.team1.globalScore]
                : [0, 0],
            takeGift: [],
            sendGift: [],
            sendingGifts: [],
            nextUsers: refreshData ? refreshData.nextUser : [],
            announcement: [],
            showAnnouncement: [],
            allowPlay: false,
            cards: refreshData ? refreshData.validCards : [],
            showUserAnnouncement: [],
            declareAnnouncementWords: [],
            validCards: [],
            counter: 0,
            declareAnnouncementUserId: -1,
            bazarSuit: -1,
            anounnceUserId: [],
            roundData: false,
            startFrom: [],
            startFromUser:refreshData? refreshData.lastHand.startFrom:[],
            winnerUser:refreshData? refreshData.lastHand.winnerUser:[],
            roomType: refreshData ? refreshData.roomType : '',
            partnerId: 0,
            contraUserId: refreshData
                ? refreshData.bazarInfo.isContra.user
                : '',
            recontraUserId: refreshData
                ? refreshData.bazarInfo.isRecontra.user
                : '',
            talkUserId: '',
            contraTooltipUserId: '',
            firstPlayContraUserId: '',
            isCapout: refreshData ? refreshData.bazarInfo.isCapout : false,
            timeout: '',
            timeoutUserIds: refreshData ? refreshData.nextUser : [],
            isContra: refreshData ? refreshData.bazarInfo.isContra : false,
            isRecontra: refreshData ? refreshData.bazarInfo.isRecontra : false,
            allowContra: {},
            firstPlay: null,
            gameEndRound: false,
            leaveUsers: [],
            takeChat: null,
            isBlot: '',
            roundGameEnded: false,
            staticGifts:
                refreshData && refreshData.usersInfo
                    ? refreshData.usersInfo.map((item) => item.gifts)
                    : [null, null, null, null],
            replayData: false,
        };

        this.state = JSON.parse(JSON.stringify(this.initialState)); // {...this.initialState};

        this.userSeatOffsets = {};

        this.gamePlayRef = createRef();

        this.watchUserChannelEventsBinding = this.watchUserChannelEvents.bind(
            this
        );
        this.watchRoomChannelEventsBinding = this.watchRoomChannelEvents.bind(
            this
        );
    }
    resetGame = (keepState) => {
        let _state = JSON.parse(JSON.stringify(this.initialState));
        _state.waiting = false;
        _state = { ..._state, ...keepState };
        // this.gamePlayRef.current.innerHTML = '';
        this.pixiCards.clearDeck();

        this.setState(_state);
    };
    async getAllGifts() {
        try {
            const allGiftsData = await getAllGifts_req();
            this.props.setAllGifts(allGiftsData);
        } catch (e) {}
    }
    componentDidMount() {
        this.getAllGifts();
        this.createGameScene();

        const { refreshData } = this.props;
        if (refreshData) {
            this.pixiCards.resetGame(
                refreshData.cards,
                refreshData.cardsLength,
                refreshData.currentHand
            );
            this.findMySeat(refreshData.usersInfo, refreshData.users);
            refreshData.usersInfo.forEach((userInfo, index) => {
                this.userSeatOffsets[userInfo.userId] = seatOffset(
                    index,
                    this.mySeat
                );
            });

            socketBridge.watchRoomChannel(this.watchRoomChannelEventsBinding);
        }
        // this.testCards();
        socketBridge.watchUserChannel(this.watchUserChannelEventsBinding);
    }

    componentWillUnmount() {
        socketBridge.unwatchUserChannel(this.watchUserChannelEventsBinding);
        socketBridge.unwatchRoomChannel(this.watchRoomChannelEventsBinding);
    }

    findMySeat(usersInfo, userSeats) {
        const { me } = this.props;
        this.mySeat = usersInfo.findIndex((data) => data.userId === me.userId);
        const partners = [2, 3, 0, 1];

        this.setState({
            usersInfo,
            userSeats,
            partnerId: usersInfo[partners[this.mySeat]].userId,
            team: this.mySeat % 2,
        });
    }

    createGameScene() {
        if (this.gamePlayRef.current) {
            this.pixiCards = new PixiCards(this.gamePlayRef);

            this.watchPixiCardsEvents();
        }
    }

    watchPixiCardsEvents() {
        this.pixiCards.emitter.on(pixiCardsEvents.PLAYED_CARD, (data) => {
            socketBridge.emitToRoomChannel({
                event: RoomChannelEmittingEvents.PLAY,
                card: data.card.cardId,
                state: roomSingleton.playState || 0,
                roomId: roomSingleton.roomId,
            });
            this.pixiCards.deactivateUser(0);
        });
    }

    watchUserChannelEvents({ event, ...args }) {
        switch (event) {
            case UserChannelHandlingEvents.JOIN_ROOM:
                this.joinRoomEvent(args);
                this.setState({
                    waiting: false,
                });
                break;
            case UserChannelHandlingEvents.PLAYERS_INFO:
                this.findMySeat(args.data.usersInfo, args.data.users);
                this.props.setUsersInfo(args.data.usersInfo);
                args.data.usersInfo.forEach((userInfo, index) => {
                    this.userSeatOffsets[userInfo.userId] = seatOffset(
                        index,
                        this.mySeat
                    );
                });

                break;
            case UserChannelHandlingEvents.GET_CARDS:

                this.roomJoinBet();
                this.pixiCards.createDeck(4, 8);
                this.dealCards(args);
                this.setState({
                    cards: args.cards,
                });

                break;
            case UserChannelHandlingEvents.VALID_CARDS:
                this.setState({
                    announcement: args.announcements,
                });

                setTimeout(() => {
                    this.pixiCards.activateUser(0, args.cards);
                }, 0);

                if (args.announcements) {
                    this.pixiCards.announceCards(args.announcements);
                }
                break;
            case UserChannelHandlingEvents.ALLOW_PLAY:
                this.setState({
                    waiting: true,
                    replayData: false,
                });

                break;
            case UserChannelHandlingEvents.WELCOME_USER_CB:
                this.setState({
                    basarState: args.bazarState,
                });
                break;
            default:
                break;
        }
    }

    async joinRoomEvent(args) {
        socketBridge.setRoomChannelNames(args.roomId);
        roomSingleton.roomId = args.roomId;
        await socketBridge.subscribeToRoomChannel();
        socketBridge.watchRoomChannel(this.watchRoomChannelEventsBinding);
    }

    watchRoomChannelEvents({ event, ...args }) {
        const { me } = this.props;

        switch (event) {
            case RoomChannelHandlingEvents.UPDATE_GLOBAL_SCORE: {
                const _state = {
                    roundScoreData: args,
                    roundData: true,
                    roundGameEnded: false,
                    bazar: false,
                    timeout: '',
                    roundScore: args.score,
                };

                if (_state.roundData) {
                    _state.score = [0, 0];
                    _state.bazarFinish = null;
                    _state.bazarValue = 8;
                    _state.lastHandCards = [];
                    _state.contractSuit = -1;
                    _state.contractValue = -1;
                    _state.isContra = false;
                    _state.isCapout = false;
                    _state.isRecontra = false;
                    _state.bazarFinishUserId = 0;
                    _state.firstPlay = null;
                    _state.firstPlayContraUserId = '';
                    _state.userSaySuits = [];
                }
                if (args.forceEnd) {
                    _state.roundGameEnded = true;
                }

                if (args.gameEnded) {
                    this.resetGame(_state);

                    this.setState({
                        replayData: true,
                    });
                } else {
                    this.pixiCards.removeAllPlayedCards(8);
                    this.setState(_state);
                }

                break;
            }
            case RoomChannelHandlingEvents.TAKE_GIFT:
                const _sendingGifts = [...this.state.sendingGifts];
                _sendingGifts.push({
                    giftId: args.giftId,
                    senderSeat: this.userSeatOffsets[args.userId],
                    receiverSeat: this.userSeatOffsets[args.receiverId],
                });
                setTimeout(() => {
                    const _staticGifts = [...this.state.staticGifts];
                    _staticGifts[this.userSeatOffsets[args.receiverId]] =
                        args.giftId;
                    this.setState({
                        staticGifts: _staticGifts,
                    });
                }, 1000);

                this.setState({
                    sendingGifts: _sendingGifts,
                    takeGift: args,
                });

                break;
            case RoomChannelHandlingEvents.PLAYED_CARD: {
                roomSingleton.playState = args.playState;
                clearTimeout(this.announcementTimeout);
                const _state = {
                    handScoreData: args,
                    showUserAnnouncement: args.showAnnouncements,
                    score: args.score || this.state.score,
                    declareAnnouncementWords: args.declareAnnouncementWords,
                    declareAnnouncementUserId: args.userId,
                    timeoutUserIds: args.users,
                    counter: this.state.counter + 1,
                    timeout: args.timeout,
                    isBlot: args.isBlot,
                    anounnceUserId: args.users[0],
                    announcement: [],
                };
                if (args.hand && args.hand.cards) {
                    _state.lastHandCards = args.hand.cards;
                    console.log('args',args)
                    _state.startFromUser=args.hand.startFrom
                    _state.winnerUser=args.hand.winnerUser

                }

                // if (args.score) {
                //     _state.score = args.score;
                // }

                this.pixiCards.playCard(
                    this.userSeatOffsets[args.userId],
                    args.card,
                    args.hand && args.hand.winnerUser
                        ? args.handNumber - 1
                        : args.handNumber
                );

                this.pixiCards.deactivateUser(0);

                if (args.hand && args.hand.winnerUser) {
                    setTimeout(() => {
                        this.pixiCards.moveToBita(
                            this.userSeatOffsets[args.hand.winnerUser],
                            args.handNumber - 1
                        );
                    }, 500);
                }

                this.announcementTimeout = setTimeout(() => {
                    this.setState({
                        declareAnnouncementWords: [],

                        showAnnouncement: [],
                    });
                }, 2000);

                this.setState(_state);
                break;
            }
            case RoomChannelHandlingEvents.NEXT: {
                const _state = {
                    currentBazarUsers: [args.userId],
                    startFrom: [args.startFrom],
                    bazar: false,
                    timeout: args.timeout,
                    counter: this.state.counter + 1,
                    timeoutUserIds: [args.userId],
                };

                if (args.userId === me.userId) {
                    _state.bazar = true;
                }
                this.setState(_state);
                break;
            }
            case RoomChannelHandlingEvents.TALK:
                const _userSaySuits = [...this.state.userSaySuits];
                const userSeat = this.userSeatOffsets[args.userId];
                _userSaySuits[userSeat] = {
                    value: args.value,
                    suit: args.suit,
                    pass: args.pass || undefined,
                    isCapout: args.isCapout,
                };
                const _state = {
                    userSaySuits: _userSaySuits,
                };

                roomSingleton.bazarState = args.state;
                _state.contractValue =
                    args && args.bazarFinish && args.bazarFinish.contractValue;
                _state.contractSuit =
                    args && args.bazarFinish && args.bazarFinish.contractSuit;
                _state.bazarFinishUserId =
                    args && args.bazarFinish && args.bazarFinish.userId;
                _state.bazar = true;
                _state.allowContra = args.allowContra;
                _state.timeout = args.timeout;
                _state.counter = this.state.counter + 1;
                _state.bazarFinish = args.bazarFinish;
                _state.fourPass = args?.bazarFinish?.fourPass;
                if (args && args.bazarFinish && args.bazarFinish.fourPass) {
                    this.pixiCards.resetCards(
                        this.state.cards,
                        this.state.cards.length
                    );
                }
                if (
                    args.suit > -1 &&
                    args.suit !== null &&
                    args.userId === me.userId
                ) {
                    _state.bazarSuit = args.suit;
                    _state.talkUserId = args.userId;
                }

                _state.contraUserId =
                    args.bazarFinish &&
                    args.bazarFinish.isContra &&
                    args.bazarFinish.isContra.userId;
                _state.recontraUserId =
                    args.bazarFinish &&
                    args.bazarFinish.isRecontra &&
                    args.bazarFinish.isRecontra.userId;
                _state.contraTooltipUserId = args.userId;
                if (args.bazarFinish) {
                    _state.userSaySuits = [];
                    this.sayIsContraFirst();
                    _state.bazarSuit = -1;
                }
                if (args.value) {
                    _state.talk = args.value || null;
                    _state.bazarValue = _state.talk + 1;
                }
                if (args.nextUsers && args.nextUsers.length) {
                    _state.currentBazarUsers = args.nextUsers;
                    _state.timeoutUserIds = args.nextUsers;
                }
                if (args.isCapout) {
                    this.sayCapout();
                }
                if (args && args.isContra) {
                    this.sayIsContra();
                }
                if (args && args.isRecontra) {
                    this.sayIsRecontra();
                }
                if (args.bazarFinish) {
                    _state.currentPlayUser = {
                        userId: args.nextUsers[0],
                        timeout: args.timeout,
                    };
                }
                this.setState(_state);
                break;
            case RoomChannelHandlingEvents.FIRST_PLAY: {
                this.setState({
                    firstPlay: args,
                    contractValue: args.value,
                    contractSuit: args.suit,
                    bazarFinishUserId: args.userId,
                    firstPlayContraUserId: args.isContra.userId,
                });
                this.sayIsContraFirst();
                break;
            }
            case RoomChannelHandlingEvents.ANNOUNCEMENT: {
                roomSingleton.announceState = args.state;
                break;
            }
            case RoomChannelHandlingEvents.ANNOUNCEMENT_PUBLIC:
                this.setState({
                    showAnnouncement: args,
                });
                break;
            case RoomChannelHandlingEvents.LEAVE_ROOM:
                const _leaveUsers = [...this.state.leaveUsers];
                if (_leaveUsers.indexOf(args.userId) === -1) {
                    _leaveUsers.push(args.userId);
                    this.setState({
                        leaveUsers: _leaveUsers,
                    });
                }
                break;
            case RoomChannelHandlingEvents.SEND_GIFT: {
                this.setState({
                    sendGift: args,
                });
                break;
            }
            case RoomChannelHandlingEvents.TAKE_EMOJI:
                {
                    this.setState({
                        takeChat: args,
                    });
                    setTimeout(() => {
                        this.closeTakeChat();
                    }, 3000);
                }
                break;

            default:
                break;
        }
    }

    dealCards(args) {
        // this.pixiCards.createDeck();

        setTimeout(async () => {
            await this.pixiCards.dealCards([0, 1, 2, 3], 3);
            setTimeout(() => {
                this.pixiCards.flipDealtCards(
                    0,
                    args.cards,
                    args.firstCardsIndexes
                );
            }, 2000);

            this.pixiCards.emitter.on(pixiCardsEvents.PLAYED_CARD, (data) => {
                this.pixiCards.playCard(data.user, data.card.cardId);
            });
        }, 1000);
    }

    setBazar = () => {
        this.setState({
            bazar: false,
        });
    };
    roomTypeFn(roomType) {
        switch (roomType) {
            case 'micro':
                return -300;
            case 'big':
                return -430;
            case 'professional':
                return -1200;
            case 'bonus':
                return -200;
            default:
                return;
        }
    }
    roomJoinBet = () => {
        this.props.setChip([
            {
                chip: roomSingleton.roomType === 'bonus' ? 'green' : 'red',
                value: this.roomTypeFn(roomSingleton.roomType),
            },
        ]);
    };
    sayCapout = () => {
        this.setState({
            isCapout: true,
        });
    };
    sayIsContra = () => {
        this.setState({
            isContra: true,
        });
    };
    sayIsContraFirst = () => {
        this.setState({
            isContra: false,
        });
    };
    sayIsRecontraFirst = () => {
        this.setState({
            isRecontra: false,
        });
    };
    sayIsRecontra = () => {
        this.setState({
            isRecontra: true,
        });
    };
    closeRoundScore = () => {
        this.setState({
            roundData: false,
        });
    };
    closeRoundGameEnd = () => {
        this.setState({
            roundGameEnded: true,
        });
    };

    closeTakeChat = () => {
        this.setState({
            takeChat: null,
        });
    };
    leaveRoom = (dataReplay) => {
        if (dataReplay) {
            this.leaveTimeout = setTimeout(() => {
                this.props.navigate(routesEnum.LOBBY);
                roomSingleton.reset();
                this.props.welcomeUserRefresh({ data: null });
                socketBridge.unwatchRoomChannel();
                roomSingleton.bazarState = 0;
                roomSingleton.playState = 0;
            }, 7000);
        }
    };

    replayGame = () => {
        const { partnerIdData } = this.props;
        clearTimeout(this.leaveTimeout);
        socketBridge.emitToUserChannel({
            event: UserChannelEmittingEvents.REPLAY,
            roomType: roomSingleton.roomType,
            partnerId: partnerIdData,
        });
        this.props.setUsersInfo({ data: [] });
        this.replayGameRound();
    };
    leaveGame = () => {
        this.props.navigate(routesEnum.LOBBY);
        clearTimeout(this.leaveTimeout);
        roomSingleton.reset();
        this.props.welcomeUserRefresh({ data: null });
        this.props.setUsersInfo({ data: [] });
        socketBridge.unwatchRoomChannel();
        roomSingleton.bazarState = 0;
        roomSingleton.playState = 0;
    };
    testCards() {
        this.pixiCards.createDeck(4, 8);

        setTimeout(async () => {
            await this.pixiCards.dealCards([0, 1, 2, 3], 8, 3);

            await this.pixiCards.activateUser(0);

            await this.pixiCards.flipDealtCards(0);
        }, 1000);
    }
    removeGift = (index) => {
        this.setState({
            sendingGifts: this.state.sendingGifts.splice(index, 1),
        });
    };
    replayGameRound = () => {
        this.setState({
            roundGameEnded: false,
        });
    };

    render() {
        const { me, refreshData } = this.props;
        const {
            userSeats,
            usersInfo,
            talk,
            bazarValue,
            bazar,
            bazarFinish,
            userSaySuits,
            roundScoreData,
            bazarState,
            lastHandCards,
            handScoreData,
            team,
            announcement,
            showAnnouncement,
            showUserAnnouncement,
            declareAnnouncementWords,
            currentBazarUsers,
            startFrom,
            roomType,
            declareAnnouncementUserId,
            isCapout,
            talkUserId,
            waiting,
            contractSuit,
            bazarSuit,
            roundScore,
            contractValue,
            roundData,
            counter,
            isContra,
            isRecontra,
            firstPlay,
            takeChat,
            allowContra,
            timeout,
            timeoutUserIds,
            sendingGifts,
            staticGifts,
            contraUserId,
            recontraUserId,
            contraTooltipUserId,
            firstPlayContraUserId,
            score,
            connectedUser,
            leaveUsers,
            partnerId,
            isBlot,
            anounnceUserId,
            roundGameEnded,
            bazarFinishUserId,
            replayData,
            startFromUser,
            winnerUser
        } = this.state;

        return (
            <>
                <div id="game-play">
                    <div className="game_info_bg">
                        <div className="game-bg-img">
                            <img src={micro} alt="#" />
                        </div>
                        {lastHandCards && lastHandCards.length > 0 ? (
                            <LastHand
                                startFrom={startFromUser}
                                lastHand={lastHandCards}
                                mySeat={this.mySeat}
                                winnerUser={winnerUser}
                                usersInfo={usersInfo}
                            />
                        ) : null}

                        <ScoreInfo
                            score={roundScore}
                            roomType={roomType}
                            team={team}
                        />

                        <UserMenu
                            bazarFinish={bazarFinish}
                            firstPlay={firstPlay}
                        />
                        <div className="game_user">
                            {userSeats.length && usersInfo.length
                                ? userSeats.map((user, index) => {
                                      const seat = seatOffset(
                                          index,
                                          this.mySeat
                                      );
                                      return (
                                          <UserImageInfo
                                              key={user}
                                              seat={seat + 1}
                                              userInfo={usersInfo[index]}
                                              connectedUser={connectedUser}
                                              handScoreData={handScoreData}
                                              roundScore={
                                                  roundScoreData.roundScore
                                              }
                                              userSaySuit={userSaySuits[seat]}
                                              bazarFinish={bazarFinish}
                                              announcement={announcement}
                                              playIndex={
                                                  roomSingleton.playState
                                              }
                                              stateIndex={
                                                  roomSingleton.bazarState
                                              }
                                              score={score}
                                              showAnnouncement={
                                                  showAnnouncement
                                              }
                                              showUserAnnouncement={
                                                  showUserAnnouncement
                                              }
                                              isBlot={isBlot}
                                              playedCardUserId={
                                                  handScoreData.userId
                                              }
                                              startFrom={startFrom}
                                              isContra={isContra}
                                              isRecontra={isRecontra}
                                              firstPlayContraUserId={
                                                  firstPlayContraUserId
                                              }
                                              firstPlay={firstPlay}
                                              isBot={leaveUsers.includes(
                                                  usersInfo[index].userId
                                              )}
                                              allowContra={
                                                  seat === 0 &&
                                                  allowContra[
                                                      usersInfo[index].userId
                                                  ]
                                              }
                                              partnerId={partnerId}
                                              team={team}
                                              timeout={timeout}
                                              timeoutUserIds={timeoutUserIds}
                                              counter={counter}
                                              isCapout={isCapout}
                                              contraUserId={contraUserId}
                                              recontraUserId={recontraUserId}
                                              currentBazarUsers={
                                                  currentBazarUsers
                                              }
                                              declareAnnouncementWords={
                                                  declareAnnouncementWords
                                              }
                                              declareAnnouncementUserId={
                                                  declareAnnouncementUserId
                                              }
                                              contraTooltipUserId={
                                                  contraTooltipUserId
                                              }
                                              takeChat={takeChat}
                                              anounnceUserId={anounnceUserId}
                                              teamOne={
                                                  refreshData
                                                      ? refreshData.team0
                                                      : null
                                              }
                                              teamFirst={
                                                  refreshData
                                                      ? refreshData.team1
                                                      : null
                                              }
                                              contractSuit={contractSuit}
                                              contractValue={contractValue}
                                              bazarFinishUserId={
                                                  bazarFinishUserId
                                              }
                                              refreshData={refreshData}
                                          />
                                      );
                                  })
                                : null}
                        </div>
                        {roundScoreData &&
                            roundScoreData.roundScore &&
                            roundData && (
                                <RoundScore
                                    data={roundScoreData}
                                    closeRoundScore={this.closeRoundScore}
                                    closeRoundGameEnd={this.closeRoundGameEnd}
                                    team={team}
                                />
                            )}

                        {(!bazar &&
                            firstPlay === null &&
                            bazarFinish !== null &&
                            bazarState !== -1 &&
                            !isContra &&
                            currentBazarUsers.includes(me.userId)) ||
                        (bazar &&
                            bazarFinish === null &&
                            firstPlay === null &&
                            !isContra &&
                            currentBazarUsers.includes(me.userId)) ? (
                            <BazarWindow
                                setBazar={this.setBazar}
                                isCapout={isCapout}
                                talk={talk}
                                bazarValue={bazarValue}
                                stateIndex={roomSingleton.bazarState}
                                changeBazarValue={(_bazarValue) =>
                                    this.setState({
                                        bazarValue: _bazarValue,
                                    })
                                }
                                bazarFinish={bazarFinish}
                                allowContra={allowContra}
                                bazarSuit={bazarSuit}
                                talkUserId={talkUserId}
                            />
                        ) : null}
                    </div>
                    <div className={'sending-gifts'}>
                        {sendingGifts && sendingGifts.length ? (
                            sendingGifts.map((giftData, index) => (
                                <SendGift
                                    fromUser={giftData.senderSeat}
                                    toUser={giftData.receiverSeat}
                                    userSeatOffsets={this.userSeatOffsets}
                                    giftId={giftData.giftId}
                                    removeGift={() => this.removeGift(index)}
                                    key={
                                        giftData.giftId +
                                        giftData.senderSeat +
                                        giftData.receiverSeat
                                    }
                                />
                            ))
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className={'static-gifts'}>
                        {staticGifts && staticGifts.length
                            ? staticGifts.map((giftId, seat) => {
                                  if (!giftId) {
                                      return <></>;
                                  }
                                  return (
                                      <StaticGift
                                          toUser={seat + 1}
                                          giftId={
                                              refreshData
                                                  ? giftId &&
                                                    giftId[0] &&
                                                    giftId[0].id
                                                  : giftId
                                          }
                                          key={giftId + '-' + seat}
                                      />
                                  );
                              })
                            : null}
                    </div>
                    {roundScoreData &&
                        roundScoreData.gameEnded &&
                        !waiting &&
                        roundGameEnded &&
                        replayData && (
                            <GameEnd
                                data={roundScoreData}
                                replayGame={() => this.replayGame()}
                                replayData={replayData}
                                leaveRoom={() => this.leaveRoom(replayData)}
                                leaveGame={() => this.leaveGame()}
                            />
                        )}
                    {waiting && <WaitingPlayers userInfo={me} />}

                    {/* <ExitGame /> */}
                    <div id={'game-play-cards'} ref={this.gamePlayRef} />
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        me: state.me.data,
        refreshData: state.welcomeUserCb.data,
        partnerIdData: state.userInfo.partnerId,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        setAllGifts: (gifts) => dispatch(setAllGifts(gifts)),
        setUsersInfo: (users) => dispatch(setUsersInfo(users)),
        navigate: (data) => dispatch(navigate(data)),
        welcomeUserRefresh: (data) => dispatch(welcomeUserRefresh(data)),
        setChip: (data) => dispatch(setChip(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GamePlay);
