import * as SocketCluster from 'socketcluster-es6-client';

import env from '../env';

class SocketBridge {
    connect(jwt) {
        return new Promise((resolve, reject) => {
            this.client = SocketCluster.connect({
                hostname: env.SOCKET_HOST,
                port: env.SOCKET_PORT,
                secure: true,
                rejectUnauthorized: true,
                query: {
                    token: jwt,
                },
            });

            this.client.once('connect', resolve);
            this.client.once('error', reject);
        });
    }

    setUserChannelNames(userId) {
        this.userChannelClientName = `user_${userId}_client`;
        this.userChannelServerName = `user_${userId}_server`;
    }

    setRoomChannelNames(roomId) {
        this.roomChannelClientName = `room_${roomId}_client`;
        this.roomChannelServerName = `room_${roomId}_server`;
    }

    async subscribeToUserChannel() {
        return new Promise((resolve) => {
            this.userChannel = this.client.subscribe(
                this.userChannelClientName
            );

            this.client.once('subscribe', resolve);
        });
    }

    async subscribeToRoomChannel() {
        return new Promise((resolve) => {
            this.roomChannel = this.client.subscribe(
                this.roomChannelClientName
            );
            this.client.once('subscribe', resolve);
        });
    }

    watchUserChannel(callback) {
        this.userChannel.watch(callback);
    }

    unwatchUserChannel(callback) {
        this.userChannel && this.userChannel.unwatch(callback);
    }

    watchRoomChannel(callback) {
        this.roomChannel.watch(callback);
    }

    unwatchRoomChannel() {
        if (this.roomChannel) {
            this.roomChannel.unwatch();
            this.roomChannel.unsubscribe();
        }
    }

    emitToUserChannel(data) {
        this.client.publish(this.userChannelServerName, data);
    }

    emitToRoomChannel(data) {
        this.client.publish(this.roomChannelServerName, data);
    }
    subscribeToTeamChannel(teamId) {
        if (this.client) {
            this.unsubscribeTeamGameChannel();

            this.teamGameChannel = this.client.subscribeToChannel(
                teamId + '_team_game_client'
            );
        }
    }

    unsubscribeTeamGameChannel() {
        if (this.teamGameChannel) {
            this.teamGameChannel.unsubscribe();
        }
    }
}

export default new SocketBridge();
