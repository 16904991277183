import React from 'react';
import { useDispatch } from 'react-redux';
import { red_popup, settings_close } from '../../../assets/image.assets';
import BackgroundPopup from '../../../components/background/background-popup/background-popup';
import ButtonTreeD from '../../../components/buttons/button_3d/button-3d';
import routesEnum from '../../../enums/routes.enum';
import { navigate } from '../../../redux/global.slice';
import { closePopup } from '../../../redux/popup.slice';
import './not-enough-red.scss';

const NotEnoughRed = () => {
    const dispatch = useDispatch();
    const buyNow = () => {
        dispatch(closePopup());
        dispatch(navigate(routesEnum.STORE));
    };
    const closeRedPopup = () => {
        dispatch(closePopup());
    };
    const getFree = () => {
        dispatch(closePopup());
        dispatch(navigate(routesEnum.STORE));
    };
    return (
        <div className="not-enough-red">
            <BackgroundPopup>
                <div className="close-icon" onClick={closeRedPopup}>
                    <img src={settings_close} alt="close" />
                </div>
                <div className="not-enough-block">
                    <div className="red-block">
                        <img src={red_popup} alt="#" />
                    </div>
                    <div className="red-block-text">
                        <p className="red-text-first">you have not enough</p>
                        <span className="red-text-second">red chips</span>
                        <p className="red-text-third">to play game</p>
                    </div>
                </div>
                <div className="vip_block_button">
                    <div className="vip-info-popup" onClick={getFree}>
                        <ButtonTreeD text="get free" />
                    </div>
                    <div className="vip-buy-now" onClick={buyNow}>
                        <ButtonTreeD text="buy now" />
                    </div>
                </div>
            </BackgroundPopup>
        </div>
    );
};
export default NotEnoughRed;
