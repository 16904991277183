import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import './loading.page.scss';

import routesEnum from '../../enums/routes.enum';
import themeEnum from '../../enums/theme.enum';
import loadersEnum from '../../enums/loaders.enum';

import { navigate, changeLocale, setInitiated } from '../../redux/global.slice';

import audioLoader from '../../loaders/audio.loader';
import imageLoader from '../../loaders/image.loader';
import pixiLoader from '../../loaders/pixi.loader';

import { switchTheme } from '../../helpers/theme.helper';

import LoadingBar from '../../components/bars/loading/loading.bar';
import { getUser } from '../../redux/me.slice';
import socketBridge from '../../socket';
import LoadProgressHelper from '../../helpers/loadProgress.helper';
import {
    UserChannelEmittingEvents,
    UserChannelEmittingEventsName,
    UserChannelHandlingEvents,
} from '../../socket/socket.events';
import { bazar_blot_logo } from '../../assets/image.assets';
import { welcomeUserRefresh } from '../../redux/welcome.user.cb.slice';
import roomSingleton from '../../singletons/room.singleton';
import userSingleton from '../../singletons/user.singleton';
import { getCookie } from '../../helpers/cookie.helpers';

const LoadingPage = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const me = useSelector((state) => state.me);
    const refreshData = useSelector((state) => state.welcomeUserCb.data);

    const [loadProgress, setLoadProgress] = useState(0);
    const [socketState, setSocketState] = useState(1);
    // audioLoader.play()
    function loadAssets() {
        const loaders = [
            imageLoader.loadImages.bind(imageLoader),
            pixiLoader.load.bind(pixiLoader),
            audioLoader.loadAudios.bind(audioLoader),
        ];

        const loadProgressHelper = new LoadProgressHelper(loaders.length * 100);

        loaders.forEach((loadFunc) => loadFunc.call());

        pixiLoader.loader.onProgress.add((data) => {
            setLoadProgress(
                loadProgressHelper.calculateProgress(
                    Math.ceil(data.progress),
                    0
                )
            );
        });

        audioLoader.emitter.on(loadersEnum.AUDIO_LOAD_PROGRESS, (data) => {
            setLoadProgress(
                loadProgressHelper.calculateProgress(data.progress, 1)
            );
        });

        imageLoader.emitter.on(loadersEnum.IMAGE_LOAD_PROGRESS, (data) => {
            setLoadProgress(
                loadProgressHelper.calculateProgress(data.progress, 2)
            );
        });
    }

    function onLanguageChange(e) {
        dispatch(changeLocale(e.target.value));
    }

    function loadingInfo() {
        if (loadProgress < 100) {
            return `Loading Assets: ${parseInt(loadProgress, 0)}`;
        }
        if (loadProgress === 100 && me.data && socketState === 2) {
            dispatch(navigate(routesEnum.LANGUAGE));
        }

        if (me.error) {
            return me.error;
        }

        if (!me.data) {
            return 'Getting user data';
        }

        switch (socketState) {
            case 1: {
                return 'Connecting to Socket';
            }
            case 2: {
                return 'Connected to Socket';
            }
            case 3: {
                return 'Socket connection Error';
            }
            default:
                return '';
        }
    }

    useEffect(() => {
        loadAssets();
    }, []);
    const langCookie = getCookie('locale');

    useEffect(() => {
        if (socketState === 2) {
            if (langCookie) {
                dispatch(navigate(routesEnum.LOBBY));
            } else {
                dispatch(navigate(routesEnum.LANGUAGE));
            }
        }
    }, [socketState]);
    useEffect(() => {
        if (loadProgress === 100) {
            dispatch(getUser());
        }
    }, [loadProgress, dispatch]);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (me.data) {
            userSingleton.jwt = me.data.jwt;
            userSingleton.userId = me.data.userId;

            function watcher({ event, ...args }) {
                switch (event) {
                    case UserChannelHandlingEvents.WELCOME_USER_CB:
                        dispatch(welcomeUserRefresh(args));

                        roomSingleton.roomId =
                            args && args.data && args.data.roomId;
                        socketBridge.setRoomChannelNames(roomSingleton.roomId);
                        socketBridge.subscribeToRoomChannel(
                            roomSingleton.roomId
                        );

                        roomSingleton.bazarState =
                            args && args.data && args.data.bazarState;
                        if (args.data) {
                            dispatch(navigate(routesEnum.GAMEPLAY));
                        }
                        break;
                    case UserChannelHandlingEvents.ALLOW_PLAY:
                        break;
                }
            }

            async function connectToSocket() {
                try {
                    await socketBridge.connect(me.data.jwt);
                    setSocketState(2);

                    socketBridge.setUserChannelNames(me.data.userId);
                    await socketBridge.subscribeToUserChannel();

                    socketBridge.watchUserChannel(watcher);

                    dispatch(setInitiated(true));

                    socketBridge.emitToUserChannel({
                        event: UserChannelEmittingEvents.WELCOME,
                        eventName: UserChannelEmittingEventsName.WELCOME,
                    });
                } catch (e) {
                    setSocketState(3);
                }
            }

            connectToSocket();

            return () => {
                socketBridge.unwatchUserChannel(watcher);
            };
        }
    }, [me.data]);

    const welcomeGamePlay = () => {
        socketBridge.emitToUserChannel({
            event: UserChannelEmittingEvents.ALLOW_PLAY,
            eventName: UserChannelEmittingEventsName.ALLOW_PLAY,
        });
    };
    return (
        <div id="loading-page" className="page">
            <div className="loading_wrapper">
                <img
                    src={bazar_blot_logo}
                    alt="candy wings logo"
                    className="logo"
                />
                <LoadingBar progress={loadProgress} />
            </div>
            <div className="language_bg_border"></div>
            <div className="loading_bg"></div>
        </div>
    );
};

export default LoadingPage;
