import React from 'react';
import { pencil } from '../../../../assets/image.assets';
import './edit.icon.scss';

const EditIcon = ({ _onClick = () => {} }) => {
    return (
        <div className={'edit-icon'} onClick={_onClick}>
            <div className="edit-icon-inner">
                <img src={pencil} alt="pencil" />
            </div>
        </div>
    );
};

export default EditIcon;
