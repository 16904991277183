import React from 'react';
import { useIntl } from 'react-intl/lib';

import './battle-item.scss';

import {
    gold_chip,
    lock_icon,
    red_chip,
    star_with_back,
} from '../../../assets/image.assets';
import _3dButton from '../../../components/team-game/buttons/3d-button/3d-button';
import SecondaryLabel from '../../../components/team-game/labels/secondary-label/secondary-label';
import StandardLabel from '../../../components/team-game/labels/standard-label/standard-label';

const getBattleTranslation = (roomType, intl) => {
    switch (roomType) {
        case 'micro':
            return intl.messages.team_game.micro_game;
        case 'big':
            return intl.messages.team_game.big_game;
        case 'professional':
            return intl.messages.team_game.professional;
        default:
            return '';
    }
};

const getChipImageByCurrency = (currency) => {
    switch (currency) {
        case 'red':
            return red_chip;
        case 'gold':
        case 'yellow':
            return gold_chip;
        default:
            return red_chip;
    }
};

const BattleItem = ({ item = {}, _onClick = () => {}, lockedLevel = 0 }) => {
    const intl = useIntl();
    return (
        <div className={'battle-item'}>
            <div className="battle-item-image-wrapper">
                <img
                    src={item.imageUrl}
                    alt="menu item"
                    className={'menu-item-image'}
                />
                {item.stars ? (
                    <div className="additional-stars">
                        <div className={'additional-data'}>
                            <span className={'stars-count'}>
                                <span className={'darker'}>+</span>
                                {item.stars}
                            </span>
                            <SecondaryLabel
                                text={intl.messages.additional}
                                fontSize={12}
                            />
                        </div>
                        <img
                            src={star_with_back}
                            alt="star"
                            className={'star-hex'}
                        />
                    </div>
                ) : (
                    <></>
                )}
            </div>
            <div className="battle-name-wrapper">
                <StandardLabel
                    text={getBattleTranslation(item.roomType, intl)}
                />
            </div>
            <div className={'bet-count'}>
                <img src={getChipImageByCurrency(item.currency)} alt="chip" />
                <SecondaryLabel
                    text={intl.formatMessage(
                        {
                            id: 'bet_chips',
                        },
                        {
                            chips: item.amount,
                        }
                    )}
                />
            </div>
            {lockedLevel ? (
                <div className={'locked-level'}>
                    <img
                        src={lock_icon}
                        alt="lock icon"
                        className={'lock-icon'}
                    />
                    <StandardLabel
                        text={'Unlock on Level ' + lockedLevel}
                        fontSize={15}
                    />
                </div>
            ) : (
                <div className={'play-button-wrapper'}>
                    <_3dButton
                        type={3}
                        text={intl.messages.team_game.play}
                        _onClick={_onClick}
                    />
                </div>
            )}
        </div>
    );
};

export default BattleItem;
