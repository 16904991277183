export const daysDifference = (date1, date2) => {
    const ONE_DAY = 1000 * 60 * 60 * 24;

    const date1_ms = date1.getTime();
    const date2_ms = date2.getTime();

    const difference_ms = Math.abs(date1_ms - date2_ms);

    return Math.round(difference_ms / ONE_DAY);
};
