import React, { useEffect, useState, createRef } from 'react';
import { useIntl } from 'react-intl';

import './choose-team-logo-section.scss';

import { FormattedMessage } from 'react-intl/lib';

import {
    active_random,
    gold_chips,
    gold_team,
    inactive_random,
} from '../../../assets/image.assets';
import StandardLabel from '../../../components/team-game/labels/standard-label/standard-label';
import UniversalShield from '../../../components/team-game/shields/universalShield/universal.shield';
import FloatingTabChanger from '../../../components/team-game/tab-changers/floating-tab-changer/floating-tab-changer';
import SecondaryLabel from '../../../components/team-game/labels/secondary-label/secondary-label';
import _3dButton from '../../../components/team-game/buttons/3d-button/3d-button';
import {
    iconsList_req,
    purchaseIconSet_req,
    validateCreateTeam_req,
} from '../../../api/team.game/team.game.api';
import createTeamEnum from '../../../enums/team-game/createTeam.enum';
import { joinErrorCodes } from '../../../enums/team-game/errors.enum';
import { useDispatch, useSelector } from 'react-redux';
import PopupsEnum from '../../../enums/popups.enum';
import routesEnum from '../../../enums/routes.enum';
import { openPopups } from '../../../redux/popup.slice';
import { navigate } from '../../../redux/global.slice';
import userSingleton from '../../../singletons/user.singleton';

const divideToLines = (icons) => {
    const iconsInRow = 6;
    const divided = [];

    for (let i = 0; i < icons.length; i += iconsInRow) {
        divided.push(icons.slice(i, i + iconsInRow));
    }

    return divided;
};

const getTabIcons = (sections, configs) => {
    return sections.map(
        (section) => configs.iconBaseUrl + 'icon-' + section.setIcon + '.svg'
    );
};

const getAvailableIcons = (sections) => {
    let availableIcons = [];

    for (const section of sections) {
        if (section.purchased) {
            availableIcons = availableIcons.concat(section.icons);
        }
    }

    return availableIcons;
};

const translateSectionName = (sectionName, intl) => {
    let id = '';
    switch (sectionName) {
        case 'main-set':
            id = 'main_icon_set';
            break;
        case 'brutal-set':
            id = 'brutal_set';
            break;
        case 'gentleman-set':
            id = 'gentleman';
            break;
        case 'cool-set':
            id = 'cool_stuff';
            break;
    }

    return intl.formatMessage({ id });
};

const ChooseTeamLogoSection = ({
    pageData,
    changePage,
    leftShowState,
    rightShowState,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const logoChoseIcons = useSelector((state) => state.global.navigateData);
    const navigateData = useSelector((state) => state.global.navigateData.data);
    const [highlighted, setHighlighted] = useState(1);

    const [randomActivated, setRandomActivated] = useState(false);
    const [showState, setShowState] = useState(0);
    const [chosenIcons, setChosenIcons] = useState(
        logoChoseIcons?.chosenIcons || []
    );
    const [iconTab, setIconTab] = useState(0);
    const [iconSections, setIconSections] = useState([]);
    const [shakingButton, setShackingButton] = useState(-1);
    const teamInfo = useSelector((state) => state.teamGame.teamInfo);

    const configs = useSelector((state) => state.teamGame.configs);
    const teamName = userSingleton.teamName;

    const scrollRef = createRef();
    const iconSectionRefs = iconSections.map(() => createRef());
    const myUserInfo = useSelector((state) => state.me.data);
    const getIcons = async () => {
        const icons = await iconsList_req();
        setIconSections(Object.values(icons));
    };

    useEffect(() => {
        setShowState(1);
        getIcons();
    }, []);

    const chooseRandom = () => {
        setRandomActivated(true);
        setHighlighted(0);

        const availableIcons = getAvailableIcons(iconSections);

        const randomized = [];
        for (let i = 0; i < 3; i++) {
            const randomIndex = Math.floor(
                Math.random() * availableIcons.length
            );
            const randomId = availableIcons[randomIndex];

            randomized.push({
                icon: randomId,
                url: configs.iconBaseUrl + 'icon-' + randomId + '.svg',
            });

            availableIcons.splice(randomIndex, 1);
        }

        setChosenIcons(randomized);
        return randomized;
    };

    const highlightShield = (side) => {
        setRandomActivated(false);
        setHighlighted(side);
    };

    const chooseIcon = (iconId) => {
        const _chosenIcons = [...chosenIcons];
        _chosenIcons[highlighted - 1] = {
            icon: iconId,
            url: configs.iconBaseUrl + 'icon-' + iconId + '.svg',
        };

        setChosenIcons(_chosenIcons);
    };

    const prev = () => {
        setShowState(0);
        setTimeout(() => {
            changePage(1, {
                teamName: teamInfo.name,
            });
        }, 300);
    };

    const _cancelAction = () => {
        dispatch(
            openPopups({
                popupIds: [PopupsEnum.PROMPT_POPUP],
                popupData: [
                    {
                        title: intl.formatMessage({
                            id: 'discard_changes',
                        }),
                        text: intl.formatMessage({
                            id:
                                pageData &&
                                pageData.data &&
                                pageData.data._cancelAction
                                    ? 'do_you_really_want_to_cancel'
                                    : 'do_you_really_want_to_cancel_team_creation',
                        }),
                        positiveText: intl.messages.team_game.yes,
                        negativeText: intl.messages.team_game.no,
                        _positiveAction: () => {
                            if (
                                pageData &&
                                pageData.data &&
                                pageData.data._cancelAction
                            ) {
                                pageData.data._cancelAction();
                            } else {
                                dispatch(navigate(routesEnum.JOIN_TEAM));
                            }
                        },
                    },
                ],
            })
        );
    };

    const next = async () => {
        let notEnough = false;
        const _chosenIcons = [...chosenIcons];

        if (chosenIcons.length !== 3) {
            notEnough = true;

            const availableIcons = getAvailableIcons(iconSections);
            const existingLength = _chosenIcons.length;

            for (let i = 0; i < 3 - existingLength; i++) {
                const randomIndex = Math.floor(
                    Math.random() * availableIcons.length
                );
                const randomId = availableIcons[randomIndex];

                _chosenIcons.push({
                    icon: randomId,
                    url: configs.iconBaseUrl + 'icon-' + randomId + '.svg',
                });

                availableIcons.splice(randomIndex, 1);
            }
        }

        const successAction = () => {
            setChosenIcons(_chosenIcons);
            setShowState(2);
            if (navigateData) {
                setTimeout(() => {
                    dispatch(
                        navigate({
                            route: routesEnum.MORE_INFO_SECTION,
                            navigateData: {
                                chosenIcons: _chosenIcons,
                            },
                        })
                    );
                }, 300);
            } else {
                setTimeout(() => {
                    dispatch(
                        navigate({
                            route: routesEnum.JOINING_CONDITION,
                            navigateData: {
                                chosenIcons: _chosenIcons,
                            },
                        })
                    );
                }, 300);
            }
        };

        const validate = async () => {
            return await validateCreateTeam_req(
                createTeamEnum.logo,
                (notEnough ? _chosenIcons : chosenIcons).map(
                    (data) => data.icon
                )
            );
        };

        try {
            const isValid = await validate();

            if (isValid && isValid.success) {
                successAction();
            }
        } catch (e) {
            notEnough = false;
            chooseRandom();
            setTimeout(async () => {
                const isValid = await validate();
                if (isValid && isValid.success) {
                    successAction();
                }
            }, 1000);
        }
    };

    const chooseTab = (tabIndex) => {
        const section = iconSectionRefs[tabIndex];

        if (section && section.current) {
            section.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };

    const handleIconsScroll = () => {
        if (scrollRef && scrollRef.current) {
            const scrollTop = scrollRef.current.scrollTop;
            let neededItem = 0;
            iconSectionRefs.forEach((sectionRef, index) => {
                if (sectionRef && sectionRef.current) {
                    const scrollTopSection = sectionRef.current.offsetTop;

                    if (scrollTop >= scrollTopSection - 111) {
                        neededItem = index;
                    }
                }
            });

            if (
                scrollRef.current.scrollHeight - scrollTop ===
                scrollRef.current.clientHeight
            ) {
                neededItem = iconSectionRefs.length - 1;
            }

            if (iconTab !== neededItem) {
                setIconTab(neededItem);
            }
        }
    };

    const shakeButton = (sectionIndex) => {
        setShackingButton(sectionIndex);

        setTimeout(() => {
            setShackingButton(-1);
        }, 1000);
    };

    const openBuyGold = () => {
        dispatch(
            openPopups({
                popupIds: [PopupsEnum.NOT_ENOUGH_GOLD],
                popupData: [
                    {
                        type: 'yellow',
                        text: intl.formatMessage({
                            id: 'you_need_more_gold_chips_to_buy_icon_set',
                        }),
                        boldText: intl.formatMessage({
                            id: 'buy_now',
                        }),
                    },
                ],
            })
        );
    };

    const buyIconSet = async (section) => {
        if (
            myUserInfo &&
            myUserInfo.yellow &&
            myUserInfo.yellow >= section.price
        ) {
            try {
                await purchaseIconSet_req(section.setId);
                getIcons();
            } catch (e) {
                if (e && e.response && e.response.data) {
                    const { errorCode } = e.response.data;

                    switch (errorCode) {
                        case joinErrorCodes.NOT_ENOUGH_CURRENCY:
                            openBuyGold();
                            break;
                    }
                }
            }
        } else {
            openBuyGold();
        }
    };

    return (
        <div className={'choose-team-logo divided-view-content'}>
            <div className="left-side-wrapper">
                <div className={'left-side' + leftShowState(showState)}>
                    <div className="text-wrapper">
                        <StandardLabel
                            text={intl.formatMessage({
                                id: 'create_your_team_logo',
                            })}
                        />
                        <span className={'hint-text'}>
                            <FormattedMessage id={'choose_area_to_set_icon'} />
                        </span>
                    </div>
                    <div className="highlight-buttons">
                        {/*<_3dButton*/}
                        {/*    icon={*/}
                        {/*        highlighted === 1*/}
                        {/*            ? ActiveLeftImg*/}
                        {/*            : InActiveLeftImg*/}
                        {/*    }*/}
                        {/*    type={highlighted === 1 ? 1 : 2}*/}
                        {/*    _onClick={() => highlightShield(1)}*/}
                        {/*/>*/}
                        {/*<_3dButton*/}
                        {/*    icon={*/}
                        {/*        highlighted === 2*/}
                        {/*            ? ActiveRightImg*/}
                        {/*            : InActiveRightImg*/}
                        {/*    }*/}
                        {/*    type={highlighted === 2 ? 1 : 2}*/}
                        {/*    _onClick={() => highlightShield(2)}*/}
                        {/*/>*/}
                        {/*<_3dButton*/}
                        {/*    icon={*/}
                        {/*        highlighted === 3*/}
                        {/*            ? ActiveBottomImg*/}
                        {/*            : InActiveBottomImg*/}
                        {/*    }*/}
                        {/*    type={highlighted === 3 ? 1 : 2}*/}
                        {/*    _onClick={() => highlightShield(3)}*/}
                        {/*/>*/}
                    </div>
                    <div className={'choose-team-shield-wrapper'}>
                        <img
                            src={
                                randomActivated
                                    ? active_random
                                    : inactive_random
                            }
                            alt="random"
                            className={'random-image'}
                            onClick={chooseRandom}
                        />

                        <UniversalShield
                            highlighted={highlighted}
                            text={teamName ? teamName : teamInfo.name}
                            icons={
                                chosenIcons
                                    ? chosenIcons.map((data) => data.url)
                                    : logoChoseIcons?.chosenIcons?.map(
                                          (data) => data.url
                                      )
                            }
                            onHighlightClick={highlightShield}
                        />
                    </div>
                </div>
            </div>
            <div className="right-side-wrapper">
                <div className={'right-side' + rightShowState(showState)}>
                    <div className="floating-tabs-wrapper">
                        <FloatingTabChanger
                            currentTab={iconTab}
                            setCurrentTab={chooseTab}
                            imageTabs={getTabIcons(iconSections, configs)}
                        />
                    </div>
                    <div
                        ref={scrollRef}
                        className="icons-content"
                        onScroll={handleIconsScroll}
                    >
                        {iconSections.map((section, index) => {
                            return (
                                <div
                                    key={section.name}
                                    ref={iconSectionRefs[index]}
                                    className="icon-section"
                                >
                                    <div className="icon-namings">
                                        <div className="left-namings">
                                            <StandardLabel
                                                text={translateSectionName(
                                                    section.name,
                                                    intl
                                                )}
                                                fontSize={18}
                                            />
                                            {!section.price ? (
                                                <SecondaryLabel
                                                    text={intl.formatMessage({
                                                        id: 'free',
                                                    })}
                                                />
                                            ) : (
                                                <span className={'premium'}>
                                                    <FormattedMessage
                                                        id={'premium'}
                                                    />
                                                </span>
                                            )}
                                        </div>
                                        {section.price > 0 &&
                                            !section.purchased && (
                                                <div
                                                    className={
                                                        'buy-icons' +
                                                        (shakingButton === index
                                                            ? ' shaking'
                                                            : '')
                                                    }
                                                >
                                                    <img
                                                        src={gold_team}
                                                        alt="gold chips"
                                                    />
                                                    <div className="price-wrapper">
                                                        <StandardLabel
                                                            text={section.price}
                                                            fontSize={18}
                                                        />
                                                        <SecondaryLabel
                                                            text={intl.formatMessage(
                                                                {
                                                                    id: 'price',
                                                                }
                                                            )}
                                                            fontSize={14}
                                                        />
                                                    </div>

                                                    <_3dButton
                                                        text={intl.formatMessage(
                                                            {
                                                                id: 'buy_now',
                                                            }
                                                        )}
                                                        _onClick={() =>
                                                            buyIconSet(section)
                                                        }
                                                    />
                                                </div>
                                            )}
                                    </div>
                                    <div
                                        className={
                                            'icon-listing' +
                                            (!section.purchased
                                                ? ' not-purchased'
                                                : '')
                                        }
                                    >
                                        {divideToLines(section.icons).map(
                                            (iconLine, lineKey) => {
                                                return (
                                                    <div
                                                        className="listing-line"
                                                        key={
                                                            'icon-line-key-' +
                                                            lineKey
                                                        }
                                                    >
                                                        {iconLine.map(
                                                            (icon, iconKey) => {
                                                                const disabled =
                                                                    chosenIcons.findIndex(
                                                                        (
                                                                            data
                                                                        ) => {
                                                                            if (
                                                                                data
                                                                            ) {
                                                                                return (
                                                                                    data.icon ===
                                                                                    icon
                                                                                );
                                                                            }

                                                                            return false;
                                                                        }
                                                                    ) !== -1;

                                                                return (
                                                                    <div
                                                                        className={
                                                                            'icon-wrapper' +
                                                                            (disabled
                                                                                ? ' disabled'
                                                                                : '')
                                                                        }
                                                                        key={
                                                                            'icon-' +
                                                                            iconKey
                                                                        }
                                                                        onClick={() => {
                                                                            if (
                                                                                section.purchased &&
                                                                                !disabled
                                                                            ) {
                                                                                chooseIcon(
                                                                                    icon
                                                                                );
                                                                            } else {
                                                                                shakeButton(
                                                                                    index
                                                                                );
                                                                            }
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                configs.iconBaseUrl +
                                                                                'icon-' +
                                                                                icon +
                                                                                '.svg'
                                                                            }
                                                                            alt="icon"
                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="buttons-wrapper">
                        <_3dButton
                            text={intl.formatMessage({ id: 'cancel' })}
                            type={2}
                            _onClick={_cancelAction}
                        />
                        <_3dButton
                            text={intl.formatMessage({
                                id: 'save_and_continue',
                            })}
                            _onClick={
                                pageData &&
                                pageData.data &&
                                pageData.data._saveAction
                                    ? () => {
                                          pageData.data._saveAction(
                                              chosenIcons
                                          );
                                      }
                                    : next
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

// const mapDispatchToProps = (dispatch) => {
//     return {
//         navigate: (routeData) => dispatch(routerActions.navigate(routeData)),
//         openPopups: (popupData) =>
//             dispatch(popupsActions.openPopups(popupData)),
//     };
// };

export default ChooseTeamLogoSection;

// connect((state) => {
//     return {
//         configs: state.teamGame.configs,
//         myUserInfo: state.teamGame.myUserInfo,
//     };
// }, mapDispatchToProps)(ChooseTeamLogoSection);
