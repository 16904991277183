import React from 'react';

import './universal-table.scss';

const UniversalTable = ({ children }) => {
    return (
        <div className={'universal-table'}>
            <table>{children}</table>
        </div>
    );
};

export default UniversalTable;
