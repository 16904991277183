import React from 'react';
import PropTypes from 'prop-types';

import './loading.bar.scss';
import { loading } from '../../../assets/image.assets';

function LoadingBar({ progress }) {
    return (
        <div className="loading_bar">
            <div className="loading_block">
                <div
                    className="loading_bar_fill"
                    style={{
                        width: `${progress - 1}%`,
                    }}
                />
            </div>
            <span>Loading</span>
        </div>
    );
}

LoadingBar.propTypes = {
    progress: PropTypes.number,
};

export default LoadingBar;
