import React from 'react';
import { useIntl } from 'react-intl';

import './lock-button.scss';

import StandardLabel from '../../labels/standard-label/standard-label';
import { lock_icon } from '../../../../assets/image.assets';

const LockButton = ({ _onClick = () => {} }) => {
    const intl = useIntl();
    return (
        <div className={'lock-button'} onClick={_onClick}>
            <img src={lock_icon} alt="lock icon" />
            <div className="text-wrapper">
                <StandardLabel
                    text={intl.formatMessage(
                        {
                            id: 'unlock_on_level',
                        },
                        {
                            level: 20,
                        }
                    )}
                    fontSize={15}
                />
                <span className={'unlock-info'}>
                    {intl.formatMessage(
                        {
                            id: 'get_access_all_league_features',
                        },
                        {
                            level: 20,
                        }
                    )}
                </span>
            </div>
            <div className="action-space">
                <StandardLabel text={intl.messages.close} fontSize={15} />
            </div>
        </div>
    );
};

export default LockButton;
